import * as constants from './constants'
import filters from './filters'
import tabs from './tabs'
import itemSettings from './itemSettings'
import listSettings from './listSettings'
import subtitles from './subtitles'
import images from './images'

const storeSettingSubtitle = setting => {
  return `These ${setting} settings will be applied to any revenue centers that do not have ${setting} settings of their own. That is, if you set ${setting} settings at the revenue center level, they will OVERRIDE the settings here.`
}

const revenueCenterSettingSubtitle = setting => {
  return `Use this page to update ${setting} settings for this revenue center. These settings will be inherited from this revenue center's parent store if NOT set here (assuming they're set at the store level).`
}

export const fields = {
  categories: [
    {
      name: 'Name',
      field: ['full_name'],
      link: 'category_id',
      linkEndpoint: '/categories'
    },
    { name: 'Quick Links', field: ['links'], format: 'iconLinks' },
    // {
    //   name: 'Menu Items',
    //   field: ['edit menu items', 'items'],
    //   link: 'category_id',
    //   linkEndpoint: '/categories',
    //   format: 'path'
    // },
    { name: 'Internal Name', field: ['short_name'] },
    { name: 'Order Type', field: ['order_type'], format: 'enum' },
    {
      name: 'Menu',
      field: ['menus.menu.name'],
      format: 'array'
    },
    {
      name: 'Section',
      field: ['pos_section.name']
    },
    {
      name: 'Order',
      field: ['pos_display_order']
    },
    {
      name: 'POS Color',
      field: ['pos_display_color'],
      format: 'swatch'
    },
    { name: 'Active', field: ['is_active'], format: 'bool' },
    // { name: 'Menu Position', field: ['menu_position'] },
    // { name: 'Parent ID', field: ['parent_category_id'] },
    { name: 'ID', field: ['category_id'] }
  ],
  modifierGroups: [
    {
      name: 'Internal Name',
      field: ['short_name'],
      link: 'modifier_group_id'
    },
    { name: 'Name', field: ['full_name'] },
    { name: 'Quick Links', field: ['links'], format: 'iconLinks' },
    {
      name: 'Menu',
      field: ['menus.menu.name'],
      format: 'array'
    },
    { name: 'Is Size', field: ['is_size'], format: 'bool' },
    {
      name: 'POS Color',
      field: ['pos_display_color'],
      format: 'swatch'
    },
    { name: 'Active', field: ['is_active'], format: 'bool' },
    { name: 'ID', field: ['modifier_group_id'] }
  ],
  items: [
    {
      name: 'Name',
      field: ['full_name'],
      link: 'item_id',
      linkEndpoint: '/items'
    },
    { name: 'Quick Links', field: ['links'], format: 'iconLinks' },
    { name: 'Active', field: ['is_active'], format: 'bool' },
    { name: 'Internal Name', field: ['short_name'] },
    // { name: 'Shorthand', field: ['shorthand'] },
    // {
    //   name: 'Categories',
    //   field: ['categories.category.short_name'],
    //   format: 'array'
    // },
    // {
    //   name: 'Modifier Groups',
    //   field: ['modifier_groups.modifier_group.short_name'],
    //   format: 'array'
    // },
    {
      name: 'POS Color',
      field: ['pos_display_color'],
      format: 'swatch'
    },
    { name: 'Tags', field: ['tags.tag.name'], format: 'array' },
    {
      name: 'Allergens',
      field: ['allergens.allergen.name'],
      format: 'array'
    },
    // { name: 'Menu Position', field: ['menu_position'] },
    { name: 'ID', field: ['item_id'] }
  ],
  address: [
    { label: 'Street', field: 'street', type: 'text', required: true },
    {
      label: 'Cross Streets',
      field: 'cross_streets',
      type: 'text',
      classes: '-half',
      required: true
    },
    {
      label: 'City',
      field: 'city',
      type: 'text',
      classes: '-half -right',
      required: true
    },
    {
      label: 'State',
      field: 'state',
      type: 'select',
      classes: '-half',
      options: constants.statesProvinces,
      required: true
    },
    {
      label: 'Zip Code',
      field: 'postal_code',
      type: 'text',
      classes: '-half -right',
      required: true
    },
    { label: 'Phone', field: 'phone', type: 'text', classes: '-half' },
    {
      label: 'Fax',
      field: 'fax',
      type: 'text',
      classes: '-half -right'
    },
    { label: 'Directions Link', field: 'directions_link', type: 'text' },
    {
      field: 'latitude',
      type: 'hidden'
    },
    {
      field: 'longitude',
      type: 'hidden'
    }
  ],
  gratuity: [
    {
      label: 'Service Types with Gratuity Collection',
      field: 'service_type',
      type: 'select',
      options: constants.gratuityServiceTypes,
      defaultValue: 'NEITHER'
    },
    {
      label: 'Use Standard Increments',
      field: 'increments',
      type: 'checkbox'
    },
    {
      label: 'Suggested Gratuity Percentage',
      field: 'percentage',
      type: 'text',
      valType: constants.valTypes.decimal
    },
    {
      label: 'Maximum Dollar Amount',
      field: 'maximum',
      type: 'text',
      valType: constants.valTypes.decimal
    },
    {
      label: 'Minimum Dollar Amount',
      field: 'minimum',
      type: 'text',
      valType: constants.valTypes.decimal
    }
  ],
  email: [
    {
      label: 'Confirmation Emails Recipients',
      field: 'bcc_emails',
      type: 'text'
    },
    {
      label: 'Notification Emails Recipients',
      field: 'notification_emails',
      type: 'text'
    },
    {
      label: 'Sender Name',
      field: 'sender_name',
      type: 'text',
      classes: '-half'
    },
    {
      label: 'Sender Email',
      field: 'sender_email',
      type: 'text',
      classes: '-half -right'
    },
    {
      label: 'Use Sender for Confirmation Emails',
      field: 'confirmation_emails',
      type: 'checkbox'
    },
    {
      label: 'Use Sender for Resent Emails',
      field: 'resent_emails',
      type: 'checkbox'
    }
  ],
  throttle: [
    {
      label: 'Pickup Dollar Amount per Time Slot',
      field: 'dollar_amount',
      type: 'text',
      classes: '-half',
      valType: constants.valTypes.decimal
    },
    {
      label: 'Pickup Orders per Time Slot',
      field: 'orders_amount',
      type: 'text',
      classes: '-half -right',
      valType: constants.valTypes.int,
      defaultValue: ''
    },
    {
      label: 'Delivery Dollar Amount per Time Slot',
      field: 'dollar_amount_delivery',
      type: 'text',
      classes: '-half',
      valType: constants.valTypes.decimal
    },
    {
      label: 'Delivery Orders per Time Slot',
      field: 'orders_amount_delivery',
      type: 'text',
      classes: '-half -right',
      valType: constants.valTypes.int,
      defaultValue: ''
    },
    {
      label: 'Is Active',
      field: 'is_active',
      type: 'checkbox'
    },
    {
      label: 'Throttle By Service Type',
      field: 'throttle_by_service_type',
      type: 'checkbox',
      tooltip:
        'If checked, pickup orders will be throttled separately from delivery orders.'
    }
  ],
  credit: [
    {
      label: 'Payment Processor',
      field: 'integration',
      type: 'select',
      options: constants.creditIntegrations,
      required: true
    },
    {
      label: 'Testing Only, NOT Processing Real Payments',
      field: 'is_testing',
      type: 'checkbox'
    },
    {
      label: 'Allow Customers to Save Credit Cards',
      field: 'saved_cards',
      type: 'checkbox'
    },
    {
      label: 'Merchant ID',
      field: 'merchant_id',
      type: 'text',
      classes: '-half'
    },
    {
      label: 'Password',
      field: 'merchant_password',
      type: 'text',
      classes: '-half -right',
      tooltip: "This is only relevant if you're using Worldpay."
    },
    {
      label: 'API Key',
      field: 'merchant_api_key',
      type: 'text',
      tooltip: "This is only relevant if you're using the Open Tender Gateway."
    },
    // {
    //   label: 'Username',
    //   field: 'merchant_username',
    //   type: 'text',
    //   classes: '-half',
    //   tooltip: "This is only sometimes relevant if you're using the Open Tender Gateway."
    // },
    { field: 'merchant_username', type: 'hidden' },
    {
      label: 'Offline Transaction Limit',
      field: 'offline_limit',
      type: 'text',
      classes: '-half',
      valType: constants.valTypes.decimal,
      tooltip: 'Only relevant for POS transactions.'
    },
    {
      label: 'Signature Threshold',
      field: 'signature_threshold',
      type: 'select',
      options: constants.signatureThresholds,
      classes: '-half -right',
      tooltip: 'Only relevant for POS transactions.',
      valType: constants.valTypes.nullable
    }
  ],
  externalPOS: [
    {
      label: 'External POS Integration',
      field: 'integration',
      type: 'select',
      options: constants.posIntegrations,
      defaultValue: 'NONE'
    },
    {
      label: 'Order Send Timing',
      field: 'pos_stage',
      type: 'select',
      options: constants.posSendTiming,
      defaultValue: 'PLACED'
    },
    {
      label: 'ItsaCheckmate Environment (ignore for other integrations)',
      field: 'checkmate_toos_env',
      type: 'select',
      options: constants.checkmateEnv,
      defaultValue: 'PRODUCTION'
    },
    {
      label: 'Restaurant ID',
      field: 'pos_location_id',
      type: 'text',
      tooltip: 'This is the ID of this restaurant in your POS system.'
    },
    {
      label: 'Station ID or Amazon S3 Bucket',
      field: 'pos_station_id',
      type: 'text',
      tooltip:
        'For Toast, this is the Amazon S3 bucket for this restaurant. For Revel, this is the ID of the POS terminal where you want orders to show up.'
    },
    {
      label: 'User ID or Menu GUIDs (optional)',
      field: 'pos_user_id',
      type: 'text',
      tooltip:
        'For Toast, you can enter one or more comma-separated menu GUIDs if you only want to sync specific menus in Toast (leave blank if you want to sync all). For Revel, this is the ID of a user that has access to this restaurant.'
    },
    {
      label: 'POS Server URL (Rpower only)',
      field: 'pos_url',
      type: 'text',
      tooltip: 'This is only relevant for Rpower.'
    },
    {
      label: 'Testing vs. POS sandbox',
      field: 'is_testing',
      type: 'checkbox'
    },
    {
      label: 'Automatically sync menus each night',
      field: 'auto_sync',
      type: 'checkbox',
      tooltip: 'Only relevant for Salido.'
    },
    {
      label: 'Use name, description, and image from POS',
      field: 'use_meta',
      type: 'checkbox',
      tooltip: 'Only relevant for Toast right now'
    },
    {
      field: 'resend_orders',
      type: 'hidden',
      valType: constants.valTypes.bool
    },
    {
      field: 'sync_rc_hours',
      type: 'hidden',
      valType: constants.valTypes.bool
    },
    {
      field: 'use_asap',
      type: 'hidden',
      valType: constants.valTypes.bool
    }
  ],
  integrations: [
    {
      label: 'LevelUp Location ID',
      field: 'levelup_location_id',
      type: 'text',
      valType: constants.valTypes.int,
      defaultValue: ''
    },
    {
      label: 'CTUIT Location ID',
      field: 'ctuit_ext_id',
      type: 'text'
    }
  ],
  delivery: [
    {
      label: 'External Delivery Integration',
      field: 'external_api',
      type: 'select',
      options: constants.deliveryIntegrations,
      defaultValue: 'NONE'
    },
    {
      label: 'Delivery Service Location ID',
      field: 'external_id',
      type: 'text',
      tooltip:
        "This is required for both Onfleet (it's the Team ID for this restaurant) and Relay (it's the ID in Relay's system) and can be obtained from your contact at the delivery service."
    },
    {
      label: 'Service Types to be sent to Delivery Service',
      field: 'service_type',
      type: 'select',
      options: constants.deliveryServiceTypes,
      defaultValue: 'NONE'
    },
    {
      label: 'Send Delay (in seconds)',
      field: 'send_delay',
      type: 'text',
      valType: constants.valTypes.int
    },
    {
      label: 'Maximum Gratuity (in dollars)',
      field: 'max_gratuity',
      type: 'text',
      valType: constants.valTypes.decimalNullable
    },
    {
      label: 'Confirm orders via delivery service',
      field: 'confirm',
      type: 'checkbox'
    },
    {
      label: 'Automatically confirm order when sent to delivery service',
      field: 'auto_confirm',
      type: 'checkbox'
    },
    {
      label: 'Send Immediately & Send Updates',
      field: 'send_updates',
      type: 'checkbox'
    },
    {
      label: 'Send 3rd Party (i.e. PORTAL) orders to delivery service',
      field: 'send_portal_orders',
      type: 'checkbox'
    },
    {
      label: 'Send a specific pickup time (DoorDash only)',
      field: 'send_pickup_time',
      type: 'checkbox'
    },
    {
      label: 'Surcharge (only relevant for Postmates)',
      field: 'surcharge_id',
      type: 'select',
      options: {
        endpoint: 'surcharges',
        entity: 'surcharge',
        value: 'surcharge_id',
        label: 'name'
      },
      valType: constants.valTypes.int,
      tooltip:
        'For Postmates, you must assign the Postmates Delivery Fee to a Surcharge that exists in Open Tender.'
    },
    {
      label: 'Subsidized Fee Tiers (only relevant for Postmates)',
      field: 'fees',
      type: 'inputTable',
      valType: 'arrayObjects',
      fields: [
        { label: 'Minimum Postmates Fee', field: 'minimum' },
        { label: 'Maximum Postmates Fee', field: 'maximum' },
        { label: 'Fee for Customer', field: 'fee' }
      ],
      tooltip:
        'These tiers can be used to subsidize the delivery fee charged to customers. If the Postmates fee falls between the Minimum and Maximum set below, the customer will only be charged the Fee amount.'
    }
    // {
    //   field: 'fees',
    //   type: 'hidden',
    //   defaultValue: []
    // }
  ],
  crm: [
    {
      label: 'CRM Region Name',
      field: 'region',
      type: 'text',
      classes: '-half',
      tooltip: 'This must the CRM name EXACTLY.'
    },
    {
      label: 'CRM Location Name',
      field: 'name',
      type: 'text',
      classes: '-half -right',
      tooltip: 'This must the CRM name EXACTLY.'
    }
  ],
  pos: [
    {
      label: 'POS Dashboard Greeting',
      field: 'greeting',
      type: 'text',
      required: true,
      classes: '-half'
    },
    {
      label: 'Starting Cash',
      field: 'starting_cash',
      type: 'text',
      required: true,
      valType: constants.valTypes.nullable,
      classes: '-half -right'
    },
    {
      label: 'Week Start Day',
      field: 'week_start_day',
      type: 'select',
      options: constants.weekdayOptions,
      defaultValue: 'MONDAY',
      classes: '-half'
    },
    {
      label: 'End of Day Auto-clock Out Time',
      field: 'end_of_day',
      type: 'text',
      valType: constants.valTypes.time,
      required: true,
      classes: '-half -right',
      tooltip:
        'This is the time of day when any employees who forgot to punch out will be automatically punched out'
    },
    {
      label: 'Weekly Overtime Threshold (in hours)',
      field: 'overtime_threshold',
      type: 'text',
      classes: '-half',
      required: true,
      valType: constants.valTypes.int
    },
    {
      label: 'Daily Overtime Threshold (in hours)',
      field: 'overtime_threshold_daily',
      type: 'text',
      classes: '-half -right',
      required: true,
      valType: constants.valTypes.int
    },
    {
      label: 'Punch In Authorization Type',
      field: 'punch_type',
      type: 'select',
      options: constants.posAuthType,
      defaultValue: 'PIN',
      classes: '-half'
    },
    {
      label: 'Order Page Timeout (in minutes)',
      field: 'page_timeout',
      type: 'select',
      options: constants.posPageTimeout,
      valType: constants.valTypes.int,
      classes: '-half -right'
    },
    {
      label: 'Service Types',
      field: 'service_types',
      type: 'checkboxGroup',
      options: [...constants.serviceType].splice(1),
      valType: constants.valTypes.arrayString
    },
    {
      label: 'Require manager approval of item-level discounts',
      field: 'item_discount_approval',
      type: 'checkbox'
    },
    {
      label: 'Require authorization for Order Page access',
      field: 'order_page_swipe',
      type: 'checkbox'
    },
    {
      label: 'Require manager approval for tax exempt orders',
      field: 'tax_exempt_approval',
      type: 'checkbox'
    },
    {
      label: 'Send POS offline alert emails',
      field: 'send_offline_alerts',
      type: 'checkbox'
    },
    {
      label: 'Display the dine-in option',
      field: 'dine_in_show',
      type: 'checkbox',
      tooltip:
        'If checked, cashiers will have the option of designated an order either as Eat In or To Go.'
    },
    {
      label: 'Display dine-in prompt when cashier hits the Pay button',
      field: 'dine_in_prompt',
      type: 'checkbox',
      tooltip:
        'If checked, cashiers will be prompted to select either Eat In or To Go when they hit the Pay button'
    },
    {
      label: 'Kitchen Chit Printing',
      field: 'kitchen_chit_printing',
      type: 'break'
    },
    {
      label: 'Kitchen Chit Hub Server ID',
      field: 'olo_print_server_id',
      type: 'text',
      valType: constants.valTypes.int,
      classes: '-half'
    },
    {
      label: 'Kitchen Chit Hub Printer ID',
      field: 'olo_print_device_id',
      type: 'text',
      valType: constants.valTypes.int,
      classes: '-half -right'
    },
    {
      label: 'Kitchen Chit Order Types',
      field: 'olo_print_order_type',
      type: 'select',
      options: constants.bothOrderType,
      defaultValue: 'OLO_ONLY',
      classes: '-half'
    },
    {
      label: 'Kitchen Chit Print Timing',
      field: 'olo_print_stage',
      type: 'select',
      options: constants.posSendTiming,
      defaultValue: 'PLACED',
      classes: '-half -right'
    },
    {
      label: 'Receipt Settings',
      field: 'receipt_settings',
      type: 'break'
    },
    {
      label: 'Receipt Header',
      field: 'receipt_header',
      type: 'textarea',
      required: true
    },
    {
      label: 'Receipt Footer',
      field: 'receipt_footer',
      type: 'textarea',
      required: true
    },
    {
      label: 'Display restaurant phone number on the receipt',
      field: 'receipt_phone',
      type: 'checkbox'
    }
  ],
  discounts: [
    {
      label: 'Discount Type',
      field: 'discount_type',
      type: 'select',
      options: constants.discountType,
      defaultValue: 'DOLLAR'
      // classes: '-half',
    },
    {
      label: 'Amount',
      field: 'amount',
      type: 'text',
      required: true,
      valType: constants.valTypes.decimal
      // classes: '-half -right',
    },
    {
      label: 'Days Valid',
      field: 'days_valid',
      type: 'text',
      valType: constants.valTypes.intNullable
    },
    {
      label: 'Minimum Dollar Amount',
      field: 'min_amount',
      type: 'text',
      valType: constants.valTypes.decimal,
      classes: '-half'
    },
    {
      label: 'Maximum Dollar Amount',
      field: 'max_amount',
      type: 'text',
      valType: constants.valTypes.decimal,
      classes: '-half -right'
    },
    {
      label: 'Minimum Order Size',
      field: 'min_order_size',
      type: 'text',
      valType: constants.valTypes.decimal,
      classes: '-half'
    },
    {
      label: 'Max Order Size',
      field: 'max_order_size',
      type: 'text',
      valType: constants.valTypes.decimal,
      classes: '-half -right'
    },
    {
      label: 'Order Type',
      field: 'order_type',
      type: 'select',
      options: constants.orderType,
      defaultValue: '',
      classes: '-half',
      valType: constants.valTypes.nullable
    },
    {
      label: 'Service Type',
      field: 'service_type',
      type: 'select',
      options: constants.serviceType,
      defaultValue: '',
      classes: '-half -right',
      valType: constants.valTypes.nullable
    },
    {
      label: 'Channel Type',
      field: 'channel_type',
      type: 'select',
      options: constants.channelType,
      defaultValue: '',
      valType: constants.valTypes.nullable
    },
    {
      label: 'Start Date',
      field: 'start_date',
      type: 'datepicker',
      placeholder: 'YYYY-MM-DD',
      valType: constants.valTypes.dateNullable,
      classes: '-half'
    },
    {
      label: 'End Date',
      field: 'end_date',
      type: 'datepicker',
      placeholder: 'YYYY-MM-DD',
      valType: constants.valTypes.dateNullable,
      classes: '-half -right'
    },
    {
      label: 'Start Time',
      field: 'start_time',
      type: 'text',
      placeholder: '11:00 AM',
      valType: constants.valTypes.timeNullable,
      classes: '-half'
    },
    {
      label: 'End Time',
      field: 'end_time',
      type: 'text',
      placeholder: '5:00 PM',
      valType: constants.valTypes.timeNullable,
      classes: '-half -right'
    },
    {
      label: 'Title (up to 256 characters)',
      field: 'title',
      type: 'text',
      tooltip:
        'This is the main title for the discount displayed throughout the Open Tender web app'
    },
    {
      label: 'Short Description (up to 256 characters)',
      field: 'internal_desc',
      type: 'text',
      tooltip: 'This shows up in the deal "card" in the list of deals'
    },
    {
      label: 'Description (up to 1024 characters)',
      field: 'description',
      type: 'textarea',
      tooltip:
        'This shows up in the popup that appears when a customer clicks on a deal to learn more about it'
    },
    {
      label: 'Active',
      field: 'is_active',
      type: 'checkbox',
      defaultValue: true
    },
    {
      label: 'Taxed',
      field: 'is_taxed',
      type: 'checkbox',
      defaultValue: true,
      tooltip:
        'If checked, tax will be calculated on the order total AFTER the discount has been applied. If NOT checked, tax will be calculated on the order total BEFORE the discount has been applied.'
    },
    {
      label: 'Before Surcharge',
      field: 'before_surcharge',
      type: 'checkbox',
      tooltip:
        'If checked, the discount will be applied BEFORE the surcharge so the surcharge amount will not be impacted by the discount.'
    },
    {
      field: 'display_order',
      type: 'hidden',
      valType: constants.valTypes.int
    },
    {
      field: 'menu_id',
      type: 'hidden',
      valType: constants.valTypes.nullable
    },
    {
      field: 'entity_type',
      type: 'hidden',
      valType: constants.valTypes.nullable
    },
    {
      field: 'discounted_items',
      type: 'hidden',
      valType: constants.valTypes.nullable
    },
    {
      field: 'discounted_quantity',
      type: 'hidden',
      valType: constants.valTypes.nullable
    },
    {
      field: 'required_items',
      type: 'hidden',
      valType: constants.valTypes.nullable
    },
    {
      field: 'required_quantity',
      type: 'hidden',
      valType: constants.valTypes.nullable
    }
  ],
  qrCodes: [
    {
      name: 'Code',
      field: ['code'],
      link: 'discount_qrcode_id'
      // format: 'truncate'
    },
    { name: 'Is Active', field: ['is_active'], format: 'bool' },
    // { name: 'Is Single Use', field: ['is_single_use'], format: 'bool' },
    // { name: 'Is Redeemed', field: ['is_redeemed'], format: 'bool' },
    { name: 'QR Code', field: ['qr_code_url'], format: 'qrcode' }
  ],
  qrCode: [
    {
      label: 'Is Active',
      field: 'is_active',
      type: 'checkbox',
      defaultValue: true
    }
  ],
  customer: [
    {
      label: 'First Name',
      field: 'first_name',
      type: 'text',
      required: true,
      classes: '-half'
    },
    {
      label: 'Last Name',
      field: 'last_name',
      type: 'text',
      required: true,
      classes: '-half -right'
    },
    {
      label: 'Email',
      field: 'email',
      type: 'text',
      required: true,
      classes: '-half'
    },
    {
      label: 'Password',
      field: 'password',
      type: 'text',
      required: true,
      placeholder: '********',
      classes: '-half -right',
      tooltip: 'Passwords must be at least 8 characters'
    },
    {
      label: 'Phone',
      field: 'phone',
      type: 'text',
      required: true,
      classes: '-half'
    },
    {
      label: 'Company',
      field: 'company',
      type: 'text',
      classes: '-half -right'
    },
    {
      label: 'Gender',
      type: 'select',
      field: 'gender',
      value: constants.genderType[0].value,
      options: constants.genderType,
      classes: '-half'
    },
    {
      label: 'Birth Date',
      type: 'datepicker',
      field: 'birth_date',
      placeholder: 'YYYY-MM-DD',
      valType: constants.valTypes.dateNullable,
      classes: '-half -right'
    }
  ],
  customerAddress: [
    {
      label: 'Street',
      field: 'street',
      type: 'text',
      required: true,
      classes: '-half'
    },
    {
      label: 'Street Line 2',
      field: 'street2',
      type: 'text',
      classes: '-half -right'
    },
    { label: 'Unit', field: 'unit', type: 'text', classes: '-half' },
    {
      label: 'City',
      field: 'city',
      type: 'text',
      classes: '-half -right',
      required: true
    },
    {
      label: 'State',
      field: 'state',
      type: 'select',
      classes: '-half',
      options: constants.statesProvinces
    },
    {
      label: 'Zip Code',
      field: 'postal_code',
      type: 'text',
      classes: '-half -right',
      required: true
    },
    {
      label: 'Company',
      field: 'company',
      type: 'text',
      classes: '-half'
    },
    {
      label: 'Description',
      field: 'description',
      type: 'text',
      classes: '-half -right'
    },
    {
      label: 'Contact',
      field: 'contact',
      type: 'text',
      classes: '-half'
    },
    {
      label: 'Phone',
      field: 'phone',
      type: 'text',
      classes: '-half -right'
    },
    {
      label: 'Address Notes',
      field: 'notes',
      type: 'textarea'
    },
    { label: 'Is Default', field: 'is_default', type: 'checkbox' },
    {
      label: 'Is Active',
      field: 'is_active',
      type: 'checkbox',
      defaultValue: true
    },
    { field: 'country', type: 'hidden' },
    { field: 'formatted_address', type: 'hidden' },
    {
      field: 'latitude',
      type: 'hidden',
      valType: constants.valTypes.decimalNullable
    },
    {
      field: 'longitude',
      type: 'hidden',
      valType: constants.valTypes.decimalNullable
    }
  ]
}

const validation = {
  credit: {
    integration: {
      NMI: {
        required: ['merchant_id', 'merchant_api_key'],
        empty: ['merchant_password', 'merchant_username']
      },
      VANTIV: {
        required: ['merchant_id', 'merchant_password'],
        empty: ['merchant_api_key', 'merchant_username']
      },
      CARDCONNECT: {
        required: ['merchant_id'],
        empty: ['merchant_api_key', 'merchant_username', 'merchant_password']
      },
      NONE: {
        required: [],
        empty: [
          'merchant_id',
          'merchant_api_key',
          'merchant_username',
          'merchant_password'
        ]
      }
    }
  },
  externalPOS: {
    integration: {
      TOAST: {
        // pos_location_id = restaurant GUID
        // pos_station_id = Amazon S3 bucket ID
        // pos_user_id = optional and used for menu GUIDs
        required: ['pos_location_id', 'pos_station_id'],
        empty: ['pos_url']
      },
      REVEL: {
        // pos_location_id = location ID
        // pos_station_id = station ID
        // pos_user_id = user ID
        required: ['pos_location_id', 'pos_station_id', 'pos_user_id'],
        empty: ['pos_url']
      },
      SALIDO: {
        // pos_location_id = restaurant GUID
        // pos_station_id = optional, restaurant-specific pickup order type GUID
        // pos_user_id = optional, restaurant-specific delivery order type GUID
        required: ['pos_location_id'],
        empty: ['pos_url']
      },
      GUSTO: {
        // pos_location_id = location ID
        required: ['pos_location_id'],
        empty: ['pos_station_id', 'pos_user_id', 'pos_url']
      },
      RPOWER: {
        // pos_url = the URL of the POS server in the store itself
        required: ['pos_url'],
        empty: ['pos_location_id', 'pos_station_id', 'pos_user_id']
      },
      CHOWLY_TOOS: {
        required: ['pos_location_id'],
        empty: ['pos_url', 'pos_station_id']
      },
      OTTER: {
        required: ['pos_location_id'],
        empty: ['pos_url', 'pos_station_id', 'pos_user_id']
      },
      CHECKMATE: {
        required: [],
        empty: ['pos_url', 'pos_location_id', 'pos_station_id', 'pos_user_id']
      },
      CHECKMATE_TOOS: {
        required: [],
        empty: ['pos_url', 'pos_station_id', 'pos_user_id']
      },
      EATSA: {
        // pos_location_id = location ID
        required: ['pos_location_id'],
        empty: ['pos_station_id', 'pos_user_id', 'pos_url']
      },
      WEBHOOK: {
        required: [],
        empty: ['pos_url', 'pos_location_id', 'pos_station_id', 'pos_user_id']
      },
      NONE: {
        required: [],
        empty: ['pos_url', 'pos_location_id', 'pos_station_id', 'pos_user_id']
      }
    }
  }
}

const pages = {
  '/not_found': {
    type: constants.pageType.content,
    title: 'Page Not Found',
    subtitle: 'Whoops. Nothing seems to exist here.',
    content: '/not_found',
    image: { url: images.notFound, position: 'center bottom' }
  },
  '/': {
    type: constants.pageType.content,
    classes: 'page--home',
    title: 'Open Tender Admin Portal',
    subtitle: 'Hi there! How can we help you today?',
    content: '/',
    image: { url: images.home, position: 'center bottom' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Orders',
            description: 'Review or edit existing orders, or place new ones',
            endpoint: '/order-mgmt',
            icon: 'ShoppingBag',
            section: 'ORDERS'
          },
          {
            title: 'Customers',
            description:
              'Review your customers and their orders, start new orders, update addresses',
            endpoint: '/customers',
            icon: 'Users',
            section: 'CUSTOMERS'
          },
          {
            title: 'House Accounts',
            description:
              'Manage your house accounts and their orders, place new ones',
            endpoint: '/house-accounts',
            icon: 'Home',
            section: 'CUSTOMERS'
          },
          {
            title: 'Loyalty',
            description: 'Manage your loyalty programs and create new ones',
            endpoint: '/loyalty-programs',
            icon: 'Heart',
            section: 'CUSTOMERS'
          },
          {
            title: 'Promo Codes',
            description: subtitles.promoCodes,
            endpoint: '/promo-codes',
            icon: 'Grid',
            section: 'CUSTOMERS'
          },
          {
            title: 'Deals',
            description: subtitles.deals,
            endpoint: '/deals',
            icon: 'DollarSign',
            section: 'CUSTOMERS'
          },
          {
            title: 'Rewards',
            description: subtitles.rewards,
            endpoint: '/rewards',
            icon: 'Award',
            section: 'CUSTOMERS'
          },
          {
            title: 'Announcements',
            description: subtitles.announcements,
            endpoint: '/announcements',
            icon: 'MessageSquare',
            setting: 'CUSTOMERS'
          },
          {
            title: 'Gift Cards',
            description: 'Manage existing gift card batches or create new ones',
            endpoint: '/gift-cards',
            icon: 'Gift',
            section: 'CUSTOMERS'
          }
        ]
      },
      {
        name: 'section-02',
        subsections: [
          {
            title: 'Stores',
            description: subtitles.stores,
            endpoint: '/restaurants',
            icon: 'ShoppingCart',
            section: 'LOCATIONS'
          },
          {
            title: 'Revenue Centers',
            description: subtitles.revenueCenters,
            endpoint: '/revenue-centers',
            icon: 'DollarSign',
            section: 'LOCATIONS'
          },
          {
            title: 'Menus',
            description: 'The heart and soul of your restaurant',
            endpoint: '/menu-mgmt',
            icon: 'Map',
            section: 'MENUS'
          },
          {
            title: 'Point of Sale',
            description:
              'Manage pages, sections, and buttons that show up on your Open Tender POS',
            endpoint: '/point-of-sale',
            icon: 'Monitor',
            section: 'MENUS'
          },
          {
            title: 'People',
            description:
              'Manage employees, departments, pay rates, and swipe cards',
            endpoint: '/people',
            icon: 'User',
            section: 'LABOR'
          },
          {
            title: 'Timeclock',
            description: 'Edit time punches and review hours worked',
            endpoint: '/timeclock',
            icon: 'Clock',
            section: 'LABOR'
          }
        ]
      },
      {
        name: 'section-03',
        subsections: [
          {
            title: 'Reporting',
            description:
              'Monitor both real-time performance and historical trends',
            endpoint: '/reporting',
            icon: 'BarChart',
            section: 'REPORTING'
          },
          {
            title: 'Downloads',
            description:
              'CSVs and other downloadable files that are processed as background tasks',
            endpoint: '/downloads',
            icon: 'Download',
            section: 'REPORTING'
          }
        ]
      },
      {
        name: 'section-04',
        subsections: [
          {
            title: 'App Config',
            description: subtitles.config,
            endpoint: '/config',
            icon: 'Sliders',
            section: 'SETTINGS'
          },
          {
            title: 'Settings',
            description:
              'Surcharges, discounts, taxes, and various other settings',
            endpoint: '/settings',
            icon: 'Settings',
            section: 'SETTINGS'
          },
          {
            title: 'Integrations',
            description:
              'Configure integrations with 3rd party services (POS, loyalty, etc.)',
            endpoint: '/integrations',
            icon: 'Code',
            section: 'SETTINGS'
          },
          {
            title: 'Content',
            description: 'Pages and other content that appears on your website',
            endpoint: '/content',
            icon: 'Chrome',
            section: 'WEBSITE'
          },
          {
            title: 'Users',
            description:
              'Manage access and permissions for the Open Tender Admin Portal',
            endpoint: '/users',
            icon: 'User',
            section: 'USERS'
          },
          {
            title: 'Help',
            description:
              'Various resources to assist with any questions that arise',
            icon: 'HelpCircle',
            endpoint: '/help'
          }
        ]
      }
    ]
  },
  '/people': {
    type: constants.pageType.content,
    title: 'People',
    subtitle: 'Manage your users, employees, and user permissions',
    image: { url: images.people, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Employees',
            description:
              'The folks who work in your stores and do NOT need access to this admin portal',
            endpoint: '/employees',
            sections: 'LABOR'
          },
          {
            title: 'Departments',
            description:
              'Employees can be associated with one or more departments',
            endpoint: '/departments',
            sections: 'LABOR'
          },
          {
            title: 'Payroll Locations',
            description:
              'A mapping between your Open Tender stores and your payroll locations',
            endpoint: '/payroll-locations',
            sections: 'LABOR'
          },
          {
            title: 'Swipe Cards',
            description:
              'All of your employee swipe cards, optionally filtered by batch',
            endpoint: '/cards',
            sections: 'LABOR'
          },
          {
            title: 'Card Batches',
            description: 'Batches of employee swipe cards',
            endpoint: '/batches',
            sections: 'LABOR'
          }
        ]
      }
    ]
  },
  '/users': {
    type: constants.pageType.list,
    title: 'Users',
    subtitle: 'These are the folks who have access to this admin portal',
    content: '',
    list: {
      endpoint: '/users',
      id: 'user_id',
      sorting: { sortBy: 'last_name', sortType: 'alpha' },
      classes: 'table--users',
      search: {
        label: 'Or search by name',
        endpoint: 'users',
        placeholder: 'enter name + hit enter'
      },
      filters: [
        {
          label: 'Active',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        },
        {
          label: 'Role',
          type: 'select',
          field: 'role',
          value: '',
          options: constants.roles
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['last_name', 'first_name'],
          separator: ', ',
          link: 'user_id'
        },
        { name: 'Role', field: ['role'], format: 'enum' },
        { name: 'Email', field: ['email'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Activated', field: ['is_activated'], format: 'bool' },
        { name: 'Sections', field: ['sections'], format: 'arrayEnum' },
        { name: 'ID', field: ['user_id'] }
      ]
    }
  },
  '/users/:id': {
    type: constants.pageType.item,
    title: 'User',
    subtitle: 'Use the form below to update this user',
    content: '',
    item: {
      endpoint: '/users',
      id: 'user_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.users,
      tabActions: [{ name: 'Send Activation', type: 'activateUser' }],
      fields: [
        {
          label: 'First Name',
          field: 'first_name',
          type: 'text',
          required: true
        },
        {
          label: 'Last Name',
          field: 'last_name',
          type: 'text',
          required: true
        },
        { label: 'Email', field: 'email', type: 'email', required: true },
        {
          label: 'Role',
          field: 'role',
          type: 'select',
          options: [...constants.roles].slice(1)
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        { field: 'is_super', type: 'hidden', valType: constants.valTypes.bool },
        {
          field: 'sections',
          type: 'hidden',
          valType: constants.valTypes.arrayString
        },
        {
          field: 'stores',
          type: 'hidden',
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/users/:id/sections': {
    type: constants.pageType.item,
    title: 'User',
    subtitle: 'Choose which sections this user should have access to',
    content: '',
    item: {
      endpoint: '/users',
      id: 'user_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.users,
      fields: [
        { field: 'first_name', type: 'hidden' },
        { field: 'last_name', type: 'hidden' },
        { field: 'email', type: 'hidden' },
        { field: 'role', type: 'hidden' },
        { field: 'is_active', type: 'hidden' },
        { field: 'is_super', type: 'hidden' },
        {
          label: 'Sections',
          field: 'sections',
          type: 'checkboxGroup',
          options: [...constants.sections].splice(1),
          valType: constants.valTypes.arrayString
        },
        {
          field: 'stores',
          type: 'hidden',
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/users/:id/stores': {
    type: constants.pageType.item,
    title: 'User',
    subtitle: 'Choose which sections this user should have access to',
    options: ['stores'],
    item: {
      endpoint: '/users',
      id: 'user_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.users,
      fields: [
        { field: 'first_name', type: 'hidden' },
        { field: 'last_name', type: 'hidden' },
        { field: 'email', type: 'hidden' },
        { field: 'role', type: 'hidden' },
        { field: 'is_active', type: 'hidden' },
        { field: 'is_super', type: 'hidden' },
        {
          field: 'sections',
          type: 'hidden',
          valType: constants.valTypes.arrayString
        },
        {
          label: 'Stores',
          field: 'stores',
          type: 'checkboxGroup',
          options: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/permissions': {
    type: constants.pageType.list,
    title: 'Permissions',
    subtitle:
      'Set the access level required for certain functions in the admin portal',
    list: {
      endpoint: '/permissions',
      id: 'tag',
      hideNew: true,
      actions: [{ text: 'Update Permissions', type: 'upsertItems' }],
      sorting: { sortBy: 'name', sortType: 'alpha' },
      classes: 'table--permissions table--light',
      fields: [
        { name: 'Name', field: ['name'] },
        {
          name: 'Role',
          field: ['role'],
          format: 'select',
          id: 'tag',
          options: [...constants.roles].slice(1)
        },
        { name: 'Description', field: ['description'] }
      ]
    }
  },
  '/departments': {
    type: constants.pageType.list,
    title: 'Departments',
    subtitle: 'Employees can be associated with one or more departments',
    list: {
      endpoint: '/departments',
      id: 'department_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'department_id'
        },
        { name: 'Code', field: ['code'] },
        { name: 'ID', field: ['department_id'] }
      ]
    }
  },
  '/departments/:id': {
    type: constants.pageType.item,
    title: 'Department',
    subtitle: 'Use the form below to update this department',
    list: {
      title: 'Departments',
      endpoint: '/departments',
      id: 'department_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/departments',
      id: 'department_id',
      title: 'name',
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Code', field: 'code', type: 'text', required: true }
      ]
    }
  },
  '/payroll-locations': {
    type: constants.pageType.list,
    title: 'Payroll Locations',
    subtitle: 'Associate your stores with payroll locations',
    classes: 'app--aside',
    list: {
      endpoint: '/payroll-locations',
      id: 'payroll_location_id',
      params: [{ field: 'expand', value: true }],
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'payroll_location_id'
        },
        // { name: 'Revenue Center', field: ['revenue_center_id'] },
        { name: 'Store', field: ['store.full_name'] },
        { name: 'Code', field: ['code'] },
        { name: 'ID', field: ['payroll_location_id'] }
      ]
    }
  },
  '/payroll-locations/:id': {
    type: constants.pageType.item,
    title: 'Payroll',
    subtitle: 'Use the form below to update this payroll location',
    options: ['stores'],
    list: {
      title: 'Payroll Locations',
      endpoint: '/payroll-locations',
      id: 'payroll_location_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      isReplace: true,
      endpoint: '/payroll-locations',
      id: 'payroll_location_id',
      title: 'name',
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Code', field: 'code', type: 'text', required: true },
        {
          label: 'Store',
          field: 'store_id',
          type: 'select',
          options: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/employees': {
    type: constants.pageType.list,
    title: 'Employees',
    subtitle:
      'These are the folks who work in the restaurant and need to be able to clock into your Open Tender POS',
    options: ['stores'],
    list: {
      endpoint: '/employees',
      id: 'employee_id',
      search: {
        label: 'Or search by name or ID',
        endpoint: 'employees',
        placeholder: 'enter name or ID + hit enter'
      },
      params: [{ field: 'expand', value: 'store' }],
      sorting: { sortBy: 'last_name', sortType: 'alpha' },
      actions: [{ text: 'Download CSV', endpoint: '/employees/csv' }],
      filters: [
        {
          label: 'Store',
          type: 'select',
          field: 'store_id',
          settings: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        },
        // {
        //   label: 'Revenue Center',
        //   type: 'select',
        //   field: 'revenue_center_id',
        //   settings: {
        //     endpoint: 'revenue-centers',
        //     key: 'OLO',
        //     value: 'revenue_center_id',
        //     label: 'full_name'
        //   },
        //   options: null,
        //   valType: constants.valTypes.int,
        //   includeNone: true
        // },
        {
          label: 'Role',
          type: 'select',
          field: 'role',
          value: '',
          options: constants.employeeRoles
        },
        {
          label: 'Status',
          type: 'select',
          field: 'status',
          value: '',
          options: constants.employeeStatus
        },
        {
          label: 'Type',
          type: 'select',
          field: 'employee_type',
          value: '',
          options: constants.employeeTypes
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['last_name', 'first_name'],
          separator: ', ',
          link: 'employee_id'
        },
        { name: 'Timeclock ID', field: ['timeclock_ext_id'] },
        { name: 'Payroll ID', field: ['payroll_ext_id'] },
        { name: 'Store', field: ['store.full_name'] },
        { name: 'Role', field: ['role'], format: 'enum' },
        { name: 'Type', field: ['employee_type'], format: 'enum' },
        // { name: 'Payment Type', field: ['payment_type'], format: 'enum' },
        { name: 'Status', field: ['status'], format: 'enum' },
        { name: 'ID', field: ['employee_id'] }
      ]
    }
  },
  '/employees/:id': {
    type: constants.pageType.item,
    title: 'Employee',
    subtitle: 'Use the form below to update this employee',
    options: ['stores'],
    list: {
      title: 'Employees',
      endpoint: '/employees',
      id: 'employee_id',
      field: ['last_name', 'first_name'],
      separator: ', ',
      sorting: { sortBy: 'last_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/employees',
      id: 'employee_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.employees,
      fields: [
        {
          label: 'First Name',
          field: 'first_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Last Name',
          field: 'last_name',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Timeclock ID',
          field: 'timeclock_ext_id',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Payroll ID',
          field: 'payroll_ext_id',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Role',
          field: 'role',
          type: 'select',
          options: [...constants.employeeRoles].slice(1),
          classes: '-half'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: [...constants.employeeStatus].slice(1),
          classes: '-half -right'
        },
        {
          label: 'Type',
          field: 'employee_type',
          type: 'select',
          options: [...constants.employeeTypes].slice(1),
          classes: '-half'
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
          type: 'select',
          options: constants.employeePaymentTypes,
          classes: '-half -right'
        },
        {
          label: 'Store',
          field: 'store_id',
          type: 'select',
          options: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name',
            defaultOption: { name: 'All Stores', value: 0 }
          },
          valType: constants.valTypes.int,
          classes: '-half'
        }
      ]
    }
  },
  '/employees/:id/departments': {
    type: constants.pageType.itemRelations,
    title: 'Departments',
    subtitle: '',
    options: ['departments'],
    list: {
      title: 'Employees',
      endpoint: '/employees',
      id: 'employee_id',
      field: ['last_name', 'first_name'],
      separator: ', ',
      sorting: { sortBy: 'last_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/employees',
      id: 'employee_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.employees
    },
    relations: {
      endpoint: '/employees/:id/departments',
      id: 'department_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      classes: 'table--employee-departments',
      fields: [
        {
          name: 'Name',
          field: ['department.name'],
          link: 'department_id',
          list: 'departments'
        },
        { name: 'Code', field: ['department.code'] },
        { name: 'Pay Rate', field: ['pay_rate'], format: 'dollars' },
        {
          name: 'Overtime Pay Rate',
          field: ['overtime_pay_rate'],
          format: 'dollars'
        },
        {
          name: 'Active',
          field: ['is_active'],
          format: 'bool'
        },
        { name: 'ID', field: ['department.department_id'] }
      ]
    }
  },
  '/employees/:id/departments/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Department',
    subtitle: 'Use the form below to update this department for this employee',
    options: ['departments'],
    list: {
      title: 'Employees',
      endpoint: '/employees',
      id: 'employee_id',
      field: ['last_name', 'first_name'],
      separator: ', ',
      sorting: { sortBy: 'last_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/employees',
      id: 'employee_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.employees
    },
    relations: {
      title: 'Departments',
      endpoint: '/employees/:id/departments',
      id: 'department_id',
      field: ['name'],
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/employees/:id/departments',
      id: 'department_id',
      title: 'name',
      hasDelete: true,
      fields: [
        {
          label: 'Department',
          field: 'department_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'departments',
            entity: 'department',
            value: 'department_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Pay Rate',
          field: 'pay_rate',
          type: 'text',
          required: true
        },
        {
          label: 'Overtime Pay Rate',
          field: 'overtime_pay_rate',
          type: 'text',
          required: true
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'select',
          options: [...constants.active].slice(1),
          valType: constants.valTypes.bool,
          defaultValue: true
        }
      ]
    }
  },
  '/cards': {
    type: constants.pageType.list,
    title: 'Employees Swipe Cards',
    subtitle:
      'Use this page to export batches of employee swipe cards or create new ones',
    options: ['batches'],
    list: {
      endpoint: '/cards',
      id: 'employee_card_id',
      sorting: {},
      newButtonText: 'New Card Batch',
      filters: [
        {
          label: 'Batches',
          type: 'select',
          field: 'batch_id',
          settings: {
            endpoint: 'batches',
            value: 'batch_id',
            label: 'batch_id'
          },
          options: null,
          valType: constants.valTypes.int
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: '',
          options: constants.active
        },
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      fields: [
        { name: 'Card Number', field: ['card_number'] },
        { name: 'Code', field: ['code'] },
        {
          name: 'Batch',
          field: ['batch_id'],
          link: 'batch_id',
          linkEndpoint: '/batches',
          string: 'Batch {}'
        },
        { name: 'Created', field: ['created_at'], format: 'datetime' },
        { name: 'Activated', field: ['activated_at'], format: 'datetime' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['employee_card_id'] }
      ]
    }
  },
  '/cards/:id': {
    type: constants.pageType.item,
    title: 'Card Batch',
    subtitle: "Enter the number of cards you'd like to create",
    item: {
      endpoint: '/batches',
      redirect: '/cards',
      id: 'batch_id',
      title: 'name',
      tabs: [],
      fields: [
        {
          label: 'Count',
          field: 'count',
          type: 'text',
          valType: constants.valTypes.int,
          required: true
        }
      ]
    }
  },
  '/batches': {
    type: constants.pageType.list,
    title: 'Employee Card Batches',
    subtitle:
      'All of the card batches that have been used to create employee cards',
    classes: 'app--aside',
    list: {
      endpoint: '/batches',
      id: 'batch_id',
      sorting: { sortBy: 'name', sortType: 'order', noDrag: true },
      fields: [
        { name: 'Batch', field: ['batch_id'], link: 'batch_id' },
        { name: 'Count', field: ['count'] },
        {
          name: 'Download',
          field: ['download CSV or TXT', ''],
          format: 'path',
          link: 'batch_id'
        }
      ]
    }
  },
  '/batches/:id': {
    type: constants.pageType.item,
    title: 'Card Batch',
    subtitle:
      'Use the buttons below to download this batch of cards as a CSV or TXT file',
    list: {
      title: 'Batches',
      endpoint: '/batches',
      id: 'batch_id',
      field: 'batch_id',
      sorting: { sortBy: 'batch_id', sortType: 'order' }
    },
    item: {
      endpoint: '/batches',
      id: 'batch_id',
      title: 'batch_id',
      tabs: [],
      fields: [
        {
          label: 'Count',
          field: 'count',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          readonly: true
        }
      ],
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/batches/:id/cards/csv'
        },
        {
          text: 'Download TXT',
          endpoint: '/batches/:id/cards/txt',
          filename: 'batch_:id.txt',
          fileType: 'text/plain'
        }
      ]
    }
  },
  '/restaurants': {
    type: constants.pageType.content,
    title: 'Stores',
    subtitle:
      'Manage your stores, revenue centers, pricing regions, and store groups',
    image: { url: images.stores, position: 'center bottom' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Stores',
            description: subtitles.stores,
            endpoint: '/stores',
            section: 'LOCATIONS'
          },
          {
            title: 'Store Groups',
            description: subtitles.storeGroups,
            endpoint: '/store-groups',
            section: 'LOCATIONS'
          },
          {
            title: 'Revenue Centers',
            description: subtitles.revenueCenters,
            endpoint: '/revenue-centers',
            section: 'LOCATIONS'
          },
          {
            title: 'Pricing Regions',
            description: subtitles.pricingRegions,
            endpoint: '/regions',
            section: 'LOCATIONS'
          }
        ]
      }
    ]
  },
  '/regions': {
    type: constants.pageType.list,
    title: 'Pricing Regions',
    subtitle: subtitles.pricingRegions,
    // classes: 'app--aside',
    list: {
      endpoint: '/regions',
      id: 'region_id',
      params: [{ field: 'with_related', value: true }],
      filters: [
        {
          label: 'Active',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'region_id' },
        { name: 'Is Active', field: ['is_active'], format: 'bool' },
        {
          name: 'Revenue Centers',
          field: ['revenue_centers.null.short_name'],
          format: 'array'
        },
        { name: 'ID', field: ['region_id'] }
      ]
    }
  },
  '/regions/:id': {
    type: constants.pageType.item,
    title: 'Region',
    subtitle: 'Use the form below to update this region',
    content: '',
    list: listSettings.regions,
    item: {
      endpoint: '/regions',
      id: 'region_id',
      title: 'name',
      hasDelete: false,
      tabs: tabs.regions,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Active', field: 'is_active', type: 'checkbox' }
      ]
    }
  },
  '/regions/:id/revenue-centers': {
    type: constants.pageType.itemRelations,
    title: 'Revenue Centers',
    subtitle: 'Below are the revenue centers associated with this region',
    list: listSettings.regions,
    item: itemSettings.regions,
    relations: {
      endpoint: '/regions/:id/revenue-centers',
      hideNew: true,
      id: 'revenue_center_id',
      sorting: {
        sortBy: 'revenue_center_type',
        sortType: 'alpha'
      },
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'revenue_center_id',
          linkEndpoint: '/revenue-centers'
        },
        { name: 'Internal Name', field: ['short_name'] },
        { name: 'Type', field: ['revenue_center_type'] },
        { name: 'ID', field: ['revenue_center_id'] }
      ]
    }
  },
  '/store-groups': {
    type: constants.pageType.list,
    title: 'Store Groups',
    subtitle: subtitles.storeGroups,
    classes: 'app--aside',
    list: {
      endpoint: '/store-groups',
      id: 'store_group_id',
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['full_name'], link: 'store_group_id' },
        { name: 'Internal Name', field: ['short_name'] },
        { name: 'Description', field: ['description'] },
        { name: 'ID', field: ['store_group_id'] }
      ]
    }
  },
  '/store-groups/:id': {
    type: constants.pageType.item,
    title: 'Store Group',
    subtitle: 'Use the form below to update this store group',
    list: {
      title: 'Store Groups',
      endpoint: '/store-groups',
      id: 'store_group_id',
      field: 'full_name',
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/store-groups',
      id: 'store_group_id',
      title: 'full_name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'full_name', type: 'text', required: true },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        { label: 'Description', field: 'description', type: 'textarea' }
      ]
    }
  },
  '/stores': {
    type: constants.pageType.list,
    title: 'Stores',
    subtitle: subtitles.stores,
    content: '',
    list: {
      endpoint: '/stores',
      id: 'store_id',
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      classes: 'table--stores',
      params: [
        { field: 'with_related', value: 'revenue_centers' },
        { field: 'with_settings', value: 'address' }
      ],
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'store_id'
        },
        {
          name: 'Internal',
          field: ['short_name']
        },
        {
          name: 'Revenue Centers',
          field: ['browse revenue centers', 'revenue-centers'],
          link: 'store_id',
          linkEndpoint: 'stores',
          format: 'path'
        },
        // {
        //   name: 'Revenue Centers',
        //   field: ['revenue_centers.null.revenue_center_type'],
        //   link: 'revenue_center_id',
        //   linkEndpoint: 'revenue-centers/:id',
        //   format: 'array'
        // },
        { name: 'Number', field: ['number'] },
        {
          name: 'Address',
          field: [
            'address.street',
            'address.city',
            'address.state',
            'address.postal_code'
          ],
          format: 'address'
        },
        { name: 'Slug', field: ['slug'] },
        // { name: 'Closed', field: ['closed'], format: 'bool' },
        // { name: 'Coming Soon', field: ['coming_soon'], format: 'bool' },
        // { name: 'New', field: ['is_new'], format: 'bool' },
        { name: 'ID', field: ['store_id'] }
      ]
    }
  },
  '/stores/:id': {
    type: constants.pageType.item,
    title: 'Store',
    subtitle: 'Use the form below to update this store',
    content: '',
    options: ['stores', 'store-groups'],
    list: {
      title: 'Stores',
      endpoint: '/stores',
      id: 'store_id',
      field: 'full_name',
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/stores',
      id: 'store_id',
      title: 'full_name',
      tabs: tabs.stores,
      fields: [
        {
          label: 'Name',
          field: 'full_name',
          type: 'text',
          classes: '-half',
          required: true
        },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Timezone',
          field: 'timezone',
          type: 'select',
          required: true,
          options: [
            { value: '', name: 'none selected' },
            ...[...constants.timezones].slice(1)
          ]
        },
        {
          label: 'Store Number',
          field: 'number',
          type: 'text',
          classes: '-half'
        },
        { label: 'Slug', field: 'slug', type: 'text', classes: '-half -right' },
        {
          label: 'Store Group',
          field: 'store_group_id',
          type: 'select',
          classes: '-half',
          options: {
            endpoint: 'store-groups',
            entity: 'store_group',
            value: 'store_group_id',
            label: 'full_name'
          },
          valType: constants.valTypes.intNullable
        },
        {
          label: 'Parent Store',
          field: 'parent_store_id',
          type: 'select',
          classes: '-half -right',
          options: {
            endpoint: 'stores',
            entity: 'store',
            value: 'store_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Description',
          field: 'description',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        {
          label: 'Hours Description',
          field: 'hours_description',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        { label: 'Closed', field: 'closed', type: 'checkbox' },
        { label: 'Coming Soon', field: 'coming_soon', type: 'checkbox' },
        { label: 'Is New', field: 'is_new', type: 'checkbox' },
        { label: 'Is Master', field: 'is_master', type: 'checkbox' },
        { label: 'KDS Settings', field: 'kds_settings', type: 'break' },
        {
          label: 'Using POS Version 2',
          field: 'has_pos_v2',
          type: 'checkbox'
        },
        { label: 'Hide Delay Button', field: 'hide_delay', type: 'checkbox' },
        {
          label: 'Mark Done on Print',
          field: 'done_on_print',
          type: 'checkbox'
        },
        {
          label: 'Hide Default Modifiers',
          field: 'hide_default_mods',
          type: 'checkbox'
        },
        {
          label: 'Disable Complete until all items complete',
          field: 'disable_complete',
          type: 'checkbox'
        },
        {
          label: 'Print receipt when order completed',
          field: 'print_on_completed',
          type: 'checkbox'
        },
        {
          label: 'Display "Ready At" time on KDS receipt',
          field: 'display_ready_at',
          type: 'checkbox'
        },
        {
          label: 'Display "Promised At" time on KDS receipt',
          field: 'display_promised_at',
          type: 'checkbox'
        },
        {
          label: 'Display Revenue Center on KDS',
          field: 'display_revenue_center',
          type: 'checkbox'
        },
        {
          label: 'Dine-In Prep Time (in minutes)',
          field: 'walkin_prep_minutes',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Dine-In Buffer (in minutes)',
          field: 'walkin_minutes',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Prep Time (in minutes)',
          field: 'prep_minutes',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Pickup Buffer (in minutes)',
          field: 'pickup_minutes',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Delivery Buffer (in minutes)',
          field: 'delivery_minutes',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Prep Warning (in minutes)',
          field: 'warning_minutes',
          type: 'text',
          valType: constants.valTypes.int,
          tooltip: 'This dicates when the "ready by" time turns red'
        },
        {
          label: 'Prep Alert (in minutes)',
          field: 'alert_minutes',
          type: 'text',
          valType: constants.valTypes.int,
          tooltip: 'This dicates when the "ready by" time turns yellow'
        }
      ]
    }
  },
  '/stores/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this store',
    list: listSettings.stores,
    item: itemSettings.stores,
    files: {
      endpoint: '/stores/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/stores/:id/address': {
    type: constants.pageType.itemSetting,
    title: 'Address',
    subtitle: storeSettingSubtitle('address'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/address',
      id: 'store_id',
      hasDelete: true,
      hasGeocoder: true,
      fields: fields.address
    }
  },
  '/stores/:id/gratuity': {
    type: constants.pageType.itemSetting,
    title: 'Gratuity',
    subtitle: storeSettingSubtitle('gratuity'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/gratuity',
      id: 'store_id',
      hasDelete: true,
      fields: fields.gratuity
    }
  },
  '/stores/:id/email': {
    type: constants.pageType.itemSetting,
    title: 'Email Settings',
    subtitle: storeSettingSubtitle('email'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/email',
      id: 'store_id',
      hasDelete: true,
      fields: fields.email
    }
  },
  '/stores/:id/throttle': {
    type: constants.pageType.itemSetting,
    title: 'Order Throttling',
    subtitle: storeSettingSubtitle('order throttling'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/throttle',
      id: 'store_id',
      hasDelete: true,
      fields: fields.throttle
    }
  },
  '/stores/:id/credit': {
    type: constants.pageType.itemSetting,
    title: 'Payment Processing',
    subtitle: storeSettingSubtitle('payment processing'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/credit',
      id: 'store_id',
      hasDelete: true,
      fields: fields.credit,
      validation: validation.credit
    }
  },
  '/stores/:id/external-pos': {
    type: constants.pageType.itemSetting,
    title: 'POS Integration',
    subtitle: storeSettingSubtitle('POS integration'),
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/external-pos',
      id: 'store_id',
      hasDelete: true,
      fields: fields.externalPOS,
      validation: validation.externalPOS
    }
  },
  '/stores/:id/integrations': {
    type: constants.pageType.itemSetting,
    title: 'Integrations',
    subtitle:
      'These integration settings are only maintained at the store level.',
    list: listSettings.stores,
    item: itemSettings.stores,
    setting: {
      endpoint: '/stores/:id/settings/integrations',
      id: 'store_id',
      hasDelete: true,
      fields: fields.integrations
    }
  },
  '/stores/:id/revenue-centers': {
    type: constants.pageType.itemRelations,
    title: 'Revenue Centers',
    subtitle: 'Below are the revenue centers associated with this store',
    list: listSettings.stores,
    item: itemSettings.stores,
    relations: {
      endpoint: '/stores/:id/revenue-centers',
      hideNew: true,
      id: 'revenue_center_id',
      sorting: {
        sortBy: 'revenue_center_type',
        sortType: 'alpha'
      },
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'revenue_center_id',
          linkEndpoint: '/revenue-centers'
        },
        { name: 'Internal Name', field: ['short_name'] },
        { name: 'Type', field: ['revenue_center_type'] },
        { name: 'Outpost', field: ['is_outpost'], format: 'bool' },
        { name: 'ID', field: ['revenue_center_id'] }
      ]
    }
  },
  '/revenue-centers': {
    type: constants.pageType.list,
    title: 'Revenue Centers',
    subtitle: subtitles.revenueCenters,
    options: ['store-groups', 'regions'],
    list: {
      endpoint: '/revenue-centers',
      id: 'revenue_center_id',
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      params: [
        { field: 'limit', value: 1000 },
        { field: 'expand', value: true },
        { field: 'with_related', value: 'menus' },
        { field: 'with_settings', value: 'address' }
      ],
      filters: [
        {
          label: 'Revenue Center Type',
          type: 'select',
          field: 'revenue_center_type',
          value: 'OLO',
          options: [...constants.revenueCenters].slice(1)
        },
        {
          label: 'Store Group',
          type: 'select',
          field: 'store_group_id',
          settings: {
            endpoint: 'store-groups',
            value: 'store_group_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          sort: true
        },
        {
          label: 'Pricing Region',
          type: 'select',
          field: 'region_id',
          settings: {
            endpoint: 'regions',
            value: 'region_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          sort: true,
          activeOnly: true
        },
        {
          label: 'Open / Closed',
          type: 'select',
          field: 'closed',
          value: '',
          options: constants.closed
        },
        {
          label: 'Outposts',
          type: 'select',
          field: 'is_outpost',
          value: '',
          options: constants.isOutpost
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          separator: ' -- ',
          link: 'revenue_center_id'
        },
        { name: 'Internal', field: ['short_name'] },
        { name: 'Status', field: ['closed'], format: 'closed' },
        { name: 'Outpost', field: ['is_outpost'], format: 'bool' },
        {
          name: 'Address',
          field: [
            'address.street',
            'address.city',
            'address.state',
            'address.postal_code'
          ],
          format: 'address'
        },
        { name: 'Store', field: ['store.full_name'] },
        { name: 'Region', field: ['region.name'] },
        {
          name: 'Menus',
          field: ['menus.menu.name'],
          format: 'array'
        },
        { name: 'Sales Tax', field: ['sales_tax'], format: 'percentage' },
        // { name: 'Coming Soon', field: ['coming_soon'], format: 'bool' },
        // { name: 'New', field: ['is_new'], format: 'bool' },
        { name: 'ID', field: ['revenue_center_id'] }
      ]
    }
  },
  '/revenue-centers/:id': {
    type: constants.pageType.item,
    title: 'Revenue Center',
    subtitle: 'Use the form below to update this revenue center',
    options: ['stores', 'pickup-wait-times', 'delivery-wait-times', 'regions'],
    list: listSettings.revenueCenters,
    item: {
      endpoint: '/revenue-centers',
      id: 'revenue_center_id',
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true }
      ],
      title: 'full_name',
      tabs: tabs.revenueCenters,
      fields: [
        {
          label: 'Store',
          field: 'store_id',
          type: 'select',
          classes: '-half',
          required: true,
          options: {
            endpoint: 'stores',
            entity: 'store',
            value: 'store_id',
            label: 'full_name'
            // hideNone: true
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Sales Tax',
          field: 'sales_tax',
          type: 'text',
          classes: '-half -right',
          required: true
        },
        {
          label: 'Revenue Center Type',
          field: 'revenue_center_type',
          type: 'select',
          classes: '-half',
          readonly: true,
          options: [...constants.revenueCenters].slice(1)
        },
        {
          label: 'Pricing Region (choose "none" for Default)',
          field: 'region_id',
          type: 'select',
          classes: '-half -right',
          options: {
            endpoint: 'regions',
            entity: 'region',
            value: 'region_id',
            label: 'name'
          },
          valType: constants.valTypes.intNullable
        },
        {
          label: 'Default Pickup Wait Time',
          field: 'pickup_wait_time_id',
          type: 'select',
          classes: '-half',
          options: {
            endpoint: 'pickup-wait-times',
            entity: 'pickup_wait_time',
            value: 'pickup_wait_time_id',
            label: 'pickup_wait_time',
            hideNone: true
          },
          valType: constants.valTypes.int,
          tooltip:
            "This revenue center's pickup wait time will be reset to this value at the beginning of each day"
        },
        {
          label: 'Default Delivery Wait Time',
          field: 'delivery_wait_time_id',
          type: 'select',
          classes: '-half -right',
          options: {
            endpoint: 'delivery-wait-times',
            entity: 'delivery_wait_time',
            value: 'delivery_wait_time_id',
            label: 'delivery_wait_time',
            hideNone: true
          },
          valType: constants.valTypes.int
        },
        { label: 'CLOSED', field: 'closed', type: 'checkbox' },
        { label: 'Testing', field: 'is_testing', type: 'checkbox' },
        {
          label: 'Hidden from location lists',
          field: 'is_hidden',
          type: 'checkbox'
        },
        { label: 'Coming Soon', field: 'coming_soon', type: 'checkbox' },
        { label: 'New', field: 'is_new', type: 'checkbox' },
        { label: 'Outpost', field: 'is_outpost', type: 'checkbox' },
        {
          label: 'Parent Revenue Center (for Food Halls)',
          field: 'is_parent',
          type: 'checkbox'
        },
        {
          label: 'Optional Settings',
          field: 'optional_settings',
          type: 'break'
        },
        {
          label: 'Name',
          field: 'full_name',
          type: 'text',
          classes: '-half',
          tooltip:
            'This is only necessary if you want the name to be different than the store name'
        },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          classes: '-half -right',
          tooltip:
            'This is only necessary if you want the internal name to be different than the store internal name'
        },
        {
          label: 'Slug',
          field: 'slug',
          type: 'text',
          tooltip:
            'This determines the location of a dedicated landing page for each revenue center and should be as short as possible. If you leave this field blank, it will be determined for you based on the revenue center name.'
        },
        {
          label: 'Pickup Time Range',
          field: 'pickup_time_range',
          type: 'select',
          classes: '-half',
          options: constants.waitTimeRange,
          defaultValue: 0,
          valType: constants.valTypes.int,
          tooltip: 'Display a time range to the user instead of a specific time'
        },
        {
          label: 'Delivery Time Range',
          field: 'delivery_time_range',
          type: 'select',
          classes: '-half -right',
          options: constants.waitTimeRange,
          defaultValue: 0,
          valType: constants.valTypes.int
        },
        {
          label: 'Pickup Minimum',
          field: 'pickup_minimum',
          type: 'text',
          classes: '-half',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Pickup Maximum',
          field: 'pickup_maximum',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Delivery Minimum',
          field: 'delivery_minimum',
          type: 'text',
          classes: '-half',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Delivery Maximum',
          field: 'delivery_maximum',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Third party delivery URL',
          field: 'delivery_url',
          type: 'text'
        },
        {
          label: 'Online Ordering Days Ahead',
          field: 'days_ahead',
          type: 'select',
          classes: '-half',
          options: constants.daysAhead,
          valType: constants.valTypes.intNullable
        },
        {
          label: 'Printer Type',
          field: 'printer_type',
          type: 'select',
          classes: '-half -right',
          options: constants.printerType,
          defaultValue: 'DESKTOP'
        },
        {
          label: 'Large Order Minimum',
          field: 'large_order_minimum',
          type: 'text',
          classes: '-half',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Large Order Timing',
          field: 'large_order_timing',
          type: 'select',
          classes: '-half -right',
          options: constants.largeOrderTiming,
          defaultValue: 'NOW'
        },
        {
          label: 'Order Lead Time (in hours)',
          field: 'order_lead_time',
          type: 'text',
          classes: '-half',
          valType: constants.valTypes.intNullable
        },
        {
          label: 'Next Day Order Cutoff Time',
          field: 'cutoff_time',
          type: 'select',
          classes: '-half -right',
          options: constants.cutoffTimes,
          valType: constants.valTypes.intNullable
          // defaultValue: null
        },
        {
          label: 'Allow group ordering',
          field: 'group_ordering',
          type: 'checkbox'
        },
        {
          label: 'Group Order Lead Time',
          field: 'group_lead_time',
          type: 'select',
          classes: '-half',
          options: constants.prepTimes,
          valType: constants.valTypes.int,
          tooltip:
            'This is the time you want to suggest that customers allow for their cart guests to submit their orders before a group order automatically closes.'
        },
        {
          label: 'Group Order Exta Prep Time',
          field: 'group_prep_time',
          type: 'select',
          classes: '-half -right',
          options: constants.prepTimes,
          valType: constants.valTypes.int,
          tooltip:
            'This is the extra prep time you want to allow ON TOP OF your current pickup or delivery wait time since group orders tend to be larger and require more time to prepare (naming, etc.).'
        },
        {
          label: 'Group Order Person Limit',
          field: 'group_guest_limit',
          type: 'text',
          valType: constants.valTypes.intNullable,
          defaultValue: null
        },
        {
          label: 'Using Open Tender loyalty features',
          field: 'has_loyalty',
          type: 'checkbox'
        },
        {
          label: 'Has curbside pickup',
          field: 'has_order_fulfillment',
          type: 'checkbox'
        },
        {
          label: 'Allow tax exempt orders',
          field: 'tax_exempt',
          type: 'checkbox'
        },
        {
          label: 'Require tax exempt verification before removing tax',
          field: 'tax_exempt_verify',
          type: 'checkbox'
        },

        {
          label: 'Allow guest order cancellation',
          field: 'guest_order_cancel',
          type: 'checkbox'
        },
        {
          label: 'Is designated as premium',
          field: 'is_premium',
          type: 'checkbox'
        },
        {
          label: 'Show service type filter on order confirmation page',
          field: 'allow_service_type_filter',
          type: 'checkbox'
        },
        {
          label: 'Require confirmation before charging / closing order',
          field: 'confirm_charge',
          type: 'checkbox'
        },
        {
          label: 'Use new menu builder',
          field: 'force_build_with_checkmate',
          type: 'checkbox'
        },
        {
          label: 'Send future orders as ASAP',
          field: 'send_future_as_asap',
          type: 'checkbox'
        },
        { label: 'PDF Link', field: 'pdf_link', type: 'text' },
        {
          label: 'Description',
          field: 'description',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        {
          label: 'Hours Description',
          field: 'hours_desc',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        // {
        //   field: 'initial_receipt_status',
        //   type: 'hidden',
        // },
        {
          label: 'Initial Receipt Status',
          field: 'initial_receipt_status',
          type: 'select',
          options: constants.initialReceiptStatus,
          defaultValue: 'OPEN',
          tooltip: 'Pending is only relevant for catering orders.'
        },
        {
          field: 'ext_dining_option_id',
          type: 'hidden',
          valType: constants.valTypes.nullable
        }
      ]
    }
  },
  '/revenue-centers/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    files: {
      endpoint: '/revenue-centers/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/revenue-centers/:id/menus': {
    type: constants.pageType.itemRelations,
    title: 'Menus',
    subtitle: 'Use this page to associate menus with this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      isList: true,
      endpoint: '/revenue-centers/:id/menus',
      entity: 'menu',
      id: 'menu_id',
      sorting: {
        sortBy: 'menu_position',
        sortType: 'order',
        fields: [],
        isEntity: true
      },
      classes: 'table--rc-menus',
      fields: [
        { name: 'Name', field: ['name'], link: 'menu_id' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['menu_id'] }
      ]
    }
  },
  '/revenue-centers/:id/menus/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Menus',
    subtitle: 'Use this page to associate menus with this revenue center',
    options: ['menus'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      title: 'Menus',
      endpoint: '/revenue-centers/:id/menus',
      id: 'menu_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/revenue-centers/:id/menus',
      id: 'menu_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Menu',
          field: 'menu_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'menus',
            entity: 'menu',
            value: 'menu_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/revenue-centers/:id/menu-preview': {
    type: constants.pageType.menu,
    title: 'Menu Preview',
    subtitle: 'Use this page to preview yours menus at this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/attributes': {
    type: constants.pageType.revenueCenterAttributes,
    title: 'Prices & Other Attributes',
    subtitle:
      'Set prices, serving sizes, minimumns, maximums, and increments for each of your items & modifiers',
    // classes: 'page--revenue-center-attributes',
    options: ['categories', 'modifier-groups', 'regions'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    attributes: {
      endpoint: '/attributes',
      filters: [
        {
          label: 'Browse by category...',
          type: 'select',
          field: 'category_id',
          settings: {
            endpoint: 'categories',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['modifier_group_id'],
          savedFilter: 'category_id',
          isRequired: true,
          isDefault: true
        },
        {
          label: 'Or modifier group...',
          type: 'select',
          field: 'modifier_group_id',
          settings: {
            endpoint: 'modifier-groups',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['category_id'],
          savedFilter: 'modifier_group_id',
          isRequired: true
        },
        {
          label: 'And an attribute type',
          type: 'select',
          field: 'attribute_type',
          value: 'PRICE',
          options: constants.attributes
        }
      ]
    }
  },
  '/stores/:id/inventory': {
    type: constants.pageType.inventory,
    title: 'Inventory',
    subtitle: subtitles.storeInventory,
    options: ['categories', 'modifier-groups'],
    list: listSettings.stores,
    item: itemSettings.stores,
    inventory: {
      endpoint: '/stores/:id/inventory',
      filters: [
        {
          label: 'Browse by category...',
          type: 'select',
          field: 'category_id',
          settings: {
            endpoint: 'categories',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['modifier_group_id']
        },
        {
          label: 'Or modifier group...',
          type: 'select',
          field: 'modifier_group_id',
          settings: {
            endpoint: 'modifier-groups',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['category_id']
        }
      ]
    }
  },
  '/revenue-centers/:id/dayparts': {
    type: constants.pageType.dayparts,
    title: 'Dayparts',
    subtitle:
      'Use this page to set the times at which this revenue offers each type of service',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/delivery-zone': {
    type: constants.pageType.deliveryZone,
    title: 'Delivery Zone',
    subtitle: 'Set your delivery zone, description, or delivery zone priority',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    deliveryZone: {
      endpoint: '/delivery-zones',
      fields: [
        {
          label: 'Priority',
          field: 'priority',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea',
          defaultValue: ''
        }
      ]
    }
  },
  '/revenue-centers/:id/hours': {
    type: constants.pageType.hours,
    title: 'Hours',
    subtitle: 'Set your hours for each of your revenue centers',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/holidays': {
    type: constants.pageType.holidays,
    title: 'Holidays',
    subtitle: 'Set your holiday hours for each of your revenue centers',
    options: ['revenue-centers'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/surcharges': {
    type: constants.pageType.itemRelations,
    title: 'Surcharges',
    subtitle: 'Use this page to associate surcharges with this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      related: 'surcharges',
      endpoint: '/revenue-centers/:id/surcharges',
      id: 'surcharge_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'surcharge_id' },
        { name: 'Type', field: ['surcharge_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Amount', field: ['amount'] },
        { name: 'Is Taxed', field: ['is_taxed'], format: 'bool' }
      ]
    }
  },
  '/revenue-centers/:id/surcharges/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Surcharges',
    subtitle: 'Use this page to associate surcharges with this revenue center',
    options: ['surcharges'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      title: 'Surcharges',
      endpoint: '/revenue-centers/:id/surcharges',
      id: 'surcharge_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/revenue-centers/:id/surcharges',
      id: 'surcharge_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Surcharge',
          field: 'surcharge_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'surcharges',
            entity: 'surcharge',
            value: 'surcharge_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/revenue-centers/:id/taxes': {
    type: constants.pageType.itemRelations,
    title: 'Taxes',
    subtitle: 'Use this page to associate taxes with this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      related: 'taxes',
      endpoint: '/revenue-centers/:id/taxes',
      entity: 'tax',
      id: 'tax_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'tax_id' },
        { name: 'Type', field: ['tax_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'tax_type', type: 'surchargeType' }
        },
        { name: 'Active', field: ['is_active'], format: 'bool' }
      ]
    }
  },
  '/revenue-centers/:id/taxes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Taxes',
    subtitle: 'Use this page to associate taxes with this revenue center',
    options: ['taxes'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relations: {
      title: 'Taxes',
      endpoint: '/revenue-centers/:id/taxes',
      entity: 'tax',
      id: 'tax_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/revenue-centers/:id/taxes',
      id: 'tax_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Tax',
          field: 'tax_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'taxes',
            entity: 'tax',
            value: 'tax_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/revenue-centers/:id/tender-types': {
    type: constants.pageType.itemSetting,
    title: 'Tender Types',
    subtitle:
      'Use this page to associate tender types with this revenue center',
    classes: 'revenue-center-tender-types',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/tender-types',
      id: 'revenue_center_id',
      fields: [
        {
          label: 'Walk-in',
          field: 'WALKIN',
          type: 'checkboxGroup',
          options: constants.tenderTypeOptions,
          valType: constants.valTypes.arrayString
        },
        {
          label: 'Pick-Up',
          field: 'PICKUP',
          type: 'checkboxGroup',
          options: constants.tenderTypeOptions,
          valType: constants.valTypes.arrayString
        },
        {
          label: 'Delivery',
          field: 'DELIVERY',
          type: 'checkboxGroup',
          options: constants.tenderTypeOptions,
          valType: constants.valTypes.arrayString
        }
      ]
    }
  },
  '/revenue-centers/:id/address': {
    type: constants.pageType.itemSetting,
    title: 'Address',
    subtitle: revenueCenterSettingSubtitle('address'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/address',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/address',
        id: 'store_id'
      },
      hasDelete: true,
      hasGeocoder: true,
      fields: fields.address
    }
  },
  '/revenue-centers/:id/gratuity': {
    type: constants.pageType.itemSetting,
    title: 'Gratuity',
    subtitle: revenueCenterSettingSubtitle('gratuity'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/gratuity',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/gratuity',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.gratuity
    }
  },
  '/revenue-centers/:id/email': {
    type: constants.pageType.itemSetting,
    title: 'Email Settings',
    subtitle: revenueCenterSettingSubtitle('email'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/email',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/email',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.email
    }
  },
  '/revenue-centers/:id/throttle': {
    type: constants.pageType.itemSetting,
    title: 'Order Throttling',
    subtitle: revenueCenterSettingSubtitle('order throttling'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/throttle',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/throttle',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.throttle
    }
  },
  '/revenue-centers/:id/credit': {
    type: constants.pageType.itemSetting,
    title: 'Payment Processing',
    subtitle: revenueCenterSettingSubtitle('payment processing'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/credit',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/credit',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.credit,
      validation: validation.credit
    }
  },
  '/revenue-centers/:id/delivery': {
    type: constants.pageType.itemSetting,
    title: 'Delivery Integration',
    subtitle: revenueCenterSettingSubtitle('delivery integration'),
    options: ['surcharges'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/delivery',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/delivery',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.delivery,
      validation: validation.delivery
    }
  },
  '/revenue-centers/:id/external-pos': {
    type: constants.pageType.itemSetting,
    title: 'POS Integration',
    subtitle: revenueCenterSettingSubtitle('POS integration'),
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/external-pos',
      id: 'revenue_center_id',
      parent: {
        entity: 'store',
        child: 'revenue center',
        endpoint: '/stores/:id/external-pos',
        id: 'store_id'
      },
      hasDelete: true,
      fields: fields.externalPOS,
      validation: validation.externalPOS
    }
  },
  '/revenue-centers/:id/crm': {
    type: constants.pageType.itemSetting,
    title: 'CRM Integration',
    subtitle:
      'CRM integration settings are only set at the revenue center level, not the store level.',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/crm',
      id: 'revenue_center_id',
      hasDelete: true,
      fields: fields.crm,
      validation: validation.crm
    }
  },
  '/revenue-centers/:id/pos': {
    type: constants.pageType.itemSetting,
    title: 'POS Settings',
    subtitle:
      'POS settings are only set at the revenue center level, not the store level.',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    setting: {
      endpoint: '/revenue-centers/:id/settings/pos',
      id: 'revenue_center_id',
      hasDelete: true,
      fields: fields.pos,
      validation: validation.pos
    }
  },
  '/revenue-centers/:id/blocked-hours': {
    type: constants.pageType.blockedHours,
    title: 'Blocked Hours',
    subtitle:
      'Use this page to manage your blocked ordering times at this revenue center',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/order-times': {
    type: constants.pageType.orderTimes,
    title: 'Order Windows',
    subtitle:
      'Order windows can be used in place of hours for one or more service types. That is, you could use regular hours for pickup orders but set specific order windows for delivery.',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters
  },
  '/revenue-centers/:id/devices': {
    type: constants.pageType.itemLists,
    title: 'POS Devices',
    subtitle: 'Use this page to manage your POS devices',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    itemLists: [
      {
        title: 'Hub Servers',
        endpoint: '/pos-servers',
        filter: 'revenue_center_id',
        id: 'pos_server_id',
        sorting: { sortBy: 'name', sortType: 'alpha' },
        fields: [
          { name: 'Name', field: ['name'], link: 'pos_server_id' },
          { name: 'Active', field: ['is_active'], format: 'bool' },
          { name: 'IP Address', field: ['ip_address'] },
          { name: 'Last Ping', field: ['last_alert_sent'], format: 'datetime' },
          { name: 'ID', field: ['pos_server_id'] }
        ]
      },
      {
        title: 'Terminals',
        endpoint: '/pos-terminals',
        filter: 'revenue_center_id',
        id: 'pos_terminal_id',
        sorting: { sortBy: 'name', sortType: 'alpha' },
        fields: [
          { name: 'Name', field: ['name'], link: 'pos_terminal_id' },
          { name: 'IP Address', field: ['ip_address'] },
          { name: 'ID', field: ['pos_terminal_id'] }
        ]
      },
      {
        title: 'Printers',
        endpoint: '/pos-printers',
        filter: 'revenue_center_id',
        id: 'pos_printer_id',
        sorting: { sortBy: 'name', sortType: 'alpha' },
        fields: [
          { name: 'Name', field: ['name'], link: 'pos_printer_id' },
          { name: 'IP Address', field: ['ip_address'] },
          {
            name: 'Print KDS Receipt',
            field: ['print_kds_receipt'],
            format: 'bool'
          },
          { name: 'ID', field: ['pos_printer_id'] }
        ]
      },
      {
        title: 'Kitchen Display Systems',
        endpoint: '/kds-terminals',
        filter: 'revenue_center_id',
        id: 'kds_terminal_id',
        sorting: { sortBy: 'name', sortType: 'alpha' },
        fields: [
          { name: 'Name', field: ['name'], link: 'kds_terminal_id' },
          { name: 'IP Address', field: ['ip_address'] },
          { name: 'Active', field: ['is_active'], format: 'bool' },
          { name: 'ID', field: ['kds_terminal_id'] }
        ]
      }
    ]
  },
  '/revenue-centers/:id/pos-servers/:idd': {
    type: constants.pageType.relatedItem,
    title: 'Hub Server',
    subtitle: 'Update settings for this hub server',
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relatedItems: {
      title: 'Hub Servers',
      endpoint: '/pos-servers',
      filter: 'revenue_center_id',
      id: 'pos_server_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relatedItem: {
      endpoint: '/pos-servers',
      backEndpoint: '/revenue-centers/:id/devices',
      id: 'pos_server_id',
      title: 'name',
      // hasDelete: true,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        { label: 'Active', field: 'is_active', type: 'checkbox' }
      ]
    }
  },
  '/revenue-centers/:id/pos-terminals/:idd': {
    type: constants.pageType.relatedItem,
    title: 'Terminal',
    subtitle: 'Update settings for this terminal',
    options: [
      { endpoint: 'pos-servers', filter: 'revenue_center_id' },
      { endpoint: 'pos-printers', filter: 'revenue_center_id' }
    ],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relatedItems: {
      title: 'Terminals',
      endpoint: '/pos-terminals',
      filter: 'revenue_center_id',
      id: 'pos_terminal_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relatedItem: {
      endpoint: '/pos-terminals',
      backEndpoint: '/revenue-centers/:id/devices',
      id: 'pos_terminal_id',
      title: 'name',
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        {
          label: 'Hub Server',
          field: 'pos_server_id',
          type: 'select',
          options: {
            endpoint: 'pos-servers',
            filter: 'revenue_center_id',
            entity: 'pos_server',
            value: 'pos_server_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Printer',
          field: 'pos_printer_id',
          type: 'select',
          options: {
            endpoint: 'pos-printers',
            entity: 'pos_printer',
            value: 'pos_printer_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Print Receipts',
          field: 'print_receipt',
          type: 'select',
          options: constants.printReceiptOptions
        },
        { label: 'Print to KDS', field: 'print_kds', type: 'checkbox' },
        { label: 'Has Timeclock', field: 'has_timeclock', type: 'checkbox' },
        { label: 'Has Cash Drawer', field: 'has_cashdrawer', type: 'checkbox' },
        { label: 'Has Credit Pinpad', field: 'has_pinpad', type: 'checkbox' },
        { label: 'Has Card Reader', field: 'has_cardreader', type: 'checkbox' },
        {
          label: 'Card Reader Device ID',
          field: 'cardreader_id',
          type: 'text'
        },
        { label: 'Has Barcode Scanner', field: 'has_qrcode', type: 'checkbox' },
        {
          label: 'Barcode Scanner Device ID',
          field: 'scanner_id',
          type: 'text'
        },
        { label: 'Is Active', field: 'is_active', type: 'checkbox' },
        {
          field: 'has_cardwriter',
          type: 'hidden',
          valType: constants.valTypes.bool
        }
      ]
    }
  },
  '/revenue-centers/:id/pos-printers/:idd': {
    type: constants.pageType.relatedItem,
    title: 'Receipt Printer',
    subtitle: 'Update settings for this receipt printer',
    options: ['pos-printers'],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relatedItems: {
      title: 'Receipt Printers',
      endpoint: '/pos-printers',
      filter: 'revenue_center_id',
      id: 'pos_printer_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relatedItem: {
      endpoint: '/pos-printers',
      backEndpoint: '/revenue-centers/:id/devices',
      id: 'pos_printer_id',
      title: 'name',
      hasDelete: true,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        {
          label: 'Backup Printer',
          field: 'backup_pos_printer_id',
          type: 'select',
          options: {
            endpoint: 'pos-printers',
            // entity: 'POS',
            value: 'pos_printer_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Print KDS Receipt when order hits assembly queue',
          field: 'print_kds_receipt',
          type: 'checkbox',
          tooltip:
            'If checked, the KDS receipt will print automatically when an order moves into the assembly queue.'
        },
        {
          label: 'Use beeper or buzzer',
          field: 'use_beeper',
          type: 'checkbox',
          tooltip:
            "If checked, we'll attempt to make a request to a beeper or buzzer attached to your receipt printer when a ticket is printed."
        }
      ]
    }
  },
  '/revenue-centers/:id/kds-terminals/:idd': {
    type: constants.pageType.relatedItem,
    title: 'Kitchen Display System',
    subtitle: 'Update settings for this kitchen display system',
    options: [{ endpoint: 'pos-printers', filter: 'revenue_center_id' }],
    list: listSettings.revenueCenters,
    item: itemSettings.revenueCenters,
    relatedItems: {
      title: 'Kitchen Display Systems',
      endpoint: '/kds-terminals',
      filter: 'revenue_center_id',
      id: 'kds_terminal_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relatedItem: {
      endpoint: '/kds-terminals',
      backEndpoint: '/revenue-centers/:id/devices',
      id: 'kds_terminal_id',
      title: 'name',
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          label: 'Printer',
          field: 'pos_printer_id',
          type: 'select',
          options: {
            endpoint: 'pos-printers',
            entity: 'pos_printer',
            value: 'pos_printer_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/kds-terminals': {
    type: constants.pageType.list,
    title: 'KDS Terminals',
    subtitle: 'All your KDS terminals across all of your revenue centers',
    classes: 'app--aside',
    options: ['revenue-centers'],
    list: {
      endpoint: '/kds-terminals',
      id: 'kds_terminal_id',
      search: {
        label: 'Or search by name',
        endpoint: 'kds-terminals',
        placeholder: 'enter name + hit enter'
      },
      params: [{ field: 'expand', value: 'revenue_center,pos_printer' }],
      sorting: { sortBy: 'name', sortType: 'alpha' },
      filters: [
        {
          label: 'Revenue Center',
          type: 'select',
          field: 'revenue_center_id',
          settings: {
            endpoint: 'revenue-centers',
            key: 'POS',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: '',
          options: constants.active
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'kds_terminal_id' },
        { name: 'IP Address', field: ['ip_address'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Revenue Center', field: ['revenue_center.full_name'] },
        { name: 'Printer', field: ['pos_printer.name'] },
        { name: 'ID', field: ['kds_terminal_id'] }
      ]
    }
  },
  '/kds-terminals/:id': {
    type: constants.pageType.item,
    title: 'KDS Terminal',
    subtitle: 'Use the form below to update this KDS terminal',
    options: ['revenue-centers', 'pos-printers'],
    list: listSettings.kdsTerminals,
    item: {
      endpoint: '/kds-terminals',
      id: 'kds_terminal_id',
      title: 'name',
      tabs: tabs.kdsTerminals,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          label: 'Revenue Center',
          field: 'revenue_center_id',
          type: 'select',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Printer',
          field: 'pos_printer_id',
          type: 'select',
          options: {
            endpoint: 'pos-printers',
            entity: 'pos_printer',
            value: 'pos_printer_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/kds-terminals/:id/item-types': {
    type: constants.pageType.itemRelations,
    title: 'KDS Item Types',
    subtitle: 'Use this page to associate item types with this KDS terminal',
    list: listSettings.kdsTerminals,
    item: itemSettings.kdsTerminals,
    relations: {
      related: 'item_types',
      endpoint: '/kds-terminals/:id/item-types',
      id: 'item_type_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'item_type_id' },
        { name: 'Prep Time', field: ['prep_time'] },
        { name: 'Default', field: ['is_default'], format: 'bool' },
        { name: 'Grouped', field: ['is_grouped'], format: 'bool' },
        { name: 'ID', field: ['item_type_id'] }
      ]
    }
  },
  '/kds-terminals/:id/item-types/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Item Types',
    subtitle: 'Use this page to associate item types with this KDS terminal',
    options: ['item-types'],
    list: listSettings.kdsTerminals,
    item: itemSettings.kdsTerminals,
    relations: {
      title: 'Item Types',
      endpoint: '/kds-terminals/:id/item-types',
      id: 'item_type_id',
      field: 'name',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    relation: {
      endpoint: '/kds-terminals/:id/item-types',
      id: 'item_type_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Item Types',
          field: 'item_type_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'item-types',
            // entity: 'item_type',
            value: 'item_type_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/prep-stations': {
    type: constants.pageType.list,
    title: 'Prep Stations',
    subtitle: 'All your prep stations across all of your stores',
    // classes: 'app--aside',
    options: ['stores'],
    list: {
      endpoint: '/prep-stations',
      id: 'prep_station_id',
      search: {
        label: 'Or search by name',
        endpoint: 'prep-stations',
        placeholder: 'enter name + hit enter'
      },
      params: [{ field: 'expand', value: 'store' }],
      sorting: { sortBy: 'name', sortType: 'alpha' },
      filters: [
        {
          label: 'Store',
          type: 'select',
          field: 'store_id',
          settings: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: '',
          options: constants.active
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'prep_station_id' },
        { name: 'Prep Type', field: ['prep_type'], format: 'enum' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Store', field: ['store.full_name'] },
        { name: 'ID', field: ['prep_station_id'] },
        {
          name: 'Display Modifiers',
          field: ['show_modifiers'],
          format: 'bool'
        },
        { name: 'Display Groups', field: ['show_groups'], format: 'bool' },
        { name: 'Display Counts', field: ['show_counts'], format: 'bool' }
      ]
    }
  },
  '/prep-stations/:id': {
    type: constants.pageType.item,
    title: 'Prep Station',
    subtitle: 'Use the form below to update this prep station',
    options: ['stores'],
    list: listSettings.prepStations,
    item: {
      endpoint: '/prep-stations',
      id: 'prep_station_id',
      title: 'name',
      tabs: tabs.prepStations,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Store',
          field: 'store_id',
          type: 'select',
          options: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Prep Type',
          type: 'select',
          field: 'prep_type',
          value: '',
          options: constants.prepType
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          label: 'Show Print on Assembly',
          field: 'assembly_print',
          type: 'checkbox'
        },
        {
          label: 'Show Done on Assembly',
          field: 'assembly_done',
          type: 'checkbox'
        },
        {
          label: 'Only show Done orders on Expo',
          field: 'expo_done_only',
          type: 'checkbox'
        },
        {
          label: 'Show Print on Expo',
          field: 'expo_print',
          type: 'checkbox'
        },
        {
          label: 'Show Reset on Expo',
          field: 'expo_reset',
          type: 'checkbox'
        },
        {
          label: 'Require Ticket Complete on Expo',
          field: 'expo_complete',
          type: 'checkbox'
        },
        {
          label: 'Allow Complete All Tickets',
          field: 'expo_complete_all',
          type: 'checkbox'
        },
        {
          label: 'Show Modifiers',
          field: 'show_modifiers',
          type: 'checkbox'
        },
        {
          label: 'Show Groups',
          field: 'show_groups',
          type: 'checkbox'
        },
        {
          label: 'Show Counts',
          field: 'show_counts',
          type: 'checkbox'
        }
      ]
    }
  },
  '/prep-stations/:id/item-types': {
    type: constants.pageType.itemRelations,
    title: 'Prep Station Item Types',
    subtitle: 'Use this page to associate item types with this prep station',
    list: listSettings.prepStations,
    item: itemSettings.prepStations,
    relations: {
      related: 'item_types',
      endpoint: '/prep-stations/:id/item-types',
      id: 'item_type_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'item_type_id' },
        { name: 'Prep Time', field: ['prep_time'] },
        { name: 'Default', field: ['is_default'], format: 'bool' },
        { name: 'Grouped', field: ['is_grouped'], format: 'bool' },
        { name: 'ID', field: ['item_type_id'] }
      ]
    }
  },
  '/prep-stations/:id/item-types/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Item Types',
    subtitle: 'Use this page to associate item types with this prep station',
    options: ['item-types'],
    list: listSettings.prepStations,
    item: itemSettings.prepStations,
    relations: {
      title: 'Item Types',
      endpoint: '/prep-stations/:id/item-types',
      id: 'item_type_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/prep-stations/:id/item-types',
      id: 'item_type_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Item Types',
          field: 'item_type_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'item-types',
            // entity: 'item_type',
            value: 'item_type_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/pos-printers': {
    type: constants.pageType.list,
    title: 'POS Printers',
    subtitle: 'All your POS printers across all of your revenue centers',
    classes: 'app--aside',
    options: ['revenue-centers'],
    list: {
      endpoint: '/pos-printers',
      id: 'pos_printer_id',
      search: {
        label: 'Or search by name',
        endpoint: 'pos-printers',
        placeholder: 'enter name + hit enter'
      },
      params: [{ field: 'expand', value: 'revenue_center' }],
      sorting: { sortBy: 'name', sortType: 'alpha' },
      filters: [
        {
          label: 'Revenue Center',
          type: 'select',
          field: 'revenue_center_id',
          settings: {
            endpoint: 'revenue-centers',
            key: 'POS',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'pos_printer_id' },
        { name: 'IP Address', field: ['ip_address'] },
        { name: 'KDS Receipt', field: ['print_kds_receipt'], format: 'bool' },
        { name: 'Revenue Center', field: ['revenue_center.full_name'] },
        { name: 'ID', field: ['pos_printer_id'] }
      ]
    }
  },
  '/pos-printers/:id': {
    type: constants.pageType.item,
    title: 'POS Printer',
    subtitle: 'Use the form below to update this POS printer',
    options: ['revenue-centers', 'pos-printers'],
    list: listSettings.posPrinters,
    item: {
      endpoint: '/pos-printers',
      id: 'kds_terminal_id',
      title: 'name',
      tabs: tabs.posPrinters,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'IP Address',
          field: 'ip_address',
          type: 'text',
          required: true
        },
        {
          label: 'Revenue Center',
          field: 'revenue_center_id',
          type: 'select',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Backup Printer',
          field: 'backup_pos_printer_id',
          type: 'select',
          options: {
            endpoint: 'pos-printers',
            // entity: 'POS',
            value: 'pos_printer_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Print KDS Receipt when order hits assembly queue',
          field: 'print_kds_receipt',
          type: 'checkbox',
          tooltip:
            'If checked, the KDS receipt will print automatically when an order moves into the assembly queue.'
        },
        {
          label: 'Use beeper or buzzer',
          field: 'use_beeper',
          type: 'checkbox',
          tooltip:
            "If checked, we'll attempt to make a request to a beeper or buzzer attached to your receipt printer when a ticket is printed."
        }
      ]
    }
  },
  '/pos-printers/:id/item-types': {
    type: constants.pageType.itemRelations,
    title: 'POS Printer Item Types',
    subtitle: 'Use this page to associate item types with this POS printer',
    list: listSettings.posPrinters,
    item: itemSettings.posPrinters,
    relations: {
      related: 'item_types',
      endpoint: '/pos-printers/:id/item-types',
      id: 'item_type_id',
      sorting: { sortBy: 'display_order', sortType: 'order', noDrag: true },
      fields: [
        { name: 'Name', field: ['name'], link: 'item_type_id' },
        { name: 'Prep Time', field: ['prep_time'] },
        { name: 'Default', field: ['is_default'], format: 'bool' },
        { name: 'Grouped', field: ['is_grouped'], format: 'bool' },
        { name: 'ID', field: ['item_type_id'] }
      ]
    }
  },
  '/pos-printers/:id/item-types/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Item Types',
    subtitle: 'Use this page to associate item types with this POS printer',
    options: ['item-types'],
    list: listSettings.posPrinters,
    item: itemSettings.posPrinters,
    relations: {
      title: 'Item Types',
      endpoint: '/pos-printers/:id/item-types',
      id: 'item_type_id',
      field: 'name',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    relation: {
      endpoint: '/pos-printers/:id/item-types',
      id: 'item_type_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Item Types',
          field: 'item_type_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'item-types',
            // entity: 'item_type',
            value: 'item_type_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/content': {
    type: constants.pageType.content,
    title: 'Content',
    subtitle: 'This is where you edit content for your website',
    image: { url: images.content, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Pages',
            description:
              'Individual pages that appear on your Open Tender-hosted site',
            endpoint: '/pages',
            setting: 'WEBSITE'
          },
          {
            title: 'Posts',
            description:
              'Image sliders and snippets of content that appear on various pages of your website',
            endpoint: '/posts',
            setting: 'WEBSITE'
          },
          {
            title: 'Post Categories',
            description:
              'Categories of posts that are used to organize content on your website',
            endpoint: '/post_categories',
            setting: 'WEBSITE'
          },
          {
            title: 'Post Tags',
            description:
              'Attached to posts and used to highlight certain content on your website',
            endpoint: '/post_tags',
            setting: 'WEBSITE'
          },
          {
            title: 'Forms',
            description:
              'Custom forms that are you used to collect feedback from your customers',
            endpoint: '/forms',
            setting: 'WEBSITE'
          },
          {
            title: 'Page Templates',
            description:
              'Different templates with unique designs used for different pages of your website',
            endpoint: '/templates',
            setting: 'WEBSITE'
          }
        ]
      }
    ]
  },
  '/pages': {
    type: constants.pageType.list,
    title: 'Pages',
    subtitle: '',
    content: '',
    list: {
      endpoint: '/pages',
      id: 'page_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      params: [{ field: 'with_related', value: true }],
      filters: [
        {
          label: 'Page Status',
          type: 'select',
          field: 'status',
          value: '',
          options: constants.pageStatus
        }
      ],
      fields: [
        { name: 'Title', field: ['title'], link: 'page_id' },
        { name: 'Order', field: ['display_order'] },
        { name: 'Status', field: ['status'], format: 'enum' },
        { name: 'Published', field: ['published_at'] },
        { name: 'Template', field: ['template.name'] },
        { name: 'Author', field: ['user.first_name'] }
      ]
    }
  },
  '/pages/:id': {
    type: constants.pageType.item,
    title: 'Page',
    subtitle: 'Use the form below to update this page',
    content: '',
    options: ['templates'],
    list: {
      title: 'Pages',
      endpoint: '/pages',
      id: 'page_id',
      field: 'title',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    item: {
      endpoint: '/pages',
      id: 'page_id',
      title: 'title',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ],
      fields: [
        {
          label: 'Template',
          field: 'template_id',
          type: 'select',
          classes: '-half',
          options: {
            endpoint: 'templates',
            entity: 'PAGE',
            value: 'template_id',
            label: 'name'
          },
          valType: constants.valTypes.int,
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          classes: '-half -right',
          options: [...constants.pageStatus].slice(1)
        },
        { label: 'Title', field: 'title', type: 'text', required: true },
        { label: 'Navigation Title', field: 'title_nav', type: 'text' },
        { label: 'Subitle', field: 'subtitle', type: 'text' },
        { label: 'Excerpt', field: 'excerpt', type: 'text' },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        { label: 'Slug', field: 'slug', type: 'text' },
        { label: 'Redirect', field: 'redirect', type: 'text' },
        {
          label: 'Open in new window',
          field: 'new_window',
          type: 'checkbox'
        },
        { label: 'Classes', field: 'classes', type: 'text' },
        { label: 'SEO Title', field: 'seo_title', type: 'text' },
        { label: 'SEO Description', field: 'seo_description', type: 'text' }
      ]
    }
  },
  '/pages/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images & other files for this page',
    content: '',
    list: {
      title: 'Pages',
      endpoint: '/pages',
      id: 'page_id',
      field: 'title',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    item: {
      endpoint: '/pages',
      id: 'page_id',
      title: 'title',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ],
      fields: []
    },
    files: {
      endpoint: '/pages/:id/files',
      id: 'file_id',
      files: [
        { name: 'PDF', type: 'PDF' },
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Featured Image', type: 'FEATURED_IMAGE' },
        { name: 'Secondary Image', type: 'SECONDARY_IMAGE' }
      ]
    }
  },
  '/posts': {
    type: constants.pageType.list,
    title: 'Posts',
    subtitle: 'Below is a list of the posts on your website',
    options: ['post_categories'],
    list: {
      endpoint: '/posts',
      id: 'post_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true }
      ],
      filters: [
        {
          label: 'Post Category',
          type: 'select',
          field: 'post_category_id',
          settings: {
            endpoint: 'post_categories',
            value: 'post_category_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          savedFilter: 'post_category_id',
          includeNone: true,
          required: true,
          sort: true,
          includeUnassigned: true
        },
        {
          label: 'Post Status',
          type: 'select',
          field: 'status',
          value: '',
          options: constants.pageStatus
        }
      ],
      fields: [
        { name: 'Title', field: ['title'], link: 'post_id' },
        { name: 'Category', field: ['post_category.name'] },
        { name: 'Tags', field: ['post_tags.post_tag.name'], format: 'array' },
        { name: 'Order', field: ['display_order'] },
        { name: 'Status', field: ['status'], format: 'enum' },
        { name: 'Published', field: ['published_at'], format: 'datetime' },
        { name: 'Template', field: ['template.name'] },
        { name: 'Author', field: ['user.first_name', 'user.last_name'] }
      ]
    }
  },
  '/posts/:id': {
    type: constants.pageType.item,
    title: 'Post',
    subtitle: 'Use the form below to update this post',
    content: '',
    options: ['templates', 'post_categories'],
    list: {
      endpoint: '/posts',
      title: 'Posts',
      id: 'post_id',
      field: 'title',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      filterBy: { field: 'post_category_id', value: null }
    },
    item: {
      endpoint: '/posts',
      id: 'post_id',
      title: 'title',
      tabs: tabs.posts,
      fields: [
        {
          label: 'Template',
          field: 'template_id',
          type: 'select',
          classes: '-half',
          options: {
            endpoint: 'templates',
            entity: 'POST',
            value: 'template_id',
            label: 'name'
          },
          valType: constants.valTypes.int,
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          classes: '-half -right',
          options: [
            { value: 'DRAFT', name: 'Draft' },
            { value: 'PUBLISHED', name: 'Published' },
            { value: 'PRIVATE', name: 'Private' },
            { value: 'TRASHED', name: 'Trashed' }
          ]
        },
        {
          label: 'Post Category',
          field: 'post_category_id',
          type: 'select',
          options: {
            endpoint: 'post_categories',
            entity: 'post_category',
            value: 'post_category_id',
            label: 'name'
          },
          valType: constants.valTypes.int,
          required: true
        },
        { label: 'Title', field: 'title', type: 'text', required: true },
        { label: 'Subitle', field: 'subtitle', type: 'text' },
        { label: 'Excerpt', field: 'excerpt', type: 'text' },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg
        },
        { label: 'Slug', field: 'slug', type: 'text' },
        { label: 'Link', field: 'link', type: 'text' },
        { label: 'Open in new window', field: 'new_window', type: 'checkbox' }
      ]
    }
  },
  '/posts/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images & other files for this post',
    content: '',
    list: listSettings.posts,
    item: itemSettings.posts,
    files: {
      endpoint: '/posts/:id/files',
      id: 'file_id',
      files: [
        { name: 'PDF', type: 'PDF' },
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Featured Image', type: 'FEATURED_IMAGE' },
        { name: 'Secondary Image', type: 'SECONDARY_IMAGE' }
      ]
    }
  },
  '/posts/:id/post_tags': {
    type: constants.pageType.itemRelations,
    title: 'Post Tags',
    subtitle: 'Use this page to associate tags with this post',
    list: listSettings.posts,
    item: itemSettings.posts,
    relations: {
      endpoint: '/posts/:id/post_tags',
      entity: 'post_tag',
      id: 'post_tag_id',
      sorting: { sortBy: 'post_tag.name', sortType: 'alpha' },
      fields: [
        {
          name: 'Name',
          field: ['post_tag.name'],
          link: 'post_tag.post_tag_id'
        },
        { name: 'ID', field: ['post_tag.post_tag_id'] }
      ]
    }
  },
  '/posts/:id/post_tags/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Tags',
    subtitle: 'Use this page to associate tags with this post',
    options: ['post_tags'],
    list: listSettings.posts,
    item: itemSettings.posts,
    relations: {
      title: 'Tags',
      endpoint: '/posts/:id/post_tags',
      entity: 'post_tag',
      id: 'post_tag_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/posts/:id/post_tags',
      id: 'post_tag_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Tag',
          field: 'post_tag_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'post_tags',
            entity: 'post_tag',
            value: 'post_tag_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/post_categories': {
    type: constants.pageType.list,
    title: 'Post Categories',
    subtitle:
      'Used to put categories in groups that can be displayed in certain places on your site',
    classes: 'app--aside',
    list: {
      endpoint: '/post_categories',
      id: 'post_category_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'post_category_id' },
        { name: 'Slug', field: ['slug'] }
        // { name: 'Description', field: ['description'] }
      ]
    }
  },
  '/post_categories/:id': {
    type: constants.pageType.item,
    title: 'Post Category',
    subtitle: 'Use the form below to update this post category',
    list: {
      title: 'Post Categories',
      endpoint: '/post_categories',
      id: 'post_category_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/post_categories',
      id: 'post_category_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Slug', field: 'slug', type: 'text' },
        { label: 'Description', field: 'description', type: 'text' }
      ]
    }
  },
  '/post_tags': {
    type: constants.pageType.list,
    title: 'Post Tags',
    subtitle:
      'Post tagse can be attached to posts for post highlighting and filtering',
    classes: 'app--aside',
    list: {
      endpoint: '/post_tags',
      id: 'post_tag_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'post_tag_id' },
        { name: 'Slug', field: ['slug'] }
      ]
    }
  },
  '/post_tags/:id': {
    type: constants.pageType.item,
    title: 'Post Tag',
    subtitle: 'Use the form below to update this post tag',
    list: {
      title: 'Post Tags',
      endpoint: '/post_tags',
      id: 'post_tag_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/post_tags',
      id: 'post_tag_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Slug', field: 'slug', type: 'text' }
      ]
    }
  },
  '/forms': {
    type: constants.pageType.list,
    title: 'Forms',
    subtitle:
      'Custom forms that can live pretty much anywhere on your site (with a little help from our dev team)',
    list: {
      endpoint: '/forms',
      id: 'form_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'form_id' },
        { name: 'Slug', field: ['slug'] },
        { name: 'Title', field: ['title'] },
        { name: 'Recipients', field: ['recipients'] },
        { name: 'Active', field: ['is_active'], format: 'bool' }
      ]
    }
  },
  '/forms/:id': {
    type: constants.pageType.item,
    title: 'Form',
    subtitle: 'Use the form below to update this custom form',
    content: '',
    list: {
      title: 'Forms',
      endpoint: '/forms',
      id: 'form_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/forms',
      id: 'form_id',
      title: 'name',
      hasDelete: true,
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Fields', path: 'fields' }
      ],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Slug (read-only)',
          field: 'slug',
          type: 'text',
          readonly: true
        },
        {
          label: 'Recipients',
          field: 'recipients',
          type: 'text',
          required: true
        },
        { label: 'Title', field: 'title', type: 'text', required: true },
        {
          label: 'Description',
          field: 'description',
          type: 'text',
          required: true
        },
        {
          label: 'Submit Button',
          field: 'submit',
          type: 'text',
          required: true
        },
        {
          label: 'Success Message',
          field: 'success',
          type: 'text',
          required: true
        },
        {
          label: 'Include recaptcha',
          field: 'include_recaptcha',
          type: 'checkbox'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        }
      ]
    }
  },
  '/forms/:id/fields': {
    type: constants.pageType.itemRelations,
    title: 'Fields',
    subtitle: '',
    list: {
      title: 'Forms',
      endpoint: '/forms',
      id: 'form_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/forms',
      id: 'form_id',
      title: 'name',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Fields', path: 'fields' }
      ]
    },
    relations: {
      endpoint: '/forms/:id/fields',
      id: 'field_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      fields: [
        { name: 'Label', field: ['label'], link: 'field_id' },
        { name: 'Type', field: ['type'] },
        { name: 'Order', field: ['display_order'] },
        { name: 'Name', field: ['name'] },
        { name: 'Options', field: ['options'] }
      ]
    }
  },
  '/forms/:id/fields/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Field',
    subtitle: 'Use the form below to update this field',
    list: {
      title: 'Forms',
      endpoint: '/forms',
      id: 'form_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/forms',
      id: 'form_id',
      title: 'name',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Fields', path: 'fields' }
      ]
    },
    relations: {
      title: 'Fields',
      endpoint: '/forms/:id/fields',
      id: 'field_id',
      field: ['label'],
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    relation: {
      endpoint: '/forms/:id/fields',
      isItem: true,
      id: 'field_id',
      title: 'label',
      hasDelete: true,
      fields: [
        { label: 'Label', field: 'label', type: 'text', required: true },
        // {
        //   label: 'Name (read-only)',
        //   field: 'name',
        //   type: 'text',
        //   readonly: true
        // },
        { field: 'name', type: 'hidden' },
        {
          label: 'Type',
          field: 'type',
          type: 'select',
          options: constants.fieldTypes
        },
        {
          label: 'Placeholder',
          field: 'placeholder',
          type: 'text'
        },
        { label: 'Comment', field: 'comment', type: 'text' },
        {
          label: 'Options',
          field: 'options',
          type: 'textarea'
        },
        {
          label: 'Required Field',
          field: 'required',
          type: 'checkbox'
        }
        // { field: 'display_order', type: 'hidden', valType: constants.valTypes.int }
      ]
    }
  },
  '/templates': {
    type: constants.pageType.list,
    title: 'Templates',
    subtitle: 'Used to apply specialized designs to pages & posts',
    list: {
      endpoint: '/templates',
      id: 'template_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      // filters: [
      //   {
      //     label: 'Template Type',
      //     type: 'select',
      //     field: 'type',
      //     value: '',
      //     options: constants.templateType
      //   }
      // ],
      fields: [
        { name: 'Name', field: ['name'], link: 'template_id' },
        { name: 'Type', field: ['type'], format: 'enum' },
        { name: 'Filename', field: ['path'] }
      ]
    }
  },
  '/templates/:id': {
    type: constants.pageType.item,
    title: 'Template',
    subtitle: 'Use the form below to update this template',
    list: {
      title: 'Templates',
      endpoint: '/templates',
      id: 'template_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/templates',
      id: 'template_id',
      title: 'name',
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Filename', field: 'path', type: 'text', required: true },
        {
          label: 'Template Type',
          field: 'type',
          type: 'select',
          options: [...constants.templateType].slice(1)
        }
      ]
    }
  },
  '/menu-mgmt': {
    type: constants.pageType.content,
    title: 'Menus',
    subtitle:
      'Use this section to manage the different menus in use across your stores',
    image: { url: images.menu, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Regenerate',
            description: subtitles.regenerate,
            endpoint: '/regenerate-menus',
            section: 'MENUS'
          },
          {
            title: 'Menus',
            description:
              'At least one for each type of order (regular in-store, catering, and merch)',
            endpoint: '/menus',
            section: 'MENUS'
          },
          {
            title: 'Categories',
            description: 'All your categories across all of your menus',
            endpoint: '/categories',
            section: 'MENUS'
          },
          {
            title: 'Modifier Groups',
            description: 'The modifier groups that get attached to your items',
            endpoint: '/modifier-groups',
            section: 'MENUS'
          },
          {
            title: 'Items',
            description:
              'Both menu items and modifiers, which are actually the same thing',
            endpoint: '/items',
            section: 'MENUS'
          },
          {
            title: 'Prices',
            description:
              'Prices and other attributes for your items & modifiers',
            endpoint: '/attributes',
            section: 'MENUS'
          },
          {
            title: 'Availabilities',
            description:
              'Item availabilities across all of your revenue centers',
            endpoint: '/avails',
            section: 'MENUS'
          },
          {
            title: 'Dayparts',
            description:
              'Different segments of the day for different order types',
            endpoint: '/dayparts',
            section: 'MENUS'
          },
          {
            title: 'Allergens',
            description: 'Associated with menu items and modifiers',
            endpoint: '/allergens',
            section: 'MENUS'
          },
          {
            title: 'Tags',
            description: 'Associated with menu items and modifiers',
            endpoint: '/tags',
            section: 'MENUS'
          }
        ]
      }
    ]
  },
  '/regenerate-menus': {
    type: constants.pageType.list,
    title: 'Regenerate Menus',
    subtitle: subtitles.regenerate,
    options: ['store-groups', 'regions'],
    list: {
      endpoint: '/regenerate-menus',
      id: 'revenue_center_id',
      hideNew: true,
      actions: [{ text: 'Regenerate Menus', type: 'regenMenus' }],
      classes: 'table--light table--regenerate',
      sorting: {
        sortBy: 'revenue_center.full_name',
        sortType: 'alpha'
      },
      params: [{ field: 'expand', value: true }],
      filters: [
        {
          label: 'Revenue Center Type',
          type: 'select',
          field: 'revenue_center_type',
          value: 'OLO',
          options: [...constants.revenueCenters].slice(1)
        },
        {
          label: 'Store Group',
          type: 'select',
          field: 'store_group_id',
          settings: {
            endpoint: 'store-groups',
            value: 'store_group_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          sort: true
        },
        {
          label: 'Pricing Region',
          type: 'select',
          field: 'region_id',
          settings: {
            endpoint: 'regions',
            value: 'region_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          sort: true,
          activeOnly: true
        },
        {
          label: 'Open / Closed',
          type: 'select',
          field: 'closed',
          value: '',
          options: constants.closed
        },
        {
          label: 'Outposts',
          type: 'select',
          field: 'is_outpost',
          value: '',
          options: constants.isOutpost
        }
      ],
      fields: [
        {
          name: 'All',
          field: ['isSelected'],
          id: 'revenue_center_id',
          format: 'checkbox',
          selectAll: true
        },
        {
          name: 'Name',
          field: ['revenue_center.full_name'],
          link: 'revenue_center_id',
          linkEndpoint: '/revenue-centers'
        },
        {
          name: 'Type',
          field: ['revenue_center.revenue_center_type'],
          format: 'enum'
        },
        { name: 'Status', field: ['revenue_center.closed'], format: 'closed' },
        {
          name: 'Outpost',
          field: ['revenue_center.is_outpost'],
          format: 'bool'
        },
        {
          name: 'Last Updated',
          field: ['last_built_at'],
          format: 'datetime'
        },
        {
          name: 'Result',
          field: ['status']
        },
        {
          name: 'Errors',
          field: ['errors'],
          format: 'regenErrors'
        },
        {
          name: 'ID',
          field: ['revenue_center_id']
        }
      ]
    }
  },
  '/menus': {
    type: constants.pageType.list,
    title: 'Menus',
    subtitle: 'A list of all of your menus',
    list: {
      endpoint: '/menus',
      id: 'menu_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'menu_id' },
        {
          name: 'Categories',
          field: ['edit categories', 'categories'],
          link: 'menu_id',
          linkEndpoint: '/menus',
          format: 'path'
        },
        { name: 'Internal Name', field: ['short_name'] },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Description', field: ['description'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['menu_id'] }
      ]
    }
  },
  '/menus/:id': {
    type: constants.pageType.item,
    title: 'Menu',
    subtitle: 'Use the form below to update this menu',
    content: '',
    list: {
      title: 'Menus',
      endpoint: '/menus',
      id: 'menu_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/menus',
      id: 'menu_id',
      title: 'name',
      tabs: tabs.menus,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        { label: 'Description', field: 'description', type: 'text' },
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          readonly: true,
          options: [...constants.orderType].slice(1)
        },
        { label: 'Is Active', field: 'is_active', type: 'checkbox' },
        { label: 'Is External', field: 'is_external', type: 'checkbox' },
        {
          label:
            'Import all items & modifiers separately (i.e. turn off deduplication)',
          field: 'map_using_path',
          type: 'checkbox'
        }
      ]
    }
  },
  '/menus/:id/categories': {
    type: constants.pageType.itemRelations,
    title: 'Categories',
    subtitle: 'Browse the categories that are associated with this menu',
    // appClass: 'app--nav',
    list: listSettings.menus,
    item: itemSettings.menus,
    relations: {
      isList: true,
      endpoint: '/menus/:id/categories',
      entity: 'category',
      id: 'category_id',
      sorting: {
        sortBy: 'menu_position',
        sortType: 'order',
        fields: [],
        isEntity: true
      },
      groupBy: { child: 'parent_category_id', parent: 'category_id' },
      classes: 'table--menu-categories',
      search: {
        modalTitle: 'Add New Category',
        modalSubtitle: 'Find and add an existing category to this menu',
        modalType: 'groupItem',
        modalSettings: {
          endpoint: 'categories',
          resultId: 'category_id',
          resultName: 'short_name',
          resultParens: 'full_name',
          placeholder: 'enter item name'
        }
      },
      fields: [
        // {
        //   name: 'Name',
        //   field: ['full_name'],
        //   link: 'category_id',
        //   linkEndpoint: '/categories'
        // },
        {
          name: 'Internal Name',
          field: ['short_name'],
          link: 'category_id',
          linkEndpoint: '/categories'
        },
        {
          name: 'Items',
          field: ['edit items', 'items'],
          link: 'category_id',
          linkEndpoint: '/categories',
          format: 'path'
        },
        {
          name: 'Remove',
          field: ['remove'],
          format: 'button',
          id: 'category_id'
        },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['category_id'] }
      ]
    }
  },
  '/categories': {
    type: constants.pageType.list,
    title: 'Categories',
    subtitle: 'A list of categories on each of your menus',
    options: ['menus'],
    list: {
      isList: true,
      endpoint: '/categories',
      id: 'category_id',
      // entity: 'category',
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true }
      ],
      sorting: { sortBy: 'menu_position', sortType: 'order' },
      groupBy: { child: 'parent_category_id', parent: 'category_id' },
      actions: [
        {
          text: 'See Unassigned',
          type: 'link',
          endpoint: 'categories-unassigned'
        }
      ],
      classes: 'table--categories',
      filters: [
        {
          label: 'Menu',
          type: 'select',
          field: 'menu_id',
          settings: {
            endpoint: 'menus',
            value: 'menu_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          sort: true,
          endpoint: '/menus/:id/categories/reorder'
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: '',
          options: constants.active
        }
      ],
      fields: fields.categories
    }
  },
  '/categories-unassigned': {
    type: constants.pageType.list,
    title: 'Unassigned Categories',
    subtitle: "All categories that aren't currently assigned to a menu",
    list: {
      endpoint: '/categories',
      id: 'category_id',
      hideNew: true,
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true },
        { field: 'not_assigned_only', value: true },
        { field: 'limit', value: 1000 }
      ],
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      groupBy: { child: 'parent_category_id', parent: 'category_id' },
      actions: [
        {
          text: 'Back To Assigned',
          type: 'link',
          endpoint: 'categories'
        }
      ],
      classes: 'table--categories',
      fields: fields.categories
    }
  },
  '/categories/:id': {
    type: constants.pageType.item,
    title: 'Category',
    subtitle: 'Use the form below to update this category',
    options: [
      'categories', // we need this for parent categories,
      'pos-sections',
      'pos-buttons'
    ],
    list: listSettings.categories,
    item: {
      endpoint: '/categories',
      id: 'category_id',
      title: ['full_name'],
      tabs: tabs.categories,
      defaults: { menu_position: 99 },
      fields: [
        {
          label: 'Name',
          field: 'full_name',
          type: 'text',
          required: true,
          classes: '-half',
          tooltip:
            'This is the customer-facing name that appears for guests on your website'
        },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is the internal name that appears here in the Admin Portal and on your Open Tender POS'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Editable on the Daily Menus page',
          field: 'is_editable',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          options: [...constants.orderType].slice(1),
          defaultValue: 'MAIN_MENU',
          classes: '-half'
        },
        {
          label: 'Parent Category',
          field: 'parent_category_id',
          type: 'select',
          options: {
            endpoint: 'categories',
            entity: 'category',
            value: 'category_id',
            label: 'short_name'
          },
          valType: constants.valTypes.intNullable,
          classes: '-half -right'
        },
        {
          label: 'Image Size',
          field: 'appearance',
          type: 'select',
          options: constants.categoryAppearance,
          defaultValue: 'MEDIUM',
          classes: '-half'
        },
        {
          label: 'POS Settings (only relevant for Open Tender POS customers)',
          field: 'pos_settings',
          type: 'break'
        },
        {
          label: 'POS Section',
          field: 'pos_section_id',
          type: 'select',
          // options: constants.posSection,
          options: {
            endpoint: 'pos-sections',
            entity: 'pos-section',
            value: 'pos_section_id',
            label: 'name'
          },
          valType: constants.valTypes.intNullable,
          classes: '-half'
        },
        {
          label: 'POS Display Order',
          field: 'pos_display_order',
          type: 'text',
          valType: constants.valTypes.intNullable,
          classes: '-half -right',
          tooltip:
            'This is the order in which this category is displayed if a POS Section is selected above'
        },
        {
          label: 'POS Button Color',
          field: 'pos_display_color',
          type: 'swatches',
          // valType: constants.valTypes.nullable,
          options: {
            endpoint: 'pos-buttons',
            value: 'hex_code',
            label: 'hex_code',
            sorting: { sortBy: 'position', sortType: 'order' }
          },
          tooltip:
            'This is the color of the buttons on the POS for all items in this category'
        }
      ]
    }
  },
  '/categories/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this category',
    list: listSettings.categories,
    item: itemSettings.categories,
    files: {
      endpoint: '/categories/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/categories/:id/menus': {
    type: constants.pageType.itemRelations,
    title: 'Menus',
    subtitle: 'Use this page to assign this category to different menus',
    list: listSettings.categories,
    item: itemSettings.categories,
    relations: {
      endpoint: '/categories/:id/menus',
      id: 'menu_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Menu', field: ['name'], link: 'menu_id' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        {
          name: 'Remove',
          field: ['remove'],
          format: 'button',
          id: 'menu_id'
        },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        // { name: 'Position', field: ['menu_position'] },
        { name: 'ID', field: ['menu_id'] }
      ]
    }
  },
  '/categories/:id/menus/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Menus',
    subtitle: 'Add or remove a category assignment',
    options: ['menus'],
    list: listSettings.categories,
    item: itemSettings.categories,
    relations: {
      title: 'Menus',
      endpoint: '/categories/:id/menus',
      id: 'menu_id',
      field: ['name'],
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/categories/:id/menus',
      id: 'menu_id',
      title: 'name',
      hasDelete: true,
      fields: [
        {
          label: 'Menu',
          field: 'menu_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'menus',
            entity: 'menu',
            value: 'menu_id',
            label: ['name', 'short_name']
          },
          valType: constants.valTypes.int
        },
        {
          field: 'menu_position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/categories/:id/dayparts': {
    type: constants.pageType.dayparts,
    title: 'Dayparts',
    subtitle:
      'Use this page to set the times at which this category should appear on the menu',
    list: listSettings.categories,
    item: itemSettings.categories
  },
  '/categories/:id/items': {
    type: constants.pageType.itemRelations,
    title: 'Items',
    subtitle:
      'Use this page to assign menu items to this category. Click on an item name if you need to remove it from this category or consider using the Availabilities page if you only want to remove this item temporarily.',
    list: listSettings.categories,
    item: itemSettings.categories,
    relations: {
      endpoint: '/categories/:id/items',
      id: 'item_id',
      sorting: {
        sortBy: 'menu_position',
        sortType: 'order'
      },
      search: {
        modalTitle: 'Add New Item',
        modalSubtitle: 'Find and add an existing item to this category',
        modalType: 'groupItem',
        modalSettings: {
          endpoint: 'items',
          resultId: 'item_id',
          resultName: 'short_name',
          resultParens: 'full_name',
          placeholder: 'enter item name',
          defaults: {
            menu_position: 99
          }
        }
      },
      fields: [
        {
          name: 'Menu Item',
          field: ['short_name'],
          link: 'item_id',
          linkEndpoint: '/items'
        },
        {
          name: 'Modifier Groups',
          field: ['edit modifier groups', ''],
          link: 'item_id',
          format: 'path'
        },
        {
          name: 'Remove',
          field: ['remove'],
          format: 'button',
          id: 'item_id'
        }
      ]
    }
  },
  '/categories/:id/recommended-items': {
    type: constants.pageType.recommendedItems,
    title: 'Recommended Items',
    subtitle:
      'Use this page to assign both upsell and similar items to ALL menu items in this category. If you configure these settings for any items in this category, then the item-specific settings will OVERRIDE AND REPLACE the items listed below.',
    list: listSettings.categories,
    item: {
      ...itemSettings.categories,
      params: [{ field: 'with_related', value: 'menus' }]
    },
    recommendedItems: {
      endpoint: '/categories/:id/recommended-items',
      id: 'item_id',
      sorting: {
        sortBy: 'display_order',
        sortType: 'order',
        fields: ['category_id', 'recommendation_type']
      },
      classes: 'table--recommended-items',
      fields: [
        {
          name: 'Menu Item',
          field: ['item.short_name']
          // link: 'item_id',
          // linkEndpoint: '/items'
        },
        {
          name: 'Category',
          field: ['category.short_name']
          // link: 'category_id',
          // linkEndpoint: '/categories'
        },
        {
          name: 'Type',
          field: ['recommendation_type']
        }
        // {
        //   name: 'Remove',
        //   field: ['remove'],
        //   format: 'button',
        //   id: 'item_id'
        // }
      ]
    }
  },
  '/categories/:id/items/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Item',
    subtitle:
      'Update the settings and/or modifier groups for this item in this category',
    classes: 'app--modifier-groups',
    list: listSettings.categories,
    item: itemSettings.categories,
    relations: {
      title: 'Items',
      endpoint: '/categories/:id/items',
      id: 'item_id',
      field: ['full_name'],
      sorting: { sortBy: 'menu_position', sortType: 'order' }
    },
    relation: {
      endpoint: '/categories/:id/items',
      id: 'item_id',
      title: 'full_name',
      hasDelete: true,
      fields: [
        {
          field: 'menu_position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ],
      recommendedItems: {
        endpoint: '/categories/:id/items/:idd/recommended-items',
        id: 'item_id',
        sorting: {
          sortBy: 'display_order',
          sortType: 'order',
          fields: ['category_id', 'recommendation_type']
        },
        classes: 'table--recommended-items',
        fields: [
          {
            name: 'Menu Item',
            field: ['item.short_name']
          },
          {
            name: 'Category',
            field: ['category.short_name']
          },
          {
            name: 'Type',
            field: ['recommendation_type']
          }
        ]
      },
      list: {
        path: 'modifier-groups',
        id: 'modifier_group_id',
        sorting: {
          sortBy: 'menu_position',
          sortType: 'order',
          fields: ['included_options', 'min_options', 'max_options']
        },
        search: {
          isRelationList: true,
          modalTitle: 'Add New Modifier Group',
          modalSubtitle: 'Find and add an existing modifier group to this item',
          modalType: 'itemModifierGroup'
        },
        title: 'Modifier groups for this item',
        fields: [
          {
            name: 'Modifier Group',
            field: ['modifier_group.short_name'],
            link: 'modifier_group_id',
            linkEndpoint: '/modifier-groups'
          },
          {
            name: 'Inc',
            field: ['included_options'],
            format: constants.valTypes.int,
            label: true
          },
          {
            name: 'Min',
            field: ['min_options'],
            format: constants.valTypes.int,
            label: true
          },
          {
            name: 'Max',
            field: ['max_options'],
            format: constants.valTypes.int,
            label: true
          },
          {
            name: 'Rules',
            field: ['edit', ''],
            button: 'modifier_group_id',
            format: 'path',
            settings: {
              modalTitle: 'Edit Modifier Group',
              modalSubtitle: "Update this modifier group's rules",
              modalType: 'modifierGroup',
              classes: 'modal--big'
            }
          },
          {
            name: 'Modifiers',
            field: ['edit', 'modifiers'],
            button: 'modifier_group_id',
            format: 'path',
            settings: {
              modalTitle: 'modifier_group.short_name',
              modalSubtitle:
                'Edit displayed and default modifiers for this group',
              classes: 'modal--big',
              items: 'modifiers',
              rows: {
                endpoint: '/modifier-groups/:id?with_related=true',
                entity: 'items.item',
                name: 'short_name',
                value: 'item_id'
              },
              cols: {
                endpoint: [
                  { name: 'Displayed', value: 'DISPLAYED' },
                  { name: 'Default', value: 'DEFAULT' }
                ],
                value: 'status'
              }
            }
          },
          {
            name: 'Remove',
            field: ['remove', ''],
            button: 'modifier_group_id',
            format: 'path',
            settings: {
              modalTitle: 'Are you sure?',
              modalSubtitle:
                'Please click the delete button to confirm removal of this modifier group from this item',
              delete: true
            }
          }
        ]
      }
    }
  },
  '/avails': {
    type: constants.pageType.avails,
    showLoading: true,
    title: 'Availabilities',
    subtitle:
      'Item & modifier availabilities across all of your revenue centers',
    options: ['stores', 'store-groups', 'categories', 'modifier-groups'],
    avails: {
      endpoint: '/restricted-items'
    }
  },
  '/expired': {
    type: constants.pageType.avails,
    title: "86'ed Items",
    subtitle: "Items & modifiers that have been 86'ed for the day",
    options: ['stores', 'store-groups', 'categories', 'modifier-groups'],
    avails: {
      endpoint: '/expired-items',
      checkboxClass: 'checkbox__removed'
    }
  },
  '/daily-items': {
    type: constants.pageType.dailyItems,
    title: 'Daily Specials',
    subtitle: 'Set your daily specials at one revenue center at a time',
    options: ['revenue-centers', 'categories', 'modifier-groups'],
    dailyItems: {
      endpoint: '/daily-items',
      filters: []
    }
  },
  '/attributes': {
    type: constants.pageType.attributes,
    title: 'Prices & Other Attributes',
    subtitle:
      'Set prices, serving sizes, minimumns, maximums, and increments for each of your items & modifiers',
    options: ['categories', 'modifier-groups', 'regions'],
    attributes: {
      endpoint: '/attributes',
      filters: [
        {
          label: 'Browse by category...',
          type: 'select',
          field: 'category_id',
          settings: {
            endpoint: 'categories',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['modifier_group_id'],
          savedFilter: 'category_id',
          isRequired: true,
          isDefault: true
        },
        {
          label: 'Or modifier group...',
          type: 'select',
          field: 'modifier_group_id',
          settings: {
            endpoint: 'modifier-groups',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          reset: ['category_id'],
          savedFilter: 'modifier_group_id',
          isRequired: true
        },
        {
          label: 'And an attribute type',
          type: 'select',
          field: 'attribute_type',
          value: 'PRICE',
          options: constants.attributes
        }
      ]
    }
  },
  '/items': {
    type: constants.pageType.list,
    title: 'Items',
    subtitle:
      'All of your items across all of your menus, filtered by category or modifier group (items can live in both categories & modifier groups)',
    options: ['menus', 'categories', 'modifier-groups'],
    list: {
      endpoint: '/items',
      id: 'item_id',
      classes: 'table--items',
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true },
        { field: 'limit', value: 1000 }
      ],
      sorting: {
        sortBy: 'menu_position',
        sortType: 'order'
      },
      search: {
        label: 'Or search by name',
        endpoint: 'items',
        placeholder: 'enter name + hit enter'
      },
      actions: [
        {
          text: 'See Unassigned',
          type: 'link',
          endpoint: 'items-unassigned'
        }
        // { text: 'Download CSV', endpoint: '/items/csv' }
      ],
      filtersRequired: true,
      filters: [
        {
          label: 'Browse by category...',
          type: 'select',
          field: 'category_id',
          settings: {
            endpoint: 'categories',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          endpoint: '/categories/:id/items',
          reset: ['modifier_group_id'],
          savedFilter: 'category_id',
          isRequired: true,
          isDefault: true
        },
        {
          label: 'Or modifier group...',
          type: 'select',
          field: 'modifier_group_id',
          settings: {
            endpoint: 'modifier-groups',
            value: 'value',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          endpoint: '/modifier-groups/:id/items',
          reset: ['category_id'],
          savedFilter: 'modifier_group_id',
          isRequired: true
        }
      ],
      fields: fields.items
    }
  },
  '/items-unassigned': {
    type: constants.pageType.list,
    title: 'Unassigned Items',
    subtitle:
      "These items aren't currently associated with a category or modifier group",
    list: {
      endpoint: '/items',
      id: 'item_id',
      hideNew: true,
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true },
        { field: 'not_assigned_only', value: true },
        { field: 'limit', value: 1000 }
      ],
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      actions: [
        {
          text: 'Back To Assigned',
          type: 'link',
          endpoint: 'items'
        }
      ],
      classes: 'table--items',
      fields: fields.items
    }
  },
  '/items/:id': {
    type: constants.pageType.item,
    title: 'Item',
    subtitle:
      'Items can be added to Categories as Menu Items or Modifier Groups as Modifiers',
    options: ['categories', 'modifier-groups', 'item-types', 'pos-buttons'],
    list: listSettings.items,
    item: {
      endpoint: '/items',
      id: 'item_id',
      title: ['full_name'],
      tabs: tabs.items,
      params: [{ field: 'with_related', value: true }],
      defaults: {
        menu_position: 99
      },
      fields: [
        {
          label: 'Name',
          field: 'full_name',
          type: 'text',
          required: true,
          classes: '-half',
          tooltip:
            'This is the customer-facing name that appears for guests on your website'
        },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is the internal name that appears here in the Admin Portal and on your Open Tender POS, up to a maximum of 50 characters'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Optional Settings',
          field: 'optional_settings',
          type: 'break'
        },
        {
          label: 'Item Type',
          field: 'item_type_id',
          type: 'select',
          options: {
            endpoint: 'item-types',
            value: 'item_type_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'Shorthand',
          field: 'shorthand',
          type: 'text',
          tooltip:
            'This can be used for internal purposes or as another field displayed on the POS, up to a maximum of 50 characters',
          classes: '-half'
        },
        {
          label: 'PLU',
          field: 'plu',
          type: 'text',
          tooltip: "This isn't really used anywhere",
          classes: '-half -right'
        },
        {
          label: 'Short Description',
          field: 'short_description',
          type: 'textarea',
          classes: '-half'
        },
        {
          label: 'Ingredients',
          field: 'ingredients',
          type: 'textarea',
          classes: '-half -right'
        },
        {
          label: 'Sales Tax Override',
          field: 'sales_tax_override',
          type: 'text',
          valType: constants.valTypes.decimalNullable,
          placeholder: '0.000',
          tooltip:
            'If provided, this will override the sales tax percentage set at each location where this item is sold.',
          classes: '-half'
        },
        {
          label: 'Temperature',
          field: 'temperature',
          type: 'select',
          options: constants.temperature,
          defaultValue: 'COLD',
          classes: '-half -right'
        },
        {
          label: 'Start Date',
          field: 'start_date',
          type: 'datepicker',
          placeholder: 'YYYY-MM-DD',
          valType: constants.valTypes.dateNullable,
          classes: '-half',
          tooltip:
            'This is used only for display purposes - it does not have any impact on whether the item shows up on the frontend or not.'
        },
        {
          label: 'End Date',
          field: 'end_date',
          type: 'datepicker',
          placeholder: 'YYYY-MM-DD',
          valType: constants.valTypes.dateNullable,
          classes: '-half -right',
          tooltip:
            'This is used only for display purposes - it does not have any impact on whether the item shows up on the frontend or not.'
        },
        {
          label: 'Featured Position',
          field: 'featured_position',
          type: 'text',
          valType: constants.valTypes.intNullable,
          tooltip:
            'Use this field to denote this item as featured on the menu and set the order in which it shows up'
        },
        {
          label: 'Is Alcohol',
          field: 'is_alcohol',
          type: 'checkbox',
          tooltip:
            'Use this field to denote this item as an alcoholic item for menus shared with Chowly'
        },
        {
          label: 'Restricted Service Types',
          field: 'service_restrictions',
          type: 'checkboxGroup',
          options: [...constants.serviceType].splice(1),
          valType: constants.valTypes.arrayString,
          tooltip:
            'This item will NOT be available for any service types selected here'
        },
        {
          label: 'POS Display Color',
          field: 'pos_display_color',
          type: 'swatches',
          valType: constants.valTypes.nullable,
          options: {
            endpoint: 'pos-buttons',
            value: 'hex_code',
            label: 'hex_code',
            sorting: { sortBy: 'position', sortType: 'order' }
          },
          tooltip:
            'This is the color of the buttons on the POS for all items in this category'
        },
        {
          label: 'Merch Settings',
          field: 'merch_settings',
          type: 'break'
        },
        {
          label: 'Length (in inches)',
          field: 'length',
          type: 'text',
          valType: constants.valTypes.float,
          placeholder: '0.0',
          tooltip: 'This is only used for merch items',
          classes: '-half'
        },
        {
          label: 'Width (in inches)',
          field: 'width',
          type: 'text',
          valType: constants.valTypes.float,
          placeholder: '0.0',
          tooltip: 'This is only used for merch items',
          classes: '-half -right'
        },
        {
          label: 'Height (in inches)',
          field: 'height',
          type: 'text',
          valType: constants.valTypes.float,
          placeholder: '0.0',
          tooltip: 'This is only used for merch items',
          classes: '-half'
        },
        {
          label: 'Weight (in ounces)',
          field: 'weight',
          type: 'text',
          valType: constants.valTypes.float,
          placeholder: '0.0',
          tooltip: 'This is only used for merch items',
          classes: '-half -right'
        },
        {
          label: 'Maximum Delivery Days',
          field: 'delivery_days',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '0',
          tooltip:
            'This is only used for perishable merch items. It represents the maximum number of days the item can be transit before it goes bad. Remember to take potential shipping delays into account if possible.',
          classes: '-half'
        }
      ]
    }
  },
  '/items/:id/attributes': {
    type: constants.pageType.itemAttributes,
    title: 'Prices & Other Attributes',
    subtitle:
      'Use this page to update prices and other attributes for this item',
    appClass: 'app--nav',
    list: listSettings.items,
    item: itemSettings.items,
    attributes: {
      endpoint: '/attributes',
      filters: [
        {
          label: 'Choose an attribute type',
          type: 'select',
          field: 'attribute_type',
          value: 'PRICE',
          options: constants.attributes
        }
      ]
    }
  },
  '/items/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this item',
    list: listSettings.items,
    item: itemSettings.items,
    files: {
      endpoint: '/items/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/items/:id/taxes': {
    type: constants.pageType.itemRelations,
    title: 'Taxes',
    subtitle: 'Use this page to associate taxes with this item',
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      endpoint: '/items/:id/taxes',
      entity: 'tax',
      id: 'tax_id',
      sorting: { sortBy: 'tax.name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['tax.name'], link: 'tax.tax_id' },
        { name: 'Type', field: ['tax.tax_type'], format: 'enum' },
        { name: 'Amount', field: ['tax.amount'] },
        { name: 'Override Amount', field: ['amount'] },
        { name: 'Active', field: ['tax.is_active'], format: 'bool' }
      ]
    }
  },
  '/items/:id/taxes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Taxes',
    subtitle: 'Use this page to associate taxes with this item',
    options: ['taxes'],
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      title: 'Taxes',
      endpoint: '/items/:id/taxes',
      entity: 'tax',
      id: 'tax_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/items/:id/taxes',
      id: 'tax_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Tax',
          field: 'tax_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'taxes',
            entity: 'tax',
            value: 'tax_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Item Override Amount',
          field: 'amount',
          type: 'text',
          valType: constants.valTypes.decimalNullable,
          tooltip:
            'Enter a value here only if the tax amount for this item is different than the amount set on the tax itself'
        }
      ]
    }
  },
  '/items/:id/tags': {
    type: constants.pageType.itemRelations,
    title: 'Tags',
    subtitle: 'Use this page to associate tags with this item',
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      endpoint: '/items/:id/tags',
      entity: 'tag',
      id: 'tag_id',
      sorting: { sortBy: 'tag.name', sortType: 'alpha' },
      fields: [
        {
          name: 'Name',
          field: ['tag.name'],
          link: 'tag.tag_id'
        },
        { name: 'ID', field: ['tag.tag_id'] }
      ]
    }
  },
  '/items/:id/tags/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Tags',
    subtitle: 'Use this page to associate tags with this item',
    options: ['tags'],
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      title: 'Tags',
      endpoint: '/items/:id/tags',
      entity: 'tag',
      id: 'tag_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/items/:id/tags',
      id: 'tag_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Tag',
          field: 'tag_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'tags',
            entity: 'tag',
            value: 'tag_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/items/:id/allergens': {
    type: constants.pageType.itemRelations,
    title: 'Allergens',
    subtitle: 'Use this page to associate allergens with this item',
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      endpoint: '/items/:id/allergens',
      entity: 'allergen',
      id: 'allergen_id',
      sorting: { sortBy: 'allergen.name', sortType: 'alpha' },
      fields: [
        {
          name: 'Name',
          field: ['allergen.name'],
          link: 'allergen.allergen_id'
        },
        { name: 'ID', field: ['allergen.allergen_id'] }
      ]
    }
  },
  '/items/:id/allergens/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Allergens',
    subtitle: 'Use this page to associate allergens with this item',
    options: ['allergens'],
    list: listSettings.items,
    item: itemSettings.items,
    relations: {
      title: 'Allergens',
      endpoint: '/items/:id/allergens',
      entity: 'allergen',
      id: 'allergen_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/items/:id/allergens',
      id: 'allergen_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Allergen',
          field: 'allergen_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'allergens',
            entity: 'allergen',
            value: 'allergen_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/items/:id/nutritional-info': {
    type: constants.pageType.itemSetting,
    title: 'Nutritional Info',
    subtitle: 'Use this page to associate nutritional info with this item',
    list: listSettings.items,
    item: itemSettings.items,
    setting: {
      endpoint: '/items/:id/nutritional-info',
      id: 'item_id',
      hasDelete: true,
      fields: [
        {
          label: 'Serving Size (in ounces)',
          field: 'serving_size',
          type: 'text',
          valType: constants.valTypes.decimal
        },
        {
          label: 'Calories',
          field: 'calories',
          type: 'text'
        },
        {
          label: 'Total Fat (in grams)',
          field: 'total_fat',
          type: 'text'
        },
        {
          label: 'Saturated Fat (in grams)',
          field: 'saturated_fat',
          type: 'text'
        },
        {
          label: 'Trans Fat (in grams)',
          field: 'trans_fat',
          type: 'text'
        },
        {
          label: 'Cholesteroal (in milligrams)',
          field: 'cholesterol',
          type: 'text'
        },
        {
          label: 'Sodium (in milligrams)',
          field: 'sodium',
          type: 'text'
        },
        {
          label: 'Total Carbs (in grams)',
          field: 'total_carbs',
          type: 'text'
        },
        {
          label: 'Dietary Fiber (in grams)',
          field: 'dietary_fiber',
          type: 'text'
        },
        {
          label: 'Sugars (in grams)',
          field: 'sugars',
          type: 'text'
        },
        {
          label: 'Protein (in grams)',
          field: 'protein',
          type: 'text'
        }
      ]
    }
  },
  '/modifier-groups': {
    type: constants.pageType.list,
    title: 'Modifier Groups',
    subtitle: 'A list of modifier groups on each of your menus',
    options: ['menus'],
    list: {
      endpoint: '/modifier-groups',
      id: 'modifier_group_id',
      classes: 'table--modifier-groups',
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true }
      ],
      sorting: { sortBy: 'short_name', sortType: 'alpha' },
      actions: [
        {
          text: 'See Unassigned',
          type: 'link',
          endpoint: 'modifier-groups-unassigned'
        }
      ],
      filters: [
        {
          label: 'Menu',
          type: 'select',
          field: 'menu_id',
          settings: {
            endpoint: 'menus',
            value: 'menu_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true,
          sort: true
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: fields.modifierGroups
    }
  },
  '/modifier-groups-unassigned': {
    type: constants.pageType.list,
    title: 'Unassigned Modifier Groups',
    subtitle:
      "All modifier groups that aren't currently assigned to a menu. Unlike categories, modifier groups do NOT need to be assigned to a menu.",
    list: {
      endpoint: '/modifier-groups',
      id: 'modifier_group_id',
      hideNew: true,
      params: [
        { field: 'expand', value: true },
        { field: 'with_related', value: true },
        { field: 'not_assigned_only', value: true },
        { field: 'limit', value: 1000 }
      ],
      sorting: { sortBy: 'full_name', sortType: 'alpha' },
      actions: [
        {
          text: 'Back To Assigned',
          type: 'link',
          endpoint: 'modifier-groups'
        }
      ],
      classes: 'table--modifier-groups',
      fields: fields.modifierGroups
    }
  },
  '/modifier-groups/:id': {
    type: constants.pageType.item,
    title: 'Modier Group',
    subtitle: 'Use the form below to update this modifier group',
    options: ['pos-buttons'],
    list: listSettings.modifierGroups,
    item: {
      endpoint: '/modifier-groups',
      id: 'modifier_group_id',
      title: ['full_name'],
      tabs: tabs.modifierGroups,
      fields: [
        {
          label: 'Name',
          field: 'full_name',
          type: 'text',
          required: true,
          classes: '-half',
          tooltip:
            'This is the customer-facing name that appears for guests on your website'
        },
        {
          label: 'Internal Name',
          field: 'short_name',
          type: 'text',
          classes: '-half -right',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is the internal name that appears here in the Admin Portal and on your Open Tender POS'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Editable on the Daily Menus page',
          field: 'is_editable',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'POS ID',
          field: 'pos_ext_id',
          type: 'text',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support',
          classes: '-half'
        },
        {
          label: 'POS Name',
          field: 'pos_ext_name',
          type: 'text',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support',
          classes: '-half -right'
        },
        {
          label: 'Upsell',
          field: 'is_upsell',
          type: 'checkbox',
          tooltip:
            'If yes, this group will be removed from the set of modifier groups initially displayed to the customer and will be shown as an additional choice to make when they actually add the item to their cart by clicking the "Add To Order" button'
        },
        {
          label: 'Size',
          field: 'is_size',
          type: 'checkbox',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support'
        },
        {
          label: 'Combo',
          field: 'is_combo',
          type: 'checkbox',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support'
        },
        {
          label: 'Modifier used as Menu Item',
          field: 'is_pos_addl_name',
          type: 'checkbox',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support'
        },
        {
          label: 'Prefixed',
          field: 'is_prefixed',
          type: 'checkbox',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support'
        },
        {
          label: 'Add Prefix',
          field: 'prefix_add',
          type: 'text',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support',
          classes: '-half'
        },
        {
          label: 'Sub Prefix',
          field: 'prefix_sub',
          type: 'text',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support',
          classes: '-half -right'
        },
        {
          label: 'Remove Prefix',
          field: 'prefix_remove',
          type: 'text',
          tooltip:
            'This field is specific to certain POS integrations and should be ignored unless you hear otherwise from Open Tender support',
          classes: '-half'
        },
        {
          label: 'POS Display Color',
          field: 'pos_display_color',
          type: 'swatches',
          valType: constants.valTypes.nullable,
          options: {
            endpoint: 'pos-buttons',
            value: 'hex_code',
            label: 'hex_code',
            sorting: { sortBy: 'position', sortType: 'order' }
          },
          tooltip:
            'This is the color of the buttons on the POS for all items in this category'
        }
      ]
    }
  },
  '/modifier-groups/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this modifier group',
    list: listSettings.modifierGroups,
    item: itemSettings.modifierGroups,
    files: {
      endpoint: '/modifier-groups/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/modifier-groups/:id/menus': {
    type: constants.pageType.itemRelations,
    title: 'Menus',
    subtitle: 'Use this page to assign this modifier group to different menus',
    list: listSettings.modifierGroups,
    item: itemSettings.modifierGroups,
    relations: {
      endpoint: '/modifier-groups/:id/menus',
      id: 'menu_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['menu.name'], link: 'menu.menu_id' },
        { name: 'Order Type', field: ['menu.order_type'], format: 'enum' },
        { name: 'Active', field: ['menu.is_active'], format: 'bool' },
        { name: 'Position', field: ['menu_position'] },
        { name: 'ID', field: ['menu.menu_id'] }
      ]
    }
  },
  '/modifier-groups/:id/menus/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Menus',
    subtitle: 'Add or remove a modifier group assignment',
    options: ['menus'],
    list: listSettings.modifierGroups,
    item: itemSettings.modifierGroups,
    relations: {
      title: 'Menus',
      endpoint: '/modifier-groups/:id/menus',
      id: 'menu_id',
      field: ['name'],
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/modifier-groups/:id/menus',
      id: 'menu_id',
      title: 'name',
      hasDelete: true,
      fields: [
        {
          label: 'Menu',
          field: 'menu_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'menus',
            entity: 'menu',
            value: 'menu_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/modifier-groups/:id/items': {
    type: constants.pageType.itemRelations,
    title: 'Items',
    subtitle: 'Use this page to assign modifiers to this modifier group',
    list: listSettings.modifierGroups,
    item: itemSettings.modifierGroups,
    relations: {
      endpoint: '/modifier-groups/:id/items',
      id: 'item_id',
      sorting: {
        sortBy: 'menu_position',
        sortType: 'order'
      },
      search: {
        modalTitle: 'Add New Modifier',
        modalSubtitle: 'Find and add an existing item to this modifier group',
        modalType: 'groupItem',
        modalSettings: {
          endpoint: 'items',
          resultId: 'item_id',
          resultName: 'short_name',
          resultParens: 'full_name',
          placeholder: 'enter item name',
          defaults: {
            menu_position: 99
          }
        }
      },
      fields: [
        { name: 'Modifier', field: ['short_name'], link: 'item_id' },
        {
          name: 'Remove',
          field: ['remove'],
          format: 'button',
          id: 'item_id'
        },
        // { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['item_id'] }
      ]
    }
  },
  '/modifier-groups/:id/items/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Menus',
    subtitle: 'Add or remove an item to this category',
    list: listSettings.modifierGroups,
    item: itemSettings.modifierGroups,
    relations: {
      title: 'Items',
      endpoint: '/modifier-groups/:id/items',
      id: 'item_id',
      field: ['full_name'],
      sorting: { sortBy: 'menu_position', sortType: 'order' }
    },
    relation: {
      endpoint: '/modifier-groups/:id/items',
      id: 'item_id',
      title: 'full_name',
      hasDelete: true,
      fields: [
        {
          field: 'menu_position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/dayparts': {
    type: constants.pageType.list,
    title: 'Dayparts',
    subtitle: 'A list of the dayparts that can be associated with your menus',
    classes: 'app--aside',
    list: {
      endpoint: '/dayparts',
      id: 'daypart_id',
      sorting: { sortBy: 'start_time', sortType: 'alphaTime' },
      filters: [
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: [...constants.orderType].slice(1)[0].value,
          options: [...constants.orderType].slice(1)
        }
      ],
      fields: [
        { name: 'Name', field: ['full_name'], link: 'daypart_id' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Start Time', field: ['start_time'], format: 'time' },
        { name: 'End Time', field: ['end_time'], format: 'time' },
        { name: 'ID', field: ['daypart_id'] }
      ]
    }
  },
  '/dayparts/:id': {
    type: constants.pageType.item,
    title: 'Daypart',
    subtitle: 'Use the form below to update this daypart',
    content: '',
    list: {
      title: 'Dayparts',
      endpoint: '/dayparts',
      id: 'daypart_id',
      field: 'full_name',
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/dayparts',
      id: 'daypart_id',
      title: 'full_name',
      // hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'full_name', type: 'text', required: true },
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          required: true,
          readonly: true,
          options: [...constants.orderType].slice(1)
        },
        {
          label: 'Start Time',
          field: 'start_time',
          type: 'text',
          valType: constants.valTypes.time,
          required: true
        },
        {
          label: 'End Time',
          field: 'end_time',
          type: 'text',
          valType: constants.valTypes.time,
          required: true
        }
      ]
    }
  },
  '/allergens': {
    type: constants.pageType.list,
    title: 'Allergens',
    subtitle:
      'A list of the allergens that can be associated with menu items and modifiers',
    classes: 'app--aside',
    list: {
      endpoint: '/allergens',
      id: 'allergen_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'allergen_id' },
        { name: 'Description', field: ['description'] },
        { name: 'ID', field: ['allergen_id'] }
      ]
    }
  },
  '/allergens/:id': {
    type: constants.pageType.item,
    title: 'Allergen',
    subtitle: 'Use the form below to update this allergen',
    content: '',
    list: {
      title: 'Allergens',
      endpoint: '/allergens',
      id: 'allergen_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/allergens',
      id: 'allergen_id',
      title: 'name',
      hasDelete: true,
      tabs: tabs.allergens,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Description', field: 'description', type: 'text' }
      ]
    }
  },
  '/allergens/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this allergen',
    list: listSettings.allergens,
    item: itemSettings.allergens,
    files: {
      endpoint: '/allergens/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/tags': {
    type: constants.pageType.list,
    title: 'Tags',
    subtitle:
      'A list of the tags that can be associated with menu items and modifiers',
    classes: 'app--aside',
    list: {
      endpoint: '/tags',
      id: 'tag_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'tag_id' },
        { name: 'Description', field: ['description'] },
        { name: 'ID', field: ['tag_id'] }
      ]
    }
  },
  '/tags/:id': {
    type: constants.pageType.item,
    title: 'Tag',
    subtitle: 'Use the form below to update this tag',
    content: '',
    list: {
      title: 'Tags',
      endpoint: '/tags',
      id: 'tag_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/tags',
      id: 'tag_id',
      title: 'name',
      hasDelete: true,
      tabs: tabs.tags,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'Description', field: 'description', type: 'text' }
      ]
    }
  },
  '/tags/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this tag',
    list: listSettings.tags,
    item: itemSettings.tags,
    files: {
      endpoint: '/tags/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/point-of-sale': {
    type: constants.pageType.content,
    title: 'Point of Sale Settings',
    subtitle: 'Various settings used specifically for your Open Tender POS',
    content: '',
    image: { url: images.pos, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Pages',
            description:
              'Used to organize how your menu shows up on your Open Tender POS',
            endpoint: '/pos-pages',
            section: 'MENUS'
          },
          {
            title: 'Sections',
            description: 'Pages are composed of one or more Sections',
            endpoint: '/pos-sections',
            section: 'MENUS'
          },
          {
            title: 'Buttons',
            description:
              'Choose up to 24 colors of buttons for your Open Tender POS',
            endpoint: '/pos-buttons',
            section: 'MENUS'
          },
          {
            title: 'Messages',
            description: subtitles.posMessages,
            endpoint: '/pos-messages',
            section: 'MENUS'
          },
          {
            title: 'Paid Ins & Outs',
            description:
              'Choose which Paid In and Paid Out options should appear on your Open Tender POS',
            endpoint: '/select-options',
            section: 'MENUS'
          },
          {
            title: 'Item Types',
            description: 'Used to organize items on your Open Tender KDS',
            endpoint: '/item-types',
            section: 'MENUS'
          },
          {
            title: 'KDS Terminals',
            description:
              'Associate KDS Terminals with revenue centers and item types',
            endpoint: '/kds-terminals',
            section: 'MENUS'
          },
          {
            title: 'Prep Stations',
            description: 'Associate Prep Stations with stores and item types',
            endpoint: '/prep-stations',
            section: 'MENUS'
          },
          {
            title: 'POS Printers',
            description:
              'Associate KDS Terminals with revenue centers and item types',
            endpoint: '/pos-printers',
            section: 'MENUS'
          }
        ]
      }
    ]
  },
  '/pos-pages': {
    type: constants.pageType.list,
    title: 'Pages',
    subtitle:
      'Used to reorganize your menu for more efficient use by your employees on your Open Tender POS',
    classes: 'app--aside',
    list: {
      endpoint: '/pos-pages',
      id: 'pos_page_id',
      sorting: { sortBy: 'menu_position', sortType: 'order' },
      filters: [
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: [...constants.orderType].slice(1)[0].value,
          options: [...constants.orderType].slice(1)
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'pos_page_id' },
        { name: 'ID', field: ['pos_page_id'] }
      ]
    }
  },
  '/pos-pages/:id': {
    type: constants.pageType.item,
    title: 'Page',
    subtitle: 'Update a POS page or add a new one',
    list: {
      title: 'Pages',
      endpoint: '/pos-pages',
      id: 'pos_page_id',
      field: 'name',
      sorting: { sortBy: 'menu_position', sortType: 'order' }
    },
    item: {
      endpoint: '/pos-pages',
      id: 'pos_page_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          options: [...constants.orderType].slice(1)
        },
        {
          field: 'menu_position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/pos-sections': {
    type: constants.pageType.list,
    title: 'Sections',
    subtitle:
      'Menu categories are allocated to Sections for the purpose of reorganizing your menu on the POS',
    classes: 'app--aside',
    options: ['pos-pages'],
    list: {
      endpoint: '/pos-sections',
      id: 'pos_section_id',
      sorting: { sortBy: 'menu_position', sortType: 'order' },
      filters: [
        {
          label: 'Pages',
          type: 'select',
          field: 'pos_page_id',
          settings: {
            endpoint: 'pos-pages',
            value: 'pos_page_id',
            label: ['name', 'order_type']
          },
          options: null,
          valType: constants.valTypes.int
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'pos_section_id' },
        { name: 'ID', field: ['pos_section_id'] }
      ]
    }
  },
  '/pos-sections/:id': {
    type: constants.pageType.item,
    title: 'Section',
    subtitle: 'Update a POS section or add a new one',
    options: ['pos-pages'],
    list: {
      title: 'Sections',
      endpoint: '/pos-sections',
      id: 'pos_section_id',
      field: 'name',
      sorting: { sortBy: 'menu_position', sortType: 'order' }
    },
    item: {
      endpoint: '/pos-sections',
      id: 'pos_section_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Page',
          field: 'pos_page_id',
          type: 'select',
          options: {
            endpoint: 'pos-pages',
            entity: 'pos_page',
            value: 'pos_page_id',
            label: ['name', 'order_type']
          },
          valType: constants.valTypes.int
        },
        {
          field: 'menu_position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/pos-buttons': {
    type: constants.pageType.list,
    title: 'Buttons',
    subtitle:
      'Choose the button colors that can be used on your Open Tender POS',
    classes: 'app--aside',
    list: {
      endpoint: '/pos-buttons',
      id: 'pos_button_id',
      sorting: { sortBy: 'position', sortType: 'order' },
      fields: [
        {
          name: 'Hex Code',
          field: ['hex_code'],
          link: 'pos_button_id',
          format: 'hex_code'
        },
        {
          name: 'Color Swatch',
          field: ['hex_code'],
          format: 'swatch'
        },
        { name: 'Position', field: ['position'] },
        { name: 'ID', field: ['pos_button_id'] }
      ]
    }
  },
  '/pos-buttons/:id': {
    type: constants.pageType.item,
    title: 'Button',
    subtitle: 'Update a POS button color or add a new one',
    list: {
      title: 'Buttons',
      endpoint: '/pos-buttons',
      id: 'pos_button_id',
      field: 'hex_code',
      sorting: { sortBy: 'position', sortType: 'order' }
    },
    item: {
      endpoint: '/pos-buttons',
      id: 'pos_button_id',
      title: 'hex_code',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Color (as hexidecimal code)',
          field: 'hex_code',
          type: 'inputColor',
          required: true
        },
        {
          field: 'position',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/pos-messages': {
    type: constants.pageType.list,
    title: 'Messages',
    subtitle: subtitles.posMessages,
    // classes: 'app--aside',
    list: {
      endpoint: '/pos-messages',
      id: 'pos_message_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      // filters: [
      //   {
      //     label: 'Status',
      //     type: 'select',
      //     field: 'is_active',
      //     value: true,
      //     options: constants.active
      //   }
      // ],
      fields: [
        { name: 'Name', field: ['name'], link: 'pos_message_id' },
        { name: 'URL', field: ['message_url'] },
        { name: 'Message Above', field: ['message_above'] },
        { name: 'Message Below', field: ['message_below'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['pos_message_id'] }
      ]
    }
  },
  '/pos-messages/:id': {
    type: constants.pageType.item,
    title: 'Message',
    subtitle: 'Update a POS message or add a new one',
    list: {
      title: 'Messages',
      endpoint: '/pos-messages',
      id: 'pos_message_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/pos-messages',
      id: 'pos_message_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        { label: 'URL for QR Code', field: 'message_url', type: 'text' },
        {
          label: 'Message Above QR Code',
          field: 'message_above',
          type: 'text'
        },
        {
          label: 'Message Below QR Code',
          field: 'message_below',
          type: 'text'
        },
        {
          label: 'Is Active',
          field: 'is_active',
          type: 'checkbox'
        }
      ]
    }
  },
  '/item-types': {
    type: constants.pageType.list,
    title: 'Item Types',
    subtitle:
      'Used to allocate items on your Open Tender KDS. Each item type can be routed to one or more KDS devices in each restaurant.',
    classes: 'app--aside',
    list: {
      endpoint: '/item-types',
      id: 'item_type_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      fields: [
        { name: 'Name', field: ['name'], link: 'item_type_id' },
        { name: 'Prep Time', field: ['prep_time'] },
        { name: 'Is Grouped', field: ['is_grouped'], format: 'bool' },
        { name: 'Is Default', field: ['is_default'], format: 'bool' },
        { name: 'ID', field: ['item_type_id'] }
      ]
    }
  },
  '/item-types/:id': {
    type: constants.pageType.item,
    title: 'Item Type',
    subtitle: 'Add or update an item type',
    list: {
      title: 'Item Types',
      endpoint: '/item-types',
      id: 'item_type_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/item-types',
      id: 'item_type_id',
      title: 'name',
      hasDelete: true,
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Prep Time',
          field: 'prep_time',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'Grouped',
          field: 'is_grouped',
          type: 'checkbox',
          tooltip:
            'Grouped is used to determine if items of this type should be grouped on the KDS as a single printable ticket or listed and printed individually.'
        },
        {
          label: 'Default',
          field: 'is_default',
          type: 'checkbox',
          tooltip:
            'All items not explicitly assigned item types will be assigned to this type for display on the KDS'
        },
        {
          label: 'Hidden on Assembly',
          field: 'is_hidden_assembly',
          type: 'checkbox'
        },
        {
          label: 'Hidden on QA',
          field: 'is_hidden_qa',
          type: 'checkbox'
        },
        {
          label: 'Print on Completed',
          field: 'print_on_completed',
          type: 'checkbox'
        },
        {
          label: 'Initial Prep Status',
          field: 'default_prep_status',
          type: 'select',
          options: constants.prepStatus,
          defaultValue: 'TODO'
        }
      ]
    }
  },
  '/select-options': {
    type: constants.pageType.list,
    title: 'Paid Ins & Outs',
    subtitle: 'Edit or reorder your paid ins and paid outs or add new ones',
    classes: 'app--aside',
    list: {
      endpoint: '/select-options',
      id: 'select_option_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      filters: [
        {
          label: 'Payment Type',
          type: 'select',
          field: 'option_type',
          value: 'PAID_OUT',
          options: constants.paidInsOuts
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'select_option_id',
          linkEndpoint: '/select-options'
        },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['select_option_id'] }
      ]
    }
  },
  '/select-options/:id': {
    type: constants.pageType.item,
    title: 'Paid In / Out',
    subtitle: 'Use the form below to update this paid in / out',
    content: '',
    list: {
      endpoint: '/select-options',
      title: 'Paid Ins & Outs',
      id: 'select_option_id',
      field: 'name',
      sorting: { sortBy: 'display_order', sortType: 'order' }
      // filterBy: { field: 'option_type', value: null }
    },
    item: {
      endpoint: '/select-options',
      id: 'select_option_id',
      title: 'name',
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Payment Type',
          field: 'option_type',
          type: 'select',
          options: constants.paidInsOuts
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        },
        {
          field: 'display_order',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/order-mgmt': {
    type: constants.pageType.content,
    title: 'Orders',
    subtitle: 'Manage and review existing orders and create new ones',
    image: { url: images.orders, position: 'center top' },
    search: 'order',
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Manage Orders',
            description:
              'Manage upcoming and past orders for one store at a time',
            endpoint: '/manage-orders',
            section: 'ORDERS'
          },
          {
            title: '86 Items',
            description: "Items & modifiers that have been 86'ed for the day",
            endpoint: '/expired',
            section: 'ORDERS',
            permission: 'temp_remove'
          },
          {
            title: 'Daily Specials',
            description:
              'Set daily item availabilities at each of your revenue centers',
            endpoint: '/daily-items',
            section: 'ORDERS',
            permission: 'edit_daily_menu'
          },
          {
            title: 'Upcoming Orders',
            description:
              'Manage your upcoming orders across all of your stores',
            endpoint: '/upcoming-orders',
            section: 'ORDERS'
          },
          {
            title: 'Held Orders',
            description:
              "Reopen an order that's been held while waiting to hear from a customer",
            endpoint: '/held-orders',
            section: 'ORDERS',
            permission: 'edit_orders_full'
          },
          {
            title: 'Uncharged Orders',
            description:
              "Review any credit card orders that weren't succcesfully charged when an order was closed",
            endpoint: '/uncharged-orders',
            section: 'ORDERS'
          },
          {
            title: 'All Orders',
            description:
              'Download orders in bulk for processing in Excel or elsewhere',
            endpoint: '/orders',
            section: 'ORDERS',
            permission: 'edit_orders_full'
          },
          {
            title: 'Place An Order',
            description: "Open a new order or edit one that's already open",
            endpoint: '/order',
            section: 'ORDERS',
            permission: 'edit_orders_full'
          }
        ]
      }
    ]
  },
  '/orders': {
    type: constants.pageType.list,
    title: 'Orders',
    subtitle: '',
    appClass: 'app--cards',
    options: ['stores'],
    list: {
      endpoint: '/bulk-orders',
      id: 'receipt_id',
      hideNew: true,
      autoRefresh: false,
      actions: [
        { text: 'Download CSV', endpoint: '/bulk-orders/csv' },
        { text: 'New Order', type: 'newOrder' }
      ],
      classes: 'table--orders',
      params: [
        { field: 'sort-by', value: 'requested_at' },
        { field: 'sort-direction', value: 'DESC' },
        { field: 'expand', value: 'revenue_center,customer,pos_terminal' }
        // { field: 'with_related', value: true }
      ],
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.limit,
        filters.saleType,
        filters.channelType,
        filters.orderStatus,
        filters.tenderStatus,
        filters.store,
        filters.orderType,
        filters.serviceType
      ],
      fields: [
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Store',
          field: ['revenue_center.full_name'],
          link: 'revenue_center.store_id',
          linkEndpoint: '/manage-orders'
        },
        {
          name: 'POS',
          field: ['pos_terminal.name']
        },
        { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        {
          name: 'Customer',
          field: ['customer.first_name', 'customer.last_name']
        },
        { name: 'Order Source', field: ['order_source'], format: 'enum' },
        { name: 'Channel', field: ['channel_type'], format: 'enum' },
        { name: 'Device', field: ['device_type'], format: 'enum' },
        {
          name: 'Status',
          field: ['receipt_status'],
          format: 'enum'
        },
        {
          name: 'Tender Status',
          field: ['tender_status'],
          format: 'enum'
        },
        { name: 'Total', field: ['total'], format: 'dollars' }
      ]
    }
  },
  '/orders/:id': {
    type: constants.pageType.receipt,
    title: 'Order',
    subtitle: 'Use this page to review the details of an individual order',
    item: itemSettings.orders
  },
  '/orders/:id/requests': {
    type: constants.pageType.receiptList,
    title: '3rd Party API Requests',
    subtitle:
      'Use this page to review the details of API requests made to 3rd party systems',
    item: itemSettings.orders,
    itemList: {
      endpoint: '/orders/:id/requests',
      id: 'ext_api_request_id',
      hideNew: true,
      fields: [
        { name: 'External API', field: ['external_api'] },
        { name: 'Created At', field: ['created_at'], format: 'datetime' },
        { name: 'Successful', field: ['is_success'], format: 'bool' },
        {
          name: 'Details',
          field: ['view details', ''],
          button: 'ext_api_request_id',
          format: 'path',
          settings: {
            title: 'API Request Details',
            type: 'apiRequest',
            classes: 'modal--big modal--wide'
          }
        }
      ]
    }
  },
  '/orders/:id/refunds': {
    type: constants.pageType.receiptList,
    title: 'Refunds',
    subtitle: 'Listed below are the refunds related to this order',
    item: itemSettings.orders,
    itemList: {
      endpoint: '/bulk-orders',
      params: [{ field: 'parent_receipt_id', value: ':id' }],
      id: 'receipt_id',
      hideNew: true,
      fields: [
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Refunded At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        { name: 'Total', field: ['total'], format: 'dollars' }
      ]
    }
  },
  '/order': {
    type: constants.pageType.order,
    title: 'Order',
    subtitle: "You're currently placing a order",
    hideHeader: true
  },
  '/manage-orders': {
    type: constants.pageType.orderMgmtStores,
    title: 'Manage Orders',
    subtitle: 'Manage upcoming and past orders at one store at a time',
    options: ['pickup-wait-times', 'delivery-wait-times']
  },
  '/manage-orders/:id': {
    type: constants.pageType.orderMgmt,
    title: 'Manage Orders',
    subtitle: '',
    hideHeader: true,
    classes: 'app--order-mgmt',
    options: ['pickup-wait-times', 'delivery-wait-times']
  },
  '/upcoming-orders': {
    type: constants.pageType.orders,
    title: 'Upcoming Orders',
    subtitle:
      'All of your upcoming catering orders (excluding cancelled and held orders), grouped by date or location',
    classes: 'app--cards',
    orders: {
      classes: 'table--orders',
      params: [
        // { field: 'order_type', value: 'CATERING' },
        { field: 'start_date', value: 'today' },
        { field: 'limit', value: 1000 },
        { field: 'sort-by', value: 'requested_at' },
        { field: 'sort-direction', value: 'ASC' },
        { field: 'expand', value: true }
      ],
      exclude: [{ field: 'receipt_status', value: ['HELD', 'VOID'] }],
      filters: [
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: 'CATERING',
          options: constants.orderType
        },
        {
          label: 'Order Status',
          type: 'select',
          field: 'receipt_status',
          value: 'OPEN',
          options: constants.receiptStatus
        },
        {
          label: 'Group Results By',
          type: 'select',
          field: 'group_by',
          value: 'business_date',
          options: constants.ordersGroupBy
        }
      ],
      fields: [
        {
          name: 'Store',
          field: ['revenue_center.full_name'],
          link: 'revenue_center.store_id',
          linkEndpoint: '/manage-orders'
        },
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Edit',
          field: ['orderEditFull'],
          format: 'button'
        },
        {
          name: 'Status',
          field: ['receipt_status'],
          format: 'enum',
          classes: '-upper'
        },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'timeRange',
          tz: 'timezone'
        },
        {
          name: 'Company',
          field: ['customer_address.company'],
          classes: '-ellipsis'
        },
        // { name: 'Contact', field: ['customer_address.contact'] },
        // {
        //   name: 'Street Address',
        //   // field: ['customer_address.street']
        //   field: [
        //     'customer_address.street',
        //     'customer_address.city',
        //     'customer_address.state',
        //     'customer_address.postal_code'
        //   ],
        //   format: 'address'
        // },
        {
          name: 'Notes',
          field: ['notes'],
          format: 'tooltip',
          classes: '-tooltip'
        },
        {
          name: 'People',
          field: ['person_count'],
          label: true
        },
        {
          name: 'Total',
          field: ['total'],
          format: 'dollars',
          classes: '-right'
        }
      ]
    }
  },
  '/held-orders': {
    type: constants.pageType.list,
    title: 'Held Orders',
    subtitle:
      'Below is a list of orders that have been started and saved (i.e. held)',
    appClass: 'app--cards',
    list: {
      endpoint: '/bulk-orders',
      id: 'receipt_id',
      hideNew: true,
      classes: 'table--orders',
      params: [
        { field: 'receipt_status', value: 'HELD' },
        { field: 'sort-by', value: 'requested_at' },
        { field: 'sort-direction', value: 'ASC' },
        { field: 'expand', value: true }
      ],
      filters: [
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 200,
          options: constants.limit
        }
      ],
      fields: [
        {
          name: 'Store',
          field: ['revenue_center.full_name'],
          link: 'revenue_center.store_id',
          linkEndpoint: '/manage-orders'
        },
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Edit',
          field: ['orderEditFull'],
          format: 'button'
        },
        {
          name: 'Cancel',
          field: ['orderCancel'],
          format: 'button'
        },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Customer',
          field: ['customer.first_name', 'customer.last_name'],
          separator: ' ',
          linkEndpoint: '/customers',
          link: 'customer.customer_id'
        },
        {
          name: 'Address',
          field: [
            'customer_address.street',
            'customer_address.city',
            'customer_address.state',
            'customer_address.postal_code'
          ],
          format: 'address'
        },
        {
          name: 'Total',
          field: ['total'],
          format: 'dollars',
          classes: '-bold'
        }
      ]
    }
  },
  '/uncharged-orders': {
    type: constants.pageType.list,
    title: 'Uncharged Orders',
    subtitle:
      'Below is a list of orders that have been closed but were not successfully charged. You should attempt to recharge these orders and follow up with your credit card processor if a transaction continues to fail.',
    appClass: 'app--cards',
    list: {
      endpoint: '/bulk-orders',
      id: 'receipt_id',
      hideNew: true,
      actions: [{ text: 'Retry Charges', type: 'retryCharges' }],
      classes: 'table--orders table--light',
      sorting: {
        sortBy: 'receipt_id',
        sortType: 'order',
        noDrag: true,
        desc: true
      },
      params: [
        { field: 'receipt_status', value: 'CLOSED' },
        { field: 'tender_status', value: 'AUTHORIZED' },
        { field: 'tender_type', value: 'CREDIT' },
        { field: 'sort-by', value: 'requested_at' },
        { field: 'sort-direction', value: 'DESC' },
        { field: 'expand', value: 'revenue_center' },
        { field: 'with_related', value: 'tenders' }
      ],
      filters: [
        // {
        //   label: 'Results per Page',
        //   type: 'select',
        //   field: 'limit',
        //   value: 100,
        //   options: constants.limit
        // },
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.limit,
        filters.channelType
      ],
      fields: [
        {
          name: 'Store',
          field: ['revenue_center.full_name'],
          link: 'revenue_center.store_id',
          linkEndpoint: '/manage-orders'
        },
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Tender Status',
          field: ['tender_status'],
          format: 'enum'
        },
        {
          name: 'Tender Type',
          field: ['tenders.null.tender_type'],
          format: 'array'
        },
        {
          name: 'Total',
          field: ['total'],
          format: 'dollars',
          classes: '-bold'
        },
        {
          name: 'Recharge',
          field: ['isSelected'],
          id: 'receipt_id',
          format: 'checkbox',
          selectAll: true
        }
      ]
    }
  },
  '/timeclock': {
    type: constants.pageType.punches,
    title: 'Timeclock',
    subtitle: 'Edit time punches or add new ones',
    options: ['stores'],
    punches: {
      filters: [
        {
          label: 'Store',
          type: 'select',
          field: 'store_id',
          settings: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int
        },
        {
          label: 'Start Date',
          type: 'datePicker',
          field: 'start',
          value: 'today'
        },
        {
          label: 'End Date',
          type: 'datePicker',
          field: 'end',
          value: 'today'
        }
      ]
    }
  },
  '/blocked-emails': {
    type: constants.pageType.list,
    title: 'Blocked Email Addresses',
    subtitle: 'These email addresses are not allowed to place orders.',
    content: '',
    list: {
      endpoint: '/blocked-emails',
      id: 'blocked_email_id',
      actions: [],
      filters: [],
      fields: [
        {
          name: 'Email Address',
          field: ['email'],
          link: 'blocked_email_id'
        },
        { name: 'ID', field: ['blocked_email_id'] }
      ]
    }
  },
  '/blocked-emails/:id': {
    type: constants.pageType.item,
    title: 'Blocked Email Address',
    subtitle:
      'Use the field below to block an email address. Or remove an email address that has been blocked.',
    list: {
      title: 'Blocked Emails',
      endpoint: '/blocked-emails',
      id: 'blocked_email_id',
      field: 'email',
      sorting: { sortBy: 'email', sortType: 'alpha' }
    },
    item: {
      endpoint: '/blocked-emails',
      id: 'blocked_email_id',
      title: ['email'],
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          required: true,
          readonly: true
        },
        {
          field: 'customer_id',
          type: 'hidden',
          valType: constants.valTypes.intNullable
        }
      ]
    }
  },
  '/blocked-phones': {
    type: constants.pageType.list,
    title: 'Blocked Phone Numbers',
    subtitle: 'These phone numbers are not allowed to place orders.',
    content: '',
    list: {
      endpoint: '/blocked-phones',
      id: 'blocked_phone_id',
      actions: [],
      filters: [],
      fields: [
        {
          name: 'Phone Number',
          field: ['phone'],
          link: 'blocked_phone_id'
        },
        { name: 'ID', field: ['blocked_phone_id'] }
      ]
    }
  },
  '/blocked-phones/:id': {
    type: constants.pageType.item,
    title: 'Blocked Phone Number',
    subtitle:
      'Use the field below to block a phone number. Or remove a phone number that has been blocked.',
    list: {
      title: 'Blocked Phone Numbers',
      endpoint: '/blocked-phones',
      id: 'blocked_phone_id',
      field: 'phone',
      sorting: { sortBy: 'phone', sortType: 'alpha' }
    },
    item: {
      endpoint: '/blocked-phones',
      id: 'blocked_phone_id',
      title: ['phone'],
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Phone',
          field: 'phone',
          type: 'text',
          required: true,
          readonly: true
        },
        {
          field: 'customer_id',
          type: 'hidden',
          valType: constants.valTypes.intNullable
        }
      ]
    }
  },
  '/blocked-cards': {
    type: constants.pageType.list,
    title: 'Blocked Credit Cards',
    subtitle: 'These credit cards cannot be used to place orders.',
    content: '',
    list: {
      endpoint: '/blocked-cards',
      id: 'blocked_card_id',
      actions: [],
      filters: [],
      fields: [
        {
          name: 'Masked Card Number',
          field: ['masked'],
          link: 'blocked_card_id'
        },
        { name: 'Card Type', field: ['card_type'] },
        { name: 'ID', field: ['blocked_card_id'] }
      ]
    }
  },
  '/blocked-cards/:id': {
    type: constants.pageType.item,
    title: 'Blocked Credit Card',
    subtitle: 'Use the delete button below to unblock a customer credit card.',
    list: {
      title: 'Blocked Credit Cards',
      endpoint: '/blocked-cards',
      id: 'blocked_card_id',
      field: 'masked',
      sorting: { sortBy: 'masked', sortType: 'alpha' }
    },
    item: {
      endpoint: '/blocked-cards',
      id: 'blocked_card_id',
      title: ['masked'],
      hasDelete: true,
      tabs: [],
      fields: [
        // {
        //   label: 'Masked Card Number',
        //   field: 'masked',
        //   type: 'text',
        //   required: true,
        //   readonly: true
        // },
        {
          field: 'customer_id',
          type: 'hidden',
          valType: constants.valTypes.intNullable
        }
      ]
    }
  },
  '/blocked-customers': {
    type: constants.pageType.list,
    title: 'Blocked Customers',
    subtitle: "A list of all of the customers you've designated as blocked.",
    list: {
      endpoint: '/customers',
      id: 'customer_id',
      classes: 'table--customers',
      filters: [
        {
          label: 'Last Order After',
          type: 'datePicker',
          field: 'last_ordered_after',
          value: 'allTime'
        },
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      params: [{ field: 'is_blocked', value: 'true' }],
      fields: [
        {
          name: 'Name',
          field: ['first_name', 'last_name'],
          link: 'customer_id'
        },
        { name: 'Email', field: ['email'], classes: '-ellipsis' },
        { name: 'Phone', field: ['phone'], format: 'phone' },
        { name: 'Is Guest', field: ['is_guest'], format: 'bool' },
        { name: 'Is Blocked', field: ['is_blocked'], format: 'bool' },
        {
          name: 'Last Ordered',
          field: ['last_ordered'],
          format: 'datetime'
        },
        { name: 'Orders', field: ['orders'] },
        {
          name: 'Updated',
          field: ['updated'],
          format: 'datetime'
        },
        { name: 'ID', field: ['customer_id'] }
      ]
    }
  },
  '/customers': {
    type: constants.pageType.list,
    title: 'Customers',
    subtitle: '',
    content: '',
    list: {
      endpoint: '/customers',
      id: 'customer_id',
      classes: 'table--customers',
      search: {
        label: 'Or search by name',
        endpoint: 'customers',
        placeholder: 'enter name + hit enter'
      },
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/customers/csv'
        }
      ],
      filters: [
        {
          label: 'Last Order After',
          type: 'datePicker',
          field: 'last_ordered_after',
          value: 'allTime'
        },
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['first_name', 'last_name'],
          link: 'customer_id'
        },
        // { name: 'Company', field: ['company'] },
        { name: 'Email', field: ['email'], classes: '-ellipsis' },
        { name: 'Phone', field: ['phone'], format: 'phone' },
        { name: 'Is Guest', field: ['is_guest'], format: 'bool' },
        { name: 'Is Blocked', field: ['is_blocked'], format: 'bool' },
        {
          name: 'Last Ordered',
          field: ['last_ordered'],
          format: 'datetime'
        },
        { name: 'Orders', field: ['orders'] },
        {
          name: 'Updated',
          field: ['updated'],
          format: 'datetime'
        },
        { name: 'ID', field: ['customer_id'] }
      ]
    }
  },
  '/customers/:id': {
    type: constants.pageType.item,
    title: 'Customer',
    subtitle:
      'Use the form below to update this customer, including their password, if needed.',
    list: listSettings.customers,
    item: {
      endpoint: '/customers',
      id: 'customer_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.customers,
      fields: fields.customer,
      tabActions: [{ name: 'Start New Order', type: 'reorderCustomer' }]
    }
  },
  '/customers/:id/block': {
    type: constants.pageType.customer,
    title: 'Block Customer',
    subtitle: 'Block or unblock this customer.',
    list: listSettings.customers,
    item: {
      endpoint: '/customers',
      id: 'customer_id',
      title: ['first_name', 'last_name'],
      tabs: tabs.customers,
      fields: []
    }
  },
  '/customers/:id/loyalty-programs': {
    type: constants.pageType.itemRelations,
    title: 'Loyalty Programs',
    subtitle:
      'Use this page to associate this customer with an opt-in loyalty program',
    // options: ['loyalty-programs'],
    list: listSettings.customers,
    item: itemSettings.customers,
    relations: {
      related: 'loyalty_programs',
      endpoint: '/customers/:id/loyalty-programs',
      id: 'loyalty_program_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Name', field: ['name'], link: 'loyalty_program_id' },
        { name: 'Is Opt In', field: ['is_opt_in'], format: 'bool' },
        { name: 'Is Active', field: ['is_active'], format: 'bool' }
      ]
    }
  },
  '/customers/:id/loyalty-programs/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Loyalty Progrmas',
    subtitle:
      'Use the form below to update this loyalty program for this customer',
    options: ['loyalty-programs'],
    list: listSettings.customers,
    item: itemSettings.customers,
    relations: {
      title: 'Loyalty Programs',
      endpoint: '/customers/:id/loyalty-programs',
      id: 'loyalty_program_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/customers/:id/loyalty-programs',
      id: 'loyalty_program_id',
      title: ['name'],
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Loyalty Program',
          field: 'loyalty_program_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'loyalty-programs',
            entity: 'loyalty_program',
            value: 'loyalty_program_id',
            label: 'name'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/customers/:id/addresses': {
    type: constants.pageType.itemRelations,
    title: 'Addresses',
    subtitle:
      'A list of all of the addresses that a customer has associated with their account',
    appClass: 'app--nav app--cards',
    list: listSettings.customers,
    item: itemSettings.customers,
    relations: {
      endpoint: '/customers/:id/addresses',
      id: 'customer_address_id',
      relation: 'customer_addresses',
      sorting: { sortBy: 'last_used_at', sortType: 'datetime', desc: true },
      fields: [
        {
          name: 'Address',
          field: ['street', 'city', 'state', 'postal_code'],
          format: 'address',
          link: 'customer_address_id'
        },
        { name: 'Unit', field: ['unit'] },
        { name: 'Company', field: ['company'] },
        { name: 'Contact', field: ['contact'] },
        { name: 'Phone', field: ['phone'], format: 'phone' },
        // { name: 'Description', field: ['description'] },
        { name: 'Default', field: ['is_default'], format: 'bool' },
        { name: 'Last Used', field: ['last_used_at'], format: 'datetimeDate' }
      ]
    }
  },
  '/customers/:id/addresses/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Address',
    subtitle: 'Use the form below to update this address',
    list: listSettings.customers,
    item: {
      ...itemSettings.customers,
      tabActions: [{ name: 'Reorder from here', type: 'reorderAddress' }]
    },
    relations: {
      title: 'Addresses',
      endpoint: '/customers/:id/addresses',
      id: 'customer_address_id',
      relation: 'customer_addresses',
      field: ['street', 'unit'],
      separator: ', ',
      sorting: { sortBy: 'street', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/customers/:id/addresses',
      upsertEndpoint: 'customer-addresses',
      isItem: true,
      hasGeocoder: true,
      id: 'customer_address_id',
      title: 'street',
      fields: fields.customerAddress
    }
  },
  '/customers/:id/orders': {
    type: constants.pageType.itemList,
    title: 'Orders',
    subtitle: '',
    list: listSettings.customers,
    item: itemSettings.customers,
    itemList: {
      endpoint: '/customers/:id/orders',
      id: 'order_id',
      hideNew: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/customers/:id/orders/csv'
        }
      ],
      classes: 'table--light',
      params: [{ field: 'limit', value: 100 }],
      filters: [
        {
          label: 'Start Date',
          type: 'datePicker',
          field: 'start_date',
          value: 'allTime'
        },
        {
          label: 'End Date',
          type: 'datePicker',
          field: 'end_date',
          value: 'allTime'
        },
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: constants.orderType[0].value,
          options: constants.orderType
        },
        {
          label: 'Order Status',
          type: 'select',
          field: 'order_status',
          value: constants.receiptStatus[0].value,
          options: constants.receiptStatus
        },
        {
          label: 'Tender Status',
          type: 'select',
          field: 'tender_status',
          value: constants.tenderStatus[0].value,
          options: constants.tenderStatus
        }
      ],
      fields: [
        {
          name: 'Order No.',
          field: ['order_id'],
          link: 'order_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Store', field: ['store_name'] },
        { name: 'Status', field: ['order_status'], format: 'enum' },
        { name: 'Payment', field: ['tender_status'], format: 'enum' },
        { name: 'Net Sales', field: ['net_sales'], format: 'dollars' },
        { name: 'Order Total', field: ['net_total'], format: 'dollars' }
      ]
    }
  },
  '/house-accounts': {
    type: constants.pageType.list,
    title: 'House Accounts',
    subtitle: '',
    content: '',
    list: {
      endpoint: '/house-accounts',
      id: 'house_account_id',
      search: {
        label: 'Or search by name',
        endpoint: 'house-accounts',
        placeholder: 'enter name + hit enter'
      },
      filters: [
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'house_account_id'
        },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Address', field: ['address'] },
        { name: 'POS Pin', field: ['pin'] },
        {
          name: 'Approved Customers',
          field: ['approved_email'],
          format: 'bool'
        },
        { name: 'Use for Payment', field: ['use_for_payment'], format: 'bool' },
        { name: 'Created', field: ['created_at'], format: 'datetime' },
        { name: 'ID', field: ['house_account_id'] }
      ]
    }
  },
  '/house-accounts/:id': {
    type: constants.pageType.item,
    title: 'House Account',
    subtitle: 'Use the form below to update this house account',
    content: '',
    list: listSettings.houseAccounts,
    item: {
      endpoint: '/house-accounts',
      id: 'house_account_id',
      title: ['name'],
      tabs: tabs.houseAccounts,
      tabActions: [{ name: 'Start New Order', type: 'reorderHouseAccount' }],
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Status',
          field: 'is_active',
          type: 'select',
          options: [...constants.active].slice(1),
          required: true,
          classes: '-half -right',
          valType: constants.valTypes.bool
        },
        {
          label: 'Order Type',
          field: 'location_type',
          classes: '-half',
          type: 'select',
          options: constants.bothOrderType,
          required: true
        },
        {
          label: 'Service Type',
          field: 'service_type',
          classes: '-half -right',
          type: 'select',
          options: constants.serviceTypeAll,
          required: true
        },
        {
          label: 'Pin for POS',
          field: 'pin',
          type: 'text',
          classes: '-half',
          required: true
        },
        {
          label: 'Domain',
          field: 'domain',
          classes: '-half -right',
          type: 'text'
        },
        {
          label: 'Address Line 1',
          field: 'address',
          type: 'text',
          classes: '-half'
        },
        {
          label: 'Address Line 2',
          field: 'address2',
          type: 'text',
          classes: '-half -right'
        },
        {
          label: 'Unit',
          field: 'unit',
          classes: '-half',
          type: 'text'
        },
        {
          label: 'City',
          field: 'city',
          classes: '-half -right',
          type: 'text'
        },
        {
          label: 'State',
          field: 'statecode',
          type: 'select',
          classes: '-half',
          options: constants.statesProvincesWithNone
        },
        {
          label: 'Postal Code',
          field: 'zipcode',
          classes: '-half -right',
          type: 'text'
        },
        {
          label: 'Phone',
          field: 'phone',
          classes: '-half',
          type: 'text'
        },
        {
          label: 'CRM External ID',
          field: 'crm_ext_id',
          classes: '-half -right',
          type: 'text'
        },
        {
          label: 'Internal Notes',
          field: 'internal_notes',
          type: 'textarea'
        },
        {
          label: 'Requires Manager Approval',
          field: 'approval',
          type: 'checkbox'
        },
        {
          label: 'Requires Approved Email',
          field: 'approved_email',
          type: 'checkbox'
        },
        {
          label: 'Tax Exempt',
          field: 'is_tax_exempt',
          type: 'checkbox'
        },
        {
          label: 'Used for Payment',
          field: 'use_for_payment',
          type: 'checkbox'
        },
        {
          label: 'Used for Donations',
          field: 'is_donation',
          type: 'checkbox'
        },
        {
          field: 'display_order',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/house-accounts/:id/customers': {
    type: constants.pageType.itemRelations,
    title: 'Customers',
    subtitle: '',
    content: '',
    options: [],
    list: listSettings.houseAccounts,
    item: itemSettings.houseAccounts,
    relations: {
      endpoint: '/house-accounts/:id/customers',
      id: 'customer_id',
      sorting: { sortBy: 'first_name', sortType: 'alpha' },
      search: {
        modalTitle: 'Add New Customer',
        modalSubtitle:
          'Find and add an existing customer to this house account',
        modalType: 'houseAccountCustomer'
      },
      fields: [
        {
          name: 'Name',
          field: ['first_name', 'last_name'],
          link: 'customer_id'
        },
        { name: 'Email', field: ['email'] },
        {
          name: 'Is Owner',
          field: ['is_owner'],
          format: 'bool'
        },
        { name: 'Added', field: ['created_at'], format: 'datetime' }
      ]
    }
  },
  '/house-accounts/:id/customers/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Customer',
    subtitle:
      'Use the form below to update this customer for this house account',
    list: listSettings.houseAccounts,
    item: itemSettings.houseAccounts,
    relations: {
      title: 'Customers',
      endpoint: '/house-accounts/:id/customers',
      id: 'customer_id',
      field: ['first_name', 'last_name'],
      sorting: { sortBy: 'first_name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/house-accounts/:id/customers',
      id: 'customer_id',
      title: ['first_name', 'last_name'],
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Is Owner',
          field: 'is_owner',
          type: 'select',
          required: true,
          options: constants.isOwner,
          valType: constants.valTypes.bool
        }
      ]
    }
  },
  '/house-accounts/:id/invoices': {
    type: constants.pageType.itemRelations,
    title: 'Invoices',
    subtitle: '',
    content: '',
    options: [],
    list: listSettings.houseAccounts,
    item: itemSettings.houseAccounts,
    relations: {
      endpoint: '/house-accounts/:id/invoices',
      id: 'invoice_id',
      hideNew: true,
      // sorting: { sortBy: 'invoice_id', sortType: 'alpha' },
      fields: [
        {
          name: 'Invoice No',
          field: ['invoice_id'],
          link: ''
        },
        { name: 'Issue Date', field: ['issue_date'] },
        { name: 'Due Date', field: ['due_date'] },
        { name: 'Amount Due', field: ['amount_due'], format: 'dollars' },
        {
          name: 'Is Paid',
          field: ['markPaid'],
          format: 'button'
        },
        { name: 'Download PDF', field: ['pdf_link'], format: 'download' }
      ]
    }
  },
  '/house-accounts/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle:
      'Use this page to associate revenue centers with this house account',
    options: ['revenue-centers'],
    // classes: 'revenue-center-tender-types',
    list: listSettings.houseAccounts,
    item: {
      endpoint: '/house-accounts',
      id: 'house_account_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'revenue-centers' }],
      tabs: tabs.houseAccounts,
      fields: []
    },
    setting: {
      endpoint: '/house-accounts/:id/revenue-centers',
      id: 'house_account_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/house-accounts/:id/orders': {
    type: constants.pageType.itemList,
    title: 'Orders',
    subtitle: '',
    content: '',
    options: [],
    list: listSettings.houseAccounts,
    item: itemSettings.houseAccounts,
    itemList: {
      endpoint: '/house-accounts/:id/orders',
      id: 'order_id',
      hideNew: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/house-accounts/:id/orders/csv'
        },
        { text: 'Create Invoice', type: 'createInvoice' }
      ],
      // params: { expand: true },
      classes: 'table--light',
      filters: [
        // {
        //   label: 'Results per Page',
        //   type: 'select',
        //   field: 'limit',
        //   value: 100,
        //   options: constants.limit
        // },
        {
          label: 'Start Date',
          type: 'datePicker',
          field: 'start_date',
          value: 'allTime'
        },
        {
          label: 'End Date',
          type: 'datePicker',
          field: 'end_date',
          value: 'allTime'
        },
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: constants.orderType[0].value,
          options: constants.orderType
        },
        {
          label: 'Order Status',
          type: 'select',
          field: 'order_status',
          value: constants.receiptStatus[0].value,
          options: constants.receiptStatus
        },
        {
          label: 'Tender Status',
          type: 'select',
          field: 'tender_status',
          value: constants.tenderStatus[0].value,
          options: constants.tenderStatus
        }
      ],
      fields: [
        {
          name: 'Order No.',
          field: ['order_id'],
          link: 'order_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Store', field: ['store_name'] },
        { name: 'Net Sales', field: ['net_sales'], format: 'dollars' },
        { name: 'Order Total', field: ['net_total'], format: 'dollars' },
        { name: 'Status', field: ['order_status'], format: 'enum' },
        { name: 'Payment', field: ['tender_status'], format: 'enum' },
        {
          name: 'Invoice',
          field: ['isSelected'],
          id: 'order_id',
          format: 'checkbox'
        }
      ]
    }
  },
  '/loyalty-programs': {
    type: constants.pageType.list,
    title: 'Loyalty Programs',
    subtitle: 'One or more loyalty programs',
    list: {
      endpoint: '/loyalty-programs',
      id: 'loyalty_program_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        { name: 'Name', field: ['name'], link: 'loyalty_program_id' },
        // { name: 'Description', field: ['description'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Spend Type', field: ['spend_type'], format: 'enum' },
        { name: 'Threshold', field: ['threshold'], format: 'dollars' },
        { name: 'Reward Type', field: ['loyalty_type'], format: 'enum' },
        { name: 'Reward Amount', field: ['credit_amount'], format: 'dollars' },
        { name: 'Repeating', field: ['is_repeating'], format: 'bool' },
        { name: 'Automatic', field: ['is_auto'], format: 'bool' },
        { name: 'Opt-in', field: ['is_opt_in'], format: 'bool' },
        { name: 'ID', field: ['loyalty_program_id'] }
      ]
    }
  },
  '/loyalty-programs/:id': {
    type: constants.pageType.item,
    title: 'Loyalty Program',
    subtitle: 'Use the form below to update this loyalty program',
    options: ['loyalty-discounts'],
    list: listSettings.loyaltyPrograms,
    item: {
      endpoint: '/loyalty-programs',
      id: 'loyalty_program_id',
      title: ['name'],
      tabs: tabs.loyaltyPrograms,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'text'
        },
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          options: constants.loyaltyOrderTypes,
          defaultValue: 'ALL'
        },
        {
          label: 'Program Type',
          field: 'spend_type',
          type: 'select',
          options: constants.loyaltySpendTypes,
          defaultValue: 'DOLLARS'
        },
        {
          label: 'Points Per Dollar (only relevant for points programs)',
          field: 'points_per',
          type: 'number',
          valType: constants.valTypes.intNullable
        },
        {
          label: 'Points Name (only relevant for points programs)',
          field: 'points_name',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        {
          label:
            'Points Expiration (in days) (only relevant for points programs)',
          field: 'points_expire_days',
          type: 'number',
          valType: constants.valTypes.intNullable,
          tooltip:
            'The number of days after which points expire. Set to 0 if points should never expire.'
        },
        // {
        //   label: 'Reward Type',
        //   field: 'loyalty_type',
        //   type: 'select',
        //   options: constants.loyaltyProgramTypes,
        //   defaultValue: 'CREDIT'
        // },
        // {
        //   field: 'loyalty_type',
        //   type: 'hidden',
        //   value: 'CREDIT'
        // },
        {
          label: 'Reward Threshold (NOT relevant for points programs)',
          field: 'threshold',
          type: 'text',
          valType: constants.valTypes.decimalNullable
        },
        {
          label: 'Credit Amount (NOT relevant for points programs)',
          field: 'credit_amount',
          type: 'text',
          valType: constants.valTypes.decimalNullable
        },
        {
          label: 'Status Tiers Expiration (in days)',
          field: 'tiers_expire_days',
          type: 'number',
          valType: constants.valTypes.intNullable,
          tooltip:
            'The number of days after which spend or points expire for the purpose of calculating status tiers. Leave blank for no expiration.'
        },
        {
          label:
            'Automatic Redemption (vs. Manual Redemption) (NOT relevant for points programs)',
          field: 'is_auto',
          type: 'checkbox'
        },
        // {
        //   label: 'Associated Discount (NOT relevant for points programs)',
        //   type: 'select',
        //   field: 'discount_id',
        //   // readonly: true,
        //   disabled: true,
        //   hideNew: true,
        //   options: {
        //     endpoint: 'loyalty-discounts',
        //     value: 'discount_id',
        //     label: 'name',
        //     hideNone: true
        //   },
        //   // valType: constants.valTypes.int,
        //   tooltip:
        //     'Loyalty programs must be associated with a discount so that credit amounts spent can be associated with a discount for accounting and reporting purposes. Each loyalty program should be associated with its own unique discount.'
        // },
        {
          label: 'Repeating Program (vs. Single Use)',
          field: 'is_repeating',
          type: 'checkbox'
        },
        {
          label: 'Customers Must Opt In',
          field: 'is_opt_in',
          type: 'checkbox'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-tiers': {
    type: constants.pageType.itemRelations,
    title: 'Status Tiers',
    subtitle: '',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      endpoint: '/loyalty-programs/:id/loyalty-tiers',
      id: 'loyalty_tier_id',
      sorting: { sortBy: 'amount', sortType: 'order', noDrag: true },
      fields: [
        { name: 'Name', field: ['name'], link: 'loyalty_tier_id' },
        { name: 'Amount', field: ['amount'], format: 'quantity' },
        { name: 'Color', field: ['hex_code'], format: 'swatch' },
        { name: 'Is Active', field: ['is_active'], format: 'bool' }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-tiers/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Status Tier',
    subtitle: 'Create or update a status tier',
    options: ['rewards'],
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      title: 'Status Tiers',
      endpoint: '/loyalty-programs/:id/loyalty-tiers',
      id: 'loyalty_tier_id',
      field: ['name']
    },
    relation: {
      endpoint: '/loyalty-programs/:id/loyalty-tiers',
      upsertEndpoint: 'loyalty-tiers',
      isItem: true,
      id: 'loyalty_tier_id',
      title: 'name',
      hasDelete: false,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Color (as hexidecimal code)',
          field: 'hex_code',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'text',
          valType: constants.valTypes.decimal
        },
        {
          label: 'Discount (optional)',
          field: 'discount_id',
          type: 'select',
          options: {
            endpoint: 'rewards',
            entity: 'discount',
            value: 'discount_id',
            label: 'name'
          },
          includeNone: true,
          valType: constants.valTypes.int
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-thresholds': {
    type: constants.pageType.itemRelations,
    title: 'Points Thresholds',
    subtitle: 'A list of the different point thresholds that users can achieve',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      endpoint: '/loyalty-programs/:id/loyalty-thresholds',
      id: 'loyalty_threshold_id',
      sorting: { sortBy: 'amount', sortType: 'order', noDrag: true },
      fields: [
        { name: 'Name', field: ['name'], link: 'loyalty_threshold_id' },
        { name: 'Description', field: ['description'] },
        { name: 'Amount', field: ['amount'], format: 'quantity' }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-thresholds/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Points Threshold',
    subtitle: 'Create or update a points threshold',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      title: 'Points Thresholds',
      endpoint: '/loyalty-programs/:id/loyalty-thresholds',
      id: 'loyalty_threshold_id',
      field: ['name']
    },
    relation: {
      endpoint: '/loyalty-programs/:id/loyalty-thresholds',
      upsertEndpoint: 'loyalty-thresholds',
      isItem: true,
      id: 'loyalty_threshold_id',
      title: 'name',
      hasDelete: true,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'text',
          valType: constants.valTypes.decimal
        }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-bonus': {
    type: constants.pageType.itemRelations,
    title: 'Bonus Periods',
    subtitle: '',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      endpoint: '/loyalty-programs/:id/loyalty-bonus',
      id: 'loyalty_bonus_id',
      sorting: { sortBy: 'business_date', sortType: 'datetime' },
      fields: [
        { name: 'Name', field: ['name'], link: 'loyalty_bonus_id' },
        { name: 'Amount', field: ['amount'], format: 'fixed2' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Date', field: ['business_date'] },
        { name: 'Weekday', field: ['weekday'], format: 'enum' },
        { name: 'Start Time', field: ['start_time'] },
        { name: 'End Time', field: ['end_time'] }
      ]
    }
  },
  '/loyalty-programs/:id/loyalty-bonus/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Bonus period',
    subtitle: 'Create or update a bonus period',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      title: 'Bonus Periods',
      endpoint: '/loyalty-programs/:id/loyalty-bonus',
      id: 'loyalty_bonus_id',
      field: ['name']
    },
    relation: {
      endpoint: '/loyalty-programs/:id/loyalty-bonus',
      upsertEndpoint: 'loyalty-bonus',
      isItem: true,
      id: 'loyalty_bonus_id',
      title: 'name',
      // hasDelete: true,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'Amount',
          field: 'amount',
          classes: '-half',
          type: 'text',
          valType: constants.valTypes.decimal
        },
        {
          label: 'Service Type',
          field: 'service_type',
          type: 'select',
          options: constants.serviceType,
          defaultValue: '',
          classes: '-half -right',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Business Date',
          field: 'business_date',
          type: 'datepicker',
          placeholder: 'YYYY-MM-DD',
          valType: constants.valTypes.dateNullable,
          classes: '-half',
          tooltip: 'Leave blank for a weekly recurring bonus period'
        },
        {
          label: 'Weekday',
          field: 'weekday',
          type: 'select',
          options: constants.weekdayOptions,
          defaultValue: 'SUNDAY',
          classes: '-half -right'
        },
        {
          label: 'Start Time',
          field: 'start_time',
          type: 'text',
          placeholder: '2:00 PM',
          classes: '-half'
        },
        {
          label: 'End Time',
          field: 'end_time',
          type: 'text',
          placeholder: '3:00 PM',
          classes: '-half -right'
        },
        {
          label: 'Is Active',
          field: 'is_active',
          type: 'checkbox'
        }
      ]
    }
  },
  '/loyalty-programs/:id/customers': {
    type: constants.pageType.itemRelations,
    title: 'Participating Customers',
    subtitle:
      'Customers who are currently participating in this loyalty program',
    options: [],
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      endpoint: '/loyalty-programs/:id/customers',
      entity: 'customer',
      id: 'customer_id',
      sorting: { sortBy: 'last_name', sortType: 'alpha' },
      search: {
        modalTitle: 'Add New Customer',
        modalSubtitle:
          'Find and add an existing customer to this loyalty program',
        modalType: 'loyaltyProgramCustomer'
      },
      fields: [
        {
          name: 'Customer',
          field: ['last_name', 'first_name'],
          separator: ', ',
          link: 'customer_id'
        },
        { name: 'Email', field: ['email'] },
        { name: 'ID', field: ['customer_id'] },
        {
          name: 'remove',
          field: ['remove', ''],
          button: 'customer_id',
          format: 'path',
          settings: {
            title: 'Are you sure?',
            subtitle:
              'Please click the Remove button to confirm removal of this customer from this loyalty program',
            type: 'deleteRelation'
          }
        }
      ]
    }
  },
  '/loyalty-programs/:id/emails': {
    type: constants.pageType.itemRelations,
    title: 'Emails',
    subtitle: 'Associate an email address with an opt-in loyalty program',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    upload: {
      text: 'Upload CSV',
      endpoint: '/discounts/:id/emails/import/csv'
    },
    relations: {
      endpoint: '/loyalty-programs/:id/emails',
      entity: null,
      id: 'email',
      sorting: { sortBy: 'email', sortType: 'alpha' },
      upload: {
        text: 'Upload CSV',
        endpoint: '/loyalty-programs/:id/emails/import/csv'
      },
      fields: [
        { name: 'Email', field: ['email'] },
        {
          name: 'remove',
          field: ['remove', ''],
          button: 'email',
          format: 'path',
          settings: {
            title: 'Are you sure?',
            subtitle:
              'Please click the Remove button to confirm removal of this email address from this loyalty program',
            type: 'deleteRelation'
          }
        }
      ]
    }
  },
  '/loyalty-programs/:id/emails/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Email',
    subtitle:
      'Use the form below to add a new email address to this loyalty program',
    list: listSettings.loyaltyPrograms,
    item: itemSettings.loyaltyPrograms,
    relations: {
      title: 'Emails',
      endpoint: '/loyalty-programs/:id/emails'
    },
    relation: {
      endpoint: '/loyalty-programs/:id/emails',
      id: 'email',
      title: ['email'],
      tabs: [],
      fields: [
        {
          label: 'Email Address',
          field: 'email',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/loyalty-customers': {
    type: constants.pageType.list,
    title: 'Loyalty Customers',
    subtitle:
      'A list of participating customers and awards earned across all of your loyalty programs',
    options: ['loyalty-programs'],
    list: {
      endpoint: '/loyalty-customers',
      hideNew: true,
      id: 'customer_loyalty_id',
      params: [{ field: 'expand', value: true }],
      filters: [
        {
          label: 'Loyalty Program',
          type: 'select',
          field: 'loyalty_program_id',
          settings: {
            endpoint: 'loyalty-programs',
            value: 'loyalty_program_id',
            label: 'name'
          },
          options: null,
          valType: constants.valTypes.int
        },
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      fields: [
        {
          name: 'Customer',
          field: ['customer.first_name', 'customer.last_name'],
          link: 'customer_loyalty_id'
        },
        { name: 'Email', field: ['customer.email'] },
        { name: 'Phone', field: ['customer.phone'], format: 'phone' },
        {
          name: 'Current Credit',
          field: ['credit_balance'],
          format: 'dollars'
        },
        { name: 'Credit All Time', field: ['credit_total'], format: 'dollars' },
        {
          name: 'Threshold',
          field: ['loyalty_program.threshold'],
          format: 'dollars'
        },
        // { name: 'Orders', field: ['orders_total'] },
        { name: 'Current Spend', field: ['spend_current'], format: 'dollars' },
        { name: 'Spend All Time', field: ['spend_total'], format: 'dollars' }
      ]
    }
  },
  '/loyalty-customers/:id': {
    type: constants.pageType.reward,
    title: 'Reward',
    subtitle:
      "See the details of a customer's rewards for a single loyalty program and add credit to their account",
    classes: 'app--aside'
  },
  '/gift-cards': {
    type: constants.pageType.list,
    title: 'Gift Cards',
    subtitle:
      'A list of outstanding gift cards with status, orders, balance, and the date when last used',
    options: ['gift-card-batches'],
    list: {
      endpoint: '/gift-cards',
      id: 'gift_card_id',
      sorting: {},
      hideNew: true,
      actions: [
        { text: 'Download CSV', endpoint: '/gift-cards/csv' },
        { text: 'New Card Batch', type: 'giftCardBatch' }
      ],
      search: {
        label: 'Or search by card number',
        endpoint: 'gift-cards',
        placeholder: 'enter number + hit enter'
      },
      filters: [
        {
          label: 'Batches',
          type: 'select',
          field: 'batch_id',
          settings: {
            endpoint: 'gift-card-batches',
            value: 'batch_id',
            label: 'batch_id'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: '',
          options: constants.active
        },
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      fields: [
        { name: 'Card Number', field: ['card_number'], link: 'gift_card_id' },
        // { name: 'Code', field: ['code'] },
        {
          name: 'Batch',
          field: ['batch_id'],
          link: 'batch_id',
          linkEndpoint: '/gift-card-batches',
          string: 'Batch {}'
        },
        { name: 'Created', field: ['created_at'], format: 'datetime' },
        { name: 'Activated', field: ['activated_at'], format: 'datetime' },
        { name: 'Last Used', field: ['updated_at'], format: 'datetime' },
        { name: 'Orders', field: ['orders'] },
        { name: 'Balance', field: ['balance'], format: 'dollars' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['gift_card_id'] }
      ]
    }
  },
  '/gift-cards/:id': {
    type: constants.pageType.giftCard,
    title: 'Gift Card',
    subtitle: 'Use the form below to update this gift card',
    classes: 'app--aside'
  },
  '/gift-card-batches': {
    type: constants.pageType.list,
    title: 'Employee Card Batches',
    subtitle:
      'All of the gift card batches that have been used to create employee cards',
    classes: 'app--aside',
    list: {
      endpoint: '/gift-card-batches',
      id: 'batch_id',
      sorting: { sortBy: 'name', sortType: 'order', noDrag: true },
      fields: [
        { name: 'Batch', field: ['batch_id'], link: 'batch_id' },
        { name: 'Count', field: ['count'] },
        {
          name: 'Download',
          field: ['download CSV or TXT', ''],
          format: 'path',
          link: 'batch_id'
        }
      ]
    }
  },
  '/gift-card-batches/:id': {
    type: constants.pageType.item,
    title: 'Card Batch',
    subtitle:
      'Use the buttons below to download this batch of cards as a CSV or TXT file',
    list: {
      title: 'Batches',
      endpoint: '/gift-card-batches',
      id: 'batch_id',
      field: 'batch_id',
      sorting: { sortBy: 'batch_id', sortType: 'order' }
    },
    item: {
      endpoint: '/gift-card-batches',
      id: 'batch_id',
      title: 'batch_id',
      tabs: [],
      fields: [
        {
          label: 'Count',
          field: 'count',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          readonly: true
        }
      ],
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/gift-card-batches/:id/gift-cards/csv'
        },
        {
          text: 'Download TXT',
          endpoint: '/gift-card-batches/:id/gift-cards/txt',
          filename: 'batch_:id.txt',
          fileType: 'text/plain'
        }
      ]
    }
  },
  '/downloads': {
    type: constants.pageType.list,
    title: 'Downloads',
    subtitle:
      'A list of any CSVs and other downloadable files that have been processed as background tasks',
    list: {
      endpoint: '/background-tasks',
      id: 'background_task_id',
      hideNew: true,
      classes: 'table--tasks',
      filters: [
        {
          label: 'Task Status',
          type: 'select',
          field: 'status',
          value: constants.taskSatusTypes[0].value,
          options: constants.taskSatusTypes
        }
      ],
      fields: [
        { name: 'Filename', field: ['filename'], format: 'task' },
        // { name: 'Task', field: ['background_task_id'] },
        // { name: 'Created At', field: ['created_at'], format: 'datetime' },
        { name: 'Updated At', field: ['updated_at'], format: 'datetime' },
        { name: 'Status', field: ['status'], format: 'enum' },
        { name: 'Download', field: ['result_urls'], format: 'download' }
      ]
    }
  },
  '/settings': {
    type: constants.pageType.content,
    title: 'Settings',
    subtitle: 'Various settings used across your Open Tender applications',
    content: '',
    image: { url: images.settings, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Surcharges',
            description:
              'Service charges added to certain orders, either automatically or manually',
            endpoint: '/surcharges',
            section: 'SETTINGS'
          },
          {
            title: 'Discounts',
            description: 'Discounts added via your Open Tender POS',
            endpoint: '/discounts',
            section: 'SETTINGS'
          },
          {
            title: 'Taxes',
            description: 'Taxes that can be added to locations or items',
            endpoint: '/taxes',
            section: 'SETTINGS'
          },
          {
            title: 'Pickup Wait Times',
            description:
              'The list of pick-up wait times available across the Open Tender Admin Portal',
            endpoint: '/pickup-wait-times',
            section: 'SETTINGS'
          },
          {
            title: 'Delivery Wait Times',
            description:
              'The list of delivery wait times available across the Open Tender Admin Portal',
            endpoint: '/delivery-wait-times',
            section: 'SETTINGS'
          },
          {
            title: 'Contacts',
            description:
              'The list of inquiry types that appear on the contact form on your website',
            endpoint: '/contacts',
            section: 'SETTINGS'
          },
          {
            title: 'Ops Notifications',
            description:
              'Specify one or more recipients for each notification email',
            endpoint: '/email-notifications',
            section: 'SETTINGS'
          },
          {
            title: 'Confirmations',
            description:
              'Specify senders, recipients, and content for all email types across all order types',
            endpoint: '/confirmation-emails',
            section: 'SETTINGS'
          },
          {
            title: 'Order Notifications',
            description: subtitles.orderNotifications,
            endpoint: '/notification-settings',
            section: 'SETTINGS'
          },
          {
            title: 'Email Design',
            description: 'Information required for using our invoices feature',
            endpoint: '/email-design-settings',
            section: 'SETTINGS'
          },
          {
            title: 'Image Sizes',
            description:
              'Set the dimensions for images created via automated image processing',
            endpoint: '/image-sizes',
            section: 'SETTINGS'
          },
          {
            title: 'Invoices',
            description: 'Information required for using our invoices feature',
            endpoint: '/invoice-settings',
            section: 'SETTINGS'
          },
          {
            title: 'Newsletters',
            description:
              'Send daily specials emails and allow customers to sign up for your brand newsletter',
            endpoint: '/newsletters',
            section: 'SETTINGS'
          },
          {
            title: 'Curbside Pickup',
            description:
              "Used for customizing the curbside pickup features to your brand's process",
            endpoint: '/order-fulfillment',
            section: 'SETTINGS'
          },
          {
            title: 'Order Ratings',
            description: 'Send feedback emails to customers after each order',
            endpoint: '/order-rating-settings',
            section: 'SETTINGS'
          },
          {
            title: 'Brand Settings',
            description:
              'Various brand-level design and content settings that impact your customer-facing Open Tedner website',
            endpoint: '/website',
            section: 'SETTINGS'
          },
          {
            title: 'General Settings',
            description:
              'Various brand-level settings that impact both the Admin Portal and your Open Tender customer-facing site',
            endpoint: '/general-settings',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/surcharges': {
    type: constants.pageType.list,
    title: 'Surcharges',
    subtitle:
      'All of the service charges that can be associated with your revenue centers or applied manually on the POS',
    list: {
      endpoint: '/surcharges',
      id: 'surcharge_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      filters: [
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        },
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'surcharge_id'
        },
        { name: 'Type', field: ['surcharge_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'surcharge_type', type: 'surchargeType' }
        },
        { name: 'Minimum', field: ['minimum'], format: 'dollars' },
        { name: 'Maximum', field: ['maximum'], format: 'dollars' },
        { name: 'Taxed', field: ['is_taxed'], format: 'bool' },
        { name: 'Optional', field: ['optional'], format: 'bool' },
        { name: 'Mgr Approval', field: ['approval'], format: 'bool' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['surcharge_id'] }
      ]
    }
  },
  '/surcharges/:id': {
    type: constants.pageType.item,
    title: 'Surcharge',
    subtitle: 'Use the form below to update this surcharge',
    content: '',
    list: {
      title: 'Surcharges',
      endpoint: '/surcharges',
      id: 'surcharge_id',
      field: 'name',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    item: {
      endpoint: '/surcharges',
      id: 'surcharge_id',
      title: ['name'],
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Surcharge Type',
          field: 'surcharge_type',
          type: 'select',
          options: constants.surchargeType,
          defaultValue: 'DOLLAR',
          classes: '-half'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'text',
          required: true,
          valType: constants.valTypes.decimal,
          classes: '-half -right'
        },
        {
          label: 'Minimum Dollar Amount',
          field: 'minimum',
          type: 'text',
          valType: constants.valTypes.decimal,
          classes: '-half'
        },
        {
          label: 'Maximum Dollar Amount',
          field: 'maximum',
          type: 'text',
          valType: constants.valTypes.decimal,
          classes: '-half -right'
        },
        {
          label: 'Minimum Order Size',
          field: 'order_size_minimum',
          type: 'text',
          valType: constants.valTypes.decimalNullable,
          classes: '-half'
        },
        {
          label: 'Max Order Size',
          field: 'order_size_maximum',
          type: 'text',
          valType: constants.valTypes.decimalNullable,
          classes: '-half -right'
        },
        {
          label: 'Service Types',
          field: 'service_type',
          type: 'select',
          options: constants.serviceTypeAll,
          defaultValue: 'ALL'
        },
        {
          label:
            'Travel Type (only relevant for Distance or Travel Time surcharges)',
          field: 'travel_type',
          type: 'select',
          options: constants.travelType,
          defaultValue: 'DRIVING',
          tooltip:
            'This is only relevant for Distance or Travel Time surcharges'
        },
        {
          label: 'Requires Manager Approval',
          field: 'approval',
          type: 'checkbox',
          tooltip:
            'This is only relevant for surcharges used on the Open Tender POS'
        },
        {
          label: 'Apply Automatically at POS',
          field: 'auto_apply_pos',
          type: 'checkbox',
          tooltip:
            'This is only relevant for surcharges used on the Open Tender POS'
        },
        {
          label: 'Taxed',
          field: 'is_taxed',
          type: 'checkbox',
          tooltip:
            'If checked, tax will be calculated on the order total AFTER the surcharge has been applied. If NOT checked, tax will be calculated on the order total BEFORE the surcharge has been applied.'
        },
        {
          label: 'Optional',
          field: 'optional',
          type: 'checkbox'
        },
        {
          label: 'Label (only relevant for optional surcharges)',
          field: 'label',
          type: 'text'
        },
        {
          label: 'Description (only relevant for optional surcharges)',
          field: 'description',
          type: 'textarea'
        },
        {
          label: 'POS ID',
          field: 'pos_ext_id',
          type: 'text',
          tooltip: 'This is only relevant for certain POS integrations'
        },
        {
          label: 'POS ID for Minimum',
          field: 'pos_ext_id_min',
          type: 'text',
          tooltip: 'This is only relevant for certain POS integrations'
        },
        {
          label: 'POS ID for Maximum',
          field: 'pos_ext_id_max',
          type: 'text',
          tooltip: 'This is only relevant for certain POS integrations'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          field: 'display_order',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/discounts': {
    type: constants.pageType.list,
    title: 'Discounts',
    subtitle:
      'All of the discounts that can be applied on your Open Tender POS',
    options: ['discounts'],
    list: {
      endpoint: '/discounts',
      id: 'discount_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      params: [
        { field: 'limit', value: 1000 },
        { field: 'is_promo_code', value: false },
        { field: 'is_deal', value: false },
        { field: 'is_reward', value: false }
      ],
      filterBy: {
        field: 'discount_type',
        values: ['DOLLAR', 'PERCENTAGE', 'LOYALTY']
      },
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'discount_id'
        },
        { name: 'Type', field: ['discount_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'discount_type', type: 'surchargeType' }
        },
        { name: 'Minimum', field: ['min_amount'], format: 'dollars' },
        { name: 'Maximum', field: ['max_amount'], format: 'dollars' },
        { name: 'Taxed', field: ['is_taxed'], format: 'bool' },
        { name: 'Approval', field: ['requires_approval'], format: 'bool' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Order', field: ['display_order'] },
        { name: 'ID', field: ['discount_id'] }
      ]
    }
  },
  '/discounts/:id': {
    type: constants.pageType.item,
    title: 'Discount',
    subtitle: 'Use the form below to update this discount',
    content: '',
    list: listSettings.discounts,
    item: {
      endpoint: '/discounts',
      id: 'discount_id',
      title: ['name'],
      tabs: tabs.discounts,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        ...fields.discounts,
        {
          label: 'Allow $0.00',
          field: 'allow_zero',
          type: 'checkbox',
          tooltip:
            'If checked, this promo code will be applied to the order even if it results in a discount of $0.00.'
        },
        {
          label: 'Requires Manager Approval',
          field: 'requires_approval',
          type: 'checkbox'
        },
        {
          label: 'POS ID',
          field: 'pos_ext_id',
          type: 'text'
        },
        {
          field: 'is_promo_code',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_deal',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_reward',
          type: 'hidden',
          value: false
        },
        {
          field: 'max_redemptions',
          type: 'hidden',
          value: 0
        },
        {
          field: 'max_redemptions_per',
          type: 'hidden',
          value: 0
        },
        {
          field: 'max_order_size',
          type: 'hidden',
          value: '0.00'
        },
        {
          field: 'requires_new',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_email',
          type: 'hidden',
          value: false
        },
        {
          field: 'domain',
          type: 'hidden',
          value: ''
        },
        {
          field: 'tender_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'auth_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'per_order',
          type: 'hidden',
          value: null
        }
      ]
    }
  },
  '/discounts/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle:
      'Use this page to limit of this discount to specific revenue centers',
    options: ['revenue-centers'],
    list: listSettings.discounts,
    item: {
      endpoint: '/discounts',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'revenue-centers' }],
      tabs: tabs.discounts
    },
    setting: {
      endpoint: '/discounts/:id/revenue-centers',
      id: 'discount_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/discounts/:id/items': {
    type: constants.pageType.discountItems,
    title: 'Discounted Items',
    subtitle: 'Use this page to choose which items should be discounted',
    options: ['menus'],
    list: listSettings.discounts,
    item: {
      endpoint: '/discounts',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.discounts
    }
  },
  '/discounts/:id/qrcodes': {
    type: constants.pageType.itemRelations,
    title: 'QR Codes',
    subtitle: '',
    list: listSettings.discounts,
    item: {
      endpoint: '/discounts',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.discounts
    },
    relations: {
      endpoint: '/discounts/:id/qrcodes',
      id: 'discount_qrcode_id',
      fields: fields.qrCodes
    }
  },
  '/discounts/:id/qrcodes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'QR Code',
    subtitle: 'Create or update a QR code',
    list: listSettings.discounts,
    item: {
      endpoint: '/discounts',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.discounts
    },
    relations: {
      title: 'QR Codes',
      endpoint: '/discounts/:id/qrcodes',
      id: 'discount_qrcode_id',
      field: ['code']
    },
    relation: {
      endpoint: '/discounts/:id/qrcodes',
      isItem: true,
      id: 'discount_qrcode_id',
      title: 'code',
      hasDelete: false,
      fields: fields.qrCode
    }
  },
  '/promo-codes': {
    type: constants.pageType.list,
    title: 'Promo Codes',
    subtitle: subtitles.promoCodes,
    options: ['discounts'],
    list: {
      endpoint: '/discounts',
      newEndpoint: '/promo-codes',
      id: 'discount_id',
      // sorting: { sortBy: 'name', sortType: 'alpha' },
      params: [
        { field: 'limit', value: 100 },
        { field: 'is_promo_code', value: true }
      ],
      search: {
        label: 'Search by name',
        endpoint: 'discounts',
        placeholder: 'enter name + hit enter'
      },
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Promo Code',
          field: ['name'],
          link: 'discount_id',
          linkEndpoint: '/promo-codes'
        },
        { name: 'Type', field: ['discount_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'discount_type', type: 'surchargeType' }
        },
        { name: 'Minimum', field: ['min_amount'], format: 'dollars' },
        { name: 'Maximum', field: ['max_amount'], format: 'dollars' },
        { name: 'Start Date', field: ['start_date'] },
        { name: 'End Date', field: ['end_date'] },
        { name: 'Taxed', field: ['is_taxed'], format: 'bool' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        // { name: 'Order', field: ['display_order'] },
        { name: 'ID', field: ['discount_id'] }
      ]
    }
  },
  '/promo-codes/:id': {
    type: constants.pageType.item,
    title: 'Promo Code',
    subtitle: 'Use the form below to update this promo code',
    content: '',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: ['name'],
      tabs: tabs.promoCodes,
      fields: [
        {
          label: 'Promo Code',
          field: 'name',
          type: 'text',
          required: true
        },
        ...fields.discounts,
        {
          label: 'New Customers Only',
          field: 'requires_new',
          type: 'checkbox',
          tooltip:
            'If checked, only new customers will be able to redeem this promo code. A new customer is determined by their email address.'
        },
        {
          label: 'Approved Email Addresses Only',
          field: 'requires_email',
          type: 'checkbox',
          tooltip:
            'If checked, you must add each user\'s email address to the list of approved email addresses for this promo code via the "Add Approved Email" page.'
        },
        {
          label: 'Allow $0.00',
          field: 'allow_zero',
          type: 'checkbox',
          tooltip:
            'If checked, this discount will be applied to the order even if it results in a discount of $0.00.'
        },
        {
          label: 'Domain',
          field: 'domain',
          type: 'text',
          placeholder: 'somedomain.com',
          tooltip:
            'Enter a domain to restrict promo codes to users with emails ending in a certain domain (e.g. google.com). This tends to be very useful for House Accounts. Please DO NOT enter the "http://" or the "www" in front of the domain.'
        },
        {
          label: 'Max Uses',
          field: 'max_redemptions',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half',
          tooltip:
            "The total number of times this code can be used across ALL customers. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Max Uses per Customer',
          field: 'max_redemptions_per',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half -right',
          tooltip:
            "The number of times this code can be used by a single customer. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Tender Type',
          field: 'tender_type',
          type: 'select',
          options: [{ name: 'All', value: '' }].concat(
            [...constants.tenderTypeOptions].slice(0, 4)
          ),
          defaultValue: null,
          valType: constants.valTypes.nullable
        },
        {
          label: 'POS ID',
          field: 'pos_ext_id',
          type: 'text'
        },
        {
          field: 'is_promo_code',
          type: 'hidden',
          value: true
        },
        {
          field: 'is_deal',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_reward',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_approval',
          type: 'hidden',
          value: false
        },
        {
          field: 'auth_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'per_order',
          type: 'hidden',
          value: null
        }
      ]
    }
  },
  '/promo-codes/:id/redemptions': {
    type: constants.pageType.report,
    title: 'Redemptions',
    subtitle:
      'Promo code redemptions over time broken down by QR code or store',
    options: ['stores'],
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.promoCodes
    },
    report: {
      reportType: 'promoCodes',
      endpoints: ['/reporting/promo-codes', '/reporting/discount-redemptions'],
      dateFilters: true,
      pathParam: 'discount_id',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.redemptionsGroupBy
      ]
    }
  },
  '/promo-codes/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this promo code',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.promoCodes
    },
    files: {
      endpoint: '/discounts/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/promo-codes/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle:
      'Use this page to limit of this promo code to specific revenue centers',
    options: ['revenue-centers'],
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.promoCodes
    },
    setting: {
      endpoint: '/discounts/:id/revenue-centers',
      id: 'discount_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/promo-codes/:id/items': {
    type: constants.pageType.discountItems,
    title: 'Discounted Items',
    subtitle: 'Use this page to choose which items should be discounted',
    options: ['menus'],
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.promoCodes
    }
  },
  '/promo-codes/:id/dayparts': {
    type: constants.pageType.itemRelations,
    title: 'Dayparts',
    subtitle:
      'Use this page to assign this promo code to be used only during certain dayparts',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.promoCodes
    },
    relations: {
      endpoint: '/promo-codes/:id/dayparts',
      // linkEndpoint: '/promo-codes/:id/dayparts',
      id: 'daypart_id',
      sorting: { sortBy: 'start_time', sortType: 'alphaTime' },
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'daypart_id',
          linkPath: true
        },
        // { name: 'Internal Name', field: ['short_name'] },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Start Time', field: ['start_time'], format: 'time' },
        { name: 'End Time', field: ['end_time'], format: 'time' },
        { name: 'ID', field: ['daypart_id'] }
      ]
    }
  },
  '/promo-codes/:id/dayparts/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Dayparts',
    subtitle: 'Add or remove a daypart assignment',
    options: ['dayparts'],
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.promoCodes
    },
    relations: {
      title: 'Dayparts',
      endpoint: '/promo-codes/:id/dayparts',
      id: 'daypart_id',
      field: ['full_name'],
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/discounts/:id/dayparts',
      redirect: '/promo-codes/:id/dayparts',
      backEndpoint: '/promo-codes/:id/dayparts',
      id: 'daypart_id',
      title: 'full_name',
      hasDelete: true,
      fields: [
        {
          label: 'Daypart',
          field: 'daypart_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'dayparts',
            entity: 'daypart',
            value: 'daypart_id',
            label: ['full_name', 'order_type']
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/promo-codes/:id/emails': {
    type: constants.pageType.itemRelations,
    title: 'Emails',
    subtitle: 'Associate an email address with a promo code',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'emails' }],
      tabs: tabs.promoCodes
    },
    relations: {
      endpoint: '/discounts/:id/emails',
      entity: null,
      id: 'email',
      sorting: { sortBy: 'email', sortType: 'alpha' },
      upload: {
        text: 'Upload CSV',
        endpoint: '/discounts/:id/emails/import/csv'
      },
      fields: [
        { name: 'Email', field: ['email'] },
        {
          name: 'Remove',
          field: ['remove', ''],
          button: 'email',
          format: 'path',
          settings: {
            title: 'Are you sure?',
            subtitle:
              'Please click the delete button to confirm removal of this email address from this promo code',
            type: 'deleteRelation'
          }
        }
      ]
    }
  },
  '/promo-codes/:id/emails/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Email',
    subtitle:
      'Use the form below to add a new email address to this promo code',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'emails' }],
      tabs: tabs.promoCodes
    },
    relations: {
      title: 'Emails',
      endpoint: '/promo-codes/:id/emails'
    },
    relation: {
      endpoint: '/discounts/:id/emails',
      redirect: '/promo-codes/:id/emails',
      backEndpoint: '/promo-codes/:id/emails',
      id: 'email',
      title: ['email'],
      tabs: [],
      fields: [
        {
          label: 'Email Address',
          field: 'email',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/promo-codes/:id/qrcodes': {
    type: constants.pageType.itemRelations,
    title: 'QR Codes',
    subtitle: '',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.promoCodes
    },
    relations: {
      endpoint: '/promo-codes/:id/qrcodes',
      id: 'discount_qrcode_id',
      fields: fields.qrCodes
    }
  },
  '/promo-codes/:id/qrcodes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'QR Code',
    subtitle: 'Create or update a QR code',
    list: listSettings.promoCodes,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/promo-codes',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.promoCodes
    },
    relations: {
      title: 'QR Codes',
      endpoint: '/promo-codes/:id/qrcodes',
      id: 'discount_qrcode_id',
      field: ['code']
    },
    relation: {
      endpoint: '/promo-codes/:id/qrcodes',
      isItem: true,
      id: 'discount_qrcode_id',
      title: 'code',
      hasDelete: false,
      fields: fields.qrCode
    }
  },
  '/announcements': {
    type: constants.pageType.list,
    title: 'Announcements',
    subtitle:
      'Announcements are used to tell your customers about things on your site. You them to promote new items, specials, deals, or anything else you want to highlight.',
    list: {
      endpoint: '/announcements',
      id: 'announcement_id',
      sorting: { sortBy: 'title', sortType: 'alpha' },
      params: [
        { field: 'limit', value: 100 },
        { field: 'with_related', value: true }
      ],
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Title',
          field: ['title'],
          link: 'announcement_id'
        },
        {
          name: 'Pages',
          field: ['announcement_pages.announcement_page.page'],
          format: 'array'
        },
        { name: 'URL', field: ['url'] },
        // { name: 'Subtitle', field: ['subtitle'] },
        { name: 'Vertical', field: ['vertical'], format: 'enum' },
        { name: 'Horizontal', field: ['horizontal'], format: 'enum' },
        { name: 'Hide Text', field: ['hide_text'], format: 'bool' },
        { name: 'Show Overlay', field: ['show_overlay'], format: 'bool' },
        {
          name: 'Overlay Color',
          field: ['overlay_color'],
          format: 'swatch'
        },
        {
          name: 'Text Color',
          field: ['text_color'],
          format: 'swatch'
        },
        { name: 'ID', field: ['announcement_id'] }
      ]
    }
  },
  '/announcements/:id': {
    type: constants.pageType.item,
    title: 'Announcement',
    subtitle: 'Use the form below to update this announcement',
    list: listSettings.announcements,
    item: {
      endpoint: '/announcements',
      id: 'announcement_id',
      title: 'title',
      tabs: tabs.announcements,
      hasDelete: true,
      fields: [
        { label: 'Title', field: 'title', type: 'text', required: true },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Title Size Override (in pixels)',
          field: 'title_size',
          type: 'text',
          valType: constants.valTypes.intNullable,
          classes: '-half',
          tooltip:
            'If populated, the title font size for this slide will be set to this size instead of the default size'
        },
        {
          label: 'Subtitle Size Override (in pixels)',
          field: 'subtitle_size',
          type: 'text',
          valType: constants.valTypes.intNullable,
          classes: '-half -right',
          tooltip:
            'If populated, the subtitle font size for this slide will be set to this size instead of the default size'
        },
        {
          label: 'Call To Action URL',
          field: 'url',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is a link to some webpage that you want to direct the user to for this announcement (i.e. where a call to action button goes).'
        },
        {
          label: 'Call To Action Text',
          field: 'url_text',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is the text that can be paired with the Call To Action URL (i.e. what the call to action button says).'
        },
        {
          label: 'Text Vertical Alignment',
          field: 'vertical',
          type: 'select',
          options: constants.verticalAlignment,
          defaultValue: 'CENTER',
          classes: '-half'
        },
        {
          label: 'Text Horizontal Alignment',
          field: 'horizontal',
          type: 'select',
          options: constants.horizontalAlignment,
          defaultValue: 'CENTER',
          classes: '-half -right'
        },
        {
          label: 'Title Color',
          field: 'text_color',
          type: 'inputColor',
          placeholder: 'ffffff',
          defaultValue: 'ffffff',
          classes: '-half'
        },
        {
          label: 'Subtitle Color',
          field: 'subtitle_color',
          type: 'inputColor',
          placeholder: 'ffffff',
          defaultValue: 'ffffff',
          classes: '-half -right'
        },
        {
          label: 'Overlay Color',
          field: 'overlay_color',
          type: 'inputColor',
          placeholder: '000000',
          defaultValue: '000000',
          classes: '-half'
        },
        {
          label: 'Overlay Opacity (percentage)',
          field: 'overlay_opacity',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '30',
          defaultValue: 0,
          classes: '-half -right'
        },
        {
          label: 'Show Overlay',
          field: 'show_overlay',
          type: 'checkbox',
          tooltip:
            'An overlay is a partially transparent color layer that appears above the image, usually to either lighten or darken an image so that text sitting over the image is more readable.'
        },
        {
          label: 'Hide Text',
          field: 'hide_text',
          type: 'checkbox',
          tooltip:
            'If checked, the Title and Subtitle will not be display over the image.'
        },
        {
          label: 'Is Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        }
      ]
    }
  },
  '/announcements/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle:
      'Use this page to upload images & other files for this announcement',
    content: '',
    list: listSettings.announcements,
    item: itemSettings.announcements,
    files: {
      endpoint: '/announcements/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Featured Image', type: 'FEATURED_IMAGE' },
        { name: 'Secondary Image', type: 'SECONDARY_IMAGE' }
      ]
    }
  },
  '/announcement-pages': {
    type: constants.pageType.list,
    title: 'Announcement Pages',
    subtitle:
      "These are the pages on which announcements appear. You can choose which announcements show up on which pages, as well as control other settings about how they're presented. Click on a page to choose which announcements are associated with it.",
    list: {
      endpoint: '/announcement-pages',
      id: 'announcement_page_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      params: [
        { field: 'limit', value: 100 },
        { field: 'with_related', value: true }
      ],
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Page',
          field: ['page'],
          link: 'announcement_page_id',
          format: 'enum'
        },
        {
          name: 'Announcements',
          field: ['announcements.announcement.title'],
          format: 'array'
        },
        { name: 'Autoplay', field: ['autoplay'], format: 'bool' },
        { name: 'Transition', field: ['transition'] },
        { name: 'Duration', field: ['duration'] },
        { name: 'Show Arrows', field: ['show_arrows'], format: 'bool' },
        { name: 'Show Dots', field: ['show_dots'], format: 'bool' },
        { name: 'ID', field: ['announcement_page_id'] }
      ]
    }
  },
  '/announcement-pages/:id': {
    type: constants.pageType.item,
    title: 'Announcement Pages',
    subtitle: 'Use the form below to update this announcement page',
    list: listSettings.announcementPages,
    item: {
      endpoint: '/announcement-pages',
      id: 'announcement_page_id',
      title: 'page',
      tabs: tabs.announcementPages,
      hasDelete: true,
      fields: [
        {
          label: 'Page',
          field: 'page',
          type: 'select',
          readonly: true,
          options: constants.announcementPages
        },
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Transition Type',
          field: 'transition_type',
          type: 'select',
          options: constants.transitionType,
          defaultValue: 'SLIDE'
        },
        {
          label: 'Autoplay',
          field: 'autoplay',
          type: 'checkbox'
        },
        {
          label: 'Slide Tansition Speed on Desktop',
          field: 'transition',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '1000',
          defaultValue: 1000,
          classes: '-half'
        },
        {
          label: 'Slide Tansition Speed on Mobile',
          field: 'transition_mobile',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '500',
          defaultValue: 500,
          classes: '-half -right'
        },
        {
          label: 'Slide Duration on Desktop',
          field: 'duration',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '3000',
          defaultValue: 3000,
          classes: '-half'
        },
        {
          label: 'Slide Duration on Mobile',
          field: 'duration_mobile',
          type: 'text',
          valType: constants.valTypes.int,
          placeholder: '2500',
          defaultValue: 2500,
          classes: '-half -right'
        },
        {
          label: 'Show Arrows on Desktop',
          field: 'show_arrows',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Show Arrows on Mobile',
          field: 'show_arrows_mobile',
          type: 'checkbox',
          defaultValue: false
        },
        {
          label: 'Arrows Vertical Alignment',
          field: 'arrows_vertical',
          type: 'select',
          options: constants.arrowsVerticalAlignment,
          defaultValue: 'BOTTOM',
          classes: '-half'
        },
        {
          label: 'Arrows Horizontal Alignment',
          field: 'arrows_horizontal',
          type: 'select',
          options: constants.arrowsHorizontalAlignment,
          defaultValue: 'RIGHT',
          classes: '-half -right'
        },
        {
          label: 'Show Dots on Desktop',
          field: 'show_dots',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Show Dots on Mobile',
          field: 'show_dots_mobile',
          type: 'checkbox',
          defaultValue: true
        },
        {
          label: 'Slide Title Size Override (in pixels)',
          field: 'slide_title_size',
          type: 'text',
          valType: constants.valTypes.intNullable,
          classes: '-half',
          tooltip:
            'If populated, the title font size for all slides will be set to this size'
        },
        {
          label: 'Slide Subtitle Size Override (in pixels)',
          field: 'slide_subtitle_size',
          type: 'text',
          valType: constants.valTypes.intNullable,
          classes: '-half -right',
          tooltip:
            'If populated, the subtitle font size for all slides will be set to this size'
        },
        {
          label: 'Hide Slide Text on Desktop',
          field: 'hide_text',
          type: 'checkbox',
          defaultValue: true,
          tooltip:
            'If selected, the text for all slides will be hidden on desktop'
        },
        {
          label: 'Hide Slide Text on Mobile',
          field: 'hide_text_mobile',
          type: 'checkbox',
          defaultValue: true,
          tooltip:
            'If selected, the text for all slides will be hidden on mobile'
        },
        {
          label: 'Is Active',
          field: 'is_active',
          type: 'checkbox',
          defaultValue: true
        }
      ]
    }
  },
  '/announcement-pages/:id/announcements': {
    type: constants.pageType.itemRelations,
    title: 'Announcements',
    subtitle: 'Use this page to associate announcements with specific pages',
    list: listSettings.announcementPages,
    item: itemSettings.announcementPages,
    relations: {
      isList: true,
      endpoint: '/announcement-pages/:id/announcements',
      entity: 'announcement',
      id: 'announcement_id',
      sorting: {
        sortBy: 'display_order',
        sortType: 'order',
        fields: [],
        isEntity: true
      },
      classes: 'table--rc-menus',
      fields: [
        { name: 'Title', field: ['title'], link: 'announcement_id' },
        { name: 'Order', field: ['display_order'] },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['announcement_id'] }
      ]
    }
  },
  '/announcement-pages/:id/announcements/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Announcements',
    subtitle: 'Use this page to associate announcements with this page',
    options: ['announcements'],
    list: listSettings.announcementPages,
    item: itemSettings.announcementPages,
    relations: {
      title: 'Announcements',
      endpoint: '/announcement-pages/:id/announcements',
      id: 'announcement_id',
      field: 'title',
      sorting: { sortBy: 'display_order', sortType: 'order' }
    },
    relation: {
      endpoint: '/announcement-pages/:id/announcements',
      id: 'announcement_id',
      title: 'title',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Announcement',
          field: 'announcement_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'announcements',
            entity: 'announcement',
            value: 'announcement_id',
            label: 'title'
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/deals': {
    type: constants.pageType.list,
    title: 'Deals',
    subtitle: subtitles.deals,
    options: ['discounts'],
    list: {
      endpoint: '/discounts',
      newEndpoint: '/deals',
      id: 'discount_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      params: [
        { field: 'limit', value: 100 },
        { field: 'is_deal', value: true }
      ],
      search: {
        label: 'Search by name',
        endpoint: 'discounts',
        placeholder: 'enter name + hit enter'
      },
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Deal',
          field: ['name'],
          link: 'discount_id',
          linkEndpoint: '/deals'
        },
        { name: 'Type', field: ['discount_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'discount_type', type: 'surchargeType' }
        },
        { name: 'Title', field: ['title'] },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Auth Type', field: ['auth_type'], format: 'enum' },
        { name: 'End Date', field: ['end_date'] },
        { name: 'Max Uses', field: ['max_redemptions'] },
        { name: 'Max Uses Per', field: ['max_redemptions_per'] },
        // { name: 'Taxed', field: ['is_taxed'], format: 'bool' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        // { name: 'Order', field: ['display_order'] },
        { name: 'ID', field: ['discount_id'] }
      ]
    }
  },
  '/deals/:id': {
    type: constants.pageType.item,
    title: 'Deal',
    subtitle: 'Use the form below to update this deal',
    content: '',
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: ['name'],
      tabs: tabs.deals,
      fields: [
        {
          label: 'Deal',
          field: 'name',
          type: 'text',
          required: true,
          tooltip:
            "This will show up on the customer's receipt, but otherwise they'll see the reward's title in most places"
        },
        ...fields.discounts,
        {
          label: 'Authorization Type',
          field: 'auth_type',
          type: 'select',
          options: constants.discountAuthType,
          defaultValue: 'ACCOUNT',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Max Uses',
          field: 'max_redemptions',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half',
          tooltip:
            "The total number of times this code can be used across ALL customers. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Max Uses per Customer',
          field: 'max_redemptions_per',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half -right',
          tooltip:
            "The number of times this code can be used by a single customer. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Total Deals per Order',
          field: 'per_order',
          type: 'text',
          required: false,
          valType: constants.valTypes.intNullable,
          tooltip:
            'Use this to limit the number of deals that can be applied to an order. For instance, if you set this to 1, only one deal can be applied to an order when this deal is applied, so this deal can only be used by itself and not in combination with other deals.'
        },
        {
          field: 'pos_ext_id',
          type: 'hidden',
          value: ''
        },
        {
          field: 'is_promo_code',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_deal',
          type: 'hidden',
          value: true
        },
        {
          field: 'is_reward',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_approval',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_new',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_email',
          type: 'hidden',
          value: false
        },
        {
          field: 'domain',
          type: 'hidden',
          value: ''
        },
        {
          field: 'tender_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'allow_zero',
          type: 'hidden',
          value: false
        }
      ]
    }
  },
  '/deals/:id/redemptions': {
    type: constants.pageType.report,
    title: 'Redemptions',
    subtitle: 'Deal redemptions over time broken down by QR code or store',
    options: ['stores'],
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.deals
    },
    report: {
      reportType: 'promoCodes',
      endpoints: ['/reporting/promo-codes', '/reporting/discount-redemptions'],
      dateFilters: true,
      pathParam: 'discount_id',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.redemptionsGroupBy
      ]
    }
  },
  '/deals/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this deal',
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.deals
    },
    files: {
      endpoint: '/discounts/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/deals/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle: 'Use this page to limit of this deal to specific revenue centers',
    options: ['revenue-centers'],
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.deals
    },
    setting: {
      endpoint: '/discounts/:id/revenue-centers',
      id: 'discount_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/deals/:id/items': {
    type: constants.pageType.discountItems,
    title: 'Discounted Items',
    subtitle: 'Use this page to choose which items should be discounted',
    options: ['menus'],
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.deals
    }
  },
  '/deals/:id/dayparts': {
    type: constants.pageType.itemRelations,
    title: 'Dayparts',
    subtitle: 'Use this page to limit this deal to certain dayparts',
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.deals
    },
    relations: {
      endpoint: '/deals/:id/dayparts',
      // linkEndpoint: '/deals/:id/dayparts',
      id: 'daypart_id',
      sorting: { sortBy: 'start_time', sortType: 'alphaTime' },
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'daypart_id',
          linkPath: true
        },
        // { name: 'Internal Name', field: ['short_name'] },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Start Time', field: ['start_time'], format: 'time' },
        { name: 'End Time', field: ['end_time'], format: 'time' },
        { name: 'ID', field: ['daypart_id'] }
      ]
    }
  },
  '/deals/:id/dayparts/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Dayparts',
    subtitle: 'Add or remove a daypart assignment',
    options: ['dayparts'],
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.deals
    },
    relations: {
      title: 'Dayparts',
      endpoint: '/deals/:id/dayparts',
      id: 'daypart_id',
      field: ['full_name'],
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/discounts/:id/dayparts',
      redirect: '/deals/:id/dayparts',
      backEndpoint: '/deals/:id/dayparts',
      id: 'daypart_id',
      title: 'full_name',
      hasDelete: true,
      fields: [
        {
          label: 'Daypart',
          field: 'daypart_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'dayparts',
            entity: 'daypart',
            value: 'daypart_id',
            label: ['full_name', 'order_type']
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/deals/:id/qrcodes': {
    type: constants.pageType.itemRelations,
    title: 'QR Codes',
    subtitle: '',
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.deals
    },
    relations: {
      endpoint: '/deals/:id/qrcodes',
      id: 'discount_qrcode_id',
      fields: fields.qrCodes
    }
  },
  '/deals/:id/qrcodes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'QR Code',
    subtitle: 'Create or update a QR code',
    list: listSettings.deals,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/deals',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.deals
    },
    relations: {
      title: 'QR Codes',
      endpoint: '/deals/:id/qrcodes',
      id: 'discount_qrcode_id',
      field: ['code']
    },
    relation: {
      endpoint: '/deals/:id/qrcodes',
      isItem: true,
      id: 'discount_qrcode_id',
      title: 'code',
      hasDelete: false,
      fields: fields.qrCode
    }
  },
  '/rewards': {
    type: constants.pageType.list,
    title: 'Rewards',
    subtitle: subtitles.rewards,
    options: ['discounts'],
    list: {
      endpoint: '/discounts',
      newEndpoint: '/rewards',
      id: 'discount_id',
      // sorting: { sortBy: 'name', sortType: 'alpha' },
      params: [
        { field: 'limit', value: 100 },
        { field: 'is_reward', value: true }
      ],
      search: {
        label: 'Search by name',
        endpoint: 'discounts',
        placeholder: 'enter name + hit enter'
      },
      filters: [
        {
          label: 'Status',
          type: 'select',
          field: 'is_active',
          value: true,
          options: constants.active
        }
      ],
      fields: [
        {
          name: 'Reward',
          field: ['name'],
          link: 'discount_id',
          linkEndpoint: '/rewards'
        },
        { name: 'Type', field: ['discount_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'discount_type', type: 'surchargeType' }
        },
        { name: 'Title', field: ['title'] },
        // { name: 'Order Type', field: ['order_type'], format: 'enum' },
        // { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Start Date', field: ['start_date'] },
        { name: 'End Date', field: ['end_date'] },
        { name: 'Max Uses', field: ['max_redemptions'], format: 'quantity' },
        {
          name: 'Max Uses Per',
          field: ['max_redemptions_per'],
          format: 'quantity'
        },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['discount_id'] }
      ]
    }
  },
  '/rewards/:id': {
    type: constants.pageType.item,
    title: 'Reward',
    subtitle: 'Use the form below to update this reward',
    content: '',
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: ['name'],
      tabs: tabs.rewards,
      fields: [
        {
          label: 'Reward',
          field: 'name',
          type: 'text',
          required: true,
          tooltip:
            "This will show up on the customer's receipt, but otherwise they'll see the reward's title in most places"
        },
        ...fields.discounts,
        {
          label: 'Max Uses',
          field: 'max_redemptions',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half',
          tooltip:
            "The total number of times this code can be used across ALL customers. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Max Uses per Customer',
          field: 'max_redemptions_per',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          defaultValue: '',
          classes: '-half -right',
          tooltip:
            "The number of times this code can be used by a single customer. Set to zero if you don't want to set a limit."
        },
        {
          label: 'Total Deals per Order',
          field: 'per_order',
          type: 'text',
          required: false,
          valType: constants.valTypes.intNullable,
          tooltip:
            'Use this to limit the number of deals that can be applied to an order. For instance, if you set this to 1, only one deal can be applied to an order when this deal is applied, so this deal can only be used by itself and not in combination with other deals.'
        },
        {
          field: 'auth_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'pos_ext_id',
          type: 'hidden',
          value: ''
        },
        {
          field: 'is_promo_code',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_deal',
          type: 'hidden',
          value: false
        },
        {
          field: 'is_reward',
          type: 'hidden',
          value: true
        },
        {
          field: 'requires_approval',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_new',
          type: 'hidden',
          value: false
        },
        {
          field: 'requires_email',
          type: 'hidden',
          value: false
        },
        {
          field: 'domain',
          type: 'hidden',
          value: ''
        },
        {
          field: 'tender_type',
          type: 'hidden',
          value: null
        },
        {
          field: 'allow_zero',
          type: 'hidden',
          value: false
        }
      ]
    }
  },
  '/rewards/:id/redemptions': {
    type: constants.pageType.report,
    title: 'Redemptions',
    subtitle: 'Reward redemptions over time broken down by QR code or store',
    options: ['stores'],
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.rewards
    },
    report: {
      reportType: 'promoCodes',
      endpoints: ['/reporting/promo-codes', '/reporting/discount-redemptions'],
      dateFilters: true,
      pathParam: 'discount_id',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.redemptionsGroupBy
      ]
    }
  },
  '/rewards/:id/files': {
    type: constants.pageType.itemFiles,
    title: 'Images',
    subtitle: 'Use this page to upload images for this reward',
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.rewards
    },
    files: {
      endpoint: '/discounts/:id/files',
      id: 'file_id',
      files: [
        { name: 'Raw Image', type: 'RAW' },
        { name: 'Small Image', type: 'SMALL_IMAGE' },
        { name: 'Large Image', type: 'LARGE_IMAGE' },
        { name: 'App Image', type: 'APP_IMAGE' }
      ]
    }
  },
  '/rewards/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle:
      'Use this page to limit of this reward to specific revenue centers',
    options: ['revenue-centers'],
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.rewards
    },
    setting: {
      endpoint: '/discounts/:id/revenue-centers',
      id: 'discount_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/rewards/:id/items': {
    type: constants.pageType.discountItems,
    title: 'Discounted Items',
    subtitle: 'Use this page to choose which items should be discounted',
    options: ['menus'],
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      tabs: tabs.rewards
    }
  },
  '/rewards/:id/dayparts': {
    type: constants.pageType.itemRelations,
    title: 'Dayparts',
    subtitle: 'Use this page to limit this reward to certain dayparts',
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.rewards
    },
    relations: {
      endpoint: '/rewards/:id/dayparts',
      // linkEndpoint: '/rewards/:id/dayparts',
      id: 'daypart_id',
      sorting: { sortBy: 'start_time', sortType: 'alphaTime' },
      fields: [
        {
          name: 'Name',
          field: ['full_name'],
          link: 'daypart_id',
          linkPath: true
        },
        // { name: 'Internal Name', field: ['short_name'] },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Start Time', field: ['start_time'], format: 'time' },
        { name: 'End Time', field: ['end_time'], format: 'time' },
        { name: 'ID', field: ['daypart_id'] }
      ]
    }
  },
  '/rewards/:id/dayparts/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Dayparts',
    subtitle: 'Add or remove a daypart assignment',
    options: ['dayparts'],
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'dayparts' }],
      tabs: tabs.rewards
    },
    relations: {
      title: 'Dayparts',
      endpoint: '/rewards/:id/dayparts',
      id: 'daypart_id',
      field: ['full_name'],
      sorting: { sortBy: 'full_name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/discounts/:id/dayparts',
      redirect: '/rewards/:id/dayparts',
      backEndpoint: '/rewards/:id/dayparts',
      id: 'daypart_id',
      title: 'full_name',
      hasDelete: true,
      fields: [
        {
          label: 'Daypart',
          field: 'daypart_id',
          type: 'select',
          required: true,
          disabled: true,
          options: {
            endpoint: 'dayparts',
            entity: 'daypart',
            value: 'daypart_id',
            label: ['full_name', 'order_type']
          },
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/rewards/:id/customers': {
    type: constants.pageType.itemRelations,
    title: 'Customers',
    subtitle: '',
    content: '',
    options: [],
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'customers' }],
      tabs: tabs.rewards
    },
    relations: {
      title: 'Customers',
      endpoint: '/discounts/:id/customers',
      id: 'customer_id',
      sorting: { sortBy: 'first_name', sortType: 'alpha' },
      upload: {
        text: 'Upload CSV',
        endpoint: '/discounts/:id/customers/import/csv'
      },
      search: {
        modalTitle: 'Add New Customer',
        modalSubtitle: 'Find a customer and award this discount',
        modalType: 'discountCustomer'
      },
      fields: [
        {
          name: 'Name',
          field: ['first_name', 'last_name'],
          link: 'customer_id',
          linkPath: true
        },
        { name: 'Awarded At', field: ['created_at'], format: 'datetime' },
        { name: 'Redeemed', field: ['is_redeemed'], format: 'bool' }
        // { name: 'Redeemed At', field: ['updated_at'], format: 'datetime' }
      ]
    }
  },
  '/rewards/:id/customers/:idd': {
    type: constants.pageType.itemRelation,
    title: 'Customer',
    subtitle:
      "Use the form below to remove this reward from the customer's account. Please note that customers CANNOT be removed if they have already redeemed the reward.",
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'customers' }],
      tabs: tabs.rewards
    },
    relations: {
      title: 'Customers',
      endpoint: '/discounts/:id/customers',
      id: 'customer_id',
      field: ['first_name', 'last_name'],
      sorting: { sortBy: 'first_name', sortType: 'alpha' }
    },
    relation: {
      endpoint: '/discounts/:id/customers',
      redirect: '/rewards/:id/customers',
      backEndpoint: '/rewards/:id/customers',
      id: 'customer_id',
      title: ['first_name', 'last_name'],
      hasDelete: true,
      tabs: [],
      fields: []
    }
  },
  '/rewards/:id/qrcodes': {
    type: constants.pageType.itemRelations,
    title: 'QR Codes',
    subtitle: '',
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.rewards
    },
    relations: {
      endpoint: '/rewards/:id/qrcodes',
      id: 'discount_qrcode_id',
      fields: fields.qrCodes
    }
  },
  '/rewards/:id/qrcodes/:idd': {
    type: constants.pageType.itemRelation,
    title: 'QR Code',
    subtitle: 'Create or update a QR code',
    list: listSettings.rewards,
    item: {
      endpoint: '/discounts',
      backEndpoint: '/rewards',
      id: 'discount_id',
      title: 'name',
      params: [{ field: 'with_related', value: 'qrcodes' }],
      tabs: tabs.rewards
    },
    relations: {
      title: 'QR Codes',
      endpoint: '/rewards/:id/qrcodes',
      id: 'discount_qrcode_id',
      field: ['code']
    },
    relation: {
      endpoint: '/rewards/:id/qrcodes',
      isItem: true,
      id: 'discount_qrcode_id',
      title: 'code',
      hasDelete: false,
      fields: fields.qrCode
    }
  },
  '/taxes': {
    type: constants.pageType.list,
    title: 'Taxes',
    subtitle:
      'All of the taxes that can be associated with revenue centers or menu items',
    list: {
      endpoint: '/taxes',
      id: 'tax_id',
      sorting: { sortBy: 'display_order', sortType: 'order' },
      fields: [
        {
          name: 'Name',
          field: ['name'],
          link: 'tax_id'
        },
        { name: 'Type', field: ['tax_type'], format: 'enum' },
        {
          name: 'Amount',
          field: ['amount'],
          format: { field: 'tax_type', type: 'surchargeType' }
        },
        { name: 'Optional', field: ['is_optional'], format: 'bool' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'ID', field: ['tax_id'] }
      ]
    }
  },
  '/taxes/:id': {
    type: constants.pageType.item,
    title: 'Tax',
    subtitle: 'Use the form below to update this surcharge',
    content: '',
    list: listSettings.taxes,
    item: {
      endpoint: '/taxes',
      id: 'tax_id',
      title: ['name'],
      tabs: tabs.taxes,
      fields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Tax Type',
          field: 'tax_type',
          type: 'select',
          options: [...constants.surchargeType].slice(0, 2),
          defaultValue: 'DOLLAR'
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'text',
          required: true,
          valType: constants.valTypes.decimal
        },
        {
          label: 'Optional',
          field: 'is_optional',
          type: 'checkbox'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          field: 'display_order',
          type: 'hidden',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/taxes/:id/revenue-centers': {
    type: constants.pageType.itemSetting,
    title: 'Revenue Centers',
    subtitle: 'Use this page to limit of this tax to specific revenue centers',
    classes: 'page--taxes-revenue-centers',
    options: ['revenue-centers'],
    list: listSettings.taxes,
    item: itemSettings.taxes,
    setting: {
      endpoint: '/taxes/:id/revenue-centers',
      id: 'tax_id',
      listId: 'revenue_center_id',
      listEntity: 'revenue-centers',
      fields: [
        {
          label: 'Point of Sale',
          field: 'POS',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'POS',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'checkboxGroup',
          options: {
            endpoint: 'revenue-centers',
            entity: 'MERCH',
            value: 'revenue_center_id',
            label: 'full_name',
            hideNone: true
          },
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/pickup-wait-times': {
    type: constants.pageType.list,
    title: 'Pickup Wait Times',
    subtitle:
      'The list of pickup wait times that your employees can choose from',
    classes: 'app--aside',
    list: {
      endpoint: '/pickup-wait-times',
      id: 'pickup_wait_time_id',
      sorting: {
        sortBy: 'pickup_wait_time',
        sortType: 'order',
        noDrag: true
      },
      fields: [
        {
          name: 'Pickup Wait Time',
          field: ['pickup_wait_time'],
          link: 'pickup_wait_time_id'
        },
        { name: 'ID', field: ['pickup_wait_time_id'] }
      ]
    }
  },
  '/pickup-wait-times/:id': {
    type: constants.pageType.item,
    title: 'Pickup Wait Time',
    subtitle: 'Update a wait time or add a new one',
    content: '',
    list: {
      title: 'Pickup Wait Times',
      endpoint: '/pickup-wait-times',
      id: 'pickup_wait_time_id',
      field: 'pickup_wait_time',
      sorting: { sortBy: 'pickup_wait_time', sortType: 'order' }
    },
    item: {
      endpoint: '/pickup-wait-times',
      id: 'pickup_wait_time_id',
      title: 'pickup_wait_time',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Pickup Wait Time',
          field: 'pickup_wait_time',
          type: 'text',
          valType: constants.valTypes.int,
          required: true
        }
      ]
    }
  },
  '/delivery-wait-times': {
    type: constants.pageType.list,
    title: 'Delivery Wait Times',
    subtitle:
      'The list of delivery wait times that your employees can choose from',
    classes: 'app--aside',
    list: {
      endpoint: '/delivery-wait-times',
      id: 'delivery_wait_time_id',
      sorting: {
        sortBy: 'delivery_wait_time',
        sortType: 'order',
        noDrag: true
      },
      fields: [
        {
          name: 'Delivery Wait Time',
          field: ['delivery_wait_time'],
          link: 'delivery_wait_time_id'
        },
        { name: 'ID', field: ['delivery_wait_time_id'] }
      ]
    }
  },
  '/delivery-wait-times/:id': {
    type: constants.pageType.item,
    title: 'Delivery Wait Time',
    subtitle: 'Update a wait time or add a new one',
    content: '',
    list: {
      title: 'Delivery Wait Times',
      endpoint: '/delivery-wait-times',
      id: 'delivery_wait_time_id',
      field: 'delivery_wait_time',
      sorting: { sortBy: 'delivery_wait_time', sortType: 'order' }
    },
    item: {
      endpoint: '/delivery-wait-times',
      id: 'delivery_wait_time_id',
      title: 'delivery_wait_time',
      hasDelete: true,
      tabs: [],
      fields: [
        {
          label: 'Delivery Wait Time',
          field: 'delivery_wait_time',
          type: 'text',
          valType: constants.valTypes.int,
          required: true
        }
      ]
    }
  },
  '/email-notifications': {
    type: constants.pageType.item,
    title: 'Notification Emails',
    subtitle:
      'Specify one or more recipients (comma-separated) for each of our notification emails',
    content: '',
    item: {
      endpoint: '/email-recipients',
      fields: [
        {
          label: 'Unconfirmed Orders',
          field: 'olo_order_unconfirmed',
          type: 'text',
          required: true,
          tooltip:
            "If you're NOT using one of our POS integrations, these recipients will receive an email when an order has gone unconfirmed for a certain number of minutes(as set on the General Settings page)."
        },
        {
          label: 'OLO Settings Changed',
          field: 'olo_settings_change',
          type: 'text',
          required: true,
          tooltip:
            'Sent when a location changes its online ordering settings (pickup wait time, delivery wait time, or location on/off status).'
        },
        {
          label: 'Daily Catering Summary',
          field: 'catering_daily_summary',
          type: 'text',
          required: true,
          tooltip:
            'A daily summary of upcoming catering orders sent out very early each morning.'
        },
        {
          label: 'POS Errors',
          field: 'pos_server_errors',
          type: 'text',
          required: false,
          tooltip: 'Only relevant for customers using the Open Tender POS'
        },
        {
          label: 'POS Offline',
          field: 'pos_server_offline',
          type: 'text',
          required: false,
          tooltip: 'Only relevant for customers using the Open Tender POS'
        },
        {
          label: 'POS Offline Alert Interval (in minutes)',
          field: 'pos_server_offline_interval',
          type: 'text',
          required: false,
          tooltip:
            'This determines how frequently an email is sent out when a POS server is offline.Must be a between 5 and 1440(24 hours).',
          valType: constants.valTypes.int
        }
      ]
    }
  },
  '/contacts': {
    type: constants.pageType.list,
    title: 'Contact Form Contacts',
    subtitle:
      'The list of inquiry types that appear on the contact form on your website',
    list: {
      endpoint: '/contacts',
      id: 'contact_id',
      sorting: { sortBy: 'name', sortType: 'alpha' },
      fields: [
        { name: 'Inquiry Type Name', field: ['name'], link: 'contact_id' },
        { name: 'Email Address(es)', field: ['email'] },
        { name: 'ID', field: ['contact_id'] }
      ]
    }
  },
  '/contacts/:id': {
    type: constants.pageType.item,
    title: 'Contacts',
    subtitle: 'Use the form below to update this contact or add a new one',
    content: '',
    list: {
      title: 'Contacts',
      endpoint: '/contacts',
      id: 'contact_id',
      field: 'name',
      sorting: { sortBy: 'name', sortType: 'alpha' }
    },
    item: {
      endpoint: '/contacts',
      id: 'contact_id',
      title: 'name',
      hasDelete: true,
      tabs: [],
      fields: [
        { label: 'Name', field: 'name', type: 'text', required: true },
        {
          label: 'Email Address(es)',
          field: 'email',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/image-sizes': {
    type: constants.pageType.item,
    title: 'Image Sizes',
    subtitle:
      'Set your default image dimensions for different types of images that can be created via our automated image conversion process. Enter the length and width, separated by a comma, such as "1200,800"',
    content: '',
    item: {
      endpoint: '/image-sizes',
      fields: [
        {
          label: 'App Image (for locations, menu items, and categories)',
          field: 'APP_IMAGE',
          type: 'text',
          required: true,
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Large Image (for locations, menu items, and categories)',
          field: 'LARGE_IMAGE',
          type: 'text',
          required: true,
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Small Image (for locations, menu items, and categories)',
          field: 'SMALL_IMAGE',
          type: 'text',
          required: true,
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Featured Image (for pages and posts)',
          field: 'FEATURED_IMAGE',
          type: 'text',
          required: true,
          valType: constants.valTypes.arrayInt
        },
        {
          label: 'Secondary Image (for pages and posts)',
          field: 'SECONDARY_IMAGE',
          type: 'text',
          required: true,
          valType: constants.valTypes.arrayInt
        }
      ]
    }
  },
  '/website': {
    type: constants.pageType.item,
    title: 'Brand Settings',
    subtitle:
      "Various brand-level settings we couldn't find a better place for",
    content: '',
    item: {
      endpoint: '/website',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ],
      fields: [
        {
          label: 'Open Tender Site Title',
          field: 'site_title',
          type: 'text',
          required: true,
          tooltip:
            "This is the name that will show up in Google search results, and it will also appear after the page title in your website visitors's browsers."
        },
        {
          label: 'Open Tender Site Description',
          field: 'site_description',
          type: 'textarea',
          required: true,
          tooltip:
            "This is the description that shows up in Google search results, and it's best to keep this description between 150 and 160 characters. Search engines generally truncate snippets longer than 160 characters."
        },
        {
          label: 'Website URL',
          field: 'site_url',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            "This is where your main website lives if it's not hosted on Open Tender."
        },
        {
          label: 'Default Email Address',
          field: 'default_sender',
          type: 'text',
          required: true,
          tooltip:
            "This is the default email address that will be used as the sender of any emails submitted via a contact form on your site (if you're using one).Your site title will be used as the name of this default sender."
        },
        {
          label: 'Google Maps API Key',
          field: 'gmaps_api_key',
          type: 'text',
          required: true,
          tooltip:
            'See https://developers.google.com/maps/documentation/embed/get-api-key on how to obtain a Google Maps API Key.'
        },
        {
          label: 'Google Tag Manager Container ID',
          field: 'gtm_container_id',
          type: 'text',
          required: true,
          tooltip:
            'This can be obtained from your Google Tag Manager account, if you have one.'
        },
        {
          label: 'Google Tag Manager Customer Implementation',
          field: 'gtm_custom',
          type: 'checkbox'
        },
        {
          label: 'Intercom active on customer-facing site',
          field: 'intercom',
          type: 'checkbox'
        },
        {
          label: 'Apple Pay Merchant ID',
          field: 'apple_pay_id',
          type: 'text',
          required: true,
          tooltip:
            'This can be obtained by creating an Apple Developer account. Please contact Open Tender support for guidance.'
        },
        {
          label: 'Google Pay Merchant ID',
          field: 'google_pay_id',
          type: 'text',
          required: true,
          tooltip:
            'This can be obtained by creating a Google merchant account. Please contact Open Tender support for guidance.'
        },
        {
          label: 'Gateway ID',
          field: 'gateway_id',
          type: 'text',
          required: true,
          tooltip:
            'Your NMI gateway ID can be found on your Account information page'
        },
        {
          label: 'Primary URL',
          field: 'origin',
          type: 'text',
          placeholder: 'https://order.yourdomain.com',
          valType: constants.valTypes.nullable,
          tooltip:
            'This is the primary URL of your customer-facing Open Tender site, such as "https://order.yourdomain.com".'
        }
      ]
    }
  },
  '/website/files': {
    type: constants.pageType.itemFiles,
    title: 'Brand Logos',
    subtitle: 'Use this page to upload logos and other images for your brand',
    item: {
      endpoint: '/website',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ]
    },
    files: {
      endpoint: '/website/files',
      id: 'file_id',
      files: [
        { name: 'Logo', type: 'LOGO', classes: 'dz-logo' },
        { name: 'Light Logo', type: 'LOGO_LIGHT', classes: 'dz-logo' },
        { name: 'Favicon', type: 'FAVICON', classes: 'dz-logo' },
        {
          name: 'Apple Touch Icon',
          type: 'APPLE_TOUCH_ICON',
          classes: 'dz-logo'
        },
        {
          name: 'Open Graph Image',
          type: 'OPEN_GRAPH_IMAGE',
          classes: 'dz-logo'
        }
      ]
    }
  },
  '/invoice-settings': {
    type: constants.pageType.setting,
    title: 'Invoice Settings',
    subtitle:
      'These fields must be filled out in order to generate invoices for house accounts',
    content: '',
    item: {
      endpoint: '/invoice-settings',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ]
    },
    setting: {
      tabsEndpoint: '/invoice-settings',
      endpoint: '/invoice-settings',
      fields: [
        {
          label: 'Company Name',
          field: 'name',
          type: 'text',
          required: true
        },
        {
          label: 'Street Address',
          field: 'street',
          type: 'text',
          required: true
        },
        {
          label: 'Floor or Suite',
          field: 'unit',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'City',
          field: 'city',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'State',
          field: 'state',
          type: 'select',
          options: constants.statesProvinces,
          classes: '-half'
        },
        {
          label: 'Postal Code',
          field: 'postal_code',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Phone',
          field: 'phone',
          type: 'text',
          classes: '-half'
        },
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          classes: '-half -right'
        },
        {
          label: 'Invoice Title',
          field: 'title',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Checks Payable To',
          field: 'payable_to',
          type: 'text',
          classes: '-half -right'
        },
        {
          label: 'Notes',
          field: 'notes',
          type: 'text'
        },
        {
          label: 'Footer',
          field: 'footer',
          type: 'text'
        },
        {
          label: 'Days Due',
          field: 'days_due',
          type: 'text',
          required: true,
          classes: '-half',
          valType: constants.valTypes.int
        },
        {
          label: 'Logo Width (in inches)',
          field: 'logo_width',
          type: 'text',
          required: true,
          classes: '-half -right',
          valType: constants.valTypes.decimal
        }
      ]
    }
  },
  '/invoice-settings/files': {
    type: constants.pageType.itemFiles,
    title: 'Invoice Logo',
    subtitle:
      'Use this page to upload a logo that will appear on your invoices',
    item: {
      endpoint: '/invoice-settings',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Images', path: 'files' }
      ]
    },
    files: {
      endpoint: '/invoice-settings/files',
      id: 'file_id',
      files: [{ name: 'Logo', type: 'LOGO', classes: 'dz-logo' }]
    }
  },
  '/confirmation-emails': {
    type: constants.pageType.list,
    title: 'Confirmation Emails',
    subtitle:
      'Specify senders, recipients, and content for all email types across all order types',
    list: {
      endpoint: '/confirmation-emails',
      id: 'confirmation_email_id',
      sorting: { sortBy: 'email_type', sortArray: constants.emailTypesArray },
      filters: [
        {
          label: 'Order Type',
          type: 'select',
          field: 'order_type',
          value: [...constants.orderType].slice(1)[0].value,
          options: [...constants.orderType].slice(1)
        }
      ],
      fields: [
        {
          name: 'Email Type',
          field: ['email_type'],
          link: 'confirmation_email_id',
          format: 'enum'
        },
        { name: 'Subject', field: ['subject'] },
        { name: 'Sender Name', field: ['sender_name'] },
        { name: 'Sender Email', field: ['sender_address'] },
        { name: 'ID', field: ['confirmation_email_id'] }
      ]
    }
  },
  '/confirmation-emails/:id': {
    type: constants.pageType.item,
    title: 'Confirmation Email',
    subtitle:
      'Use the form below to update the content for this email type and order type',
    content: '',
    list: {
      title: 'Confirmation Emails',
      endpoint: '/confirmation-emails',
      id: 'confirmation_email_id',
      field: ['email_type', 'order_type'],
      separator: ' -- ',
      sorting: { sortBy: 'email_type', sortArray: constants.emailTypesArray }
    },
    item: {
      endpoint: '/confirmation-emails',
      id: 'confirmation_email_id',
      title: 'email_type',
      fields: [
        {
          label: 'Order Type',
          field: 'order_type',
          type: 'select',
          required: true,
          readonly: true,
          options: [...constants.orderType].slice(1)
        },
        {
          label: 'Email Type',
          field: 'email_type',
          type: 'select',
          required: true,
          readonly: true,
          options: constants.emailTypes
        },
        {
          label: 'Sender Name',
          field: 'sender_name',
          type: 'text',
          required: true,
          tooltip:
            "The name that shows up in the customer's inbox. It's usually a good idea to use the name of your restaurant in this field."
        },
        {
          label: 'Sender Email',
          field: 'sender_address',
          type: 'text',
          required: true,
          tooltip:
            'This should be a real email address that your customers can respond to if they have problems with their orders.'
        },
        {
          label: 'CC Email Address(es)',
          field: 'cc_address',
          type: 'text',
          tooltip:
            'These email addresses will be cc\'ed on all emails sent out by the Open Tender system to your customers, which allows the customer to "reply all" to a group of people.'
        },
        {
          label: 'BCC Email Address(es)',
          field: 'bcc_address',
          type: 'text',
          required: true,
          tooltip:
            'These email addresses will be bcced on all emails sent out by the Open Tender system to your customers, which is very helpful for troubleshooting customer service issues.'
        },
        {
          label: 'Email Subject',
          field: 'subject',
          type: 'text',
          required: true,
          tooltip:
            'This word or phrase will appear before the order ID on your online order confirmation emails.'
        },
        {
          label: 'Email Content',
          field: 'content',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg,
          required: true
        },
        { label: 'Attach Invoice', field: 'attach_invoice', type: 'checkbox' }
      ]
    }
  },
  '/notification-settings': {
    type: constants.pageType.list,
    title: 'Order Notications',
    subtitle: subtitles.orderNotifications,
    list: {
      endpoint: '/notification-settings',
      newEndpoint: '/notification-settings',
      id: 'notification_type',
      sorting: {
        sortBy: 'notification_type',
        sortArray: constants.orderNotiificationTypesArray
      },
      filters: [
        {
          label: 'Service Type',
          type: 'select',
          field: 'service_type',
          value: [...constants.serviceType].slice(2)[0].value,
          options: [...constants.serviceType].slice(2)
        }
      ],
      fields: [
        {
          name: 'Notification Type',
          field: ['notification_type'],
          link: 'notification_settings_id',
          linkEndpoint: '/notification-settings',
          format: 'enum'
        },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Active', field: ['is_active'], format: 'bool' },
        { name: 'Subject', field: ['subject'] },
        { name: 'Message', field: ['message'] }
      ]
    }
  },
  '/notification-settings/:id': {
    type: constants.pageType.item,
    title: 'Order Notifications',
    subtitle: 'Update the content and status of this notification type',
    content: '',
    list: {
      title: 'Order Notifications',
      endpoint: '/notification-settings',
      id: 'notification_settings_id',
      field: 'notification_type',
      sorting: {
        sortBy: 'notification_type',
        sortArray: constants.orderNotiificationTypesArray
      }
    },
    item: {
      endpoint: '/notification-settings',
      id: 'notification_settings_id',
      title: 'notification_type',
      fields: [
        {
          label: 'Notification Type',
          field: 'notification_type',
          type: 'select',
          required: true,
          readonly: true,
          options: constants.orderNotiificationTypes,
          defaultValue: 'IN_PREP_QUEUE'
        },
        {
          label: 'Service Type',
          field: 'service_type',
          type: 'select',
          options: constants.serviceType,
          defaultValue: 'PICKUP'
        },
        {
          label: 'Email Subject',
          field: 'subject',
          type: 'text',
          required: true
        },
        {
          label: 'Message',
          field: 'message',
          type: 'textarea',
          required: true
        },
        { label: 'Active', field: 'is_active', type: 'checkbox' }
      ]
    }
  },
  '/email-design-settings': {
    type: constants.pageType.item,
    title: 'Email Design',
    subtitle:
      'These fields must be filled out in order for Open Tender to be able to send custom-branded emails on your behalf',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign,
      fields: [
        { label: 'Spacing & Border Radius', field: 'colors', type: 'break' },
        {
          label: 'Email Top & Bottom Padding (in pixels)',
          field: 'email_spacing',
          type: 'text',
          required: true,
          placeholder: 15,
          classes: '-half',
          tooltip: 'The padding above the header and below the footer.',
          valType: constants.valTypes.int
        },
        {
          label: 'Email Border Radius (in pixels)',
          field: 'email_border_radius',
          type: 'text',
          required: true,
          placeholder: 0,
          classes: '-half -right',
          tooltip:
            'The border radius for the top of the header and bottom of the footer.',
          valType: constants.valTypes.int
        },
        {
          label: 'Section Spacing (in pixels)',
          field: 'section_spacing',
          type: 'text',
          required: true,
          placeholder: 0,
          classes: '-half',
          tooltip: 'The spacing (in pixels) between sections of the email.',
          valType: constants.valTypes.int
        },
        {
          label: 'Section Border Radius (in pixels)',
          field: 'section_border_radius',
          type: 'text',
          required: true,
          placeholder: 0,
          classes: '-half -right',
          tooltip: 'The border radius for each section.',
          valType: constants.valTypes.int
        },
        {
          label: 'Content Alignment',
          field: 'content_alignment',
          type: 'select',
          options: constants.fontAlignment,
          defaultValue: 'Left',
          tooltip:
            'The alignment of the text in the content section at the top of the email.'
        },
        { label: 'Colors', field: 'colors', type: 'break' },
        {
          label: 'Email Background Color',
          field: 'background_color',
          type: 'inputColor',
          required: true,
          placeholder: 'f8f8f8',
          classes: '-half',
          tooltip:
            'A hexidecimal color code for the background of the email itself (outside both the header and background of the email itself).'
        },
        {
          label: 'Body Background Color',
          field: 'body_background_color',
          type: 'inputColor',
          required: true,
          placeholder: 'ffffff',
          classes: '-half -right',
          tooltip:
            'A hexidecimal color code for the background of the body of the email.'
        },
        {
          label: 'Body Border Color',
          field: 'body_border_color',
          type: 'inputColor',
          required: true,
          placeholder: '',
          classes: '-half',
          tooltip:
            'A hexidecimal code for the color of the left and right border around the email body only. If left blank, no border will appear.'
        },
        {
          label: 'Line Color',
          field: 'body_line_color',
          type: 'inputColor',
          required: true,
          placeholder: 'dddddd',
          classes: '-half -right',
          tooltip:
            'A hexidecimal code for the color of the lines that appear in certain parts of the email body.'
        },
        {
          label: 'Header Background Color',
          field: 'header_background_color',
          type: 'inputColor',
          classes: '-half',
          placeholder: 'f0f0f0',
          required: true,
          tooltip:
            'A hexidecimal color code for the background of the header and footer of the email.'
        },
        {
          label: 'Header Text Color',
          field: 'header_text_color',
          type: 'inputColor',
          required: true,
          placeholder: '000000',
          classes: '-half -right',
          tooltip:
            'A hexidecimal color code for the color of the text that appears in the header and footer areas of the email.'
        },
        { label: 'Headings', field: 'headings', type: 'break' },
        {
          label: 'Headings Font',
          field: 'headings_font',
          type: 'select',
          options: constants.fontChoices,
          defaultValue: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          tooltip:
            'The font for the handful of headings that occur throughout the email.'
        },
        {
          label: 'Headings Weight',
          field: 'headings_weight',
          type: 'select',
          classes: '-half',
          options: constants.fontWeights,
          defaultValue: 'normal',
          tooltip:
            "It's helpful to make the headings font bold in order to make it stand out a bit more."
        },
        {
          label: 'Headings Capitalization',
          field: 'headings_transform',
          type: 'select',
          classes: '-half -right',
          options: constants.fontCapitalization,
          defaultValue: 'none',
          tooltip: 'If you choose None, the text will render as typed.'
        },
        {
          label: 'Headings Letter Spacing',
          field: 'headings_spacing',
          type: 'text',
          required: true,
          placeholder: 0,
          classes: '-half',
          tooltip:
            "A value in pixels between 0 and 5. However, we wouldn't suggest using a value greater than 2.",
          valType: constants.valTypes.int
        },
        {
          label: 'Headings Color',
          field: 'body_headings_color',
          type: 'inputColor',
          required: true,
          placeholder: '222222',
          classes: '-half -right',
          tooltip:
            'A hexidecimal code for the color of the headings in the email body.'
        },
        { label: 'Text', field: 'text', type: 'break' },
        {
          label: 'Body Font',
          field: 'primary_font',
          type: 'select',
          options: constants.fontChoices,
          defaultValue: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          tooltip: 'The primary font for all of the regular text in the email.'
        },
        {
          label: 'Body Text Size',
          field: 'text_size',
          type: 'text',
          required: true,
          placeholder: 13,
          classes: '-half',
          tooltip:
            'The size in pixels of the smaller body text in the email, which also has an impact on the size of the headings (they will scale commensurately).',
          valType: constants.valTypes.int
        },
        {
          label: 'Body Text Color',
          field: 'body_text_color',
          type: 'inputColor',
          required: true,
          placeholder: '666666',
          classes: '-half -right',
          tooltip:
            'A hexidecimal code for the color of all of the smaller text in the body of the email.'
        },
        {
          label: 'Warning Text Color',
          field: 'body_warnings_color',
          type: 'inputColor',
          required: true,
          placeholder: 'ff4a46',
          classes: '-half',
          tooltip:
            'A hexidecimal code for the color of the "red" warning lines in the email body, which are meant to call attention to certain pieces of information.'
        },
        {
          label: 'Secondary Text Color',
          field: 'body_secondary_color',
          type: 'inputColor',
          required: true,
          placeholder: 'aaaaaa',
          classes: '-half -right',
          tooltip:
            'A hexidecimal code for the color of the smaller, less prominent text in your email confirmations.'
        },
        { label: 'Loyalty Section', field: 'loyalty', type: 'break' },
        {
          label: 'Loyalty Section Background Color',
          field: 'loyalty_background_color',
          type: 'inputColor',
          required: true,
          placeholder: '5a5aff',
          classes: '-half'
        },
        {
          label: 'Loyalty Section Text Color',
          field: 'loyalty_text_color',
          type: 'inputColor',
          required: true,
          placeholder: 'ffffff',
          classes: '-half -right'
        },
        {
          label: 'Loyalty Progress Background Color',
          field: 'loyalty_meter_color',
          type: 'inputColor',
          required: true,
          placeholder: 'ffffff',
          classes: '-half'
        },
        {
          label: 'Loyalty Progress Color',
          field: 'loyalty_progress_color',
          type: 'inputColor',
          required: true,
          placeholder: 'a49bdc',
          classes: '-half -right'
        }
      ]
    }
  },
  '/email-design-settings/files': {
    type: constants.pageType.itemFiles,
    title: 'Email Images',
    subtitle:
      'Use this page to upload a logo and other images that will show up in your email confirmations',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    files: {
      endpoint: '/email-design-settings/files',
      id: 'file_id',
      files: [
        { name: 'Header Logo', type: 'IMAGE', classes: 'dz-logo' },
        { name: 'Footer Logo', type: 'LOGO', classes: 'dz-logo' },
        { name: 'Email Background Image (optional)', type: 'LARGE_IMAGE' },
        { name: 'Header Background Image (optional)', type: 'SMALL_IMAGE' }
      ]
    }
  },
  '/email-design-settings/preview': {
    type: constants.pageType.iframe,
    title: 'Email Preview',
    subtitle:
      'Below is a preview of the confirmation email that will be received by your customers',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/email-preview' }
  },
  '/email-design-settings/loyalty-reward-preview': {
    type: constants.pageType.iframe,
    title: 'Loyalty Reward Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they unlock a loyalty reward.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/loyalty-reward-email-preview' }
  },
  '/email-design-settings/promo-code-preview': {
    type: constants.pageType.iframe,
    title: 'Promo Code Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they receive a promo code.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/promo-code-email-preview' }
  },
  '/email-design-settings/order-rating-preview': {
    type: constants.pageType.iframe,
    title: 'Order Rating Email Preview',
    subtitle:
      'Below is a preview of the order rating email that will be received by your customers',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/order-rating-email-preview' }
  },
  '/email-design-settings/order-rated-preview': {
    type: constants.pageType.iframe,
    title: 'Order Rated Email Preview',
    subtitle:
      'Below is a preview of the order rated email that will be received by your employees when a customer submits an order rating.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/order-rated-email-preview' }
  },
  '/email-design-settings/gift-card-preview': {
    type: constants.pageType.iframe,
    title: 'Gift Card Purchase Confirmation Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they purchase gift cards.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/gift-card-email-preview' }
  },
  '/email-design-settings/gift-card-recipient-preview': {
    type: constants.pageType.iframe,
    title: 'Gift Card Recipient Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when another customer buys one or more gift cards for them.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/gift-card-recipient-email-preview' }
  },
  '/email-design-settings/donation-preview': {
    type: constants.pageType.iframe,
    title: 'Donation Confirmation Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they submit a donation through your website.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/donation-email-preview' }
  },
  '/email-design-settings/group-order-preview': {
    type: constants.pageType.iframe,
    title: 'Group Order Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they start a group order.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/group-order-email-preview' }
  },
  '/email-design-settings/order-update-preview': {
    type: constants.pageType.iframe,
    title: 'Order Update Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when the status of the order changes to being prepared or ready for pickup / out for delivery.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/order-update-email-preview' }
  },
  '/email-design-settings/password-reset-preview': {
    type: constants.pageType.iframe,
    title: 'Password Reset Email Preview',
    subtitle:
      'Below is a preview of the email that customers receive when they reset their passwords.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/password-reset-email-preview' }
  },
  '/email-design-settings/support-preview': {
    type: constants.pageType.iframe,
    title: 'Support Email Preview',
    subtitle:
      'Below is a preview of the support email that customers can submit if they run into an issue.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/support-email-preview' }
  },
  '/email-design-settings/contact-preview': {
    type: constants.pageType.iframe,
    title: 'Contact Email Preview',
    subtitle:
      'Below is a preview of the contact email that customers can submit via your website.',
    item: {
      endpoint: '/email-design-settings',
      tabs: tabs.emailDesign
    },
    iframe: { endpoint: '/contact-email-preview' }
  },
  '/newsletters': {
    type: constants.pageType.setting,
    title: 'Newsletter Settings',
    subtitle: 'Use this page to configure your customer newsletter settings',
    options: ['categories'],
    item: {
      endpoint: '/newsletter-settings'
    },
    setting: {
      tabsEndpoint: '/newsletter-settings',
      endpoint: '/newsletter-settings',
      fields: [
        {
          label: 'Sender Name',
          field: 'email_sender_name',
          type: 'text',
          required: true
        },
        {
          label: 'Sender Email Address',
          field: 'email_sender_address',
          type: 'text',
          required: true
        },
        {
          label: 'Email Subject',
          field: 'email_subject',
          type: 'text',
          required: true
        },
        {
          label: 'CC Email Address(es)',
          field: 'email_cc',
          type: 'text'
        },
        {
          label: 'BCC Email Address(es)',
          field: 'email_bcc',
          type: 'text'
        },
        {
          label: 'Domain for links in email',
          field: 'email_domain',
          type: 'text'
        },
        {
          label: 'Sign Up Button Text',
          field: 'signup_text',
          type: 'text',
          required: true
        },
        {
          label: 'Update Button Text',
          field: 'update_text',
          type: 'text',
          required: true
        },
        { label: 'Marketing Emails', field: 'marketing_email', type: 'break' },
        {
          label: 'Yes, allow customers to sign up for marketing emails',
          field: 'email_subscribe',
          type: 'checkbox',
          title:
            "If turned on, we'll include an option to sign up for marketing / promotional emails when a customer creates an account or updates an existing account"
        },
        {
          label: 'Title / Label',
          field: 'newsletter_title',
          type: 'text'
        },
        {
          label: 'Description',
          field: 'newsletter_desc',
          type: 'text'
        },
        {
          label: 'Opt in customers by default (i.e. check the box initially)',
          field: 'newsletter_default',
          type: 'checkbox'
        },
        {
          label:
            'Order Notifications (only relevant for Open Tender POS customers)',
          field: 'order_notifications',
          type: 'break'
        },
        {
          label: 'Send order notifications',
          field: 'order_notifications',
          type: 'checkbox',
          tooltip:
            'If turned on, customers will be able to sign up to receive notifications when their order starts being prepared, is ready for pickup or out for delivery, and if it gets delayed for any reason.'
        },
        {
          label: 'Title / Label',
          field: 'order_notifications_title',
          type: 'text'
        },
        {
          label: 'Description',
          field: 'order_notifications_desc',
          type: 'text'
        },
        {
          label: 'Default Selection',
          field: 'order_notifications_default',
          type: 'select',
          options: constants.orderNotifications,
          defaultValue: 'NONE'
        },
        {
          label: 'Email Greeting',
          field: 'order_notifications_greeting',
          type: 'text'
        },
        {
          label: 'Email Closing',
          field: 'order_notifications_closing',
          type: 'text'
        }
      ]
    }
  },
  '/order-fulfillment': {
    type: constants.pageType.setting,
    title: 'Curbside Pickup',
    subtitle: 'Use this page to configure your curbside pickup settings',
    item: {
      endpoint: '/order-fulfillment-settings'
    },
    setting: {
      tabsEndpoint: '/order-fulfillment-settings',
      endpoint: '/order-fulfillment-settings',
      fields: [
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          tooltip:
            'If turned on, customers will be able to choose curbside pickup on the cheeckout page and enter information about their vehicle'
        },
        {
          label: 'Title / Label',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Description',
          field: 'description',
          type: 'text'
        },
        {
          label: 'Arrival button text',
          field: 'button',
          type: 'text',
          tooltip:
            'Something like "I\'m here". This is the call to action on the customer arrival page that allows them to tell you they\'ve arrived.',
          required: true
        },
        {
          label: 'Vehicle Type field label',
          field: 'vehicle_type',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            'If you leave this field blank, it will not show up for customers.'
        },
        {
          label: 'Vehicle Type field placeholder text',
          field: 'vehicle_type_placeholder',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Vehicle Color field label',
          field: 'vehicle_color',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            'If you leave this field blank, it will not show up for customers.'
        },
        {
          label: 'Vehicle Color field placeholder text',
          field: 'vehicle_color_placeholder',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Vehicle ID field label',
          field: 'vehicle_id',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            'If you leave this field blank, it will not show up for customers.'
        },
        {
          label: 'Vehicle ID field placeholder text',
          field: 'vehicle_id_placeholder',
          type: 'text',
          valType: constants.valTypes.nullable
        },
        {
          label: 'Arrival Info field label',
          field: 'arrival_info',
          type: 'text',
          valType: constants.valTypes.nullable,
          tooltip:
            "If populated, a field will be presented to the customer on the page where they tell you they've arrived so they can enter a parking spot or some other info."
        },
        {
          label: 'Arrival Info field placeholder text',
          field: 'arrival_info_placeholder',
          type: 'text',
          valType: constants.valTypes.nullable
        }
      ]
    }
  },
  '/order-rating-settings': {
    type: constants.pageType.setting,
    title: 'Order Ratings',
    subtitle:
      'Configure optional order rating emails for both catering and regular online orders',
    content: '',
    item: {
      endpoint: '/order-rating-settings',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Icons', path: 'files' }
      ]
    },
    setting: {
      tabsEndpoint: '/order-rating-settings',
      endpoint: '/order-rating-settings',
      fields: [
        {
          label: 'Using new web app',
          field: 'is_new_web_app',
          type: 'checkbox',
          tooltip:
            'Check this box if you brand is using the new Open Tender web app'
        },
        {
          label: 'Sender Name',
          field: 'email_sender_name',
          type: 'text',
          required: true
        },
        {
          label: 'Sender Email Address',
          field: 'email_sender_address',
          type: 'text',
          required: true
        },
        {
          label: 'Email Subject',
          field: 'email_subject',
          type: 'text',
          required: true
        },
        {
          label: 'CC Email Address(es)',
          field: 'email_cc',
          type: 'text'
        },
        {
          label: 'BCC Email Address(es)',
          field: 'email_bcc',
          type: 'text'
        },
        {
          label: 'Include location recipients on customer responses',
          field: 'email_bcc_location',
          type: 'checkbox',
          tooltip:
            'If set to "Yes", any location-specific email recipients from the "Confirmation Email BCC Address(es)" field on each location\'s Order Settings page will receive an email with the customer\'s rating and comments when a rating is submitted by the customer.'
        },
        {
          label: 'Email Content',
          field: 'email_body',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg,
          required: true
        },
        {
          label: 'Hours after order for regular Online Orders',
          field: 'olo_rate_email',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0,
          tooltip:
            'The number of hours AFTER an order has been delivered or picked up that an email will be sent to the customer asking them to rate their order. If set to 0, an order rating email will NOT be sent to the customer.'
        },
        {
          label:
            'Also allow customers to rate online orders from their account pages',
          field: 'olo_rate_orders',
          type: 'checkbox'
        },
        {
          label: 'Hours after order for Catering Orders',
          field: 'catering_rate_email',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0,
          tooltip:
            'The number of hours AFTER an order has been delivered or picked up that an email will be sent to the customer asking them to rate their order. If set to 0, an order rating email will NOT be sent to the customer.'
        },
        {
          label:
            'Also allow customers to rate catering orders from their account pages',
          field: 'catering_rate_orders',
          type: 'checkbox'
        }
      ]
    }
  },
  '/order-rating-settings/files': {
    type: constants.pageType.itemFiles,
    title: 'Order Rating Icons',
    subtitle: 'Use this page to upload icons used for ratings (such as stars)',
    item: {
      endpoint: '/order-rating-settings',
      tabs: [
        { name: 'Settings', path: '' },
        { name: 'Icons', path: 'files' }
      ]
    },
    files: {
      endpoint: '/order-rating-settings/files',
      id: 'file_id',
      files: [
        { name: 'Empty Icon', type: 'SMALL_IMAGE', classes: 'dz-logo' },
        { name: 'Full Icon', type: 'LARGE_IMAGE', classes: 'dz-logo' }
      ]
    }
  },
  '/general-settings': {
    type: constants.pageType.item,
    title: 'General Settings',
    subtitle:
      'Various brand-level settings that impact both the Admin Portal and your Open Tender customer-facing site',
    options: ['revenue-centers'],
    item: {
      endpoint: '/general-settings',
      fields: [
        { label: 'General Settings', field: 'general_settings', type: 'break' },
        {
          label: 'Week Start Day',
          field: 'start_day',
          type: 'select',
          options: constants.weekdayOptions,
          defaultValue: 'MONDAY',
          classes: '-half',
          tooltip: 'This is used for reporting purposes'
        },
        {
          label: 'Business Day Start Time',
          field: 'start_time',
          type: 'text',
          valType: constants.valTypes.time,
          required: true,
          classes: '-half -right',
          tooltip: 'This is used for reporting purposes'
        },
        {
          label: 'To be included in Net Sales calculations',
          field: 'net_sales_definition',
          type: 'checkboxGroup',
          options: constants.netSalesDefintion,
          valType: constants.valTypes.arrayString
        },
        {
          field: 'display_refunds',
          type: 'hidden',
          valType: constants.valTypes.bool,
          defaultValue: true
        },
        {
          field: 'set_delivery_prices',
          type: 'hidden',
          valType: constants.valTypes.bool,
          defaultValue: true
        },
        // {
        //   label: 'Use separate delivery prices',
        //   field: 'set_delivery_prices',
        //   type: 'checkbox',
        //   tooltip:
        //     "You can UNCHECK this box if you're Pickup and Delivery prices are the same, and you just want to update a single price field on all attributes pages."
        // },
        {
          label: 'Using Open Tender POS',
          field: 'has_pos',
          type: 'checkbox',
          tooltip:
            "This box must be checked if you're using the Open Tender POS"
        },
        {
          label: 'Using Open Tender App',
          field: 'has_app',
          type: 'checkbox',
          tooltip:
            "This box must be checked if you're using the Open Tender Mobile App"
        },
        {
          label: 'Using SMS notifications',
          field: 'has_sms',
          type: 'checkbox',
          tooltip: "This box must be checked if you're using SMS notifications"
        },
        {
          label: 'Display deals',
          field: 'has_deals',
          type: 'checkbox'
        },
        {
          label: 'Display rewards',
          field: 'has_rewards',
          type: 'checkbox'
        },
        {
          label: 'Display promo codes',
          field: 'has_promo_codes',
          type: 'checkbox'
        },
        {
          label: 'Display gift cards',
          field: 'has_gift_cards',
          type: 'checkbox'
        },
        {
          label: 'Display donations',
          field: 'has_donations',
          type: 'checkbox'
        },
        {
          label: 'Include the House Account payment method',
          field: 'house_accounts',
          type: 'checkbox',
          tooltip:
            'The house account payment method must also be selected at the revenue center level, but this gives you a way to turn it on and off at the brand level.'
        },
        {
          label: 'Mark House Account orders as paid when confirmed',
          field: 'house_accounts_paid',
          type: 'checkbox',
          tooltip:
            "By default, house account orders are not marked as paid until you send out an invoice and collect payment outside of Open Tender (you can do this from each house account's Orders page). If you check this box, all house account orders will be marked as paid when the order is confirmed and closed."
        },
        {
          label: 'Allow customers to favorite items',
          field: 'favorites',
          type: 'checkbox'
        },
        {
          label: 'Regenerate menus automatically each night',
          field: 'regenerate_menus',
          type: 'checkbox',
          tooltip:
            "If selected, we'll regenerate all of your menus each morning at 6am EST."
        },
        {
          label: 'Include calories for default modifiers in item calories',
          field: 'build_cals',
          type: 'checkbox',
          tooltip:
            'If selected, the calorie counts for menu items will include calories set on the menu item AND calories for any modifiers that are included by default.'
        },
        {
          label: 'Display location phone number in confirmation emails',
          field: 'display_location_phone',
          type: 'checkbox',
          tooltip:
            "If selected, the restaurant's phone number will be displayed in the confirmation email sent to the customer (if a phone number is included with the store or revenue center's address)."
        },
        {
          label: 'Order Processing Settings',
          field: 'order_processing_settings',
          type: 'break'
        },
        {
          label: 'OLO home page',
          field: 'olo_refresh',
          type: 'select',
          options: constants.refreshStartPageTypes,
          defaultValue: 'NEW_ORDERS',
          classes: '-half',
          tooltip:
            'The order management pages for OLO orders will automatically return to this page after a certain time period.'
        },
        {
          label: 'Catering home page',
          field: 'catering_refresh',
          type: 'select',
          options: constants.refreshStartPageTypes,
          defaultValue: 'NEW_ORDERS',
          classes: '-half -right',
          tooltip:
            'The order management pages for catering orders will automatically return to this page after a certain time period.'
        },
        {
          label: 'Automatic refresh',
          field: 'order_refresh',
          type: 'select',
          options: constants.orderRefreshTypes,
          defaultValue: 'ALL_PAGES',
          classes: '-half',
          tooltip:
            'This determines which pages should refresh and, if necessary, redirect to the order management home page specified above for each order type.'
        },
        {
          label: 'Unconfirmed order notification timing',
          field: 'notification_delay',
          type: 'select',
          options: constants.notificationDelay,
          valType: constants.valTypes.int,
          classes: '-half -right',
          tooltip:
            'This controls how soon an unconfirmed order notification email is sent out AFTER an order hits the New Orders page.'
        },
        {
          label: 'Play audio alarm when new order arrives',
          field: 'order_alarm',
          type: 'checkbox'
        },
        {
          label:
            'Require employees to print an order BEFORE an order can be confirmed',
          field: 'require_print',
          type: 'checkbox',
          tooltip:
            'If selected, employees will be required to hit the "Print" button before the "Confirm" button becomes clickable.'
        },
        {
          label:
            'Require employees to enter a code BEFORE an order can be confirmed',
          field: 'require_code',
          type: 'checkbox',
          tooltip:
            'If selected, employees will be required to enter a 2 digit code before the "Confirm" button becomes clickable.'
        },
        {
          label: 'Show additional tip & signature Lines',
          field: 'show_tip_signature',
          type: 'checkbox',
          tooltip:
            'If selected, the order printout will include lines for "Additional Tip" and "Signature".'
        },
        {
          label: 'Prevent early confirmation',
          field: 'prevent_early_confirm',
          type: 'checkbox',
          tooltip:
            'If selected, employees will not be able to confirm an order (i.e. charge a credit card) before the requested date and time.'
        },
        {
          label: 'Confirm button text',
          field: 'confirm_button_text',
          type: 'text',
          placeholder: 'Confirm',
          defaultValue: 'Confirm',
          tooltip:
            'This text will be displayed in place of "Confirm" on the New Orders page, except in the case of credit card catering orders, where it will say "Charge Card".'
        },
        {
          label: 'Catering New Orders message',
          field: 'catering_message',
          type: 'textarea',
          tooltip:
            "This is the message that appears in big red text on the New Orders page for catering orders. If you don't want to show this message at all, you can leave this field blank.",
          defaultValue:
            'Please note that catering orders should be charged on the DELIVERY DATE, not when the order is submitted.'
        },
        {
          label: 'OLO Order Settings',
          field: 'olo_order_settings',
          type: 'break'
        },
        {
          label: 'Default OLO revenue center',
          field: 'default_olo_revenue_center_id',
          type: 'select',
          options: {
            endpoint: 'revenue-centers',
            entity: 'OLO',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Order ahead days',
          field: 'days_ahead',
          type: 'select',
          classes: '-half',
          options: [...constants.daysAhead].slice(1),
          valType: constants.valTypes.int
        },
        {
          label: 'Large order send time',
          field: 'send_time',
          type: 'text',
          valType: constants.valTypes.time,
          required: true,
          classes: '-half -right',
          tooltip:
            'This is the time each day when large orders are sent to the store.'
        },
        {
          label: 'Wait Time Range',
          field: 'olo_time_range',
          type: 'select',
          options: constants.waitTimeRange,
          defaultValue: 0,
          valType: constants.valTypes.int,
          tooltip:
            'If set to anything other than None, a time range will be displayed to the customer instead of a single specific time on the online ordering start page on your customer-facing Open Tender website.'
        },
        {
          label: 'Hide ASAP option',
          field: 'hide_asap',
          type: 'checkbox',
          tooltip:
            'If selected, customers will not have an option of selecting ASAP for their order time.'
        },
        {
          label: 'Redirect out of zone addresses to waiting list',
          field: 'waiting_list_olo',
          type: 'checkbox',
          tooltip:
            'If selected, the customer will be redirected to a waiting list signup form if their address is outside all of your delivery zones.'
        },
        {
          label: 'Group modifiers in order printout',
          field: 'group_options',
          type: 'checkbox',
          tooltip:
            'If selected, modifiers will be grouped and preceeded by a quantity instead of listed out separately.'
        },
        {
          label: 'Group modifiers in customer email confirmations',
          field: 'group_options_email',
          type: 'checkbox',
          tooltip:
            'If selected, modifiers will be grouped and preceeded by a quantity instead of listed out separately in customer confirmation emails.'
        },
        {
          label: 'Required Fields',
          field: 'required_fields_olo',
          type: 'checkboxGroup',
          options: constants.requiredOloFields,
          valType: constants.valTypes.arrayString
        },
        {
          label: 'Catering Order Settings',
          field: 'catering_order_settings',
          type: 'break'
        },
        {
          label: 'Default catering revenue center',
          field: 'default_catering_revenue_center_id',
          type: 'select',
          options: {
            endpoint: 'revenue-centers',
            entity: 'CATERING',
            value: 'revenue_center_id',
            label: 'full_name'
          },
          valType: constants.valTypes.int
        },
        {
          label: 'Allow customers to manually select a catering location',
          field: 'catering_select',
          type: 'checkbox',
          tooltip:
            "If selected, the customer will have the option of selecting a catering location after they submit their address. Otherwise, they'll be routed directly to the menu page if they're within a delivery zone."
        },
        {
          label: 'Wait Time Range',
          field: 'catering_time_range',
          type: 'select',
          options: constants.waitTimeRange,
          defaultValue: 0,
          valType: constants.valTypes.int,
          tooltip:
            'If set to anything other than None, a time range will be displayed to the customer instead of a single specific time on the catering start page on your customer-facing Open Tender website.'
        },
        {
          label: 'Redirect out of zone addresses to waiting list',
          field: 'waiting_list_catering',
          type: 'checkbox',
          tooltip:
            'If selected, the customer will be redirected to a waiting list signup form if their address is outside all of your delivery zones.'
        },
        {
          label: 'Send final confirmation email',
          field: 'catering_send_final_email',
          type: 'checkbox',
          tooltip:
            'If selected, customers who place catering orders will receive a final confirmation email when their order is charged/confirmed by the restaurant.'
        },
        {
          label: 'Group modifiers in order printout',
          field: 'group_options_catering',
          type: 'checkbox',
          tooltip:
            'If selected, modifiers will be grouped and preceeded by a quantity instead of listed out separately.'
        },
        {
          label: 'Group modifiers in customer email confirmations',
          field: 'group_options_email_catering',
          type: 'checkbox',
          tooltip:
            'If selected, modifiers will be grouped and preceeded by a quantity instead of listed out separately in customer confirmation emails.'
        },
        {
          label: 'Automatically close catering orders',
          field: 'catering_auto_close',
          type: 'select',
          options: constants.autoCloseTimes,
          valType: constants.valTypes.nullable,
          tooltip:
            'If selected, all catering orders with requested dates on or before the current date re will be automatically closed after this time passes each day. If left blank, orders will NOT be closed automatically.'
        },
        {
          label: 'Required Fields',
          field: 'required_fields_catering',
          type: 'checkboxGroup',
          options: constants.requiredCateringFields,
          valType: constants.valTypes.arrayString
        },
        {
          field: 'waiting_list_on_demand',
          type: 'hidden',
          valType: constants.valTypes.bool
        },
        {
          label: 'User Interface Settings',
          field: 'user_interface_settings',
          type: 'break'
        },
        {
          label: 'Include Google reCAPTCHA on Checkout page',
          field: 'checkout_recaptcha',
          type: 'checkbox',
          tooltip:
            'If selected, your customers will need to verify a Google reCAPTCHA on the Checkout page. This can be used to enhance security on your checkout page if your site experiences attacks from bots who may be doing credit card testing.'
        },
        {
          label: 'Show LevelUp as first payment option',
          field: 'levelup_first',
          type: 'checkbox',
          tooltip:
            'If selected, modifiers will be grouped and preceeded by a quantity instead of listed out separately in customer confirmation emails.'
        },
        {
          label: 'LevelUp name on checkout page',
          field: 'levelup_name',
          type: 'text',
          required: true,
          defaultValue: 'LevelUp',
          tooltip:
            'This text will be displayed in place of "LevelUp" on the Checkout page (in the Payment Type section).'
        },
        {
          label: 'Show eating utensils selection on checkout page',
          field: 'eating_utensils_show',
          type: 'checkbox',
          tooltip:
            'This setting controls whether customers are given the option to change their eating utensils preference on the checkout page.'
        },
        {
          label: 'Include eating utensils by default',
          field: 'eating_utensils_default',
          type: 'checkbox',
          tooltip:
            'If selected, eating utensils will default to being included on the checkout page'
        },
        {
          label: 'Show serving utensils selection on checkout page',
          field: 'serving_utensils_show',
          type: 'checkbox',
          tooltip:
            'Catering orders only. This setting controls whether customers are given the option to change their serving utensils preference on the checkout page.'
        },
        {
          label: 'Include serving utensils by default',
          field: 'serving_utensils_default',
          type: 'checkbox',
          tooltip:
            'If selected, serving utensils will default to being included on the checkout page'
        },
        {
          label: 'Display menu page sidebar when returning from wizard page',
          field: 'pop_sidebar_wizard',
          type: 'checkbox',
          tooltip:
            'If selected, the order sidebar will automatically open when the customer arrives on the menu page from the wizard page (i.e. if they click the "Add To Order" button on the wizard page to get back to the menu page).'
        },
        {
          label: 'Display menu page sidebar when returning from checkout page',
          field: 'pop_sidebar_checkout',
          type: 'checkbox',
          tooltip:
            'If selected, the order sidebar will automatically open when the customer arrives on the menu page from the checkout page (i.e. if they click the "Edit Your Order" button on the checkout page).'
        },
        {
          label: 'Display nutritional info',
          field: 'display_nutritional_info',
          type: 'checkbox',
          tooltip:
            'Turn this on if you want to display nutritional info on your menu pages (both on the site and within the online ordering sections).  Only relevant for sites that support displaying nutritional info.'
        },
        {
          label: 'Display allergens',
          field: 'display_allergens',
          type: 'checkbox',
          tooltip:
            'Turn this on if you want to display allergens on your menu pages (both on the site and within the online ordering sections). Only relevant for sites that support displaying allergens.'
        },
        {
          label: 'Display tags',
          field: 'display_tags',
          type: 'checkbox',
          tooltip:
            'Turn this on if you want to display tags on your menu pages (both on the site and within the online ordering sections). Only relevant for sites that support displaying tags.'
        }
      ]
    }
  },
  '/reporting': {
    type: constants.pageType.content,
    title: 'Reporting',
    subtitle: 'Various reports designed to help you run your business',
    content: '',
    image: { url: images.reporting, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Dashboard',
            description: subtitles.dashboard,
            endpoint: '/reporting/dashboard',
            section: 'REPORTING'
          },
          {
            title: 'Sales Summary',
            description: subtitles.sales,
            endpoint: '/reporting/sales',
            section: 'REPORTING'
          },
          {
            title: 'Sales by Period',
            description: subtitles.salesByPeriod,
            endpoint: '/reporting/sales-by-period',
            section: 'REPORTING'
          },
          {
            title: 'Sales & Checks Over Time',
            description: subtitles.salesOverTime,
            endpoint: '/reporting/sales-over-time',
            section: 'REPORTING'
          },
          {
            title: 'Throughput',
            description: subtitles.throughput,
            endpoint: '/reporting/throughput',
            section: 'REPORTING'
          },
          {
            title: 'Item Counts by Store',
            description: subtitles.itemCountsByStore,
            endpoint: '/reporting/item-counts-by-store',
            section: 'REPORTING'
          },
          {
            title: 'Order Prep Performance',
            description: subtitles.orderPrepPerformance,
            endpoint: '/reporting/order-prep-performance',
            section: 'REPORTING'
          },
          {
            title: 'Order Prep Details',
            description: subtitles.orderPrep,
            endpoint: '/reporting/order-prep',
            section: 'REPORTING'
          },
          {
            title: 'Product Mix',
            description: subtitles.productMix,
            endpoint: '/reporting/product-mix',
            section: 'REPORTING'
          },
          {
            title: 'Discounts',
            description: subtitles.discounts,
            endpoint: '/reporting/discounts',
            section: 'REPORTING'
          },
          {
            title: 'Promo Codes',
            description: subtitles.reportPromoCodes,
            endpoint: '/reporting/promo-codes',
            section: 'REPORTING'
          },
          {
            title: 'Order Ratings',
            description: subtitles.ratings,
            endpoint: '/reporting/ratings',
            section: 'REPORTING'
          },
          {
            title: 'Subscribers',
            description: subtitles.subscribers,
            endpoint: '/reporting/subscribers',
            section: 'REPORTING'
          },
          {
            title: 'Donations',
            description: subtitles.donations,
            endpoint: '/reporting/donations',
            section: 'REPORTING'
          },
          {
            title: 'Gift Card Credits',
            description: subtitles.giftCardCredits,
            endpoint: '/reporting/gift-card-credits',
            section: 'REPORTING'
          },
          {
            title: 'Cash Over / Short',
            description: subtitles.cash,
            endpoint: '/reporting/cash',
            section: 'REPORTING'
          },
          {
            title: 'Paids Ins / Outs',
            description: subtitles.paidInOuts,
            endpoint: '/reporting/paid-ins-outs',
            section: 'REPORTING'
          },
          {
            title: 'Voided Orders',
            description: subtitles.voids,
            endpoint: '/reporting/voids',
            section: 'REPORTING'
          },
          {
            title: 'Refunds',
            description: subtitles.refunds,
            endpoint: '/reporting/refunds',
            section: 'REPORTING'
          },
          {
            title: 'Accounting Summary',
            description: subtitles.accounting,
            endpoint: '/reporting/accounting',
            section: 'REPORTING'
          },
          {
            title: 'Receipts',
            description: subtitles.receipts,
            endpoint: '/reporting/receipts',
            section: 'REPORTING'
          },
          {
            title: 'Tenders Summary',
            description: subtitles.tendersSummary,
            endpoint: '/reporting/tenders-summary',
            section: 'REPORTING'
          },
          {
            title: 'Tenders',
            description: subtitles.tenders,
            endpoint: '/reporting/tenders',
            section: 'REPORTING'
          },
          {
            title: 'Tender Differentials',
            description: subtitles.tendersDiff,
            endpoint: '/reporting/tenders-diff',
            section: 'REPORTING'
          },
          {
            title: 'Credit Card Summary',
            description: subtitles.creditSummary,
            endpoint: '/reporting/credit-summary',
            section: 'REPORTING'
          },
          {
            title: 'Taxes Summary',
            description: subtitles.taxesSummary,
            endpoint: '/reporting/taxes-summary',
            section: 'REPORTING'
          },
          {
            title: 'Taxes',
            description: subtitles.taxes,
            endpoint: '/reporting/taxes',
            section: 'REPORTING'
          },
          {
            title: 'Hours for Week',
            description: subtitles.hoursForWeek,
            endpoint: '/reporting/hours-for-week',
            section: 'REPORTING'
          },
          {
            title: 'LevelUp Connections',
            description: subtitles.reportLevelUpConnections,
            endpoint: '/reporting/levelup-connections',
            section: 'REPORTING'
          }
        ]
      }
    ]
  },
  '/reporting/sales': {
    type: constants.pageType.report,
    title: 'Sales Summary',
    subtitle: subtitles.sales,
    options: [
      'stores',
      'revenue-centers',
      'taxes',
      'departments',
      'general-settings'
    ],
    report: {
      reportType: 'sales',
      endpoints: [
        '/reporting/sales-summary',
        '/reporting/tender-summary',
        '/reporting/credit-card-summary',
        '/reporting/tax-summary',
        '/reporting/labor-by-department'
      ],
      dateFilters: true,
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        { ...filters.store, required: true }
      ]
    }
  },
  '/reporting/sales-by-period': {
    type: constants.pageType.report,
    title: 'Sales by Period',
    subtitle: subtitles.salesByPeriod,
    options: ['stores'],
    report: {
      reportType: 'salesByPeriod',
      endpoints: ['/reporting/sales-by-period'],
      dateFilters: true,
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.timePeriod,
        filters.store,
        filters.orderType,
        filters.serviceType
      ]
    }
  },
  '/reporting/sales-over-time': {
    type: constants.pageType.report,
    title: 'Sales & Checks Over Time',
    subtitle: subtitles.salesOverTime,
    options: ['stores'],
    report: {
      reportType: 'salesOverTime',
      endpoints: ['/reporting/sales-over-time'],
      dateFilters: true,
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.timePeriod,
        filters.salesMetric,
        filters.orderType,
        filters.serviceType,
        filters.channelType,
        filters.store
      ]
    }
  },
  '/reporting/receipts': {
    type: constants.pageType.list,
    title: 'Receipts',
    subtitle: subtitles.receipts,
    options: ['stores'],
    list: {
      endpoint: '/reporting/receipts',
      id: 'order_id',
      hideNew: true,
      autoRefresh: false,
      isDrilldown: true,
      dateFilters: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/reporting/receipts/csv'
        }
      ],
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource
      ],
      fields: [
        {
          name: 'Order ID',
          field: ['order_id'],
          link: 'order_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Store',
          field: ['store']
        },
        { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Order Source', field: ['order_source'], format: 'enum' },
        { name: 'Channel Type', field: ['channel_type'], format: 'enum' },
        {
          name: 'Total',
          field: ['total'],
          format: 'dollars'
        }
      ]
    }
  },
  '/reporting/tenders': {
    type: constants.pageType.list,
    title: 'Tenders',
    subtitle: subtitles.tenders,
    options: ['stores'],
    list: {
      endpoint: '/reporting/tenders',
      id: 'order_id',
      hideNew: true,
      autoRefresh: false,
      isDrilldown: true,
      dateFilters: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/reporting/tenders/csv'
        }
      ],
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.saleType,
        filters.orderType,
        filters.serviceType,
        filters.tenderType,
        filters.cardType,
        filters.store,
        filters.orderSource
      ],
      fields: [
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Store',
          field: ['store']
        },
        { name: 'Order Type', field: ['order_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Order Source', field: ['order_source'], format: 'enum' },
        { name: 'Tender Type', field: ['tender_type'], format: 'enum' },
        { name: 'Card Type', field: ['card_type'], format: 'enum' },
        { name: 'Gift Card', field: ['gift_card'] },
        { name: 'House Account', field: ['house_account'] },
        {
          name: 'Total',
          field: ['amount'],
          format: 'dollars'
        }
      ]
    }
  },
  '/reporting/taxes': {
    type: constants.pageType.list,
    title: 'Taxes',
    subtitle: subtitles.taxes,
    options: ['stores', 'taxes'],
    list: {
      endpoint: '/reporting/taxes',
      id: 'order_id',
      hideNew: true,
      autoRefresh: false,
      // isDrilldown: true,
      dateFilters: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/reporting/taxes/csv'
        }
      ],
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource,
        filters.tax
      ],
      fields: [
        {
          name: 'Order ID',
          field: ['receipt_id'],
          link: 'receipt_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Store',
          field: ['store']
        },
        { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Order Source', field: ['order_source'], format: 'enum' },
        {
          name: 'Tax',
          field: ['name']
          // link: 'tax_id',
          // linkEndpoint: '/taxes'
        },
        { name: 'Tax Type', field: ['tax_type'], format: 'enum' },
        {
          name: 'Total',
          field: ['receipt_amount'],
          format: 'dollars'
        }
      ]
    }
  },
  '/reporting/taxes-summary': {
    type: constants.pageType.report,
    title: 'Taxes Summary',
    subtitle: subtitles.taxesSummary,
    options: ['stores'],
    report: {
      reportType: 'taxes',
      endpoints: ['/reporting/tax-totals'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/tax-totals/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource
      ]
    }
  },
  '/reporting/tenders-summary': {
    type: constants.pageType.report,
    title: 'Tenders Summary',
    subtitle: subtitles.tendersSummary,
    options: ['stores'],
    report: {
      reportType: 'tenders',
      endpoints: ['/reporting/tender-totals'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/tender-totals/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource
      ]
    }
  },
  '/reporting/tenders-diff': {
    type: constants.pageType.report,
    title: 'Tender Differentials',
    subtitle: subtitles.tendersDiff,
    options: ['stores'],
    report: {
      reportType: 'tendersDiff',
      endpoints: ['/reporting/tenders-diff'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/tenders-diff/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource
      ]
    }
  },
  '/reporting/credit-summary': {
    type: constants.pageType.report,
    title: 'Credit Cards Summary',
    subtitle: subtitles.creditSummary,
    options: ['stores'],
    report: {
      reportType: 'credit',
      endpoints: ['/reporting/credit-totals'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/credit-totals/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.saleType,
        filters.orderSource
      ]
    }
  },
  '/reporting/gift-card-credits': {
    type: constants.pageType.report,
    title: 'Gift Card Credits',
    subtitle: subtitles.giftCardCredits,
    report: {
      reportType: 'giftCardCredits',
      endpoints: ['/reporting/gift-card-credits'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/gift-card-credits/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true }
      ]
    }
  },
  '/reporting/throughput': {
    type: constants.pageType.report,
    title: 'Throughput',
    subtitle: subtitles.throughput,
    options: ['stores', 'pos-terminals', 'general-settings'],
    report: {
      reportType: 'throughput',
      endpoints: ['/reporting/throughput'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/throughput/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        { ...filters.store, required: true },
        filters.serviceType,
        filters.timeChunk
      ]
    }
  },
  '/reporting/item-counts-by-store': {
    type: constants.pageType.report,
    title: 'Item Counts by Store',
    subtitle: subtitles.itemCountsByStore,
    options: ['stores'],
    report: {
      reportType: 'itemCountsByStore',
      endpoints: ['/reporting/counts-by-item-type'],
      dateFilters: true,
      autoRefresh: false,
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true }
      ]
    }
  },
  '/reporting/order-prep-performance': {
    type: constants.pageType.report,
    title: 'Order Prep Performance',
    subtitle: subtitles.orderPrepPerformance,
    report: {
      reportType: 'orderPrepPerformance',
      endpoints: ['/reporting/order-prep-performance'],
      dateFilters: true,
      autoRefresh: false,
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.orderSource
      ]
    }
  },
  '/reporting/order-prep': {
    type: constants.pageType.list,
    title: 'Order Prep',
    subtitle: subtitles.orderPrep,
    options: ['stores'],
    list: {
      endpoint: '/reporting/order-prep',
      id: 'order_id',
      hideNew: true,
      autoRefresh: false,
      // isDrilldown: true,
      dateFilters: true,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/reporting/order-prep/csv'
        }
      ],
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.channelType,
        filters.orderSource,
        filters.serviceType,
        filters.orderType,
        filters.store
      ],
      fields: [
        {
          name: 'Order ID',
          field: ['order_id'],
          link: 'order_id',
          linkEndpoint: '/orders'
        },
        {
          name: 'Requested At',
          field: ['requested_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Store',
          field: ['store']
        },
        // { name: 'Order Source', field: ['order_source'], format: 'enum' },
        { name: 'Receipt Type', field: ['receipt_type'], format: 'enum' },
        { name: 'Channel Type', field: ['channel_type'], format: 'enum' },
        { name: 'Service Type', field: ['service_type'], format: 'enum' },
        {
          name: 'Expected At',
          field: ['expected_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Prepare By',
          field: ['ready_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Fired At',
          field: ['fire_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Done At',
          field: ['done_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        },
        {
          name: 'Completed At',
          field: ['completed_at'],
          format: 'datetimeZoned',
          tz: 'timezone'
        }
      ]
    }
  },
  '/reporting/paid-ins-outs': {
    type: constants.pageType.report,
    title: 'Paid Ins / Outs',
    subtitle: subtitles.paidInOuts,
    options: ['stores'],
    report: {
      reportType: 'paidInsOuts',
      endpoints: ['/reporting/paid-ins-outs'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/paid-ins-outs/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        { ...filters.store, required: true }
      ]
    }
  },
  '/reporting/voids': {
    type: constants.pageType.report,
    title: 'Voided Orders',
    subtitle: subtitles.voids,
    options: ['stores'],
    report: {
      reportType: 'voids',
      endpoints: ['/reporting/voids'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/voids/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        { ...filters.store, required: true }
      ]
    }
  },
  '/reporting/refunds': {
    type: constants.pageType.report,
    title: 'Refunds',
    subtitle: subtitles.refunds,
    options: ['stores'],
    report: {
      reportType: 'refunds',
      endpoints: ['/reporting/refunds'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/refunds/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        { ...filters.store, required: true }
      ]
    }
  },
  '/reporting/promo-codes': {
    type: constants.pageType.report,
    title: 'Promo Codes',
    subtitle: subtitles.reportPromoCodes,
    options: ['stores', 'promo-codes'],
    report: {
      reportType: 'promoCodes',
      endpoints: ['/reporting/promo-codes', '/reporting/redemptions'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/promo-codes/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.promoCodes
      ]
    }
  },
  '/reporting/levelup-connections': {
    type: constants.pageType.report,
    title: 'LevelUp Connections',
    subtitle: subtitles.reportLevelUpConnections,
    report: {
      reportType: 'levelupConnections',
      endpoints: ['/reporting/levelup-connections'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/levelup-connections/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true }
      ]
    }
  },
  '/reporting/discounts': {
    type: constants.pageType.report,
    title: 'Discounts',
    subtitle: subtitles.discounts,
    options: ['stores', 'discounts'],
    report: {
      reportType: 'discounts',
      endpoints: ['/reporting/discounts'],
      // actions: [{ text: 'Download CSV' }],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/discounts/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.orderType,
        filters.serviceType,
        filters.discounts
      ]
    }
  },
  '/reporting/ratings': {
    type: constants.pageType.report,
    title: 'Order Ratings',
    subtitle: subtitles.ratings,
    options: ['stores'],
    report: {
      reportType: 'ratings',
      endpoints: ['/reporting/ratings'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/ratings/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.serviceType,
        filters.ratingsGte,
        filters.ratingsLte
      ]
    }
  },
  '/reporting/subscribers': {
    type: constants.pageType.report,
    title: 'Subscribers',
    subtitle: subtitles.subscribers,
    options: ['stores'],
    report: {
      reportType: 'subscribers',
      endpoints: ['/reporting/subscribers'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/subscribers/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true }
      ]
    }
  },
  '/reporting/donations': {
    type: constants.pageType.list,
    title: 'Donations',
    subtitle: subtitles.donations,
    list: {
      endpoint: '/reporting/donations',
      id: 'discount_id',
      hideNew: true,
      autoRefresh: false,
      actions: [
        {
          text: 'Download CSV',
          endpoint: '/reporting/donations/csv'
        }
      ],
      filters: [
        {
          label: 'Results per Page',
          type: 'select',
          field: 'limit',
          value: 100,
          options: constants.limit
        }
      ],
      fields: [
        { name: 'Donation ID', field: ['donation_id'] },
        { name: 'Created', field: ['created_at'], format: 'datetime' },
        { name: 'Charged', field: ['charged_at'], format: 'datetime' },
        { name: 'Email Address', field: ['email'] },
        { name: 'Card Type', field: ['card_type'], format: 'enum' },
        { name: 'Last 4', field: ['last4'] },
        { name: 'Amount', field: ['amount'], format: 'dollars' }
      ]
    }
  },
  '/reporting/dashboard': {
    type: constants.pageType.report,
    title: 'Dashboard',
    subtitle: subtitles.dashboard,
    // options: ['stores'],
    report: {
      reportType: 'dashboard',
      endpoints: ['/reporting/dashboard'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/dashboard/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true }
      ]
    }
  },
  '/reporting/product-mix': {
    type: constants.pageType.report,
    title: 'Product Mix',
    subtitle: subtitles.productMix,
    options: ['stores'],
    report: {
      reportType: 'productMix',
      endpoints: ['/reporting/product-mix'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/product-mix/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store,
        filters.serviceType,
        filters.orderType
        // filters.entityType
      ]
    }
  },
  '/reporting/cash': {
    type: constants.pageType.report,
    title: 'Cash Over / Short',
    subtitle: subtitles.cash,
    classes: 'app--aside',
    options: ['stores'],
    report: {
      reportType: 'cash',
      endpoints: ['/reporting/cash-over-short'],
      filters: [
        { ...filters.businessDate, noClear: true },
        { ...filters.store, required: true }
      ]
    }
  },
  '/reporting/accounting': {
    type: constants.pageType.report,
    title: 'Accounting Summary',
    subtitle: subtitles.accounting,
    options: ['stores'],
    report: {
      reportType: 'accounting',
      endpoints: ['/reporting/accounting-summary'],
      dateFilters: true,
      autoRefresh: false,
      csvEndpoint: '/reporting/accounting-summary/csv',
      filters: [
        { ...filters.startDate, noClear: true },
        { ...filters.endDate, noClear: true },
        filters.store
      ]
    }
  },
  '/reporting/hours-for-week': {
    type: constants.pageType.hoursForWeek,
    title: 'Hours for Week',
    subtitle: subtitles.hoursForWeek,
    options: ['stores'],
    hoursForWeek: {
      endpoint: '/reporting/hours-for-week',
      filters: [
        {
          label: 'Store',
          type: 'select',
          field: 'store_id',
          settings: {
            endpoint: 'stores',
            value: 'store_id',
            label: 'full_name'
          },
          options: null,
          valType: constants.valTypes.int,
          includeNone: true
        },
        {
          label: 'Start Date',
          type: 'datePicker',
          field: 'start_date',
          value: 'today'
        },
        {
          label: 'End Date',
          type: 'datePicker',
          field: 'end_date',
          value: 'today'
        }
      ],
      fields: []
    }
  },
  '/integrations': {
    type: constants.pageType.content,
    title: 'Integrations',
    subtitle: 'Manage your integrations across POS, delivery, loyalty, etc.',
    content: '',
    image: { url: images.integrations, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'POS',
            description:
              'We integrate with Toast, Revel, Salido, Qu, and Rpower',
            endpoint: '/pos',
            section: 'SETTINGS'
          },
          {
            title: 'Payments',
            description:
              'Settings for CardConnect, Worldpay, and the Open Tender Gateway',
            endpoint: '/payments',
            section: 'SETTINGS'
          },
          {
            title: 'Loyalty',
            description: 'We integrate with LevelUp, Thanx, and Como',
            endpoint: '/loyalty',
            section: 'SETTINGS'
          },
          {
            title: 'Delivery',
            description: 'We integrate with DoorDash, Postmates, and Relay',
            endpoint: '/delivery',
            section: 'SETTINGS'
          },
          {
            title: 'Maps',
            description: 'Settings for Google Maps and Mapbox (coming soon)',
            endpoint: '/maps',
            section: 'SETTINGS'
          },
          {
            title: 'Shipping',
            description:
              'We integrate with EasyPost for shipping rates and labels',
            endpoint: '/shipping',
            section: 'SETTINGS'
          },
          {
            title: 'Accounting',
            description:
              'We integrate with CTUIT and R365 for accounting and inventory management',
            endpoint: '/accounting',
            section: 'SETTINGS'
          },
          {
            title: 'Other',
            description: 'Other integrations like Tattle, Intercom, etc.',
            endpoint: '/other',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/pos': {
    type: constants.pageType.content,
    title: 'POS Integrations',
    subtitle: 'Please choose your POS integration below',
    content: '',
    image: { url: images.posModern, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          // {
          //   title: 'Toast',
          //   description:
          //     'Import your menus from Toast POS and then keep them in sync',
          //   endpoint: '/toast',
          //   section: 'SETTINGS'
          // },
          {
            title: 'Chowly',
            description: 'Manage your menus as provided by Chowly',
            endpoint: '/chowly-toos',
            section: 'SETTINGS'
          },
          {
            title: 'Revel',
            description:
              'Keep your Open Tender menus and other settings in sync with Revel POS',
            endpoint: '/revel',
            section: 'SETTINGS'
          },
          {
            title: 'Salido',
            description:
              'Import your menus from Salido POS and then keep them in sync',
            endpoint: '/salido',
            section: 'SETTINGS'
          },
          {
            title: 'Qu (formerly Gusto)',
            description:
              'Keep your Open Tender menus and other settings in sync with Qu POS',
            endpoint: '/qu',
            section: 'SETTINGS'
          },
          {
            title: 'Rpower',
            description: 'Turn your Rpower integration on and off',
            endpoint: '/rpower',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/chowly-toos': {
    type: constants.pageType.content,
    title: 'Chowly TOOS Integration',
    subtitle: 'Manage your Chowly TOOS integration',
    content: '',
    image: { url: images.pos, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Review Menus',
            description: 'Review your POS menus as provided by Chowly',
            endpoint: '/chowly-toos/menus',
            section: 'SETTINGS'
          },
          {
            title: 'Update Menus',
            description:
              'Pull the latest updates to your POS menus into Open Tender via Chowly',
            endpoint: '/chowly-toos/update-menus',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/chowly-toos/menus': {
    type: constants.pageType.menuExt,
    title: 'Review Menus',
    subtitle:
      'Review your Chowly menus before pulling in the latest updates or importing your menus for the first time',
    appClass: 'app--nav',
    options: ['revenue-centers'],
    item: itemSettings.chowlyToos,
    menuExt: {
      pos: 'CHOWLY_TOOS',
      endpoint: '/integrations/chowly-toos/:id/menus'
    }
  },
  '/chowly-toos/update-menus': {
    type: constants.pageType.menuExtUpdate,
    title: 'Update Menus',
    subtitle:
      'Use this page to pull in the latest updates to your POS menu via Chowly',
    options: ['revenue-centers'],
    item: itemSettings.chowlyToos,
    menuExtUpdate: {
      pos: 'CHOWLY_TOOS',
      endpoint: '/integrations/chowly-toos/missing-mappings',
      updateEndpoint: '/integrations/chowly-toos/update-menu-mappings'
    }
  },
  // '/toast': {
  //   type: constants.pageType.content,
  //   title: 'Toast Integration',
  //   subtitle: 'Manage your Toast POS integration',
  //   content: '',
  //   image: { url: images.pos, position: 'center center' },
  //   sections: [
  //     {
  //       name: 'section-01',
  //       subsections: [
  //         {
  //           title: 'API Credentials',
  //           description:
  //             'Authentication creds that allow us to access the Toast API',
  //           endpoint: '/toast/credentials',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Dining Options',
  //           description:
  //             'Map your Open Tender service types to Toast dining options',
  //           endpoint: '/toast/dining-options',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Payment Types',
  //           description:
  //             'Map your Open Tender tender types to Toast payment types',
  //           endpoint: '/toast/payment-types',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Discounts',
  //           description: 'Map your Open Tender discounts to Toast discounts',
  //           endpoint: '/toast/discounts',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Surcharges',
  //           description: 'Map your Open Tender surcharges to Toast surcharges',
  //           endpoint: '/toast/surcharges',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Review Menus',
  //           description:
  //             'Review your Toast menus before pulling in the latest updates or importing your menus for the first time',
  //           endpoint: '/toast/menus',
  //           section: 'SETTINGS'
  //         },
  //         {
  //           title: 'Update Menus',
  //           description:
  //             'Pull the latest updates to your Toast menus into Open Tender',
  //           endpoint: '/toast/update-menus',
  //           section: 'SETTINGS'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // '/toast/credentials': {
  //   type: constants.pageType.setting,
  //   title: 'API Credentials',
  //   subtitle: 'Authentication creds that allow us to access the Toast API',
  //   item: itemSettings.toast,
  //   setting: {
  //     tabsEndpoint: '/toast',
  //     endpoint: '/integrations/toast',
  //     fields: [
  //       {
  //         label: 'Client ID',
  //         field: 'client_id',
  //         type: 'text',
  //         required: true
  //       },
  //       {
  //         label: 'Client Secret',
  //         field: 'client_secret',
  //         type: 'text',
  //         required: true
  //       },
  //       {
  //         label: 'Restaurant Management Group GUID',
  //         field: 'restaurant_mgmt_group_guid',
  //         type: 'text',
  //         required: true
  //       },
  //       {
  //         label: 'Status',
  //         field: 'status',
  //         type: 'select',
  //         options: constants.posIntegrationStatus
  //       }
  //     ]
  //   }
  // },
  // '/toast/dining-options': {
  //   type: constants.pageType.mapping,
  //   title: 'Dining Option Mappings',
  //   subtitle: 'Map your Open Tender service types to Toast dining options',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   mapping: {
  //     entity: 'Service Types',
  //     extEntity: 'Toast Dining Options',
  //     name: 'name',
  //     field: 'service_type_id',
  //     endpoint: [
  //       { name: 'Pickup', service_type_id: 1 },
  //       { name: 'Delivery', service_type_id: 2 }
  //     ],
  //     extEndpoint: '/integrations/toast/:id/dining-options',
  //     mappingEndpoint: '/integrations/toast/:id/mapped-dining-options'
  //   }
  // },
  // '/toast/payment-types': {
  //   type: constants.pageType.mapping,
  //   title: 'Payment Type Mappings',
  //   subtitle: 'Map your Open Tender tender types to Toast payment types',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   mapping: {
  //     entity: 'Tender Types',
  //     extEntity: 'Toast Payment Types',
  //     name: 'name',
  //     field: 'tender_type_id',
  //     endpoint: [
  //       { name: 'Cash', tender_type_id: 1 },
  //       { name: 'Credit', tender_type_id: 2 },
  //       { name: 'LevelUp', tender_type_id: 3 },
  //       { name: 'House Account', tender_type_id: 4 },
  //       { name: 'Gift Cards', tender_type_id: 5 }
  //     ],
  //     extEndpoint: '/integrations/toast/:id/payment-types',
  //     mappingEndpoint: '/integrations/toast/:id/mapped-payment-types'
  //   }
  // },
  // '/toast/discounts': {
  //   type: constants.pageType.mapping,
  //   title: 'Discount Mappings',
  //   subtitle:
  //     'Map your Open Tender discounts and promo codes to Toast discounts',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   mapping: {
  //     entity: 'Discounts',
  //     extEntity: 'Toast Discounts',
  //     name: 'name',
  //     field: 'discount_id',
  //     endpoint: '/discounts?limit=1000',
  //     extEndpoint: '/integrations/toast/:id/discounts',
  //     mappingEndpoint: '/integrations/toast/:id/mapped-discounts'
  //   }
  // },
  // '/toast/surcharges': {
  //   type: constants.pageType.mapping,
  //   title: 'Surcharge Mappings',
  //   subtitle:
  //     'Map your Open Tender surcharges and promo codes to Toast surcharges',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   mapping: {
  //     entity: 'Surcharges',
  //     extEntity: 'Toast Surcharges',
  //     name: 'name',
  //     field: 'surcharge_id',
  //     endpoint: '/surcharges?limit=1000',
  //     extEndpoint: '/integrations/toast/:id/surcharges',
  //     mappingEndpoint: '/integrations/toast/:id/mapped-surcharges'
  //   }
  // },
  // '/toast/menus': {
  //   type: constants.pageType.menuExt,
  //   title: 'Review Menus',
  //   subtitle:
  //     'Review your Toast menus before pulling in the latest updates or importing your menus for the first time',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   menuExt: {
  //     pos: 'TOAST',
  //     endpoint: '/integrations/toast/:id/menus'
  //   }
  // },
  // '/toast/update-menus': {
  //   type: constants.pageType.menuExtUpdate,
  //   title: 'Update Menus',
  //   subtitle: 'Use this page to pull in the latest menu updates in Toast',
  //   options: ['revenue-centers'],
  //   item: itemSettings.toast,
  //   menuExtUpdate: {
  //     pos: 'TOAST',
  //     endpoint: '/integrations/toast/missing-mappings',
  //     updateEndpoint: '/integrations/toast/update-menu-mappings'
  //   }
  // },
  '/loyalty': {
    type: constants.pageType.content,
    title: 'Loyalty Integrations',
    subtitle: 'Open Tender integrates with the loyalty solutions below',
    content: '',
    image: { url: images.loyalty, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'LevelUp',
            description:
              'Allows customers to connect their LevelUp accounts with their Open Tender accounts, earn spend and credit, and redeem discounts',
            endpoint: '/levelup',
            section: 'SETTINGS'
          },
          {
            title: 'Thanx',
            description:
              'Allows customers to sign in with their Thanx account (i.e. "single sign on"), earn spend and credit, and redeem discounts',
            endpoint: '/thanx',
            section: 'SETTINGS'
          },
          {
            title: 'Como',
            description:
              'Allows customers to connect their Como accounts with their Open Tender accounts, earn spend and credit, and redeem discounts',
            endpoint: '/como',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/delivery': {
    type: constants.pageType.content,
    title: 'Delivery Integrations',
    subtitle:
      'Please choose your delivery integration below. Note that you can use different delivery integrations at different stores.',
    content: '',
    image: { url: images.delivery, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'DoorDash',
            description: 'For any restaurants using DoorDash Drive',
            endpoint: '/doordash',
            section: 'SETTINGS'
          },
          {
            title: 'Postmates',
            description:
              "Tap into Postmates' standard delivery service and optionally subsidize delivery fees for your customers",
            endpoint: '/postmates',
            section: 'SETTINGS'
          },
          {
            title: 'Relay',
            description:
              "For any restaurants using Relay's dedicated delivery service in New York City",
            endpoint: '/relay',
            section: 'SETTINGS'
          },
          {
            title: 'Onfleet',
            description: subtitles.integrationOnfleet,
            endpoint: '/onfleet',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/doordash': {
    type: constants.pageType.setting,
    title: 'DoorDash',
    subtitle: 'Authentication creds and other settings related to DoorDash',
    item: {},
    setting: {
      endpoint: '/integrations/doordash',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Use menu item short names for display in DoorDash',
          field: 'use_short_name',
          type: 'checkbox',
          tooltip:
            'This determines whether your employees will see full menu item names or short names in Open Tender orders sent to DoorDash.'
        },
        {
          label: 'Hide customer email from DoorDash',
          field: 'hide_customer_email',
          type: 'checkbox',
          tooltip:
            'If you don\'t want guests to receive the "How was your order?" email from DoorDash, you can use this field to NOT send the customer email address to DoorDash.'
        },
        {
          label: 'Email Content',
          field: 'email_content',
          type: 'wysiwyg',
          valType: constants.valTypes.wysiwyg,
          tooltip:
            "If populated, this message will OVERRIDE your normal email confirmation content AND we'll include a tracking URL in the email confirmation so customers can track the progress of their orders."
        }
      ]
    }
  },
  '/onfleet': {
    type: constants.pageType.setting,
    title: 'Onfleet',
    subtitle: subtitles.integrationOnfleet,
    item: {},
    setting: {
      endpoint: '/integrations/onfleet',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Assignment Type',
          field: 'assignment_type',
          type: 'select',
          options: constants.assignmentType
        },
        {
          label: 'Send delivery status updates to customers via SMS',
          field: 'sms_on',
          type: 'checkbox'
        }
      ]
    }
  },
  '/postmates': {
    type: constants.pageType.setting,
    title: 'Postmates',
    subtitle: 'Authentication creds and other settings related to Postmates',
    item: {},
    setting: {
      endpoint: '/integrations/postmates',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip:
            'Postmates will provide this to you when you sign up for a developer / API account'
        },
        {
          label: 'Customer ID',
          field: 'token',
          type: 'text',
          required: true,
          tooltip:
            'Postmates will provide this to you when you sign up for a developer / API account'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Use menu item short names for display in Postmates',
          field: 'use_short_name',
          type: 'checkbox',
          tooltip:
            'This determines whether your employees will see full menu item names or short names in Open Tender orders sent to Postmates.'
        }
      ]
    }
  },
  '/relay': {
    type: constants.pageType.setting,
    title: 'Relay',
    subtitle: 'Authentication creds and other settings related to Postmates',
    item: {},
    setting: {
      endpoint: '/integrations/relay',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip:
            'Relay will provide this to you when you sign up for a developer / API account'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Use menu item short names for display in Relay',
          field: 'use_short_name',
          type: 'checkbox',
          tooltip:
            'This determines whether your employees will see full menu item names or short names in Open Tender orders sent to Relay.'
        },
        {
          label: 'Send order time range instead of specific time',
          field: 'send_order_time_range',
          type: 'checkbox',
          tooltip:
            'If checked, Relay will receive an order time range if a location has a wait time set (ex. 11:30am - 11:45am). If NOT checked, Relay will only receive the earlier time of the range (e.g. in the 11:30am - 11:45am example, Relay will only receive 11:30am).'
        }
      ]
    }
  },
  '/shipping': {
    type: constants.pageType.content,
    title: 'Shipping Integrations',
    subtitle:
      "This is only relevant if you're using our merch product to sell things like clothing and cookbooks to your customers, which typically need to be shipped",
    content: '',
    image: { url: images.shipping, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'EasyPost',
            description:
              "Our EasyPost integration can be used to provide shipping rates and create shipping labels if you're using our merch product",
            endpoint: '/easypost',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/easypost': {
    type: constants.pageType.setting,
    title: 'EasyPost',
    subtitle: 'Authentication creds and other settings related to EasyPost',
    item: {},
    setting: {
      endpoint: '/integrations/easypost',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip:
            'Relay will provide this to you when you sign up for a developer / API account'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/thanx': {
    type: constants.pageType.content,
    title: 'Thanx Integration',
    subtitle: 'Manage your Thanx loyalty integration',
    content: '',
    image: { url: images.loyalty, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Production Creds',
            description: 'Credentials for your live Thanx account',
            endpoint: '/thanx/production',
            section: 'SETTINGS'
          },
          {
            title: 'Sandbox Creds',
            description:
              'Can be used to test out your Thanx integration prior to going live',
            endpoint: '/thanx/sandbox',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/thanx/production': {
    type: constants.pageType.setting,
    title: 'Production API Credentials',
    subtitle: 'Production API credentials for your Thanx account',
    item: itemSettings.thanx,
    setting: {
      endpoint: '/integrations/thanx/production',
      fields: [
        {
          label: 'Client ID',
          field: 'client_id',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Client Secret',
          field: 'client_secret',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Merchant Key',
          field: 'merchant_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          label: 'POS Discount ID',
          field: 'pos_discount_id',
          type: 'text',
          required: true,
          tooltip:
            "This is only necessary if you're using one of our POS integrations and is used to include Thanx loyalty discounts in orders sent to the POS"
        }
      ]
    }
  },
  '/thanx/sandbox': {
    type: constants.pageType.setting,
    title: 'Sandbox API Credentials',
    subtitle: 'Used for testing prior to going live with the Thanx integration',
    item: itemSettings.thanx,
    setting: {
      endpoint: '/integrations/thanx/sandbox',
      fields: [
        {
          label: 'Client ID',
          field: 'client_id',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Client Secret',
          field: 'client_secret',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Merchant Key',
          field: 'merchant_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Thanx'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox'
        },
        {
          label: 'POS Discount ID',
          field: 'pos_discount_id',
          type: 'text',
          required: true,
          tooltip:
            "This is only necessary if you're using one of our POS integrations and is used to include Thanx loyalty discounts in orders sent to the POS"
        }
      ]
    }
  },
  '/como': {
    type: constants.pageType.setting,
    title: 'Como',
    subtitle: 'Authentication creds and other settings related to Como',
    item: {},
    setting: {
      endpoint: '/integrations/como',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Como'
        },
        {
          label: 'Default Branch ID',
          field: 'branch_id',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Como'
        },
        {
          label: 'Location ID (for registration iframe)',
          field: 'iframe_id',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by Como'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.posIntegrationStatus
        }
      ]
    }
  },
  '/accounting': {
    type: constants.pageType.content,
    title: 'Accounting Integrations',
    subtitle:
      'Open Tender integrates with the restaurant management solutions below',
    content: '',
    image: { url: images.reporting, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'CTUIT',
            description:
              'Allows transactions and employee time punches to be synced to CTUIT on a daily basis',
            endpoint: '/ctuit',
            section: 'SETTINGS'
          },
          {
            title: 'Restaurant 365',
            description:
              'Allows transactions and employee time punches to be synced to R365 on a daily basis',
            endpoint: '/r365',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/r365': {
    type: constants.pageType.content,
    title: 'Restaurant 365',
    subtitle:
      'Allows transactions and employee time punches to be synced to R365 on a daily basis',
    content:
      "R365 pulls information from the Open Tender API, so there's nothing that needs to be set up on the Open Tender side. Please contact R365 support about activating the Open Tender integration for your restaurants.",
    image: { url: images.reporting, position: 'center center' }
  },
  '/payments': {
    type: constants.pageType.setting,
    title: 'Payments',
    subtitle: 'Credentials for your payments provider',
    item: {},
    setting: {
      endpoint: '/integrations/credit-cards',
      convert: true,
      fields: [
        {
          label: 'Payments Processor',
          field: 'integration',
          type: 'select',
          options: constants.paymentProcessors
        },
        {
          label: 'Merchant ID',
          field: 'merchant_id',
          type: 'text',
          tooltip:
            'This merchant ID will be applied to any revenue centers where a merchant ID has not been configured',
          required: true
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          tooltip: 'This is only required for Worldpay customers.'
        },
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          tooltip: 'This is only required for Open Tender Gateway customers.'
        },
        {
          label: 'Settlement Time',
          field: 'settlement_time',
          type: 'text',
          valType: constants.valTypes.time,
          required: true
        },
        {
          label: 'Testing Only',
          field: 'is_testing',
          type: 'checkbox',
          defaultValue: false
        }
      ]
    }
  },
  '/maps': {
    type: constants.pageType.content,
    title: 'Map Integrations',
    subtitle:
      'These integrations power address autocompletion and geolocation on your customer-facing site',
    content: '',
    image: { url: images.maps, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Google Maps',
            description:
              'Colors and other settings that affect the appearance of maps on your site',
            endpoint: '/google-maps',
            section: 'SETTINGS'
          },
          {
            title: 'Mapbox',
            description:
              "We'll soon be supporting Mapbox as an alternative to Google Maps",
            endpoint: '/mapbox',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/google-maps': {
    type: constants.pageType.setting,
    title: 'Google Maps',
    subtitle:
      'Colors, zoom, and other settings that affect the appearance of maps on your site',
    item: itemSettings.googleMaps,
    setting: {
      endpoint: '/integrations/maps',
      fields: [
        {
          label: 'Style Setting',
          field: 'custom_styles',
          type: 'select',
          options: constants.mapStyles,
          valType: constants.valTypes.int
        },
        {
          label: 'Label Color',
          field: 'label_color',
          type: 'text'
        },
        {
          label: 'Road Color',
          field: 'road_color',
          type: 'text'
        },
        {
          label: 'Feature Color',
          field: 'feature_color',
          type: 'text'
        },
        {
          label: 'Water Color',
          field: 'water_color',
          type: 'text'
        },
        {
          label: 'Background Color',
          field: 'background_color',
          type: 'text'
        },
        {
          label: 'Map Marker Width',
          field: 'marker_width',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Map Marker Height',
          field: 'marker_height',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Map Zoom on Location Selection Page',
          field: 'map_zoom',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Map Zoom on Locations Page on Website',
          field: 'locations_map_zoom',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Delivery Zone Color',
          field: 'zone_color',
          type: 'hidden'
        },
        {
          label: 'Custom JS',
          field: 'custom_js',
          type: 'hidden'
        }
      ]
    }
  },
  '/google-maps/files': {
    type: constants.pageType.itemFiles,
    title: 'Map Marker',
    subtitle:
      'Use this page to upload a custom map marker to denote the location of your restaurants on the Google Map displayed on your site',
    item: itemSettings.googleMaps,
    files: {
      endpoint: '/integrations/maps/files',
      id: 'file_id',
      files: [{ name: 'Map Marker', type: 'IMAGE', classes: 'dz-logo' }]
    }
  },
  '/other': {
    type: constants.pageType.content,
    title: 'Other Integrations',
    subtitle:
      "Various integrations that don't fit into our primary integration categories",
    content: '',
    image: {
      url: images.integrations,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Twilio',
            description:
              'Send order notifications via SMS for customers who opt-in',
            endpoint: '/twilio',
            section: 'SETTINGS'
          },
          {
            title: 'Firebase',
            description:
              'Send push notifications to users of your Open Tender mobile app',
            endpoint: '/firebase',
            section: 'SETTINGS'
          },
          {
            title: 'Mailchimp',
            description: 'Add subscribers to a Mailchimp email marketing list',
            endpoint: '/mailchimp',
            section: 'SETTINGS'
          },
          {
            title: 'Chowly',
            description:
              'Push your menus to various 3rd party platforms and retrieve orders to be processed through Open Tender',
            endpoint: '/chowly',
            section: 'SETTINGS'
          },
          {
            title: 'Tattle',
            description:
              'Include a call to action in your customer confirmation emails to fill out a Tattle feedback survery',
            endpoint: '/tattle',
            section: 'SETTINGS'
          },
          {
            title: 'Intercom',
            description:
              'Include an Intercom chat widget on your customer-facing site so you can answer customer questions in real-time',
            endpoint: '/intercom',
            section: 'SETTINGS'
          },
          {
            title: 'Webhook',
            description: 'Send orders to a custom-built webhook',
            endpoint: '/webhook',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/tattle': {
    type: constants.pageType.setting,
    title: 'Tattle',
    subtitle:
      'Use the Tattle integration to insert a link in your customer confirmation emails to a Tattle feedback survey',
    item: {},
    setting: {
      endpoint: '/integrations/tattle',
      fields: [
        {
          label: 'Link To Survey',
          field: 'survey_link',
          type: 'text',
          tooltip: 'The URL of the page where your survey lives',
          required: true
        },
        {
          label: 'Intro Text',
          field: 'intro_text',
          type: 'text',
          tooltip: 'This is the lead-in to the link that leads to the survey.',
          required: true
        },
        {
          label: 'Call To Action',
          field: 'call_to_action',
          type: 'text',
          tooltip: 'This is the text for the link itself',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/intercom': {
    type: constants.pageType.setting,
    title: 'Intercom',
    subtitle:
      'Configure how your Intercom chat widget appears on your customer-facing site',
    item: itemSettings.intercom,
    setting: {
      endpoint: '/integrations/intercom',
      fields: [
        {
          label: 'Intercom App ID',
          field: 'app_id',
          type: 'text'
        },
        {
          label: 'Display for new customers',
          field: 'new_customers',
          type: 'checkbox'
        },
        {
          label: 'Display for existing customers',
          field: 'existing_customers',
          type: 'checkbox'
        },
        {
          label: 'Display for regular online ordering customers',
          field: 'is_olo',
          type: 'checkbox'
        },
        {
          label: 'Display for catering customers',
          field: 'is_catering',
          type: 'checkbox'
        },
        {
          label: 'Display for merch customers',
          field: 'is_merch',
          type: 'checkbox'
        },
        {
          label: 'Use custom launcher',
          field: 'custom_launcher',
          type: 'checkbox'
        },
        {
          label: 'Custom launcher text',
          field: 'intercom_text',
          type: 'text'
        }
      ]
    }
  },
  '/intercom/files': {
    type: constants.pageType.itemFiles,
    title: 'Launcher Icon',
    subtitle:
      'Use this page to upload a custom icon to use for your Intercom launcher in the lower right of the page',
    item: itemSettings.intercom,
    files: {
      endpoint: '/integrations/intercom/files',
      id: 'file_id',
      files: [{ name: 'Launcher Icon', type: 'LOGO', classes: 'dz-logo' }]
    }
  },
  '/levelup': {
    type: constants.pageType.content,
    title: 'LevelUp Integration',
    subtitle: 'Manage your LevelUp loyalty integration',
    content: '',
    image: { url: images.loyalty, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Online Creds',
            description: 'For orders placed through your Open Tender website',
            endpoint: '/levelup/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Production POS Creds',
            description: 'For real orders placed through your Open Tender POS',
            endpoint: '/levelup/production',
            section: 'SETTINGS'
          },
          {
            title: 'Sandbox POS Creds',
            description: 'For test orders placed through your Open Tender POS',
            endpoint: '/levelup/sandbox',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/levelup/credentials': {
    type: constants.pageType.setting,
    title: 'Online Credentials',
    subtitle:
      'LevelUp credentials for orders placed through your Open Tender website',
    item: itemSettings.levelup,
    setting: {
      endpoint: '/integrations/levelup',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Client Secret',
          field: 'client_secret',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Username / Email Address',
          field: 'username',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Active',
          field: 'is_active',
          type: 'checkbox',
          tooltip:
            'Uncheck this box if you want to turn off the LevelUp integration for all of your stores'
        },
        {
          label: 'Testing vs. LevelUp sandbox',
          field: 'is_testing',
          type: 'checkbox',
          tooltip:
            'This should only be used with LevelUp sandbox credentials prior to going live'
        },
        {
          field: 'allow_loyalty_only',
          type: 'hidden',
          valType: constants.valTypes.bool
        },
        {
          label: 'Campaign ID',
          field: 'campaign_id',
          type: 'text',
          valType: constants.valTypes.int
        },
        {
          label: 'Campaign Type',
          field: 'campaign_type',
          type: 'select',
          options: constants.levelupCampaignTypes,
          defaultValue: ''
        },
        {
          label: 'Payment Tokens (generated by LevelUp)',
          field: 'payment_tokens',
          type: 'break'
        },
        {
          label: 'User ID',
          field: 'user_id',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'Merchant ID',
          field: 'merchant_id',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'Merchant Token',
          field: 'merchant_token',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'App ID',
          field: 'app_id',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'App Token',
          field: 'app_token',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'Regenerate Payment Tokens',
          field: 'regenerate_token',
          type: 'checkbox',
          tooltip:
            'Only check this if you need to update the payment tokens listed above (not recommended).'
        }
      ]
    }
  },
  '/levelup/production': {
    type: constants.pageType.setting,
    title: 'Production POS Credentials',
    subtitle:
      'LevelUp credentials for REAL orders placed through your Open Tender POS',
    item: itemSettings.levelup,
    setting: {
      endpoint: '/integrations/levelup/pos/production',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Username / Email Address',
          field: 'username',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Discount ID',
          field: 'discount_id',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          tooltip:
            'This is the ID of the Open Tender discount that will be used to capture all LevelUp discounts applied at the POS for reporting purposes'
        },
        {
          label: 'Payment Token (generated by LevelUp)',
          field: 'payment_token',
          type: 'break'
        },

        {
          label: 'Token',
          field: 'token',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'Regenerate Payment Token',
          field: 'regenerate_token',
          type: 'checkbox',
          tooltip:
            'Only check this if you need to update the LevelUp payment token listed above (not recommended).'
        }
      ]
    }
  },
  '/levelup/sandbox': {
    type: constants.pageType.setting,
    title: 'Sandbox POS Credentials',
    subtitle:
      'LevelUp credentials for TEST orders placed through your Open Tender POS',
    item: itemSettings.levelup,
    setting: {
      endpoint: '/integrations/levelup/pos/sandbox',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Username / Email Address',
          field: 'username',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          required: true,
          tooltip: 'This will be provided by LevelUp'
        },
        {
          label: 'Discount ID',
          field: 'discount_id',
          type: 'text',
          required: true,
          valType: constants.valTypes.int,
          tooltip:
            'This is the ID of the Open Tender discount that will be used to capture all LevelUp discounts applied at the POS for reporting purposes'
        },
        {
          label: 'Payment Token (generated by LevelUp)',
          field: 'payment_token',
          type: 'break'
        },

        {
          label: 'Token',
          field: 'token',
          type: 'text',
          readonly: true,
          disabled: true
        },
        {
          label: 'Regenerate Payment Token',
          field: 'regenerate_token',
          type: 'checkbox',
          tooltip:
            'Only check this if you need to update the LevelUp payment token listed above (not recommended).'
        }
      ]
    }
  },
  '/revel': {
    type: constants.pageType.content,
    title: 'Revel Integration',
    subtitle: 'Manage your Revel POS integration',
    content: '',
    image: {
      url: images.pos,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'API Credentials',
            description:
              'Authentication creds that allow us to access the Revel API',
            endpoint: '/revel/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Dining Options',
            description:
              'Map your Open Tender service types to Toast dining options',
            endpoint: '/revel/dining-options',
            section: 'SETTINGS'
          },
          {
            title: 'Payment Types',
            description:
              'Map your Open Tender tender types to Toast payment types',
            endpoint: '/revel/payment-types',
            section: 'SETTINGS'
          },
          {
            title: 'Discounts',
            description: 'Map your Open Tender discounts to Toast discounts',
            endpoint: '/revel/discounts',
            section: 'SETTINGS'
          },
          {
            title: 'Retrieve IDs',
            description:
              'Retrieve Revel barcodes, SKUs, and IDs for product, modifiers, users, and stations',
            endpoint: '/revel/entities',
            section: 'SETTINGS'
          },
          {
            title: 'Update Mappings',
            description:
              'Update the mappings between Open Tender items and Revel products once all of your barcodes and SKUs have been entered',
            endpoint: '/revel/update-mappings',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/revel/credentials': {
    type: constants.pageType.setting,
    title: 'API Credentials',
    subtitle: 'Authentication creds that allow us to access the Revel API',
    item: itemSettings.revel,
    setting: {
      tabsEndpoint: '/revel',
      endpoint: '/integrations/revel',
      fields: [
        {
          label: 'API URL',
          field: 'api_url',
          type: 'text',
          required: true,
          tooltip:
            'This is the URL of your Revel backend admin site (where you log in to make changes to your Revel settings).'
        },
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true,
          tooltip: 'This can be obtained from your Revel account rep.'
        },
        {
          label: 'API Secret',
          field: 'api_secret',
          type: 'text',
          required: true,
          tooltip: 'This can be obtained from your Revel account rep.'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Revel Identifiier Type',
          field: 'revel_id_type',
          type: 'select',
          options: constants.revelIdType,
          tooltip:
            'This is the type of identifier you use to refer to the same item across each of your Revel establishments.'
        },
        {
          label: 'Master Establishment ID',
          field: 'master_establishment_id',
          type: 'text',
          required: true,
          tooltip: 'This is the ID of your primary Revel establishment.'
        },
        {
          label: 'Send tips to Revel',
          field: 'send_tips_to_revel',
          type: 'checkbox'
        }
      ]
    }
  },
  '/revel/dining-options': {
    type: constants.pageType.mapping,
    title: 'Dining Option Mappings',
    subtitle: 'Map your Open Tender service types to Revel dining options',
    options: ['revenue-centers'],
    item: itemSettings.revel,
    mapping: {
      isGlobal: true,
      entity: 'Service Types',
      extEntity: 'Revel Dining Options',
      name: 'display_name',
      field: 'service_type_id',
      endpoint: '/integrations/revel/service-types',
      extEndpoint: '/integrations/revel/dining-options',
      mappingEndpoint: '/integrations/revel/mapped-dining-options'
    }
  },
  '/revel/payment-types': {
    type: constants.pageType.setting,
    title: 'Payment Types',
    subtitle:
      'Specify custom payment type IDs so Open Tender payments are associated with certain payment types in Revel',
    item: itemSettings.revel,
    setting: {
      tabsEndpoint: '/revel',
      endpoint: '/integrations/revel/payment-types',
      fields: [
        {
          label: '100% Comp Payment Type',
          field: '100%_comp_payment_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: '100% Comp Card Type',
          field: '100%_comp_card_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'Cash Payment Type',
          field: 'cash_payment_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'Cash Card Type',
          field: 'cash_card_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'Credit Payment Type',
          field: 'credit_payment_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 6
        },
        {
          label: 'Credit Card Type',
          field: 'credit_card_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 5
        },
        {
          label: 'LevelUp Payment Type',
          field: 'levelup_payment_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'LevelUp Card Type',
          field: 'levelup_card_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'House Account Payment Type',
          field: 'house_account_payment_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        },
        {
          label: 'House Account Card Type',
          field: 'house_account_card_type',
          type: 'text',
          valType: constants.valTypes.int,
          defaultValue: 0
        }
      ]
    }
  },
  '/revel/discounts': {
    type: constants.pageType.mapping,
    title: 'Discount Mappings',
    subtitle:
      'Map your Open Tender discounts and promo codes to Revel discounts',
    options: ['revenue-centers'],
    item: itemSettings.revel,
    mapping: {
      isGlobal: true,
      entity: 'Discounts',
      extEntity: 'Revel Discounts',
      name: 'name',
      field: 'discount_id',
      endpoint: '/discounts',
      extEndpoint: '/integrations/revel/discounts',
      mappingEndpoint: '/integrations/revel/mapped-discounts'
    }
  },
  '/revel/entities': {
    type: constants.pageType.entityExt,
    title: 'Retrieve IDs',
    subtitle:
      'This page allows you to look up Revel product barcodes and SKUs, modifier barcodes and SKUs, user IDs, and station IDs, all of which must be configured in Open Tender in order for the integration to work',
    item: itemSettings.revel,
    entityExt: {
      restEndpoint: '/integrations/revel/establishments',
      endpoint: '/integrations/revel/:id'
    }
  },
  '/revel/update-mappings': {
    type: constants.pageType.menuExtUpdate,
    title: 'Update Mappings',
    subtitle:
      'Use this page to update mappings between your Revel products & modifiers and Open Tender items & modifiers',
    options: ['revenue-centers'],
    item: itemSettings.revel,
    menuExtUpdate: {
      pos: 'REVEL',
      endpoint: '/integrations/revel/missing-mappings',
      updateEndpoint: '/integrations/revel/update-menu-mappings'
    }
  },
  '/chowly': {
    type: constants.pageType.content,
    title: 'Chowly Integration',
    subtitle: 'Manage your Chowly integration',
    content: '',
    image: {
      url: images.integrations,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'API Keys',
            description:
              'API keys for each of the revenue centers that are using the Chowly integration',
            endpoint: '/chowly/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Menu Updates',
            description: subtitles.menuExtUpdates,
            endpoint: '/chowly/menu-updates',
            section: 'SETTINGS'
          },
          {
            title: 'Import Errors',
            description:
              'Review and troubleshoot errors related to Chowly orders imported into Open Tender for a specific revenue center and business day',
            endpoint: '/chowly/import-errors',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/chowly/credentials': {
    type: constants.pageType.mapping,
    title: 'API Keys',
    subtitle:
      'Configure your Chowly API key for each revenue center using the integration',
    options: ['revenue-centers'],
    item: itemSettings.chowly,
    mapping: {
      entity: 'Revenue Centers',
      extEntity: 'API Keys',
      name: 'name',
      field: 'revenue_center_id',
      endpoint: [{ name: 'API Key', revenue_center_id: '' }],
      extEndpoint: [],
      mappingEndpoint: '/integrations/chowly/:id/mapped-api-keys'
    }
  },
  '/chowly/import-errors': {
    type: constants.pageType.importErrors,
    title: 'Import Errors',
    subtitle:
      'Use this page to review and troubleshoot errors related to orders imported from Chowly',
    options: ['revenue-centers'],
    item: itemSettings.chowly,
    importErrors: {
      orderSource: 'Chowly',
      endpoint: '/integrations/chowly/import-errors'
    }
  },
  '/chowly/menu-updates': {
    type: constants.pageType.menuExtUpdates,
    title: 'Menu Updates',
    subtitle: subtitles.menuExtUpdates,
    options: ['revenue-centers'],
    item: itemSettings.chowly,
    menuExtUpdates: {
      fields: [
        {
          name: 'Pushed',
          field: ['created_at'],
          format: 'datetime'
        },
        {
          name: 'User',
          field: ['user.last_name', 'user.first_name'],
          separator: ', '
          // link: 'user.user_id',
          // linkEndpoint: '/users'
        },
        {
          name: 'Menu Payload',
          field: ['view details', ''],
          button: 'menu_ext_id',
          format: 'path',
          settings: {
            title: 'Menu Payload',
            type: 'menuPayload',
            classes: 'modal--big modal--wide'
          }
        }
      ]
    }
  },
  '/twilio': {
    type: constants.pageType.setting,
    title: 'Twilio API Credentials',
    subtitle:
      'Authentication creds that allow us to access the Twilio API on your behalf',
    item: {},
    setting: {
      tabsEndpoint: '/twilio',
      endpoint: '/integrations/twilio',
      fields: [
        {
          label: 'Account SID',
          field: 'account_sid',
          type: 'text',
          required: true
        },
        {
          label: 'Auth Token',
          field: 'auth_token',
          type: 'text',
          required: true
        },
        {
          label: 'Long Code (phone number in +1XXXXXXXXXX format)',
          field: 'long_code',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/firebase': {
    type: constants.pageType.setting,
    title: 'Firebase Credentials',
    subtitle:
      'Authentication creds that allow us to send push notifications via Firebase on your behalf',
    item: {},
    setting: {
      tabsEndpoint: '/firebase',
      endpoint: '/integrations/firebase',
      fields: [
        {
          label: 'Service Account Info',
          field: 'service_account_info',
          type: 'textarea',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/mailchimp': {
    type: constants.pageType.setting,
    title: 'Mailchimp API Credentials',
    subtitle:
      'Authentication creds that allow us to access the Mailchimp API on your behalf',
    options: ['promo-codes'],
    item: {},
    setting: {
      tabsEndpoint: '/mailchimp',
      endpoint: '/integrations/mailchimp',
      fields: [
        {
          label: 'API URL',
          field: 'api_url',
          type: 'text',
          required: true
        },
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true
        },
        {
          label: 'List ID (i.e. Audience ID)',
          field: 'list_id',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        },
        {
          label: 'Sign Up Discount (optional)',
          field: 'discount_id',
          type: 'select',
          options: {
            endpoint: 'promo-codes',
            entity: 'discount',
            value: 'discount_id',
            label: 'name'
          },
          includeNone: true,
          valType: constants.valTypes.int,
          tooltip:
            'If a promo code is selected, a promo code will be sent to the email address submitted by new subscribers.'
        }
      ]
    }
  },
  '/salido': {
    type: constants.pageType.content,
    title: 'Salido Integration',
    subtitle: 'Manage your Salido POS integration',
    content: '',
    image: {
      url: images.pos,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'API Credentials',
            description:
              'Authentication creds that allow us to access the Salido API',
            endpoint: '/salido/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Review Menus',
            description:
              'Review your Salido menus before pulling in the latest updates or importing your menus for the first time',
            endpoint: '/salido/menus',
            section: 'SETTINGS'
          },
          {
            title: 'Update Menus',
            description:
              'Pull the latest updates to your Salido menus into Open Tender',
            endpoint: '/salido/update-menus',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/salido/credentials': {
    type: constants.pageType.setting,
    title: 'API Credentials',
    subtitle: 'Authentication creds that allow us to access the Salido API',
    item: itemSettings.salido,
    setting: {
      tabsEndpoint: '/salido',
      endpoint: '/integrations/salido',
      fields: [
        {
          label: 'Authorization URL',
          field: 'authorization_url',
          type: 'text',
          required: true
        },
        {
          label: 'Client ID',
          field: 'client_id',
          type: 'text',
          required: true
        },
        {
          label: 'Client Secret',
          field: 'client_secret',
          type: 'text',
          required: true
        },
        {
          label: 'Username',
          field: 'username',
          type: 'text',
          required: true
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.posIntegrationStatus
        },
        {
          label: 'Order Type IDs',
          field: 'order_type_ids',
          type: 'break'
        },
        {
          label: 'Pickup',
          field: 'pickup_order_type_id',
          type: 'text',
          required: true
        },
        {
          label: 'Delivery',
          field: 'delivery_order_type_id',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/salido/menus': {
    type: constants.pageType.menuExt,
    title: 'Review Menus',
    subtitle:
      'Review your Salido menus before pulling in the latest updates or importing your menus for the first time',
    options: ['revenue-centers'],
    item: itemSettings.salido,
    menuExt: {
      pos: 'SALIDO',
      endpoint: '/integrations/salido/:id/menus'
    }
  },
  '/salido/update-menus': {
    type: constants.pageType.menuExtUpdate,
    title: 'Update Menus',
    subtitle:
      'Use this page to pull in the latest menu updates from Salido, as well as to review any missing mappings down below',
    options: ['revenue-centers'],
    item: itemSettings.salido,
    menuExtUpdate: {
      pos: 'SALIDO',
      endpoint: '/integrations/salido/missing-mappings',
      updateEndpoint: '/integrations/salido/update-menu-mappings'
    }
  },
  '/qu': {
    type: constants.pageType.content,
    title: 'Qu Integration',
    subtitle: 'Manage your Qu POS integration',
    content: '',
    image: {
      url: images.pos,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'API Credentials',
            description:
              'Authentication creds that allow us to access the Qu API',
            endpoint: '/qu/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Order Types',
            description: 'Map your Open Tender order types to Qu order types',
            endpoint: '/qu/order-types',
            section: 'SETTINGS'
          },
          {
            title: 'Payment Types',
            description:
              'Map your Open Tender tender types to Qu payment types',
            endpoint: '/qu/payment-types',
            section: 'SETTINGS'
          },
          {
            title: 'ID Map',
            description:
              'Map Qu IDs in order to support multiple modifiers of a single type',
            endpoint: '/qu/id-map',
            section: 'SETTINGS'
          },
          {
            title: 'Review Menus',
            description:
              'Review your Qu menus before pulling in the latest updates or importing your menus for the first time',
            endpoint: '/qu/menus',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/qu/credentials': {
    type: constants.pageType.setting,
    title: 'API Credentials',
    subtitle: 'Authentication creds that allow us to access the Qu API',
    item: itemSettings.qu,
    setting: {
      tabsEndpoint: '/qu',
      endpoint: '/integrations/gusto',
      fields: [
        {
          label: 'API Key',
          field: 'api_key',
          type: 'text',
          required: true
        },
        {
          label: 'Customer ID',
          field: 'customer_id',
          type: 'text',
          required: true
        },
        {
          label: 'Order Channel Name',
          field: 'order_channel_name',
          type: 'text',
          required: true
        },
        {
          label: 'Order Channel ID',
          field: 'order_channel_id',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.posIntegrationStatus
        },
        {
          label: 'Settings',
          field: 'settings',
          type: 'break'
        },
        {
          label: 'Display Delivery Address in Customer Instructions',
          field: 'show_address',
          type: 'checkbox'
        },
        {
          label: 'Display Utensils in Customer Instructions',
          field: 'show_utensils',
          type: 'checkbox'
        }
      ]
    }
  },
  '/qu/order-types': {
    type: constants.pageType.setting,
    title: 'Order Types',
    subtitle:
      'Specify custom order type IDs and Names so Open Tender orders are associated with the correct order types in Qu',
    item: itemSettings.qu,
    setting: {
      tabsEndpoint: '/qu',
      endpoint: '/integrations/gusto/order-types',
      fields: [
        {
          label: 'OLO / Pickup Name',
          field: 'online_ordering_pickup_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'OLO / Pickup ID',
          field: 'online_ordering_pickup_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'OLO / Delivery Name',
          field: 'online_ordering_delivery_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'OLO / Delivery ID',
          field: 'online_ordering_delivery_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Catering / Pickup Name',
          field: 'catering_pickup_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Catering / Pickup ID',
          field: 'catering_pickup_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Catering / Delivery Name',
          field: 'catering_delivery_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Catering / Delivery ID',
          field: 'catering_delivery_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/qu/payment-types': {
    type: constants.pageType.setting,
    title: 'Payment Types',
    subtitle:
      'Specify custom payment type IDs and Names so Open Tender payments are associated with the correct payment types in Qu',
    item: itemSettings.qu,
    setting: {
      tabsEndpoint: '/qu',
      endpoint: '/integrations/gusto/payment-types',
      fields: [
        {
          label: '100% Comp Name',
          field: '100%_comp_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: '100% Comp ID',
          field: '100%_comp_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Cash Name',
          field: 'cash_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Cash ID',
          field: 'cash_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Credit Name',
          field: 'credit_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Credit ID',
          field: 'credit_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'LevelUp Name',
          field: 'levelup_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'LevelUp ID',
          field: 'levelup_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'House Account Name',
          field: 'house_account_name',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'House Account ID',
          field: 'house_account_id',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/qu/id-map': {
    type: constants.pageType.listOfItems,
    title: 'ID Map',
    subtitle:
      'Map Qu IDs in order to support multiple modifiers of a single type',
    item: itemSettings.qu,
    listOfItems: {
      endpoint: '/integrations/gusto/id-map',
      id: 'key',
      sorting: { sortBy: 'key', sortType: 'alpha' },
      fields: [
        { label: 'ID', field: 'key', type: 'text', required: true },
        { label: 'Associated ID', field: 'value', type: 'text', required: true }
      ]
    }
  },
  '/qu/menus': {
    type: constants.pageType.menuExt,
    title: 'Review Menus',
    subtitle: 'Review your Qu menus to troubleshoot any mapping issues',
    options: ['revenue-centers'],
    item: itemSettings.qu,
    menuExt: {
      pos: 'QU',
      endpoint: '/integrations/gusto/:id/menus'
    }
  },
  '/rpower': {
    type: constants.pageType.setting,
    title: 'API Credentials',
    subtitle: 'Turn your Rpower integration on and off',
    item: itemSettings.rpower,
    setting: {
      tabsEndpoint: '/rpower',
      endpoint: '/integrations/rpower',
      fields: [
        {
          label: 'Item Name Suffix',
          field: 'item_name_suffix',
          type: 'text'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/webhook': {
    type: constants.pageType.setting,
    title: 'Webhook Settings',
    subtitle: 'Manage your settings for sending orders to a 3rd party webhook',
    item: itemSettings.webhook,
    setting: {
      tabsEndpoint: '/webhook',
      endpoint: '/integrations/webhook',
      fields: [
        {
          label: 'URL',
          field: 'url',
          type: 'text',
          required: true,
          tooltip: 'This is where orders will be sent via a POST request'
        },
        {
          label: 'Username',
          field: 'username',
          type: 'text',
          tooltip:
            'This should be left blank if your webhook does not support basic auth.'
        },
        {
          label: 'Password',
          field: 'password',
          type: 'text',
          tooltip:
            'This should be left blank if your webhook does not support basic auth.'
        },
        {
          label: 'Keep Order Open',
          field: 'keep_order_open',
          type: 'checkbox',
          tooltip:
            'If checked, you will need to confirm orders on Brandibble or your webhook provider must use our API to close or cancel orders.'
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.integrationStatus
        }
      ]
    }
  },
  '/ctuit': {
    type: constants.pageType.content,
    title: 'CTUIT Integration',
    subtitle: 'Manage your CTUIT integration',
    content: '',
    image: {
      url: images.pos,
      position: 'center center'
    },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'API Credentials',
            description:
              'Authentication creds that allow us to access the CTUIT API',
            endpoint: '/ctuit/credentials',
            section: 'SETTINGS'
          },
          {
            title: 'Manual Sync',
            description:
              "Manually sync a single day's worth of data to CTUIT for a single store",
            endpoint: '/ctuit/sync',
            section: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/ctuit/credentials': {
    type: constants.pageType.setting,
    title: 'API Credentials',
    subtitle: 'Authentication creds that allow us to access the CTUIT API',
    item: itemSettings.ctuit,
    setting: {
      tabsEndpoint: '/ctuit',
      endpoint: '/integrations/ctuit',
      fields: [
        {
          label: 'API Token',
          field: 'api_token',
          type: 'text',
          required: true
        },
        {
          label: 'Company ID',
          field: 'company_id',
          type: 'text',
          required: true
        },
        {
          label: 'User ID',
          field: 'user_id',
          type: 'text',
          required: true
        },
        {
          label: 'Status',
          field: 'status',
          type: 'select',
          options: constants.posIntegrationStatus
        }
      ]
    }
  },
  '/ctuit/sync': {
    type: constants.pageType.sync,
    title: 'Sync Data to CTUIT',
    subtitle:
      "Sync a single business day's sales and labor data to CTUIT for a single store, overwriting any data that was previously synced for the specified business day.",
    options: ['stores'],
    item: itemSettings.ctuit,
    sync: {
      endpoint: '/integrations/ctuit/sync',
      xmlEndpoint: '/integrations/ctuit/data/xml'
    }
  },
  '/config': {
    type: constants.pageType.content,
    title: 'Web App Configuration',
    subtitle: subtitles.config,
    image: { url: images.settings, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Content',
            description: subtitles.configContent,
            endpoint: '/config-content',
            setting: 'SETTINGS'
          },
          {
            title: 'Styles',
            description: subtitles.configStyles,
            endpoint: '/config-styles',
            setting: 'SETTINGS'
          },
          {
            title: 'Settings',
            description: subtitles.configSettings,
            endpoint: '/config-settings',
            setting: 'SETTINGS'
          },
          {
            title: 'Clients',
            description: subtitles.configClients,
            endpoint: '/clients',
            setting: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/clients': {
    type: constants.pageType.list,
    title: 'Clients',
    subtitle: subtitles.configClients,
    list: {
      endpoint: '/oauth2-clients',
      newEndpoint: '/clients',
      id: 'oauth2_client_id',
      sorting: { sortBy: 'client_name', sortType: 'alpha' },
      fields: [
        {
          name: 'Client Name',
          field: ['client_name'],
          link: 'oauth2_client_id',
          linkEndpoint: '/clients'
        },
        {
          name: 'Domain',
          field: ['required_origin']
        },
        { name: 'Client ID', field: ['client_id'] },
        { name: 'Scope', field: ['scope'] },
        { name: 'Channel', field: ['channel_type'], format: 'enum' },
        { name: 'Issued At', field: ['issued_at'], format: 'datetime' },
        { name: 'Expires At', field: ['expires_at'], format: 'datetime' },
        { name: 'Revoked', field: ['is_revoked'], format: 'bool' },
        { name: 'ID', field: ['oauth2_client_id'] }
      ]
    }
  },
  '/clients/:id': {
    type: constants.pageType.item,
    title: 'Client',
    subtitle: 'Add a new client or revoke an existing one',
    content: '',
    list: listSettings.clients,
    item: {
      endpoint: '/oauth2-clients',
      id: 'oauth2_client_id',
      title: ['client_name'],
      // tabs: tabs.taxes,
      actions: [
        {
          text: 'Revoke',
          endpoint: '/oauth2-clients/:id/revoke',
          redirectEndpoint: '/clients'
        }
      ],
      fields: [
        {
          label: 'Client Name',
          field: 'client_name',
          type: 'text',
          required: true,
          readonly: true
        },
        {
          label: 'Domain',
          field: 'required_origin',
          type: 'text',
          required: true,
          readonly: true
        },
        {
          label: 'Channel',
          field: 'channel_type',
          type: 'select',
          options: constants.channelTypeClient,
          required: true,
          readonly: true
        },
        {
          field: 'scope',
          type: 'hidden',
          value: 'order-api'
        },
        {
          field: 'expires_at',
          type: 'hidden',
          value: null
        },
        {
          field: 'client_secret',
          type: 'hidden',
          value: null
        }
      ]
    }
  },
  '/config-content': {
    type: constants.pageType.content,
    title: 'Web App Content',
    subtitle: subtitles.configContent,
    image: { url: images.settings, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Guest',
            description: subtitles.configContentGuest,
            endpoint: '/config-content/guest',
            setting: 'SETTINGS'
          },
          {
            title: 'Account',
            description: subtitles.configContentAccount,
            endpoint: '/config-content/account',
            setting: 'SETTINGS'
          },
          {
            title: 'Order Type',
            description: subtitles.configContentOrderType,
            endpoint: '/config-content/order-type',
            setting: 'SETTINGS'
          },
          {
            title: 'Locations',
            description: subtitles.configContentLocations,
            endpoint: '/config-content/revenue-centers',
            setting: 'SETTINGS'
          },
          {
            title: 'Catering',
            description: subtitles.configContentCatering,
            endpoint: '/config-content/catering',
            setting: 'SETTINGS'
          },

          {
            title: 'Menu',
            description: subtitles.configContentMenu,
            endpoint: '/config-content/menu',
            setting: 'SETTINGS'
          },
          {
            title: 'Upsells',
            description: subtitles.configContentUpsells,
            endpoint: '/config-content/upsells',
            setting: 'SETTINGS'
          },
          {
            title: 'Checkout Flow',
            description: subtitles.configContentCheckoutFlow,
            endpoint: '/config-content/checkout-flow',
            setting: 'SETTINGS'
          },
          {
            title: 'Checkout',
            description: subtitles.configContentCheckout,
            endpoint: '/config-content/checkout',
            setting: 'SETTINGS'
          },
          {
            title: 'Confirmation',
            description: subtitles.configContentConfirmation,
            endpoint: '/config-content/confirmation',
            setting: 'SETTINGS'
          },
          {
            title: 'Curbside Pickup',
            description: subtitles.configContentFulfillment,
            endpoint: '/config-content/fulfillment',
            setting: 'SETTINGS'
          },
          {
            title: 'Orders',
            description: subtitles.configContentOrders,
            endpoint: '/config-content/orders',
            setting: 'SETTINGS'
          },
          {
            title: 'Favorites',
            description: subtitles.configContentFavorites,
            endpoint: '/config-content/favorites',
            setting: 'SETTINGS'
          },
          {
            title: 'Rewards',
            description: subtitles.configContentRewards,
            endpoint: '/config-content/rewards',
            setting: 'SETTINGS'
          },
          {
            title: 'Deals',
            description: subtitles.configContentDeals,
            endpoint: '/config-content/deals',
            setting: 'SETTINGS'
          },
          {
            title: 'Account Settings',
            description: subtitles.configContentAccountSettings,
            endpoint: '/config-content/account-settings',
            setting: 'SETTINGS'
          },
          {
            title: 'Allergens',
            description: subtitles.configContentAllergens,
            endpoint: '/config-content/allergens',
            setting: 'SETTINGS'
          },
          {
            title: 'Communication Preferences',
            description: subtitles.configContentCommunicationPrefs,
            endpoint: '/config-content/communication-preferences',
            setting: 'SETTINGS'
          },
          {
            title: 'Gift Cards',
            description: subtitles.configContentGiftCardsAccount,
            endpoint: '/config-content/gift-cards-account',
            setting: 'SETTINGS'
          },
          {
            title: 'Credit Cards',
            description: subtitles.configContentCreditCards,
            endpoint: '/config-content/credit-cards',
            setting: 'SETTINGS'
          },
          {
            title: 'Addresses',
            description: subtitles.configContentAddresses,
            endpoint: '/config-content/addresses',
            setting: 'SETTINGS'
          },
          {
            title: 'House Accounts',
            description: subtitles.configContentHouseAccounts,
            endpoint: '/config-content/house-accounts',
            setting: 'SETTINGS'
          },
          {
            title: 'Profile',
            description: subtitles.configContentProfile,
            endpoint: '/config-content/profile',
            setting: 'SETTINGS'
          },
          {
            title: 'Reset Password',
            description: subtitles.configContentReset,
            endpoint: '/config-content/reset-password',
            setting: 'SETTINGS'
          },
          {
            title: 'Sign Up',
            description: subtitles.configContentSignUp,
            endpoint: '/config-content/sign-up',
            setting: 'SETTINGS'
          },
          {
            title: 'Group Orders',
            description: subtitles.configContentGroupOrders,
            endpoint: '/config-content/group-orders',
            setting: 'SETTINGS'
          },
          {
            title: 'Gift Cards',
            description: subtitles.configContentGiftCards,
            endpoint: '/config-content/gift-cards',
            setting: 'SETTINGS'
          },
          {
            title: 'Donations',
            description: subtitles.configContentDonations,
            endpoint: '/config-content/donations',
            setting: 'SETTINGS'
          },
          {
            title: 'Accessibility Policy',
            description: subtitles.configContentAccessibility,
            endpoint: '/config-content/accessibility',
            setting: 'SETTINGS'
          },
          {
            title: 'Refund Policy',
            description: subtitles.configContentRefunds,
            endpoint: '/config-content/refunds',
            setting: 'SETTINGS'
          },
          {
            title: 'Not Found',
            description: subtitles.configContentNotFound,
            endpoint: '/config-content/not-found',
            setting: 'SETTINGS'
          },
          {
            title: 'Error',
            description: subtitles.configContentError,
            endpoint: '/config-content/error',
            setting: 'SETTINGS'
          },
          {
            title: 'Home',
            description: subtitles.configContentHome,
            endpoint: '/config-content/home',
            setting: 'SETTINGS'
          },
          {
            title: 'About',
            description: subtitles.configContentAbout,
            endpoint: '/config-content/about',
            setting: 'SETTINGS'
          },
          {
            title: 'Menu Site',
            description: subtitles.configContentMenuSite,
            endpoint: '/config-content/menu-site',
            setting: 'SETTINGS'
          },
          {
            title: 'Catering Site',
            description: subtitles.configContentCateringSite,
            endpoint: '/config-content/catering-site',
            setting: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/config-content/home': {
    type: constants.pageType.config,
    title: 'Home',
    subtitle: subtitles.configContentHome,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'home',
      fields: [
        {
          label: 'Show hero',
          field: 'showHero',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: false
        }
      ]
    }
  },
  '/config-content/revenue-centers': {
    type: constants.pageType.config,
    title: 'Revenue Centers',
    subtitle: subtitles.configContentLocations,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'revenueCenters',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Closed Message',
          field: 'CLOSED',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'statusMessages-CLOSED-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'statusMessages-CLOSED-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Temporarily Closed Message',
          field: 'CLOSED_TEMPORARILY',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'statusMessages-CLOSED_TEMPORARILY-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'statusMessages-CLOSED_TEMPORARILY-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Coming Soon Message',
          field: 'COMING_SOON',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'statusMessages-COMING_SOON-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'statusMessages-COMING_SOON-subtitle',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/config-content/about': {
    type: constants.pageType.config,
    title: 'About',
    subtitle: subtitles.configContentAbout,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'about',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        }
      ]
    }
  },
  '/config-content/catering': {
    type: constants.pageType.config,
    title: 'Catering',
    subtitle: subtitles.configContentCatering,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'catering',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        }
      ]
    }
  },
  '/config-content/catering-site': {
    type: constants.pageType.config,
    title: 'Catering Site',
    subtitle: subtitles.configContentCateringSite,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'cateringSite',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        }
      ]
    }
  },
  '/config-content/menu': {
    type: constants.pageType.config,
    title: 'Menu',
    subtitle: subtitles.configContentMenu,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'menu',
      fields: [
        {
          label: 'Video URL for Desktop (optional)',
          field: 'videoUrl',
          type: 'text'
        },
        {
          label: 'Video URL for Mobile (optional)',
          field: 'videoUrlMobile',
          type: 'text'
        },
        {
          label: 'Loading Message',
          field: 'loadingMessage',
          type: 'text',
          required: true
        },
        {
          label: 'Sold Out Message',
          field: 'soldOutMessage',
          type: 'text',
          required: true
        },
        {
          label: 'Cart Errors Pop Up',
          field: 'cartErrors',
          type: 'break'
        },
        {
          label: 'Invalid Items Title',
          field: 'sections-cartErrors-title',
          type: 'text',
          required: true,
          tooltip:
            'If a customer changes from one location to another or restarts an order after some days have passed, the available menu may not have certain items in their cart. If this happens, the customer is alerted that certain items in their cart are invalid and need to be removed.'
        },
        {
          label: 'Invalid Items Message',
          field: 'sections-cartErrors-subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Sections Displayed on Desktop',
          field: 'displayedTitle',
          type: 'break'
        },
        {
          label: 'Sections on Desktop',
          field: 'displayed',
          type: 'columns',
          required: true,
          options: ['RECENTS', 'FAVORITES', 'FEATURED', 'DEALS']
        },
        {
          label: 'Sections Displayed on Mobile',
          field: 'displayMobileTitle',
          type: 'break'
        },
        {
          label: 'Sections on Mobile',
          field: 'displayedMobile',
          type: 'columns',
          required: true,
          options: ['RECENTS', 'FAVORITES', 'FEATURED', 'DEALS']
        }
      ]
    }
  },
  '/config-content/menu-site': {
    type: constants.pageType.config,
    title: 'Menu Site',
    subtitle: subtitles.configContentMenuSite,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'menuSite',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: true
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        }
      ]
    }
  },
  '/config-content/upsells': {
    type: constants.pageType.config,
    title: 'Upsells',
    subtitle: subtitles.configContentUpsells,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'upsells',
      fields: [
        {
          label: 'Item Upsells',
          field: 'item',
          type: 'break'
        },
        {
          label: 'Show Item Upsell Options',
          field: 'sections-item-show',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'sections-item-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-item-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Skip (decline upsell options)',
          field: 'sections-item-decline',
          type: 'text',
          required: true
        },
        {
          label: 'Cart Upsells',
          field: 'cart',
          type: 'break'
        },
        {
          label: 'Show Cart Upsell Options',
          field: 'sections-cart-show',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'sections-cart-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-cart-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Skip (decline upsell options)',
          field: 'sections-cart-decline',
          type: 'text',
          required: true
        },
        {
          label: 'Checkout Upsells',
          field: 'checkout',
          type: 'break'
        },
        {
          label: 'Show Checkout Upsell Options',
          field: 'sections-checkout-show',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'sections-checkout-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-checkout-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Skip (decline upsell options)',
          field: 'sections-checkout-decline',
          type: 'text',
          required: true
        },
        {
          label: 'Proceed To Checkout',
          field: 'sections-checkout-proceed',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/config-content/checkout-flow': {
    type: constants.pageType.config,
    title: 'Checkout Flow',
    subtitle: subtitles.configContentCheckoutFlow,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'checkoutFlow',
      fields: [
        {
          label: 'Email Lookup for Logged Out Users',
          field: 'email',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-email-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-email-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Sign In Page for Recognized Email Addresses',
          field: 'signIn',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-signIn-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-signIn-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Sign Up Page for New Email Addresses',
          field: 'signUp',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-signUp-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-signUp-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Show Guest Checkout Option',
          field: 'sections-signUp-showGuest',
          type: 'checkbox'
        },
        {
          label: 'Name Entry for Walk-in Customers (QR Code Ordering)',
          field: 'name',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-name-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-name-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Thanx Magic Link',
          field: 'magicLink',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-magicLink-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-magicLink-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Resend Magic Link',
          field: 'sections-magicLink-resend',
          type: 'text',
          required: true
        },
        {
          label: 'Change Email Address',
          field: 'sections-magicLink-changeEmail',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/config-content/checkout': {
    type: constants.pageType.config,
    title: 'Checkout',
    subtitle: subtitles.configContentCheckout,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'checkout',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Order Summary',
          field: 'checkTitle',
          type: 'text',
          required: true
        },
        {
          label: 'Guest',
          field: 'guest',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-guest-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-guest-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Sign Up',
          field: 'signUp',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-signUp-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-signUp-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Account',
          field: 'account',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-account-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-account-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Address',
          field: 'address',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-address-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-address-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Order Details (location, service type, order time)',
          field: 'details',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-details-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-details-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Surcharges',
          field: 'surcharges',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-surcharges-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-surcharges-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Discounts',
          field: 'discounts',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-discounts-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-discounts-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Promo Codes',
          field: 'promoCodes',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-promoCodes-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-promoCodes-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Gift Cards',
          field: 'giftCards',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-giftCards-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-giftCards-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Tenders',
          field: 'tenders',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-tenders-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-tenders-subtitle',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/order-type': {
    type: constants.pageType.config,
    title: 'Order Type Selection',
    subtitle: subtitles.configContentOrderType,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'orderType',
      fields: [
        {
          field: 'showHero',
          type: 'hidden'
        },
        {
          label: 'Display Button Subtitles on Desktop',
          field: 'showDescriptions',
          type: 'checkbox'
        },
        {
          label: 'Display Button Subtitles on Mobile',
          field: 'showDescriptionsMobile',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        },
        {
          label: 'Video URL for Desktop (optional)',
          field: 'videoUrl',
          type: 'text'
        },
        {
          label: 'Video URL for Mobile (optional)',
          field: 'videoUrlMobile',
          type: 'text'
        },
        {
          label: 'Outpost Button',
          field: 'OUTPOST',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-OUTPOST-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-OUTPOST-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Dine-in Button',
          field: 'WALKIN',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-WALKIN-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-WALKIN-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Pickup Button',
          field: 'PICKUP',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-PICKUP-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-PICKUP-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Delivery Button',
          field: 'DELIVERY',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-DELIVERY-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-DELIVERY-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Group Order Button',
          field: 'GROUP',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-GROUP-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-GROUP-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Catering Button',
          field: 'CATERING',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-CATERING-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-CATERING-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Merch Button',
          field: 'MERCH',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-MERCH-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-MERCH-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Gift Cards Button',
          field: 'GIFT_CARDS',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-GIFT_CARDS-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-GIFT_CARDS-subtitle',
          type: 'text',
          required: true
        },
        {
          label: 'Donations Button',
          field: 'DONATIONS',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'orderTypes-DONATIONS-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'orderTypes-DONATIONS-subtitle',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/config-content/confirmation': {
    type: constants.pageType.config,
    title: 'Confirmation',
    subtitle: subtitles.configContentConfirmation,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'confirmation',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Spam Message',
          field: 'error',
          type: 'text',
          required: true
        }
      ]
    }
  },
  '/config-content/fulfillment': {
    type: constants.pageType.config,
    title: 'Curbside Pickup',
    subtitle: subtitles.configContentFulfillment,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'fulfillment',
      fields: []
    }
  },
  '/config-content/guest': {
    type: constants.pageType.config,
    title: 'Guest',
    subtitle: subtitles.configContentGuest,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'guest',
      fields: [
        {
          label: 'Show Guest Page',
          field: 'showGuest',
          type: 'checkbox',
          tooltip:
            "If not checked, we'll send the user directly to the order types page when they're not logged in."
        },
        {
          label: 'Display Logo in Header on Desktop',
          field: 'displayLogo',
          type: 'checkbox'
        },
        {
          label: 'Display Logo in Header on Mobile',
          field: 'displayLogoMobile',
          type: 'checkbox'
        },
        {
          label: 'Display Logo in Header in Mobile App',
          field: 'displayLogoApp',
          type: 'checkbox'
        },
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        },
        {
          label: 'Video URL for Desktop (optional)',
          field: 'videoUrl',
          type: 'text'
        },
        {
          label: 'Video URL for Mobile (optional)',
          field: 'videoUrlMobile',
          type: 'text'
        },
        {
          label: 'Sections Displayed on Desktop',
          field: 'displayedTitle',
          type: 'break'
        },
        {
          label: 'Sections',
          field: 'displayed',
          type: 'columns',
          required: true,
          options: ['ANNOUNCEMENTS', 'HERO', 'CONTENT', 'DEALS', 'LOYALTY']
        },
        {
          label: 'Sections Displayed on Mobile',
          field: 'displayMobileTitle',
          type: 'break'
        },
        {
          label: 'Sections on Mobile',
          field: 'displayedMobile',
          type: 'columns',
          required: true,
          options: ['ANNOUNCEMENTS', 'HERO', 'CONTENT', 'DEALS', 'LOYALTY']
        }
      ]
    }
  },
  '/config-content/account': {
    type: constants.pageType.config,
    title: 'Account',
    subtitle: subtitles.configContentAccount,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'account',
      fields: [
        {
          field: 'showHero',
          type: 'hidden'
        },
        {
          field: 'greeting',
          type: 'hidden'
        },
        // {
        //   label: 'Greeting',
        //   field: 'greeting',
        //   type: 'text',
        //   required: true,
        //   tooltip: 'Greeting for returning customers'
        // },
        {
          label: 'Display Logo in Header on Desktop',
          field: 'displayLogo',
          type: 'checkbox'
        },
        {
          label: 'Display Logo in Header on Mobile',
          field: 'displayLogoMobile',
          type: 'checkbox'
        },
        {
          label: 'Display Logo in Header in Mobile App',
          field: 'displayLogoApp',
          type: 'checkbox'
        },
        {
          label: "Display user's first name in greeting",
          field: 'showFirstName',
          type: 'checkbox'
        },
        {
          label: 'Punctuation',
          field: 'punctuation',
          type: 'text',
          tooltip: 'This will be appended to the end of the title.'
        },
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Content',
          field: 'content',
          type: 'wysiwyg',
          required: true
        },
        {
          label: 'Video URL for Desktop (optional)',
          field: 'videoUrl',
          type: 'text'
        },
        {
          label: 'Video URL for Mobile (optional)',
          field: 'videoUrlMobile',
          type: 'text'
        },
        {
          label: 'Sections Displayed on Desktop',
          field: 'displayedTitle',
          type: 'break'
        },
        {
          label: 'Sections',
          field: 'displayed',
          type: 'columns',
          required: true,
          options: [
            'ANNOUNCEMENTS',
            'HERO',
            'CONTENT',
            'LOYALTY',
            'REWARDS',
            'DEALS',
            'ORDERS',
            'GROUP_ORDERS'
          ]
        },
        {
          label: 'Sections Displayed on Mobile',
          field: 'displayMobileTitle',
          type: 'break'
        },
        {
          label: 'Sections on Mobile',
          field: 'displayedMobile',
          type: 'columns',
          required: true,
          options: [
            'ANNOUNCEMENTS',
            'HERO',
            'CONTENT',
            'LOYALTY',
            'REWARDS',
            'DEALS',
            'ORDERS',
            'GROUP_ORDERS'
          ]
        },
        {
          label: 'Recent Orders',
          field: 'recentOrders',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-recentOrders-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-recentOrders-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Empty',
          field: 'sections-recentOrders-empty',
          type: 'text',
          required: false
        },
        {
          label: 'Recently Ordered Items',
          field: 'recentItems',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-recentItems-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-recentItems-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Empty',
          field: 'sections-recentItems-empty',
          type: 'text',
          required: false
        },
        {
          label: 'Group Orders',
          field: 'groupOrders',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-groupOrders-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-groupOrders-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Favorites',
          field: 'favorites',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-favorites-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-favorites-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Empty',
          field: 'sections-favorites-empty',
          type: 'text',
          required: false
        },
        {
          label: 'Loyalty',
          field: 'loyalty',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-loyalty-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-loyalty-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Sign Up Button',
          field: 'sections-loyalty-signUp',
          type: 'text',
          required: false
        },
        {
          label: 'Skip Sign Up Button',
          field: 'sections-loyalty-skip',
          type: 'text',
          required: false
        },
        {
          label: 'Rewards',
          field: 'rewards',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-rewards-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-rewards-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Deals',
          field: 'deals',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-deals-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-deals-subtitle',
          type: 'text',
          required: false
        },
        {
          label: "LevelUp (only relevant if you're using LevelUp)",
          field: 'levelup',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-levelup-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-levelup-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Not connected',
          field: 'sections-levelup-empty',
          type: 'text',
          required: false
        },
        {
          label: "Thanx (only relevant if you're using Thanx)",
          field: 'thanx',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-thanx-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-thanx-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Missing Thanx Account',
          field: 'sections-thanx-empty',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/orders': {
    type: constants.pageType.config,
    title: 'Orders',
    subtitle: subtitles.configContentOrders,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'orders',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/favorites': {
    type: constants.pageType.config,
    title: 'Favorites',
    subtitle: subtitles.configContentFavorites,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'favorites',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/rewards': {
    type: constants.pageType.config,
    title: 'Rewards',
    subtitle: subtitles.configContentRewards,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'rewards',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Loyalty',
          field: 'loyalty',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-loyalty-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-loyalty-subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Rewards',
          field: 'rewards',
          type: 'break'
        },
        {
          label: 'Title',
          field: 'sections-rewards-title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'sections-rewards-subtitle',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/deals': {
    type: constants.pageType.config,
    title: 'Deals',
    subtitle: subtitles.configContentDeals,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'deals',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/account-settings': {
    type: constants.pageType.config,
    title: 'Account Settings',
    subtitle: subtitles.configContentAccountSettings,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'accountSettings',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/allergens': {
    type: constants.pageType.config,
    title: 'Allergens',
    subtitle: subtitles.configContentAllergens,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'allergens',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/communication-preferences': {
    type: constants.pageType.config,
    title: 'Communication Preferences',
    subtitle: subtitles.configContentCommunicationPrefs,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'communicationPreferences',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/gift-cards-account': {
    type: constants.pageType.config,
    title: 'Allergens',
    subtitle: subtitles.configContentGiftCardsAccount,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'giftCardsAccount',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/credit-cards': {
    type: constants.pageType.config,
    title: 'Credit Cards',
    subtitle: subtitles.configContentCreditCards,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'creditCards',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/addresses': {
    type: constants.pageType.config,
    title: 'Addresses',
    subtitle: subtitles.configContentAddresses,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'addresses',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/house-accounts': {
    type: constants.pageType.config,
    title: 'House Accounts',
    subtitle: subtitles.configContentHouseAccounts,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'houseAccounts',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/profile': {
    type: constants.pageType.config,
    title: 'Profile & Communication Preferences',
    subtitle: subtitles.configContentProfile,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'profile',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/reset-password': {
    type: constants.pageType.config,
    title: 'Reset Password',
    subtitle: subtitles.configContentReset,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'resetPassword',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Nevermind Message',
          field: 'back',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/sign-up': {
    type: constants.pageType.config,
    title: 'Sign Up',
    subtitle: subtitles.configContentSignUp,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'signUp',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Back To Home Page Message',
          field: 'back',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/group-orders': {
    type: constants.pageType.config,
    title: 'Group Orders',
    subtitle: subtitles.configContentGroupOrders,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'groupOrders',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/gift-cards': {
    type: constants.pageType.config,
    title: 'Gift Cards',
    subtitle: subtitles.configContentGiftCards,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'giftCards',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Content',
          field: 'subtitle',
          type: 'textarea',
          required: true
        }
      ]
    }
  },
  '/config-content/donations': {
    type: constants.pageType.config,
    title: 'Donations',
    subtitle: subtitles.configContentDonations,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'donations',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Content',
          field: 'subtitle',
          type: 'textarea',
          required: true
        }
      ]
    }
  },
  '/config-content/accessibility': {
    type: constants.pageType.config,
    title: 'Accessibility Policy',
    subtitle: subtitles.configContentAccessibility,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'accessibility',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Content',
          field: 'content',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/refunds': {
    type: constants.pageType.config,
    title: 'Refund Policy',
    subtitle: subtitles.configContentRefunds,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'refunds',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'text',
          required: false
        },
        {
          label: 'Content',
          field: 'content',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-content/not-found': {
    type: constants.pageType.config,
    title: 'Not Found',
    subtitle: subtitles.configContentNotFound,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'notFound',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        },
        {
          label: 'Back To Home Page Message',
          field: 'back',
          type: 'text',
          required: false
        }
      ]
    }
  },
  '/config-content/error': {
    type: constants.pageType.config,
    title: 'Error',
    subtitle: subtitles.configContentError,
    item: itemSettings.configContent,
    config: {
      endpoint: '/order-app-content',
      type: 'content',
      key: 'content',
      section: 'error',
      fields: [
        {
          label: 'Title',
          field: 'title',
          type: 'text',
          required: true
        },
        {
          label: 'Subtitle',
          field: 'subtitle',
          type: 'textarea',
          required: false
        }
      ]
    }
  },
  '/config-styles': {
    type: constants.pageType.content,
    title: 'Web App Styles',
    subtitle: subtitles.configStyles,
    image: { url: images.settings, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Global',
            description: subtitles.configStylesGlobal,
            endpoint: '/config-styles/global',
            setting: 'SETTINGS'
          },
          {
            title: 'Fonts',
            description: subtitles.configStylesFonts,
            endpoint: '/config-styles/fonts',
            setting: 'SETTINGS'
          },
          {
            title: 'Colors',
            description: subtitles.configStylesColors,
            endpoint: '/config-styles/colors',
            setting: 'SETTINGS'
          },
          {
            title: 'Links',
            description: subtitles.configStylesLinks,
            endpoint: '/config-styles/links',
            setting: 'SETTINGS'
          },
          {
            title: 'Button Colors',
            description: subtitles.configStylesButtonColors,
            endpoint: '/config-styles/button-colors',
            setting: 'SETTINGS'
          },
          {
            title: 'Button Styles',
            description: subtitles.configStylesButtonStyles,
            endpoint: '/config-styles/button-styles',
            setting: 'SETTINGS'
          },
          {
            title: 'Inputs',
            description: subtitles.configStylesInputs,
            endpoint: '/config-styles/inputs',
            setting: 'SETTINGS'
          },
          {
            title: 'Cards',
            description: subtitles.configStylesCards,
            endpoint: '/config-styles/cards',
            setting: 'SETTINGS'
          },
          {
            title: 'Counts',
            description: subtitles.configStylesCounts,
            endpoint: '/config-styles/counts',
            setting: 'SETTINGS'
          },
          {
            title: 'Welcome',
            description: subtitles.configStylesWelcome,
            endpoint: '/config-styles/welcome',
            setting: 'SETTINGS'
          },
          {
            title: 'Categories',
            description: subtitles.configStylesCategories,
            endpoint: '/config-styles/categories',
            setting: 'SETTINGS'
          },
          {
            title: 'Menu Items',
            description: subtitles.configStylesItems,
            endpoint: '/config-styles/items',
            setting: 'SETTINGS'
          },
          {
            title: 'Menu Item',
            description: subtitles.configStylesBuilder,
            endpoint: '/config-styles/item',
            setting: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/config-styles/global': {
    type: constants.pageType.config,
    title: 'Global Styles',
    subtitle: subtitles.configStylesGlobal,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'global',
      fields: [
        {
          label: 'Line Height',
          field: 'lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half'
        },
        {
          label: 'Bold Font Weight',
          field: 'boldWeight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right',
          tooltip:
            'This determines the font weight to be applied to certain text that is designated as bold in your Open Tender app'
        },
        {
          label: 'Logo',
          field: 'logo',
          type: 'break'
        },
        {
          label: 'Logo Width Desktop (in pixels)',
          field: 'sections-logo-width',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 300,
          classes: '-half'
        },
        {
          label: 'Logo Width Mobile (in pixels)',
          field: 'sections-logo-widthMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 300,
          classes: '-half -right'
        },
        {
          label: 'Top Margin Desktop (in pixels)',
          field: 'sections-logo-marginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -20,
          max: 20,
          classes: '-half'
        },
        {
          label: 'Top Margin Mobile (in pixels)',
          field: 'sections-logo-marginTopMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -20,
          max: 20,
          classes: '-half -right'
        },
        {
          label: 'Icons',
          field: 'icons',
          type: 'break'
        },
        {
          label: 'Icon Stroke Width',
          field: 'sections-icons-strokeWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 5,
          classes: '-half'
        },
        {
          label: 'Borders',
          field: 'border',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-border-color',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Width (in pixels)',
          field: 'sections-border-width',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Width in App (in pixels)',
          field: 'sections-border-widthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-border-radius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Small Border Radius (in pixels)',
          field: 'sections-border-radiusSmall',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Favorite Button',
          field: 'favorite',
          type: 'break'
        },
        {
          label: 'Button Size',
          field: 'sections-favorite-size',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Icon Size',
          field: 'sections-favorite-iconSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Light Overlay',
          field: 'overlayLight',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-overlayLight-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Opacity',
          field: 'sections-overlayLight-opacity',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Dark Overlay',
          field: 'overlayDark',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-overlayDark-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Opacity',
          field: 'sections-overlayDark-opacity',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Alert Overlay',
          field: 'overlayAlert',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-overlayAlert-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Opacity',
          field: 'sections-overlayAlert-opacity',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Box Shadow',
          field: 'boxShadow',
          type: 'break'
        },
        {
          label: 'Outer Box Shadow (as a CSS property)',
          field: 'sections-boxShadow-outer',
          type: 'text',
          required: true
        },
        {
          label: 'Inner Box Shadow (as a CSS property)',
          field: 'sections-boxShadow-inset',
          type: 'text',
          required: true
        },
        {
          label: 'Mobile App Small Shadow',
          field: 'sections-boxShadow-appSmall',
          type: 'text',
          required: true
        },
        {
          label: 'Mobile App Big Shadow',
          field: 'sections-boxShadow-appBig',
          type: 'text',
          required: true
        },
        {
          label: 'Layout',
          field: 'layout',
          type: 'break'
        },
        {
          label: 'Header / Nav Bar Height (in pixels)',
          field: 'sections-layout-navHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Header / Nav Bar Height on Mobile (in pixels)',
          field: 'sections-layout-navHeightMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Header Height in App (in pixels)',
          field: 'sections-layout-headerHeightApp',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Nav Height in App (in pixels)',
          field: 'sections-layout-navHeightApp',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Header Height for Site (in pixels)',
          field: 'sections-layout-headerHeightSite',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Header Height for Site on Mobile (in pixels)',
          field: 'sections-layout-headerHeightSiteMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-layout-padding',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding on Mobile (in pixels)',
          field: 'sections-layout-paddingMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100,
          classes: '-half -right'
        },
        {
          label: 'Vertical Spacing (in pixels)',
          field: 'sections-layout-margin',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Vertical Spacing on Mobile (in pixels)',
          field: 'sections-layout-marginMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Max Width for Text Content (in pixels)',
          field: 'sections-layout-maxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 1200,
          classes: '-half'
        },
        {
          label: 'Max Width for All Content (in pixels)',
          field: 'sections-layout-containerMaxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 2400,
          classes: '-half -right'
        },
        {
          label: 'Breakpoints',
          field: 'breakpoints',
          type: 'break'
        },
        {
          label: 'Laptop',
          field: 'sections-breakpoints-laptop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 2000,
          classes: '-half'
        },
        {
          label: 'Tablet Landscape',
          field: 'sections-breakpoints-narrow',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 2000,
          classes: '-half -right'
        },
        {
          label: 'Tablet Portrait',
          field: 'sections-breakpoints-tablet',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 2000,
          classes: '-half'
        },
        {
          label: 'Mobile',
          field: 'sections-breakpoints-mobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 2000,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/inputs': {
    type: constants.pageType.config,
    title: 'Inputs',
    subtitle: subtitles.configStylesInputs,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'inputs',
      fields: [
        {
          label: 'Font Family',
          field: 'family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'fontMobile',
          type: 'text',
          required: true
        },
        {
          label: 'Font Size',
          field: 'fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Font Size Mobile',
          field: 'fontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        },
        {
          label: 'Line Height',
          field: 'lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half'
        },
        {
          label: 'Letter Spacing',
          field: 'letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Vertical Padding (in pixels)',
          field: 'paddingVertical',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Style',
          field: 'borderStyle',
          type: 'select',
          options: constants.borderStyle,
          required: true
        },
        {
          label: 'Border Width (in pixels)',
          field: 'borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'radius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
          // classes: '-half -right'
        },
        {
          label: 'Text Color',
          field: 'color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color on Focus',
          field: 'colorFocus',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color on Focus',
          field: 'bgColorFocus',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Color on Focus',
          field: 'borderColorFocus',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Placeholder Color',
          field: 'placeholderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'boxShadow',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Bottom Border Only',
          field: 'bottomBorderOnly',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Label',
          field: 'showLabel',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Outline',
          field: 'showOutline',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Icon',
          field: 'showIcon',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Icon Offset Left',
          field: 'iconLeft',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 50,
          step: 1,
          classes: '-half'
        },
        {
          label: 'Input Left Padding with Icon',
          field: 'iconPadding',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 100,
          step: 1,
          classes: '-half -right'
        },
        {
          label: 'Label Font Size',
          field: 'labelFontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Label Font Size Mobile',
          field: 'labelFontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/cards': {
    type: constants.pageType.config,
    title: 'Cards',
    subtitle: subtitles.configStylesCards,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'cards',
      fields: [
        {
          label: 'Default',
          field: 'default',
          type: 'break'
        },
        {
          label: 'Display as Card',
          field: 'sections-default-displayAsCard',
          type: 'checkbox'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-default-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-default-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-default-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
          // classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-default-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-default-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-default-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Override Font Settings',
          field: 'sections-default-overrideFontColors',
          type: 'checkbox'
        },
        {
          label: 'Title Color',
          field: 'sections-default-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-default-textColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Menu Items',
          field: 'menuItem',
          type: 'break'
        },
        {
          label: 'Display as Card',
          field: 'sections-menuItem-displayAsCard',
          type: 'checkbox'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-menuItem-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-menuItem-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-menuItem-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
          // classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-menuItem-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-menuItem-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-menuItem-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Override Font Settings',
          field: 'sections-menuItem-overrideFontColors',
          type: 'checkbox'
        },
        {
          label: 'Title Color',
          field: 'sections-menuItem-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-menuItem-textColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Price Color',
          field: 'sections-menuItem-priceColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Calories Color',
          field: 'sections-menuItem-caloriesColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Title Size',
          field: 'sections-menuItem-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Title Size Mobile',
          field: 'sections-menuItem-titleSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        },
        {
          label: 'Price Size',
          field: 'sections-menuItem-priceSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half',
          tooltip: 'This size will be applied to both the price and calories'
        },
        {
          label: 'Price Size Mobile',
          field: 'sections-menuItem-priceSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right',
          tooltip: 'This size will be applied to both the price and calories'
        },
        {
          label: 'Modifiers',
          field: 'modifier',
          type: 'break'
        },
        {
          label: 'Display as Card',
          field: 'sections-modifier-displayAsCard',
          type: 'checkbox'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-modifier-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-modifier-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-modifier-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
        },
        {
          label: 'Border Color',
          field: 'sections-modifier-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-modifier-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-modifier-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Override Font Settings',
          field: 'sections-modifier-overrideFontColors',
          type: 'checkbox'
        },
        {
          label: 'Title Color',
          field: 'sections-modifier-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-modifier-textColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Price Color',
          field: 'sections-modifier-priceColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Calories Color',
          field: 'sections-modifier-caloriesColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Title Size',
          field: 'sections-modifier-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Title Size Mobile',
          field: 'sections-modifier-titleSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        },
        {
          label: 'Price Size',
          field: 'sections-modifier-priceSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half',
          tooltip: 'This size will be applied to both the price and calories'
        },
        {
          label: 'Price Size Mobile',
          field: 'sections-modifier-priceSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right',
          tooltip: 'This size will be applied to both the price and calories'
        },
        {
          label: 'Forms',
          field: 'form',
          type: 'break'
        },
        {
          label: 'Display as Card',
          field: 'sections-form-displayAsCard',
          type: 'checkbox'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-form-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-form-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-form-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
          // classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-form-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-form-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-form-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Override Font Settings',
          field: 'sections-form-overrideFontColors',
          type: 'checkbox'
        },
        {
          label: 'Title Color',
          field: 'sections-form-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-form-textColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/counts': {
    type: constants.pageType.config,
    title: 'Counts',
    subtitle: subtitles.configStylesCounts,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'counts',
      fields: [
        {
          label: 'Alerts (item counts & cart counts)',
          field: 'alerts',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-alerts-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Weight',
          field: 'sections-alerts-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-alerts-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-alerts-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-alerts-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Font Size Mobile',
          field: 'sections-alerts-fontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-alerts-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-alerts-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-alerts-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-alerts-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-alerts-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-alerts-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-alerts-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Quantity Selection',
          field: 'quantity',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-quantity-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Weight',
          field: 'sections-quantity-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-quantity-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-quantity-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-quantity-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Font Size Mobile',
          field: 'sections-quantity-fontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-quantity-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-quantity-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/colors': {
    type: constants.pageType.config,
    title: 'Colors',
    subtitle: subtitles.configStylesColors,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'colors',
      fields: [
        {
          label: 'Text Colors',
          field: 'colors',
          type: 'break'
        },
        {
          label: 'Primary',
          field: 'sections-colors-primary',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Secondary',
          field: 'sections-colors-secondary',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Tertiary',
          field: 'sections-colors-tertiary',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Alert',
          field: 'sections-colors-alert',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Success',
          field: 'sections-colors-success',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Error',
          field: 'sections-colors-error',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Tags',
          field: 'sections-colors-tags',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Allergens',
          field: 'sections-colors-allergens',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Dark',
          field: 'sections-colors-dark',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Light',
          field: 'sections-colors-light',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        // {
        //   label: 'Toast',
        //   field: 'sections-colors-toast',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half'
        // },
        {
          label: 'Background Colors',
          field: 'bgColors',
          type: 'break'
        },
        {
          label: 'Primary',
          field: 'sections-bgColors-primary',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Secondary',
          field: 'sections-bgColors-secondary',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Tertiary',
          field: 'sections-bgColors-tertiary',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Highlight',
          field: 'sections-bgColors-highlight',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Dark',
          field: 'sections-bgColors-dark',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Light',
          field: 'sections-bgColors-light',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        // {
        //   label: 'Alert',
        //   field: 'sections-bgColors-alert',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half'
        // },
        // {
        //   label: 'Error',
        //   field: 'sections-bgColors-error',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half -right'
        // },
        // {
        //   label: 'Toast',
        //   field: 'sections-bgColors-toast',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half'
        // },
        // {
        //   label: 'Allergens',
        //   field: 'sections-bgColors-allergens',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half -right'
        // },
        {
          label: 'Header Background Colors',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Primary',
          field: 'sections-header-primary',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Stuck',
          field: 'sections-header-stuck',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Alert Messages',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-alert-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-alert-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Error Messages',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-error-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-error-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Success Messages',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-success-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-success-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Toasts (notifications)',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-toast-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-toast-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/fonts': {
    type: constants.pageType.config,
    title: 'Fonts',
    subtitle: subtitles.configStylesFonts,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'fonts',
      fields: [
        {
          label: 'Stylesheets (at least 1 required)',
          field: 'body',
          type: 'break'
        },
        {
          label: 'Primary Stylesheet',
          field: 'sections-body-url',
          type: 'url',
          required: true
        },
        {
          label: 'Secondary Stylesheet (optional)',
          field: 'sections-headings-url',
          type: 'url',
          required: false,
          tooltip:
            "This isn't necessary if you primary font URL includes your heading font as well."
        },
        {
          label: 'Primary',
          field: 'body',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-body-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-body-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-body-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-body-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-body-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half'
        },
        {
          label: 'Line Height',
          field: 'sections-body-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Color',
          field: 'sections-body-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-body-fontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Headings',
          field: 'headings',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-headings-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-headings-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-headings-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-headings-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-headings-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half'
        },
        {
          label: 'Line Height',
          field: 'sections-headings-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Color',
          field: 'sections-headings-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-headings-fontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Headlines',
          field: 'headline',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-headline-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-headline-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-headline-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-headline-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-headline-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half'
        },
        {
          label: 'Line Height',
          field: 'sections-headline-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Color',
          field: 'sections-headline-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-headline-fontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Preface (the small font used for navigation and intro text)',
          field: 'preface',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-preface-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-preface-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-preface-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-preface-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-preface-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half'
        },
        {
          label: 'Line Height',
          field: 'sections-preface-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-preface-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36,
          classes: '-half'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-preface-fontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Text Sizes',
          field: 'sizes',
          type: 'break'
        },
        {
          label: 'Extra Extra Small',
          field: 'sections-sizes-xxSmall',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Extra Small',
          field: 'sections-sizes-xSmall',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Small',
          field: 'sections-sizes-small',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Primary',
          field: 'sections-sizes-main',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Big',
          field: 'sections-sizes-big',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Extra Big',
          field: 'sections-sizes-xBig',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Extra Extra Big',
          field: 'sections-sizes-xxBig',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 36
        },
        {
          label: 'Heaing Text Sizes',
          field: 'heading-sizes',
          type: 'break'
        },
        {
          label: 'h1',
          field: 'sections-sizes-h1',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        },
        {
          label: 'h2',
          field: 'sections-sizes-h2',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        },
        {
          label: 'h3',
          field: 'sections-sizes-h3',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        },
        {
          label: 'h4',
          field: 'sections-sizes-h4',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        },
        {
          label: 'h5',
          field: 'sections-sizes-h5',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        },
        {
          label: 'h6',
          field: 'sections-sizes-h6',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72
        }
      ]
    }
  },
  '/config-styles/welcome': {
    type: constants.pageType.config,
    title: 'Welcome Greeting',
    subtitle: subtitles.configStylesWelcome,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'welcome',
      fields: [
        {
          label: 'Desktop',
          field: 'desktop',
          type: 'break'
        },
        {
          label: 'Title Font Size',
          field: 'sections-desktop-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 200
        },
        {
          label: 'Title Line Height',
          field: 'sections-desktop-titleLineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05
        },
        {
          label: 'Title Margin Left',
          field: 'sections-desktop-titleMarginLeft',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -10,
          max: 10
        },
        {
          label: 'Subtitle Font Size',
          field: 'sections-desktop-subtitleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 200
        },
        {
          label: 'Subtitle Line Height',
          field: 'sections-desktop-subtitleLineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05
        },
        {
          label: 'Subtitle Margin Top',
          field: 'sections-desktop-subtitleMarginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Margin Top',
          field: 'sections-desktop-marginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Margin Bottom',
          field: 'sections-desktop-marginBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Text Alignment',
          field: 'sections-desktop-textAlign',
          type: 'select',
          options: constants.textAlign,
          required: true
        },
        {
          label: 'Button Left Padding',
          field: 'sections-desktop-buttonPaddingLeft',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Button Right Padding',
          field: 'sections-desktop-buttonPaddingRight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Show Button Icon',
          field: 'sections-desktop-showIcons',
          type: 'checkbox'
        },
        {
          label: 'Mobile (including mobile app)',
          field: 'mobile',
          type: 'break'
        },
        {
          label: 'Title Font Size',
          field: 'sections-mobile-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 200
        },
        {
          label: 'Title Line Height',
          field: 'sections-mobile-titleLineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05
        },
        {
          label: 'Title Margin Left',
          field: 'sections-mobile-titleMarginLeft',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -10,
          max: 10
        },
        {
          label: 'Subtitle Font Size',
          field: 'sections-mobile-subtitleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 200
        },
        {
          label: 'Subtitle Line Height',
          field: 'sections-mobile-subtitleLineHeight',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 3,
          step: 0.05
        },
        {
          label: 'Subtitle Margin Top',
          field: 'sections-mobile-subtitleMarginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Margin Top',
          field: 'sections-mobile-marginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Margin Bottom',
          field: 'sections-mobile-marginBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200
        },
        {
          label: 'Text Alignment',
          field: 'sections-mobile-textAlign',
          type: 'select',
          options: constants.textAlign,
          required: true
        },
        {
          label: 'Button Left Padding',
          field: 'sections-mobile-buttonPaddingLeft',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Button Right Padding',
          field: 'sections-mobile-buttonPaddingRight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Show Button Icon',
          field: 'sections-mobile-showIcons',
          type: 'checkbox'
        }
      ]
    }
  },
  '/config-styles/links': {
    type: constants.pageType.config,
    title: 'Links',
    subtitle: subtitles.configStylesLinks,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'links',
      fields: [
        {
          label: 'Text Decoration',
          field: 'textDecoration',
          type: 'select',
          options: constants.textDecoration,
          required: true
        },
        {
          label: 'Hover transition speed (in milliseconds)',
          field: 'transition',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        // {
        //   label: 'Text Decoration',
        //   field: 'textDecoration',
        //   type: 'break'
        // },
        // {
        //   label: 'Style',
        //   field: 'sections-textDecoration-style',
        //   type: 'select',
        //   options: constants.textDecoration,
        //   required: true,
        //   classes: '-half'
        // },
        // {
        //   label: 'Color',
        //   field: 'sections-textDecoration-color',
        //   type: 'inputColor',
        //   required: true,
        //   classes: '-half -right'
        // },
        {
          label: 'Primary',
          field: 'primary',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-primary-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Hover Color',
          field: 'sections-primary-hover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Light (for use in footer, navigation)',
          field: 'light',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-light-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Hover Color',
          field: 'sections-light-hover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Dark',
          field: 'dark',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-dark-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Hover Color',
          field: 'sections-dark-hover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Customize Button',
          field: 'customize',
          type: 'break'
        },
        {
          label: 'Color',
          field: 'sections-customize-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Hover Color',
          field: 'sections-customize-hover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/button-colors': {
    type: constants.pageType.config,
    title: 'Button Colors',
    subtitle: subtitles.configStylesButtonColors,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'buttonColors',
      fields: [
        {
          label: 'Primary',
          field: 'primary',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-primary-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-primaryHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-primary-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-primaryHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-primary-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Color',
          field: 'sections-primaryHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Secondary',
          field: 'secondary',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-secondary-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-secondaryHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-secondary-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-secondaryHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-secondary-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Color',
          field: 'sections-secondaryHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Header',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-header-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-headerHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-header-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-headerHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-header-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Color',
          field: 'sections-headerHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Cart',
          field: 'cart',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-cart-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Color',
          field: 'sections-cartHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-cart-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color',
          field: 'sections-cartHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-cart-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Color',
          field: 'sections-cartHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Light (used for buttons over images)',
          field: 'light',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-light-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Hover Color',
          field: 'sections-lightHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-light-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Hover Color',
          field: 'sections-lightHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-light-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Hover Color',
          field: 'sections-lightHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Large (for Order Types, Checkout buttons)',
          field: 'large',
          type: 'break'
        },
        {
          label: 'Text Color',
          field: 'sections-large-color',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Text Hover Color',
          field: 'sections-largeHover-color',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Text Color',
          field: 'sections-large-subtitleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Subtitle Text Hover Color',
          field: 'sections-largeHover-subtitleColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Icon Color',
          field: 'sections-large-iconColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Icon Hover Color',
          field: 'sections-largeHover-iconColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Background Color',
          field: 'sections-large-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Hover Color',
          field: 'sections-largeHover-bgColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Border Color',
          field: 'sections-large-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Border Hover Color',
          field: 'sections-largeHover-borderColor',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-styles/button-styles': {
    type: constants.pageType.config,
    title: 'Button Styles',
    subtitle: subtitles.configStylesButtonStyles,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'buttonSizes',
      fields: [
        {
          label: 'Default',
          field: 'default',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-default-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-default-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-default-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-default-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-default-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-default-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-default-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-default-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-default-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-default-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-default-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-default-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-default-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Small',
          field: 'small',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-small-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-small-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-small-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-small-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-small-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-small-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-small-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-small-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-small-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-small-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-small-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-small-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-small-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Big',
          field: 'big',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-big-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-big-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-big-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-big-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-big-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-big-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-big-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-big-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-big-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-big-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-big-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-big-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-big-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Header',
          field: 'header',
          type: 'break'
        },
        {
          label: 'Font Family',
          field: 'sections-header-family',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Weight',
          field: 'sections-header-weight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Mobile App Font',
          field: 'sections-header-fontMobile',
          type: 'text',
          required: true,
          classes: '-half'
        },
        {
          label: 'Letter Spacing',
          field: 'sections-header-letterSpacing',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 5,
          classes: '-half -right'
        },
        {
          label: 'Text Transform',
          field: 'sections-header-textTransform',
          type: 'select',
          options: constants.textTransform,
          required: true,
          classes: '-half'
        },
        {
          label: 'Font Smoothing',
          field: 'sections-header-fontSmoothing',
          type: 'select',
          options: constants.fontSmoothing,
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Font Size',
          field: 'sections-header-fontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-header-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-header-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-header-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-header-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-header-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-header-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Large',
          field: 'large',
          type: 'break'
        },
        {
          label: 'Border Width (in pixels)',
          field: 'sections-large-borderWidth',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half'
        },
        {
          label: 'Border Width in App (in pixels)',
          field: 'sections-large-borderWidthApp',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 10,
          step: 0.5,
          classes: '-half -right'
        },
        {
          label: 'Border Radius (in pixels)',
          field: 'sections-large-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
          // classes: '-half -right'
        },
        {
          label: 'Title Margin Left on Desktop',
          field: 'sections-large-titleMarginLeft',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Title Margin Left on Mobile',
          field: 'sections-large-titleMarginLeftMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Title Font Size on Desktop',
          field: 'sections-large-titleFontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Title Font Size on Mobile',
          field: 'sections-large-titleFontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half -right'
        },
        {
          label: 'Title Font Weight on Desktop',
          field: 'sections-large-titleFontWeight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half'
        },
        {
          label: 'Title Font on Mobile',
          field: 'sections-large-titleFontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Font Size on Desktop',
          field: 'sections-large-subtitleFontSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half'
        },
        {
          label: 'Subtitle Font Size on Mobile',
          field: 'sections-large-subtitleFontSizeMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 6,
          max: 72,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Font Weight on Desktop',
          field: 'sections-large-subtitleFontWeight',
          type: 'select',
          options: constants.themeFontWeights,
          valType: constants.valTypes.int,
          required: true,
          classes: '-half'
        },
        {
          label: 'Subtitle Font on Mobile',
          field: 'sections-large-subtitleFontMobile',
          type: 'text',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Margin Top on Desktop',
          field: 'sections-large-subtitleMarginTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Subtitle Margin Top on Mobile',
          field: 'sections-large-subtitleMarginTopMobile',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: -200,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Show Subtitle on Desktop',
          field: 'sections-large-showSubtitle',
          type: 'checkbox'
        },
        {
          label: 'Show Subtitle on Mobile',
          field: 'sections-large-showSubtitleMobile',
          type: 'checkbox'
        },
        {
          label: 'Show Order Types as Buttons on Mobile (both app & web)',
          field: 'sections-large-showButtonApp',
          type: 'checkbox'
        }
      ]
    }
  },
  '/config-styles/item': {
    type: constants.pageType.config,
    title: 'Menu Item Builder',
    subtitle: subtitles.configStylesBuilder,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'item',
      fields: [
        {
          label: 'Desktop',
          field: 'desktop',
          type: 'break'
        },
        {
          label: 'Maximum Width',
          field: 'sections-desktop-maxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 200,
          max: 2000
        },
        {
          label: 'Padding',
          field: 'sections-desktop-padding',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Padding',
          field: 'sections-desktop-imagePadding',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Height (% of vertial container)',
          field: 'sections-desktop-imageHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Minimum Height (pixels)',
          field: 'sections-desktop-imageMinHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 1000
        },
        {
          label: 'Image Maximum Height (pixels)',
          field: 'sections-desktop-imageMaxHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 1000
        },
        {
          label: 'Mobile (including mobile app)',
          field: 'mobile',
          type: 'break'
        },
        {
          label: 'Maximum Width',
          field: 'sections-mobile-maxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 200,
          max: 2000
        },
        {
          label: 'Padding',
          field: 'sections-mobile-padding',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Padding',
          field: 'sections-mobile-imagePadding',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Height (% of vertial container)',
          field: 'sections-mobile-imageHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Image Minimum Height (pixels)',
          field: 'sections-mobile-imageMinHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 1000
        },
        {
          label: 'Image Maximum Height (pixels)',
          field: 'sections-mobile-imageMaxHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 1000
        }
      ]
    }
  },
  '/config-styles/categories': {
    type: constants.pageType.config,
    title: 'Categories Layout',
    subtitle: subtitles.configStylesCategories,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'categories',
      fields: [
        {
          label: 'Desktop',
          field: 'desktop',
          type: 'break'
        },
        {
          label: 'Display Type',
          field: 'sections-desktop-displayType',
          type: 'select',
          options: constants.categoriesType,
          required: true
        },
        {
          label: 'Alignment',
          field: 'sections-desktop-justifyContent',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Categories per Row',
          field: 'sections-desktop-perRow',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 5
        },
        {
          label: 'Maximum Width',
          field: 'sections-desktop-maxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 500
        },
        {
          label: 'Minimum Width',
          field: 'sections-desktop-minWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 500
        },
        {
          label: 'Gap between Categories',
          field: 'sections-desktop-gap',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 250
        },
        {
          label: 'Border Radius',
          field: 'sections-desktop-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-desktop-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Background Color',
          field: 'sections-desktop-backgroundColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color Hover',
          field: 'sections-desktop-backgroundColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Hover transition speed (in milliseconds)',
          field: 'sections-desktop-transition',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Overlay Color',
          field: 'sections-desktop-overlayColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Overlay Opacity',
          field: 'sections-desktop-overlayOpacity',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05
        },
        {
          label: 'Overlay Opacity on Hover',
          field: 'sections-desktop-overlayOpacityHover',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05
        },
        {
          label: 'Image Ratio (width / height)',
          field: 'sections-desktop-imageRatio',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 5,
          step: 0.001
        },
        {
          label: 'Image Border Radius',
          field: 'sections-desktop-imageBorderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Image Scale',
          field: 'sections-desktop-imageScale',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 2,
          step: 0.05
        },
        {
          label: 'Image Scale Hover',
          field: 'sections-desktop-imageScaleHover',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 2,
          step: 0.05
        },
        {
          label: 'Horizontal Text Alignment',
          field: 'sections-desktop-textAlign',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Vertical Text Alignment',
          field: 'sections-desktop-textAlignVertical',
          type: 'select',
          options: constants.alignItems,
          required: true
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-desktop-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-desktop-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-desktop-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Line Type (if any)',
          field: 'sections-desktop-lineType',
          type: 'select',
          options: constants.lineType,
          required: true
        },
        {
          label: 'Line Width',
          field: 'sections-desktop-lineWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Line Height',
          field: 'sections-desktop-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 25
        },
        {
          label: 'Line Vertical Margin',
          field: 'sections-desktop-lineMargin',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
        },
        {
          label: 'Title Font Size (in pixels)',
          field: 'sections-desktop-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 64
        },
        {
          label: 'Title Color',
          field: 'sections-desktop-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Title Color on Hover',
          field: 'sections-desktop-titleColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Font Size (in pixels)',
          field: 'sections-desktop-subtitleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 64
        },
        {
          label: 'Subtitle Color',
          field: 'sections-desktop-subtitleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Subtitle Color on Hover',
          field: 'sections-desktop-subtitleColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Show Description',
          field: 'sections-desktop-showDescription',
          type: 'checkbox'
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'break'
        },
        {
          label: 'Display Type',
          field: 'sections-mobile-displayType',
          type: 'select',
          options: constants.categoriesType,
          required: true
        },
        {
          label: 'Alignment',
          field: 'sections-mobile-justifyContent',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Categories per Row',
          field: 'sections-mobile-perRow',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 5
        },
        {
          label: 'Maximum Width',
          field: 'sections-mobile-maxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 500
        },
        {
          label: 'Minimum Width',
          field: 'sections-mobile-minWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 500
        },
        {
          label: 'Gap between Categories',
          field: 'sections-mobile-gap',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 250
        },
        {
          label: 'Border Radius',
          field: 'sections-mobile-borderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Box Shadow (as a CSS property)',
          field: 'sections-mobile-boxShadow',
          type: 'text',
          required: true
        },
        {
          label: 'Background Color',
          field: 'sections-mobile-backgroundColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Background Color Hover',
          field: 'sections-mobile-backgroundColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Hover transition speed (in milliseconds)',
          field: 'sections-mobile-transition',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Overlay Color',
          field: 'sections-mobile-overlayColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Overlay Opacity',
          field: 'sections-mobile-overlayOpacity',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05
        },
        {
          label: 'Overlay Opacity on Hover',
          field: 'sections-mobile-overlayOpacityHover',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 1,
          step: 0.05
        },
        {
          label: 'Image Ratio (width / height)',
          field: 'sections-mobile-imageRatio',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 5,
          step: 0.001
        },
        {
          label: 'Image Border Radius',
          field: 'sections-mobile-imageBorderRadius',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Image Scale',
          field: 'sections-mobile-imageScale',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 2,
          step: 0.05
        },
        {
          label: 'Image Scale Hover',
          field: 'sections-mobile-imageScaleHover',
          type: 'inputRange',
          valType: constants.valTypes.float,
          required: true,
          min: 0,
          max: 2,
          step: 0.05
        },
        {
          label: 'Horizontal Text Alignment',
          field: 'sections-mobile-textAlign',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Vertical Text Alignment',
          field: 'sections-mobile-textAlignVertical',
          type: 'select',
          options: constants.alignItems,
          required: true
        },
        {
          label: 'Top Padding (in pixels)',
          field: 'sections-mobile-paddingTop',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half'
        },
        {
          label: 'Bottom Padding (in pixels)',
          field: 'sections-mobile-paddingBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Padding (in pixels)',
          field: 'sections-mobile-paddingHorizontal',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Line Type (if any)',
          field: 'sections-mobile-lineType',
          type: 'select',
          options: constants.lineType,
          required: true
        },
        {
          label: 'Line Width',
          field: 'sections-mobile-lineWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 500
        },
        {
          label: 'Line Height',
          field: 'sections-mobile-lineHeight',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 25
        },
        {
          label: 'Line Vertical Margin',
          field: 'sections-mobile-lineMargin',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 50
        },
        {
          label: 'Title Font Size (in pixels)',
          field: 'sections-mobile-titleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 64
        },
        {
          label: 'Title Color',
          field: 'sections-mobile-titleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Title Color on Hover',
          field: 'sections-mobile-titleColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Subtitle Font Size (in pixels)',
          field: 'sections-mobile-subtitleSize',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 64
        },
        {
          label: 'Subtitle Color',
          field: 'sections-mobile-subtitleColor',
          type: 'inputColor',
          required: true,
          classes: '-half'
        },
        {
          label: 'Subtitle Color on Hover',
          field: 'sections-mobile-subtitleColorHover',
          type: 'inputColor',
          required: true,
          classes: '-half -right'
        },
        {
          label: 'Show Description',
          field: 'sections-mobile-showDescription',
          type: 'checkbox'
        }
      ]
    }
  },
  '/config-styles/items': {
    type: constants.pageType.config,
    title: 'Menu Items Layout',
    subtitle: subtitles.configStylesItems,
    item: itemSettings.configStyles,
    config: {
      endpoint: '/order-app-theme',
      type: 'theme',
      key: null,
      section: 'items',
      fields: [
        {
          label: 'Desktop',
          field: 'desktop',
          type: 'break'
        },
        {
          label: 'Alignment',
          field: 'sections-desktop-justifyContent',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Items per Row',
          field: 'sections-desktop-perRow',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 8
        },
        {
          label: 'Item Min Width',
          field: 'sections-desktop-minWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 50,
          max: 1000
        },
        {
          label: 'Gap between Items',
          field: 'sections-desktop-gap',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200
        },
        {
          label: 'Bottom Margin',
          field: 'sections-desktop-marginBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Container Maximum Width',
          field: 'sections-desktop-containerMaxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 3000
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'break'
        },
        {
          label: 'Alignment',
          field: 'sections-mobile-justifyContent',
          type: 'select',
          options: constants.justifyContent,
          required: true
        },
        {
          label: 'Items per Row',
          field: 'sections-mobile-perRow',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 8
        },
        {
          label: 'Item Min Width',
          field: 'sections-mobile-minWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 50,
          max: 1000
        },
        {
          label: 'Gap between Items',
          field: 'sections-mobile-gap',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200
        },
        {
          label: 'Bottom Margin',
          field: 'sections-mobile-marginBottom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 100
        },
        {
          label: 'Container Maximum Width',
          field: 'sections-mobile-containerMaxWidth',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 100,
          max: 3000
        }
      ]
    }
  },

  '/config-settings': {
    type: constants.pageType.content,
    title: 'Web App Settings',
    subtitle: subtitles.configSettings,
    image: { url: images.settings, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Order Types',
            description: subtitles.configSettingsOrderTypes,
            endpoint: '/config-settings/order-types',
            setting: 'SETTINGS'
          },
          {
            title: 'Display Settings',
            description: subtitles.configSettingsDisplay,
            endpoint: '/config-settings/display',
            setting: 'SETTINGS'
          },
          {
            title: 'Checkout Settings',
            description: subtitles.configSettingsCheckout,
            endpoint: '/config-settings/checkout',
            setting: 'SETTINGS'
          },
          {
            title: 'Location Settings',
            description: subtitles.configSettingsLocations,
            endpoint: '/config-settings/locations',
            setting: 'SETTINGS'
          },
          {
            title: 'Google Maps',
            description: subtitles.configSettingsMaps,
            endpoint: '/config-settings/maps',
            setting: 'SETTINGS'
          },
          {
            title: 'Google Recaptcha',
            description: subtitles.configSettingsRecaptcha,
            endpoint: '/config-settings/recaptcha',
            setting: 'SETTINGS'
          }
        ]
      }
    ]
  },
  '/config-settings/order-types': {
    type: constants.pageType.config,
    title: 'Order Types',
    subtitle: subtitles.configSettingsOrderTypes,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'orderTypes',
      fields: [
        {
          label: 'Order Types',
          field: 'orderTypes',
          type: 'columns',
          required: true,
          options: [
            'WALKIN',
            'PICKUP',
            'DELIVERY',
            'GROUP',
            'OUTPOST',
            'CATERING',
            'GIFT_CARDS',
            'DONATIONS'
            // 'MERCH'
          ]
        }
      ]
    }
  },
  '/config-settings/display': {
    type: constants.pageType.config,
    title: 'Display Settings',
    subtitle: subtitles.configSettingsDisplay,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'displaySettings',
      fields: [
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'break'
        },
        {
          label: 'Store Phone',
          field: 'orderTypes-OLO-storePhone',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Nutritional Info',
          field: 'orderTypes-OLO-calories',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Allergens',
          field: 'orderTypes-OLO-allergens',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Tags',
          field: 'orderTypes-OLO-tags',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Desktop',
          field: 'orderTypes-OLO-quickAdd',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Mobile',
          field: 'orderTypes-OLO-quickAddMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Two-Step Customize Option',
          field: 'orderTypes-OLO-hasCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, customizing a menu item will be a two step process that requires the user to click a Customize button to make changes to modifiers. This works well when you have preset options and you prefer for customers NOT to customize items, but you want to give them the option nonetheless.'
        },
        {
          label: 'Skip to Customize on Desktop',
          field: 'orderTypes-OLO-skipToCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize page instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Skip to Customize on Mobile',
          field: 'orderTypes-OLO-skipToCustomizeMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize screen instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Menu Images',
          field: 'orderTypes-OLO-menuImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'These are the menu item images that appear on the menu page itself'
        },
        {
          label: 'Builder Images',
          field: 'orderTypes-OLO-builderImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the menu item image that gets displayed when a customer clicks on a menu item'
        },
        {
          label: 'Made For Field',
          field: 'orderTypes-OLO-madeFor',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Made For" field that allows a customer to add a name to an item added to the cart'
        },
        {
          label: 'Item Notes Field',
          field: 'orderTypes-OLO-notes',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Notes" field that appears when a customer clicks on an item and is used to provide instructions for a single item added to the cart'
        },
        {
          label: 'Menu Hero',
          field: 'orderTypes-OLO-menuHero',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero on Mobile',
          field: 'orderTypes-OLO-menuHeroMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero for Vendors',
          field: 'orderTypes-OLO-menuHeroChild',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: 'Menu Hero for Vendors on Mobile',
          field: 'orderTypes-OLO-menuHeroChildMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: '2 Menu Items per row on Mobile',
          field: 'orderTypes-OLO-itemsTwoPerRowMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, a compact / simple version of menu items will appear on the menu page on mobile so we can fit 2 per row'
        },
        {
          label: 'Modifier Image',
          field: 'orderTypes-OLO-modifierImage',
          type: 'checkbox',
          required: true,
          tooltip: 'Show or hide the modifier images in the menu item builder'
        },
        {
          label: 'Modifier Description',
          field: 'orderTypes-OLO-modifierDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier description in the menu item builder'
        },
        {
          label: 'Modifier Group Description',
          field: 'orderTypes-OLO-modifierGroupDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier group description in the menu item builder'
        },
        {
          label: 'Menu Display Type on Desktop',
          field: 'orderTypes-OLO-menuType',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on Mobile',
          field: 'orderTypes-OLO-menuTypeMobile',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on App',
          field: 'orderTypes-OLO-menuTypeApp',
          type: 'select',
          options: constants.menuTypeApp,
          required: true
        },
        {
          label: 'Category Display Type on Desktop',
          field: 'orderTypes-OLO-categoryType',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Category Display Type on Mobile / App',
          field: 'orderTypes-OLO-categoryTypeMobile',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Builder Type',
          field: 'orderTypes-OLO-builderType',
          type: 'select',
          options: constants.builderType,
          required: true
        },
        {
          label: 'Modifier Groups Navigation Type on App',
          field: 'orderTypes-OLO-groupsTypeApp',
          type: 'select',
          options: constants.groupsTypeApp,
          required: true
        },
        {
          label: 'Modifier Display Type',
          field: 'orderTypes-OLO-modifiersType',
          type: 'select',
          options: constants.modifiersType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page',
          field: 'orderTypes-OLO-menuItemDescription',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page on Mobile',
          field: 'orderTypes-OLO-menuItemDescriptionMobile',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'break'
        },
        {
          label: 'Store Phone',
          field: 'orderTypes-CATERING-storePhone',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Nutritional Info',
          field: 'orderTypes-CATERING-calories',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Allergens',
          field: 'orderTypes-CATERING-allergens',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Tags',
          field: 'orderTypes-CATERING-tags',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Desktop',
          field: 'orderTypes-CATERING-quickAdd',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Mobile',
          field: 'orderTypes-CATERING-quickAddMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Two-Step Customize Option',
          field: 'orderTypes-CATERING-hasCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, customizing a menu item will be a two step process that requires the user to click a Customize button to make changes to modifiers. This works well when you have preset options and you prefer for customers NOT to customize items, but you want to give them the option nonetheless.'
        },
        {
          label: 'Skip to Customize on Desktop',
          field: 'orderTypes-CATERING-skipToCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize page instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Skip to Customize on Mobile',
          field: 'orderTypes-CATERING-skipToCustomizeMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize screen instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Menu Images',
          field: 'orderTypes-CATERING-menuImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'These are the menu item images that appear on the menu page itself'
        },
        {
          label: 'Builder Images',
          field: 'orderTypes-CATERING-builderImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the menu item image that gets displayed when a customer clicks on a menu item'
        },
        {
          label: 'Made For Field',
          field: 'orderTypes-CATERING-madeFor',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Made For" field that allows a customer to add a name to an item added to the cart'
        },
        {
          label: 'Item Notes Field',
          field: 'orderTypes-CATERING-notes',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Notes" field that appears when a customer clicks on an item and is used to provide instructions for a single item added to the cart'
        },
        {
          label: 'Menu Hero',
          field: 'orderTypes-CATERING-menuHero',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero on Mobile',
          field: 'orderTypes-CATERING-menuHeroMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero for Vendors',
          field: 'orderTypes-CATERING-menuHeroChild',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: 'Menu Hero for Vendors on Mobile',
          field: 'orderTypes-CATERING-menuHeroChildMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: '2 Menu Items per row on Mobile',
          field: 'orderTypes-CATERING-itemsTwoPerRowMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, a compact / simple version of menu items will appear on the menu page on mobile so we can fit 2 per row'
        },
        {
          label: 'Modifier Image',
          field: 'orderTypes-CATERING-modifierImage',
          type: 'checkbox',
          required: true,
          tooltip: 'Show or hide the modifier images in the menu item builder'
        },
        {
          label: 'Modifier Description',
          field: 'orderTypes-CATERING-modifierDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier description in the menu item builder'
        },
        {
          label: 'Modifier Group Description',
          field: 'orderTypes-CATERING-modifierGroupDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier group description in the menu item builder'
        },
        {
          label: 'Menu Display Type on Desktop',
          field: 'orderTypes-CATERING-menuType',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on Mobile',
          field: 'orderTypes-CATERING-menuTypeMobile',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on App',
          field: 'orderTypes-CATERING-menuTypeApp',
          type: 'select',
          options: constants.menuTypeApp,
          required: true
        },
        {
          label: 'Category Display Type on Desktop',
          field: 'orderTypes-CATERING-categoryType',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Category Display Type on Mobile / App',
          field: 'orderTypes-CATERING-categoryTypeMobile',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Builder Type',
          field: 'orderTypes-CATERING-builderType',
          type: 'select',
          options: constants.builderType,
          required: true
        },
        {
          label: 'Modifier Groups Navigation Type on App',
          field: 'orderTypes-CATERING-groupsTypeApp',
          type: 'select',
          options: constants.groupsTypeApp,
          required: true
        },
        {
          label: 'Modifier Display Type',
          field: 'orderTypes-CATERING-modifiersType',
          type: 'select',
          options: constants.modifiersType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page',
          field: 'orderTypes-CATERING-menuItemDescription',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page on Mobile',
          field: 'orderTypes-CATERING-menuItemDescriptionMobile',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'break'
        },
        {
          label: 'Store Phone',
          field: 'orderTypes-MERCH-storePhone',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Nutritional Info',
          field: 'orderTypes-MERCH-calories',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Allergens',
          field: 'orderTypes-MERCH-allergens',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Tags',
          field: 'orderTypes-MERCH-tags',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Desktop',
          field: 'orderTypes-MERCH-quickAdd',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Quick Add Button on Mobile',
          field: 'orderTypes-MERCH-quickAddMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Show Two-Step Customize Option',
          field: 'orderTypes-MERCH-hasCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, customizing a menu item will be a two step process that requires the user to click a Customize button to make changes to modifiers. This works well when you have preset options and you prefer for customers NOT to customize items, but you want to give them the option nonetheless.'
        },
        {
          label: 'Skip to Customize on Desktop',
          field: 'orderTypes-MERCH-skipToCustomize',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize page instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Skip to Customize on Mobile',
          field: 'orderTypes-MERCH-skipToCustomizeMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, tapping the Customize button on the menu page will jump straight to the customize screen instead of stopping at the item summary page first ONLY WHEN AN ITEM REQUIRES CUSTOMIZATION.'
        },
        {
          label: 'Menu Images',
          field: 'orderTypes-MERCH-menuImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'These are the menu item images that appear on the menu page itself'
        },
        {
          label: 'Builder Images',
          field: 'orderTypes-MERCH-builderImages',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the menu item image that gets displayed when a customer clicks on a menu item'
        },
        {
          label: 'Made For Field',
          field: 'orderTypes-MERCH-madeFor',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Made For" field that allows a customer to add a name to an item added to the cart'
        },
        {
          label: 'Item Notes Field',
          field: 'orderTypes-MERCH-notes',
          type: 'checkbox',
          required: true,
          tooltip:
            'This is the "Notes" field that appears when a customer clicks on an item and is used to provide instructions for a single item added to the cart'
        },
        {
          label: 'Menu Hero',
          field: 'orderTypes-MERCH-menuHero',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero on Mobile',
          field: 'orderTypes-MERCH-menuHeroMobile',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Menu Hero for Vendors',
          field: 'orderTypes-MERCH-menuHeroChild',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: 'Menu Hero for Vendors on Mobile',
          field: 'orderTypes-MERCH-menuHeroChildMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'This only pertains to food hall operators who use parent and child store relationships'
        },
        {
          label: '2 Menu Items per row on Mobile',
          field: 'orderTypes-MERCH-itemsTwoPerRowMobile',
          type: 'checkbox',
          required: true,
          tooltip:
            'If checked, a compact / simple version of menu items will appear on the menu page on mobile so we can fit 2 per row'
        },
        {
          label: 'Modifier Image',
          field: 'orderTypes-MERCH-modifierImage',
          type: 'checkbox',
          required: true,
          tooltip: 'Show or hide the modifier images in the menu item builder'
        },
        {
          label: 'Modifier Description',
          field: 'orderTypes-MERCH-modifierDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier description in the menu item builder'
        },
        {
          label: 'Modifier Group Description',
          field: 'orderTypes-MERCH-modifierGroupDescription',
          type: 'checkbox',
          required: true,
          tooltip:
            'Show or hide the modifier group description in the menu item builder'
        },
        {
          label: 'Menu Display Type on Desktop',
          field: 'orderTypes-MERCH-menuType',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on Mobile',
          field: 'orderTypes-MERCH-menuTypeMobile',
          type: 'select',
          options: constants.menuType,
          required: true
        },
        {
          label: 'Menu Display Type on App',
          field: 'orderTypes-MERCH-menuTypeApp',
          type: 'select',
          options: constants.menuTypeApp,
          required: true
        },
        {
          label: 'Category Display Type on Desktop',
          field: 'orderTypes-MERCH-categoryType',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Category Display Type on Mobile / App',
          field: 'orderTypes-MERCH-categoryTypeMobile',
          type: 'select',
          options: constants.menuCategoryType,
          required: true
        },
        {
          label: 'Builder Type',
          field: 'orderTypes-MERCH-builderType',
          type: 'select',
          options: constants.builderType,
          required: true
        },
        {
          label: 'Modifier Groups Navigation Type on App',
          field: 'orderTypes-MERCH-groupsTypeApp',
          type: 'select',
          options: constants.groupsTypeApp,
          required: true
        },
        {
          label: 'Modifier Display Type',
          field: 'orderTypes-MERCH-modifiersType',
          type: 'select',
          options: constants.modifiersType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page',
          field: 'orderTypes-MERCH-menuItemDescription',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        },
        {
          label: 'Menu Item Description on Menu Page on Mobile',
          field: 'orderTypes-MERCH-menuItemDescriptionMobile',
          type: 'select',
          options: constants.menuItemDescriptionType,
          required: true
        }
      ]
    }
  },
  '/config-settings/checkout': {
    type: constants.pageType.config,
    title: 'Checkout Settings',
    subtitle: subtitles.configSettingsCheckout,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'checkout',
      fields: [
        {
          label: 'Online Ordering',
          field: 'OLO',
          type: 'break'
        },
        {
          label: 'Promo Code Limit',
          field: 'orderTypes-OLO-promo_code_limit',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          tooltip:
            'This controls how many promo codes the user can enter on the checkout page'
        },
        {
          label: 'Company for Account',
          field: 'orderTypes-OLO-customer_company',
          type: 'select',
          classes: '',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Company for Address',
          field: 'orderTypes-OLO-address_company',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Apt / Suite',
          field: 'orderTypes-OLO-address_unit',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person',
          field: 'orderTypes-OLO-address_contact',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person Phone',
          field: 'orderTypes-OLO-address_phone',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Order Notes',
          field: 'orderTypes-OLO-details_notes',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Person Count',
          field: 'orderTypes-OLO-details_count',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Eating Utensils',
          field: 'orderTypes-OLO-details_eatingUtensils',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Serving Utensils',
          field: 'orderTypes-OLO-details_servingUtensils',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Catering',
          field: 'CATERING',
          type: 'break'
        },
        {
          label: 'Promo Code Limit',
          field: 'orderTypes-CATERING-promo_code_limit',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          tooltip:
            'This controls how many promo codes the user can enter on the checkout page'
        },
        {
          label: 'Company for Account',
          field: 'orderTypes-CATERING-customer_company',
          type: 'select',
          classes: '',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Company for Address',
          field: 'orderTypes-CATERING-address_company',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Apt / Suite',
          field: 'orderTypes-CATERING-address_unit',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person',
          field: 'orderTypes-CATERING-address_contact',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person Phone',
          field: 'orderTypes-CATERING-address_phone',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Order Notes',
          field: 'orderTypes-CATERING-details_notes',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Person Count',
          field: 'orderTypes-CATERING-details_count',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Eating Utensils',
          field: 'orderTypes-CATERING-details_eatingUtensils',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Serving Utensils',
          field: 'orderTypes-CATERING-details_servingUtensils',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Merch',
          field: 'MERCH',
          type: 'break'
        },
        {
          label: 'Promo Code Limit',
          field: 'orderTypes-MERCH-promo_code_limit',
          type: 'text',
          valType: constants.valTypes.int,
          required: true,
          tooltip:
            'This controls how many promo codes the user can enter on the checkout page'
        },
        {
          label: 'Company for Account',
          field: 'orderTypes-MERCH-customer_company',
          type: 'select',
          classes: '',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Company for Address',
          field: 'orderTypes-MERCH-address_company',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Apt / Suite',
          field: 'orderTypes-MERCH-address_unit',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person',
          field: 'orderTypes-MERCH-address_contact',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Contact Person Phone',
          field: 'orderTypes-MERCH-address_phone',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Order Notes',
          field: 'orderTypes-MERCH-details_notes',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Person Count',
          field: 'orderTypes-MERCH-details_count',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Eating Utensils',
          field: 'orderTypes-MERCH-details_eatingUtensils',
          type: 'select',
          classes: '-half',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        },
        {
          label: 'Serving Utensils',
          field: 'orderTypes-MERCH-details_servingUtensils',
          type: 'select',
          classes: '-half -right',
          options: constants.checkoutFields,
          valType: constants.valTypes.nullable
        }
      ]
    }
  },
  '/config-settings/locations': {
    type: constants.pageType.config,
    title: 'Location Settings',
    subtitle: subtitles.configSettingsLocations,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'locations',
      fields: [
        {
          label: 'Display name for regular locations',
          field: 'name',
          type: 'text',
          required: true,
          tooltip: 'Typically either "location" or "restaurant"',
          classes: '-half'
        },
        {
          label: 'Plural name',
          field: 'namePlural',
          type: 'text',
          required: true,
          tooltip: 'Typically either "locations" or "restaurants"',
          classes: '-half -right'
        },
        {
          label: 'Display name for outpost locations',
          field: 'outpostName',
          type: 'text',
          required: true,
          tooltip: '"outpost" is most common',
          classes: '-half'
        },
        {
          label: 'Plural outpost name',
          field: 'outpostNamePlural',
          type: 'text',
          required: true,
          tooltip: '"outposts" is most common',
          classes: '-half -right'
        },
        {
          label: 'Display locations within how many miles of the customer?',
          field: 'maxDistance',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 3000,
          step: 10
        },
        {
          label: 'Automatic Location Selection - Online Ordering',
          field: 'OLO',
          type: 'break'
        },
        {
          label: 'Walk-in',
          field: 'autoSelect-OLO-WALKIN',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Pickup',
          field: 'autoSelect-OLO-PICKUP',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Delivery',
          field: 'autoSelect-OLO-DELIVERY',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Automatic Location Selection - Catering',
          field: 'CATERING',
          type: 'break'
        },
        {
          label: 'Walk-in',
          field: 'autoSelect-CATERING-WALKIN',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Pickup',
          field: 'autoSelect-CATERING-PICKUP',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Delivery',
          field: 'autoSelect-CATERING-DELIVERY',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Automatic Location Selection - Merch',
          field: 'MERCH',
          type: 'break'
        },
        {
          label: 'Walk-in',
          field: 'autoSelect-MERCH-WALKIN',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Pickup',
          field: 'autoSelect-MERCH-PICKUP',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Delivery',
          field: 'autoSelect-MERCH-DELIVERY',
          type: 'checkbox',
          required: true
        }
      ]
    }
  },
  '/config-settings/maps': {
    type: constants.pageType.config,
    title: 'Google Maps',
    subtitle: subtitles.configSettingsMaps,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'googleMaps',
      fields: [
        {
          label: 'Google Maps API Key',
          field: 'apiKey',
          type: 'text',
          required: true
        },
        {
          label: 'Google Maps API Key for Mobile App',
          field: 'apiKeyApp',
          type: 'text',
          required: true
        },
        {
          label: 'Default latitude for initial map load',
          field: 'latitude',
          type: 'text',
          required: true,
          valType: constants.valTypes.float,
          tooltip:
            "This will be used to display the Google Map if the customer's location has not yet been determined",
          classes: '-half'
        },
        {
          label: 'Default longitude for initial map load',
          field: 'longitude',
          type: 'text',
          required: true,
          valType: constants.valTypes.float,
          tooltip:
            "This will be used to display the Google Map if the customer's location has not yet been determined",
          classes: '-half -right'
        },
        {
          label: 'Map zoom',
          field: 'zoom',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 1,
          max: 20,
          step: 1,
          tooltip: '1 is most zoomed in and 20 is most zoomed out'
        },
        {
          label: 'Colors',
          field: 'colors',
          type: 'break'
        },
        {
          label: 'Labels',
          field: 'labelColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Roads',
          field: 'roadColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Features',
          field: 'featureColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Water',
          field: 'waterColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'Background',
          field: 'backgroundColor',
          type: 'inputColor',
          required: true
        },
        {
          label: 'User Position Icon',
          field: 'user',
          type: 'break'
        },
        {
          field: 'icons-user-url',
          type: 'hidden'
        },
        {
          label: 'Width (in pixels)',
          field: 'icons-user-width',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Height (in pixels)',
          field: 'icons-user-height',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Offset (in pixels)',
          field: 'icons-user-xOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Vertical Offset (in pixels)',
          field: 'icons-user-yOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Location Map Marker',
          field: 'location',
          type: 'break'
        },
        {
          field: 'icons-location-url',
          type: 'hidden'
        },
        {
          label: 'Width (in pixels)',
          field: 'icons-location-width',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Height (in pixels)',
          field: 'icons-location-height',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Offset (in pixels)',
          field: 'icons-location-xOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Vertical Offset (in pixels)',
          field: 'icons-location-yOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Selected Location Map Marker',
          field: 'locationSelected',
          type: 'break'
        },
        {
          field: 'icons-locationSelected-url',
          type: 'hidden'
        },
        {
          label: 'Width (in pixels)',
          field: 'icons-locationSelected-width',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Height (in pixels)',
          field: 'icons-locationSelected-height',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 20,
          max: 200,
          classes: '-half -right'
        },
        {
          label: 'Horizontal Offset (in pixels)',
          field: 'icons-locationSelected-xOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half'
        },
        {
          label: 'Vertical Offset (in pixels)',
          field: 'icons-locationSelected-yOffset',
          type: 'inputRange',
          valType: constants.valTypes.int,
          required: true,
          min: 0,
          max: 200,
          classes: '-half -right'
        }
      ]
    }
  },
  '/config-settings/recaptcha': {
    type: constants.pageType.config,
    title: 'Google Recaptcha',
    subtitle: subtitles.configSettingsRecaptcha,
    item: itemSettings.configSettings,
    config: {
      endpoint: '/order-app-config',
      type: 'config',
      key: null,
      section: 'recaptcha',
      fields: [
        {
          label: 'Add New Credit Card',
          field: 'addCard',
          type: 'checkbox',
          required: true,
          tooltip:
            'This form appears in a modal when a logged in user adds a new credit card to their account from their account page.'
        },
        {
          label: 'Donations Page',
          field: 'donations',
          type: 'checkbox',
          required: true
        },
        {
          label: 'Gift Cards Page',
          field: 'giftCards',
          type: 'checkbox',
          required: true
        }
      ]
    }
  },
  '/super': {
    type: constants.pageType.content,
    title: 'Super Admin Reports',
    subtitle: 'Various reports available only to super admin users',
    content: '',
    image: { url: images.reporting, position: 'center center' },
    sections: [
      {
        name: 'section-01',
        subsections: [
          {
            title: 'Sales by Month',
            description: subtitles.superSalesByMonth,
            endpoint: '/super/sales-by-month'
          }
        ]
      }
    ]
  },
  '/super/sales-by-month': {
    type: constants.pageType.report,
    title: 'Sales & Checks by Month',
    subtitle: subtitles.superSalesByMonth,
    report: {
      reportType: 'salesByMonth',
      endpoints: ['/super/sales-by-month'],
      dateFilters: false,
      filters: [filters.year]
    }
  }
}

export const items = Object.keys(pages)
  .filter(i => i.slice(-4) === '/:id')
  .map(i => i.split('/')[1])

export const itemLists = Object.keys(pages)
  .filter(i => i.indexOf('/:id') !== -1 && i.slice(-4) !== '/:id')
  .map(i => ({ parent: i.split('/')[1], child: i.split('/')[3] }))

export const itemListItems = Object.keys(pages)
  .filter(i => i.indexOf('/:id') !== -1 && i.indexOf('/:idd') !== -1)
  .map(i => ({ parent: i.split('/')[1], child: i.split('/')[3] }))

export default pages
