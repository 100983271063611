import { minutesToTime } from './helpersDatetime'

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

export const RADIUS_MILES = 3959 // radius of the earth in miles
export const RADIUS_KM = 6371 // radius of the earth in kilometers

// MAPS

export const timezoneMap = {
  'US/Eastern': 'America/New_York',
  'US/Central': 'America/Chicago',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles'
}

export const pageType = {
  content: 'content',
  setting: 'setting',
  list: 'list',
  item: 'item',
  itemList: 'itemList',
  receiptList: 'receiptList',
  itemLists: 'itemLists',
  itemSetting: 'itemSetting',
  itemFiles: 'itemFiles',
  itemRelations: 'itemRelations',
  itemRelation: 'itemRelation',
  relatedItem: 'relatedItem',
  recommendedItems: 'recommendedItems',
  listOfItems: 'listOfItems',
  avails: 'avails',
  dailyItems: 'dailyItems',
  inventory: 'inventory',
  dayparts: 'dayparts',
  attributes: 'attributes',
  itemAttributes: 'itemAttributes',
  revenueCenterAttributes: 'revenueCenterAttributes',
  deliveryZone: 'deliveryZone',
  menu: 'menu',
  reward: 'reward',
  giftCard: 'giftCard',
  receipt: 'receipt',
  order: 'order',
  discountItems: 'discountItems',
  hours: 'hours',
  holidays: 'holidays',
  blockedHours: 'blockedHours',
  orderTimes: 'orderTimes',
  punches: 'punches',
  iframe: 'iframe',
  orders: 'orders',
  orderMgmt: 'orderMgmt',
  orderMgmtStores: 'orderMgmtStores',
  mapping: 'mapping',
  menuExt: 'menuExt',
  menuExtUpdate: 'menuExtUpdate',
  menuExtUpdates: 'menuExtUpdates',
  entityExt: 'entityExt',
  report: 'report',
  import: 'import',
  importErrors: 'importErrors',
  sync: 'sync',
  hoursForWeek: 'hoursForWeek',
  config: 'config',
  customer: 'customer'
}

export const pageClass = {
  content: 'app--content',
  setting: 'app--item',
  list: 'app--list',
  item: 'app--item',
  itemList: 'app--item-list app--nav app--cards',
  receiptList: 'app--item',
  itemLists: 'app--item',
  itemSetting: 'app--item',
  itemFiles: 'app--item',
  itemRelations: 'app--item app--relations app--cards',
  itemRelation: 'app--item',
  relatedItem: 'app--item',
  recommendedItems: 'app--item',
  dayparts: 'app--item',
  avails: '',
  dailyItems: '',
  inventory: 'app--nav',
  attributes: '',
  itemAttributes: 'app--item',
  revenueCenterAttributes: 'app--nav',
  deliveryZone: 'app--item',
  menu: 'app--nav',
  reward: '',
  giftCard: '',
  receipt: 'app--item',
  order: 'app--order',
  discountItems: 'app--nav',
  hours: 'app--item',
  holidays: 'app--item',
  blockedHours: 'app--item',
  orderTimes: 'app--item',
  punches: '',
  iframe: 'app--nav',
  orders: '',
  orderMgmt: '',
  orderMgmtStores: '',
  mapping: 'app--item',
  menuExt: 'app--item',
  menuExtUpdate: 'app--item',
  menuExtUpdates: 'app--item',
  entityExt: 'app--item',
  report: '',
  import: 'app--item',
  importErrors: 'app--nav',
  sync: 'app--item',
  listOfItems: 'app--item',
  hoursForWeek: '',
  config: 'app--item'
}

export const valTypes = {
  nullable: 'nullable',
  bool: 'bool',
  int: 'int',
  intNullable: 'intNullable',
  float: 'float',
  decimal: 'decimal',
  decimalNullable: 'decimalNullable',
  arrayString: 'arrayString',
  arrayInt: 'arrayInt',
  arrayObjects: 'arrayObjects',
  time: 'time',
  timeNullable: 'timeNullable',
  dateNullable: 'dateNullable',
  wysiwyg: 'wysiwyg'
}

// SELECT OPTIONS

export const limit = [
  { value: 10, name: '10' },
  { value: 25, name: '25' },
  { value: 50, name: '50' },
  { value: 100, name: '100' },
  { value: 200, name: '200' },
  { value: 500, name: '500' },
  { value: 1000, name: '1000' }
]

export const timezones = [
  { value: '', name: 'All' },
  { value: 'US/Eastern', name: 'Eastern' },
  { value: 'US/Central', name: 'Central' },
  { value: 'US/Mountain', name: 'Mountain' },
  { value: 'US/Pacific', name: 'Pacific' }
]

// sections of the admin portal that users can access
export const sections = [
  { value: '', name: 'All' },
  { value: 'LABOR', name: 'People (including Timeclock)' },
  { value: 'LOCATIONS', name: 'Stores' },
  { value: 'MENUS', name: 'Menus' },
  { value: 'ORDERS', name: 'Orders' },
  {
    value: 'CUSTOMERS',
    name: 'Customers (including House Accounts, Loyalty, and Gift Cards)'
  },
  { value: 'REPORTING', name: 'Reporting' },
  { value: 'WEBSITE', name: 'Content' },
  { value: 'SETTINGS', name: 'Settings' },
  { value: 'USERS', name: 'Users' }
]

export const netSalesDefintion = [
  // { value: 'SUBTOTAL', name: 'Subtotal' },
  { value: 'SURCHARGE', name: 'Surcharge' },
  { value: 'DISCOUNT', name: 'Discount' }
  // { value: 'GIFT_CARD', name: 'Gift Card' },
  // { value: 'TAX', name: 'Tax' },
  // { value: 'TIP', name: 'Tip' },
  // { value: 'SHIPPING', name: 'Shipping' }
]

export const defaultNetSalesDefinition = ['SURCHARGE', 'DISCOUNT']

const tenderTypes = [
  'CASH',
  'CREDIT',
  'LEVELUP',
  'HOUSE_ACCOUNT',
  'GIFT_CARD',
  'COLUMBIA',
  'BRANDIBBLE',
  'SEAMLESS',
  'GRUBHUB',
  'COMP',
  'COMO',
  'INTERNAL',
  'DOORDASH',
  'UBEREATS',
  'RITUAL',
  'EATSA',
  'FOODA',
  'CAVIAR',
  'GIFT_CARD_EXT',
  'LEYE_FDP',
  'GWORLD',
  'POSTMATES',
  'BITE_SQUAD',
  'VOUCHER',
  'MEALPAL',
  'BAO_DAY',
  'GRUBHUB_WORK',
  'EZCATER',
  'CATER2ME',
  'DELIVERY_WOW',
  'TOCK',
  'APPLE_PAY',
  'GOOGLE_PAY',
  'FOODSBY',
  'GRUBBRR'
]

export const tenderTypeOptions = tenderTypes.map(value => {
  const name = value
    .split('_')
    .map(x => capitalize(x))
    .join(' ')
  return { value: value, name: name }
})

export const tenderTypeAll = [{ value: '', name: 'All' }, ...tenderTypeOptions]

export const tenderTypesMap = tenderTypes.reduce((obj, value) => {
  obj[value] = value
    .split('_')
    .map(x => capitalize(x))
    .join(' ')
  return obj
}, {})

export const cardTypes = [
  'None',
  'Visa',
  'Mastercard',
  'Discover',
  'Amex',
  'Other'
]

export const cardTypeOptions = [
  { value: 'VISA', name: 'Visa' },
  { value: 'MC', name: 'Mastercard' },
  { value: 'DISC', name: 'Discover' },
  { value: 'AMEX', name: 'Amex' },
  { value: 'OTHER', name: 'Other' }
]

export const cardTypeFiler = [{ value: '', name: 'All' }, ...cardTypeOptions]

export const active = [
  { value: '', name: 'All' },
  { value: true, name: 'Active' },
  { value: false, name: 'Inactive' }
]

export const status = [
  { value: '', name: 'All' },
  { value: 'INACTIVE', name: 'Inactive' },
  { value: 'ACTIVE', name: 'Active' }
]

export const isOwner = [
  { value: true, name: 'Yes, is owner' },
  { value: false, name: 'No, is NOT owner' }
]

export const closed = [
  { value: '', name: 'All' },
  { value: false, name: 'Open' },
  { value: true, name: 'Closed' }
]

export const isOutpost = [
  { value: '', name: 'All' },
  { value: true, name: 'Outposts Only' },
  { value: false, name: 'Exclude Outposts' }
]

export const roles = [
  { value: '', name: 'All' },
  { value: 'ADMIN', name: 'Admin' },
  { value: 'MANAGER', name: 'Manager' },
  { value: 'CASHIER', name: 'Cashier' },
  { value: 'EMPLOYEE', name: 'Employee' }
]

export const employeeRoles = roles.filter(r => r.value !== 'ADMIN')

export const employeeStatus = [
  { value: '', name: 'All' },
  { value: 'INACTIVE', name: 'Inactive' },
  { value: 'ACTIVE', name: 'Active' },
  { value: 'TERMINATED', name: 'Terminated' },
  { value: 'ON_LEAVE', name: 'On Leave' },
  { value: 'RETIRED', name: 'Retired' }
]

export const employeeTypes = [
  { value: '', name: 'All' },
  { value: 'NONE', name: 'None' },
  { value: 'FULL_TIME', name: 'Full Time' },
  { value: 'PART_TIME', name: 'Part Time' },
  { value: 'TEMPORARY', name: 'Temporary' },
  { value: 'SEASONAL', name: 'Seasonal' }
]

export const employeePaymentTypes = [
  { value: 'NONE', name: 'None' },
  { value: 'SALARY', name: 'Salary' },
  { value: 'HOURLY', name: 'Hourly' },
  { value: 'NYR', name: 'NYR' },
  { value: 'BOH', name: 'BOH' }
]

export const serviceTypes = ['WALKIN', 'PICKUP', 'DELIVERY']
export const serviceTypesIndexed = ['NONE', 'PICKUP', 'DELIVERY', 'WALKIN']

export const serviceType = [
  { value: '', name: 'All' },
  { value: 'WALKIN', name: 'Walk-In' },
  { value: 'PICKUP', name: 'Pick-Up' },
  { value: 'DELIVERY', name: 'Delivery' }
]

export const serviceTypeNull = [
  { value: null, name: 'All' },
  { value: 'WALKIN', name: 'Walk-In' },
  { value: 'PICKUP', name: 'Pick-Up' },
  { value: 'DELIVERY', name: 'Delivery' }
]

export const serviceTypeAll = [
  { value: 'ALL', name: 'All' },
  { value: 'WALKIN', name: 'Walk-In' },
  { value: 'PICKUP', name: 'Pick-Up' },
  { value: 'DELIVERY', name: 'Delivery' }
]

export const orderTypes = ['MAIN_MENU', 'CATERING', 'MERCH']
export const orderTypesIndexed = ['NONE', ...orderTypes]

export const orderType = [
  { value: '', name: 'All' },
  { value: 'MAIN_MENU', name: 'Main Menu' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'MERCH', name: 'Merch' }
]

export const orderTypeNames = {
  MAIN_MENU: 'Main Menu',
  CATERING: 'Catering',
  MERCH: 'Merch'
}

export const revenueCenterOrderTypeMap = {
  OLO: 'MAIN_MENU',
  CATERING: 'CATERING',
  MERCH: 'MERCH',
  POS: 'MAIN_MENU',
  KIOSK: 'MAIN_MENU'
}

export const orderTypeRevenueCenterMap = {
  MAIN_MENU: 'OLO',
  CATERING: 'CATERING',
  MERCH: 'MERCH'
}

export const orderSource = [
  { value: '', name: 'All' },
  { value: 'BRANDIBBLE', name: 'Open Tender' },
  { value: 'EATSA', name: 'Brightloom' },
  { value: 'CHOWLY', name: 'Chowly' }
]

export const receiptType = [
  { value: '', name: 'All' },
  { value: 'SALE', name: 'Sale' },
  { value: 'REFUND', name: 'Refund' }
]

export const salesMetric = [
  { value: 'CHANNEL', name: 'Sales Channel' },
  { value: 'ORDER_TYPE', name: 'Order Type' },
  { value: 'SERVICE_TYPE', name: 'Service Type' },
  { value: 'DEVICE_TYPE', name: 'Device Type' }
]

export const redemptionsGroupBy = [
  { value: 'QRCODE', name: 'QR Code' },
  { value: 'STORE', name: 'Store' }
]

export const channelType = [
  { value: '', name: 'All' },
  { value: 'POS', name: 'POS' },
  { value: 'ONLINE', name: 'Online' },
  { value: 'APP', name: 'App' },
  { value: 'KIOSK', name: 'Kiosk' },
  { value: 'PORTAL', name: 'Portal' }
]

export const channelTypeClient = [
  { value: '', name: 'select channel' },
  { value: 'ONLINE', name: 'Online' },
  { value: 'APP', name: 'App' },
  { value: 'KIOSK', name: 'Kiosk' }
]

export const categoryType = [
  { value: '', name: 'All' },
  { value: 'OLO', name: 'Main Menu' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'MERCH', name: 'Merch' }
]

export const bothOrderType = [
  { value: 'BOTH_OLO_CATERING', name: 'All' },
  { value: 'OLO_ONLY', name: 'Main Menu' },
  { value: 'CATERING_ONLY', name: 'Catering' }
]

export const receiptStatus = [
  { value: '', name: 'All' },
  { value: 'OPEN', name: 'Open' },
  { value: 'HELD', name: 'Held' },
  { value: 'CLOSED', name: 'Closed' },
  { value: 'VOID', name: 'Void' }
]

export const tenderStatus = [
  { value: '', name: 'All' },
  { value: 'AUTHORIZED', name: 'Authorized' },
  { value: 'PAID', name: 'Paid' },
  { value: 'REFUNDED', name: 'Refunded' },
  { value: 'VOID', name: 'Void' }
]

export const categoryAppearance = [
  { value: 'SMALL', name: 'Small' },
  { value: 'MEDIUM', name: 'Medium' },
  { value: 'LARGE', name: 'Large' }
]

export const temperature = [
  { value: 'COLD', name: 'Cold' },
  { value: 'HOT', name: 'Hot' }
]

export const attributes = [
  { value: 'PRICE', name: 'Price' },
  { value: 'POINTS', name: 'Points' },
  { value: 'SERVING_SIZE', name: 'Serving Size' },
  { value: 'MIN_QUANTITY', name: 'Minimum Quantity' },
  { value: 'MAX_QUANTITY', name: 'Maximum Quantity' },
  { value: 'INCREMENT', name: 'Increment' },
  { value: 'POS_ID', name: 'POS ID' }
]

export const surchargeType = [
  { value: 'DOLLAR', name: 'Dollar' },
  { value: 'PERCENTAGE', name: 'Percentage' },
  { value: 'DISTANCE', name: 'Distance' },
  { value: 'TRAVEL_TIME', name: 'Travel Time' }
]

export const travelType = [
  { value: 'DRIVING', name: 'Driving' },
  { value: 'WALKING', name: 'Walking' },
  { value: 'BICYCLING', name: 'Bicycling' },
  { value: 'TRANSIT', name: 'Public Transit' }
]

export const discountType = [
  { value: 'DOLLAR', name: 'Dollar' },
  { value: 'PERCENTAGE', name: 'Percentage' },
  { value: 'LOYALTY', name: 'Loyalty' }
]

export const discountAuthType = [
  { value: '', name: 'All customers (including guests)' },
  { value: 'ACCOUNT', name: 'Customers with accounts' },
  { value: 'VERIFIED', name: 'Customers with verified accounts' }
]

// TODO: this needs to be populated by the API when we have a POS sections endpoint
export const posSection = [{ value: 0, name: 'none selected' }]

export const pageStatus = [
  { value: '', name: 'All' },
  { value: 'DRAFT', name: 'Draft' },
  { value: 'PUBLISHED', name: 'Published' },
  { value: 'PRIVATE', name: 'Private' },
  { value: 'TRASHED', name: 'Trashed' }
]

export const templateType = [
  { value: '', name: 'All' },
  { value: 'PAGE', name: 'Page' },
  { value: 'POST', name: 'Post' }
]

export const fieldTypes = [
  { value: 'text', name: 'Text' },
  { value: 'textarea', name: 'Textarea' },
  { value: 'email', name: 'Email' },
  { value: 'select', name: 'Select' },
  { value: 'checkbox', name: 'Checkbox' },
  { value: 'radio', name: 'Radio' },
  { value: 'datepicker', name: 'Datepicker' },
  { value: 'break', name: 'Break' }
]

const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

const PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT'
]

export const states = STATES.map(state => ({ value: state, name: state }))
export const statesWithNone = [{ value: '', name: 'none selected' }, ...states]

export const provinces = PROVINCES.map(province => ({
  value: province,
  name: province
}))
export const statesProvinces = [
  ...states,
  { value: '', name: '', disabled: true },
  ...provinces
]

export const statesProvincesWithNone = [
  { value: '', name: 'none selected' },
  ...statesProvinces
]

export const revenueCenters = [
  { value: '', name: 'All' },
  { value: 'OLO', name: 'OLO' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'MERCH', name: 'Merch' },
  { value: 'POS', name: 'POS' }
  // { value: 'KIOSK', name: 'Kiosk' }
]

export const daysAhead = [
  { value: '', name: 'Use global setting' },
  { value: 0, name: '0 days in advance' },
  { value: 1, name: '1 days in advance' },
  { value: 2, name: '2 days in advance' },
  { value: 3, name: '3 days in advance' },
  { value: 4, name: '4 days in advance' },
  { value: 5, name: '5 days in advance' },
  { value: 6, name: '6 days in advance' }
]

// https://dev.to/ycmjason/how-to-create-range-in-javascript-539i
// NOTE: generator functions supposedly can't be imported
function* range(start, end, step) {
  for (let i = start; i <= end; i += step) {
    yield i
  }
}

const times30 = [...range(30, 1410, 30)].map(minutes => ({
  value: minutes,
  name: minutesToTime(minutes)
}))
export const cutoffTimes = [{ value: '', name: 'No Cutoff' }, ...times30]

export const waitTimeRange = [
  { value: 0, name: 'None' },
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' }
]

export const initialReceiptStatus = [
  { value: 'OPEN', name: 'Open' },
  { value: 'PENDING', name: 'Pending' }
]

export const printerType = [
  { value: 'DESKTOP', name: 'Desktop Printer' },
  { value: 'RECEIPT', name: 'Receipt Printer' }
]

export const largeOrderTiming = [
  { value: 'NOW', name: 'Immediately when order is placed' },
  { value: 'DAY_OF', name: 'Beginning of the day' }
]

export const gratuityServiceTypes = [
  { value: 'NEITHER', name: 'Neither Pick-up nor Delivery' },
  { value: 'PICKUP', name: 'Pick-up Only' },
  { value: 'DELIVERY', name: 'Delivery Only' },
  { value: 'BOTH', name: 'Both Pick-up and Delivery' }
]

export const creditIntegrations = [
  { value: 'NONE', name: 'None' },
  { value: 'NMI', name: 'Open Tender Gateway' },
  { value: 'VANTIV', name: 'Worldpay' },
  { value: 'CARDCONNECT', name: 'CardConnect' }
]

export const signatureThresholds = [
  { value: '', name: 'None' },
  { value: '0.00', name: '$0.00 (all transactions)' },
  { value: '25.00', name: '$25.00' },
  { value: '50.00', name: '$50.00' }
]

export const posIntegrations = [
  { value: 'NONE', name: 'None' },
  { value: 'CHOWLY_TOOS', name: 'Chowly' },
  { value: 'OTTER', name: 'Otter' },
  { value: 'CHECKMATE', name: 'Checkmate' },
  { value: 'CHECKMATE_TOOS', name: 'Checkmate TOOS' },
  { value: 'REVEL', name: 'Revel' },
  { value: 'SALIDO', name: 'Salido' },
  { value: 'GUSTO', name: 'Qu (formerly Gusto)' },
  { value: 'RPOWER', name: 'RPower' },
  { value: 'EATSA', name: 'Brightloom (formerly Eatsa)' },
  { value: 'WEBHOOK', name: 'Webhook' }
]

export const posSendTiming = [
  { value: 'PLACED', name: 'When placed by customer' },
  { value: 'FIRED', name: 'When time to make the order' },
  { value: 'CONFIRMED', name: 'When manually confirmed' }
]

export const checkmateEnv = [
  { value: 'PRODUCTION', name: 'Production' },
  { value: 'STAGING', name: 'Staging' },
  { value: 'SANDBOX', name: 'Sandbox' }
]

export const posAuthType = [
  { value: 'PIN', name: 'Enter Employee ID' },
  { value: 'CARD', name: 'Employee Swipe Card' }
]

export const deliveryIntegrations = [
  { value: 'NONE', name: 'None' },
  { value: 'RELAY', name: 'Relay' },
  { value: 'DOORDASH', name: 'DoorDash' },
  { value: 'UBER_DIRECT', name: 'Uber Direct' },
  { value: 'ONFLEET', name: 'Onfleet' }
]

export const deliveryServiceTypes = [
  { value: 'NONE', name: 'None' },
  { value: 'DELIVERY', name: 'Delivery' },
  { value: 'PICKUP', name: 'Pick-up' },
  { value: 'BOTH_PICKUP_DELIVERY', name: 'Both Pick-up and Delivery' }
]

export const paidInsOuts = [
  { value: 'PAID_IN', name: 'Paid In' },
  { value: 'PAID_OUT', name: 'Paid Out' }
]

export const prepTimes = [...range(0, 60, 5)].map(minutes => ({
  value: minutes,
  name: `${minutes} minutes`
}))

const minutes10 = [...range(1, 10, 1)].map(minutes => ({
  value: minutes * 60,
  name: `${minutes} minutes`
}))
export const posPageTimeout = [{ value: 0, name: 'Never' }, ...minutes10]

export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const weekdaysUpper = weekdays.map(weekday => weekday.toUpperCase())
export const weekdaysLower = weekdays.map(weekday => weekday.toLowerCase())

export const weekdayOptions = weekdays.map(weekday => ({
  value: weekday.toUpperCase(),
  name: weekday
}))

export const weekdaysShort = {
  MONDAY: 'Mon',
  TUESDAY: 'Tues',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thurs',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun'
}

export const objectTypes = {
  revenue_centers: revenueCenters
}

export const punchTypes = ['PUNCH_IN', 'BREAK_OUT', 'BREAK_IN', 'PUNCH_OUT']

export const punchInTypes = [
  { value: 'PUNCH_IN', name: 'Punch-In' },
  { value: 'BREAK_IN', name: 'Break-In' }
]

export const punchOutTypes = [
  { value: 'PUNCH_OUT', name: 'Punch-Out' },
  { value: 'BREAK_OUT', name: 'Break-Out' }
]

export const printReceiptOptions = [
  { value: 'NEVER', name: 'Never' },
  { value: 'PROMPT', name: 'Prompt' },
  { value: 'ALWAYS', name: 'Always' }
]

export const fontChoices = [
  { value: 'ARIAL', name: 'Arial' },
  { value: 'ARIAL_BLACK', name: 'Arial Black' },
  { value: 'ARIAL_NARROW', name: 'Arial Narrow' },
  { value: 'COMIC_SANS', name: 'Comic Sans' },
  { value: 'COURIER_NEW', name: 'Courier New' },
  { value: 'GENEVA', name: 'Geneva' },
  { value: 'GEORGIA', name: 'Georgia' },
  { value: 'HELVETICA', name: 'Helvetica Neue' },
  { value: 'LUCIDA_CONSOLE', name: 'Lucida Console' },
  { value: 'LUCIDA_SANS', name: 'Lucida Sans' },
  { value: 'PALANTINO', name: 'Palantino' },
  { value: 'TAHOMA', name: 'Tahoma' },
  { value: 'TIMES_NEW_ROMAN', name: 'Times New Roman' },
  { value: 'TREBUCHET_MS', name: 'Trebuchet MS' },
  { value: 'VERDANA', name: 'Verdana' }
]

export const fontWeights = [
  { value: 'NORMAL', name: 'Normal' },
  { value: 'BOLD', name: 'Bold' },
  { value: 'BOLDER', name: 'Bolder' },
  { value: 'LIGHTER', name: 'Lighter' }
]

export const fontCapitalization = [
  { value: 'NONE', name: 'None' },
  { value: 'CAPITALIZE', name: 'Capitalize' },
  { value: 'UPPERCASE', name: 'Uppercase' },
  { value: 'LOWERCASE', name: 'Lowercase' }
]

export const fontAlignment = [
  { value: 'LEFT', name: 'Left' },
  { value: 'CENTER', name: 'Center' },
  { value: 'RIGHT', name: 'Right' }
]

export const loyaltyProgramTypes = [
  { value: 'CREDIT', name: 'Credit' }
  // { value: 'PROMO_CODE', name: 'Promo Code' }
]

export const loyaltySpendTypes = [
  { value: 'DOLLARS', name: 'Spend' },
  { value: 'POINTS', name: 'Points' }
  // { value: 'ORDERS', name: 'Frequency' }
]

export const loyaltyOrderTypes = [
  { value: 'ALL', name: 'All' },
  { value: 'MAIN_MENU', name: 'Main Menu' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'MERCH', name: 'Merch' }
]

export const taskSatusTypes = [
  { value: '', name: 'All' },
  { value: 'QUEUED', name: 'Queued' },
  { value: 'RUNNING', name: 'Running' },
  { value: 'SUCCEEDED', name: 'Succeeded' },
  { value: 'FAILED', name: 'Failed' }
]

export const emailTypes = [
  { value: 'INITIAL', name: 'Initial' },
  { value: 'RESEND', name: 'Resend' },
  { value: 'FINAL', name: 'Final' },
  { value: 'CANCEL', name: 'Cancel' },
  { value: 'REFUND', name: 'Refund' },
  { value: 'POS', name: 'POS' }
]

export const emailTypesArray = emailTypes.map(i => i.value)

export const orderNotiificationTypes = [
  { value: 'NONE', name: 'none selected' },
  { value: 'IN_ASSEMBLY_QUEUE', name: 'In Assembly Queue' },
  { value: 'IN_PROGRESS', name: 'In Progress' },
  { value: 'DELAYED', name: 'Delayed' },
  { value: 'DONE', name: 'Done' },
  { value: 'COMPLETED', name: 'Completed' },
  { value: 'FULFILLED', name: 'Fulfilled' }
]

export const orderNotiificationTypesArray = orderNotiificationTypes
  .filter(i => i.value !== 'NONE')
  .map(i => i.value)

export const refreshStartPageTypes = [
  { value: 'NEW_ORDERS', name: 'New Orders' },
  { value: 'OLD_ORDERS', name: 'Old Orders' }
]

export const orderRefreshTypes = [
  { value: 'NONE', name: "Don't refresh any pages automatically" },
  { value: 'NEW_ORDERS_PAGE', name: 'Only refresh the New Orders page' },
  { value: 'ALL_PAGES', name: 'Refresh all pages after 2 minutes' }
]

const notificationDelayMinutes = [...range(1, 20, 1)].map(minutes => ({
  value: minutes,
  name: `Sent ${minutes} minutes after order hits`
}))
export const notificationDelay = [
  { value: 0, name: 'Send immediately' },
  ...notificationDelayMinutes
]

export const requiredOloFields = [
  { value: 'CUSTOMER_COMPANY', name: 'Account Company' },
  { value: 'COMPANY', name: 'Address Company' },
  { value: 'APT', name: 'Unit / Suite' },
  { value: 'CONTACT', name: 'Delivery Contact' },
  { value: 'CONTACT_PHONE', name: 'Delivery Contact Phone' },
  { value: 'CATERING_COUNT', name: 'Person Count' },
  { value: 'STORE_NOTES', name: 'Notes' }
]

export const requiredCateringFields = [
  { value: 'CUSTOMER_COMPANY', name: 'Account Company' },
  { value: 'COMPANY', name: 'Address Company' },
  { value: 'APT', name: 'Unit / Suite' },
  { value: 'CONTACT', name: 'Delivery Contact' },
  { value: 'CONTACT_PHONE', name: 'Delivery Contact Phone' },
  { value: 'CATERING_COUNT', name: 'Person Count' },
  { value: 'STORE_NOTES', name: 'Notes' }
]

export const autoCloseTimes = [
  {
    value: '',
    name: 'No, do not automatically close catering orders'
  },
  { value: '22:00:00', name: '10:00 PM' },
  { value: '22:15:00', name: '10:15 PM' },
  { value: '22:30:00', name: '10:30 PM' },
  { value: '22:45:00', name: '10:45 PM' },
  { value: '23:00:00', name: '11:00 PM' },
  { value: '23:15:00', name: '11:15 PM' },
  { value: '23:30:00', name: '11:30 PM' }
]

export const months = [
  { value: '', name: 'choose a month' },
  { value: '01', name: '01 - January' },
  { value: '02', name: '02 - February' },
  { value: '03', name: '03 - March' },
  { value: '04', name: '04 - April' },
  { value: '05', name: '05 - May' },
  { value: '06', name: '06 - June' },
  { value: '07', name: '07 - July' },
  { value: '08', name: '08 - August' },
  { value: '09', name: '09 - September' },
  { value: '10', name: '10 - October' },
  { value: '11', name: '11 - November' },
  { value: '12', name: '12 - December' }
]

export const years = [
  { value: '', name: 'choose a year' },
  { value: '20', name: '20 - 2020' },
  { value: '21', name: '21 - 2021' },
  { value: '22', name: '22 - 2022' },
  { value: '23', name: '23 - 2023' },
  { value: '24', name: '24 - 2024' },
  { value: '25', name: '25 - 2025' },
  { value: '26', name: '26 - 2026' },
  { value: '27', name: '27 - 2027' },
  { value: '28', name: '28 - 2028' },
  { value: '29', name: '29 - 2029' },
  { value: '30', name: '30 - 2030' },
  { value: '31', name: '31 - 2031' },
  { value: '32', name: '32 - 2032' },
  { value: '33', name: '33 - 2033' },
  { value: '34', name: '34 - 2034' },
  { value: '35', name: '35 - 2035' }
]

export const ordersGroupBy = [
  { value: 'business_date', name: 'Date' },
  { value: 'revenue_center.store_id', name: 'Store' }
]

export const extApiTypeMap = {
  POS: 'POS',
  PAYMENTS: 'Payments',
  PAYROLL: 'Payroll',
  DELIVERY: 'Delivery',
  SHIPPING: 'Shipping',
  CRM: 'CRM',
  LOYALTY: 'Loyalty',
  OTHER: 'Other'
}

export const posIntegrationStatus = [
  { value: 'OFF', name: 'Inactive' },
  { value: 'SANDBOX', name: 'Sandbox' },
  { value: 'LIVE', name: 'Live' }
]

export const integrationStatus = [
  { value: 'OFF', name: 'Inactive' },
  { value: 'LIVE', name: 'Live' }
]

export const assignmentType = [
  { value: 'MANUAL', name: 'Manual' },
  { value: 'LOAD', name: 'Automatic (based on load)' },
  { value: 'DISTANCE', name: 'Automatic (based on distance)' }
]

export const mapStyles = [
  { value: 0, name: 'Default' },
  { value: 1, name: 'Custom Styles (set below)' }
  // { value: 2, name: 'Custom Style Link' }
]

export const paymentProcessors = [
  { value: 'NMI', name: 'Open Tender Gateway' },
  { value: 'CARDCONNECT', name: 'CardConnect' },
  { value: 'VANTIV', name: 'Worldpay' }
]

export const revelIdType = [
  { value: 'barcode', name: 'Barcode' },
  { value: 'sku', name: 'SKU' }
]

export const timeChunks = [
  { value: 15, name: '15 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 60, name: '60 minutes' }
]

export const timePeriods = [
  { value: 'DAY', name: 'Day' },
  { value: 'WEEK', name: 'Week' },
  { value: 'MONTH', name: 'Month' }
]

export const dateRangeValues = [
  { value: 'today', name: 'Today' },
  { value: 'yesterday', name: 'Yesterday' },
  { value: 'thisWeek', name: 'This Week' },
  { value: 'lastWeek', name: 'Last Week' },
  { value: 'thisMonth', name: 'This Month' },
  { value: 'lastMonth', name: 'Last Month' }
]

export const orderRatings = [
  { value: '', name: 'none selected' },
  { value: 1, name: '1 star' },
  { value: 2, name: '2 stars' },
  { value: 3, name: '3 stars' },
  { value: 4, name: '4 stars' },
  { value: 5, name: '5 stars' }
]

export const entityType = [
  { value: '', name: 'All Items' },
  { value: 'MENU_ITEM', name: 'Menu Items' },
  { value: 'MODIFIER', name: 'Modifiers' }
]

export const year = [
  { value: '2024', name: '2024' },
  { value: '2023', name: '2023' },
  { value: '2022', name: '2022' },
  { value: '2021', name: '2021' },
  { value: '2020', name: '2020' },
  { value: '2019', name: '2019' },
  { value: '2018', name: '2018' },
  { value: '2017', name: '2017' },
  { value: '2016', name: '2016' },
  { value: '2015', name: '2015' }
]

export const textDecoration = [
  { value: 'none', name: 'None' },
  { value: 'underline', name: 'Underline' },
  { value: 'line-through', name: 'Line Through' },
  { value: 'overline', name: 'Overline' },
  { value: 'inherit', name: 'Inherit' }
]

export const textTransform = [
  { value: 'none', name: 'None' },
  { value: 'uppercase', name: 'Uppercase' },
  { value: 'lowercase', name: 'Lowercase' },
  { value: 'capitalize', name: 'Capitalize' }
]

export const borderStyle = [
  { value: 'none', name: 'None' },
  { value: 'solid', name: 'Solid' },
  { value: 'dotted', name: 'Dotted' },
  { value: 'dashed', name: 'Dashed' },
  { value: 'double', name: 'Double' },
  { value: 'groove', name: 'Groove' },
  { value: 'ridge', name: 'Ridge' },
  { value: 'inset', name: 'Inset' },
  { value: 'outset', name: 'Outset' }
]

export const fontSmoothing = [
  { value: 'none', name: 'none' },
  { value: 'auto', name: 'auto' },
  { value: 'antialiased', name: 'antialiased' },
  { value: 'subpixel-antialiased', name: 'subpixel-antialiased' }
]

export const themeFontWeights = [
  { value: 100, name: '100' },
  { value: 200, name: '200' },
  { value: 300, name: '300' },
  { value: 400, name: 'Normal' },
  { value: 500, name: '500' },
  { value: 600, name: '600' },
  { value: 700, name: 'Bold' },
  { value: 800, name: '800' },
  { value: 900, name: '900' }
]

export const textAlign = [
  { value: 'left', name: 'Left' },
  { value: 'center', name: 'Center' },
  { value: 'right', name: 'Right' },
  { value: 'justify', name: 'Justify' }
]

export const justifyContent = [
  { value: 'flex-start', name: 'Left' },
  { value: 'center', name: 'Center' },
  { value: 'flex-end', name: 'Right' }
]

export const alignItems = [
  { value: 'flex-start', name: 'Top' },
  { value: 'center', name: 'Center' },
  { value: 'flex-end', name: 'Bottom' }
]

export const lineType = [
  { value: 'none', name: 'None' },
  { value: 'overline', name: 'Overline' },
  { value: 'underline', name: 'Underline' }
]

export const appOrdertTypes = [
  { value: 'WALKIN', name: 'Walk-In' },
  { value: 'PICKUP', name: 'Pick-Up' },
  { value: 'DELIVERY', name: 'Delivery' },
  { value: 'OUTPOST', name: 'Outpost' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'GIFT_CARDS', name: 'Gift Cards' },
  { value: 'DONATIONS', name: 'Donations' },
  { value: 'MERCH', name: 'Merch' }
]

export const checkoutFields = [
  { value: '', name: 'Not Displayed' },
  { value: 'DISPLAYED', name: 'Displayed' },
  { value: 'REQUIRED', name: 'Required' }
]

export const prepStatus = [
  { value: 'TODO', name: 'To Do' },
  { value: 'IN_PROGRESS', name: 'In Progress' },
  { value: 'DONE', name: 'Done' },
  { value: 'COMPLETED', name: 'Completed' },
  { value: 'FULFILLED', name: 'Fulfilled' }
]

export const orderNotifications = [
  { value: 'NONE', name: 'Neither Email nor SMS' },
  { value: 'EMAIL', name: 'Email Only' },
  { value: 'SMS', name: 'SMS Only' },
  { value: 'ALL', name: 'Both Email and SMS' }
]

export const levelupCampaignTypes = [
  { value: '', name: 'none selected' },
  { value: 'BASIC_V1', name: 'Basic' },
  { value: 'ITEM_BASED_LOYALTY_V1', name: 'Item Based' },
  { value: 'SPEND_BASED_LOYALTY_V1', name: 'Spend Based' },
  { value: 'VISIT_BASED_LOYALTY_V1', name: 'Visit Based' }
]

export const transitionType = [
  { value: 'SLIDE', name: 'Slide' },
  { value: 'FADE', name: 'Fade' }
]

export const verticalAlignment = [
  { value: 'TOP', name: 'Top' },
  { value: 'CENTER', name: 'Center' },
  { value: 'BOTTOM', name: 'Bottom' }
]

export const horizontalAlignment = [
  { value: 'LEFT', name: 'Left' },
  { value: 'CENTER', name: 'Center' },
  { value: 'RIGHT', name: 'Right' }
]

export const arrowsVerticalAlignment = [
  { value: 'TOP', name: 'Top' },
  { value: 'BOTTOM', name: 'Bottom' }
]

export const arrowsHorizontalAlignment = [
  { value: 'LEFT', name: 'Left' },
  { value: 'RIGHT', name: 'Right' }
]

export const genderType = [
  { value: 'DECLINED', name: 'Declined' },
  { value: 'MALE', name: 'Male' },
  { value: 'FEMALE', name: 'Female' }
]

export const announcementPages = [
  { value: 'HOME', name: 'Home' },
  { value: 'MENU', name: 'Menu' },
  { value: 'CATERING', name: 'Catering' },
  { value: 'ABOUT', name: 'About' },
  { value: 'GUEST', name: 'Guest' },
  { value: 'ACCOUNT', name: 'Account' },
  { value: 'ORDER_TYPE', name: 'Order Type' },
  { value: 'CHECKOUT', name: 'Checkout' }
]

export const menuType = [
  { value: 'DEFAULT', name: 'Default' },
  { value: 'SCROLLABLE', name: 'Single Page, Scrollable' }
]

export const menuTypeApp = [
  { value: 'DEFAULT', name: 'Default' },
  { value: 'SCROLLABLE', name: 'Single Page, Scrollable' },
  { value: 'TABS', name: 'Tabs' }
]

export const menuCategoryType = [
  { value: 'HORIZONTAL', name: 'Horizontal, Small Image' },
  { value: 'VERTICAL', name: 'Vertical, Large Image' }
]

export const builderType = [
  { value: 'DEFAULT', name: 'Default' },
  { value: 'SIDEBAR', name: 'Sidebar' },
  { value: 'PAGE', name: 'Page (Content Sidebar)' },
  { value: 'PAGE_FULL', name: 'Page (Full Width)' }
]

export const categoriesType = [
  { value: 'LIST', name: 'List' },
  { value: 'CARDS', name: 'Cards' },
  { value: 'SQUARES', name: 'Squares' }
]

export const groupsTypeApp = [
  { value: 'SCROLLABLE', name: 'Single Page, Scrollable' },
  { value: 'TABS', name: 'Tabs' }
]

export const modifiersType = [
  { value: 'DEFAULT', name: 'Default (horiztonal layout)' },
  { value: 'CARDS', name: 'Cards (3 per row, 4 or more on desktop)' }
]

export const prepType = [
  { value: 'ASSEMBLY', name: 'Assembly' },
  { value: 'EXPO', name: 'Expo' }
]

export const menuItemDescriptionType = [
  { value: 'SHOW', name: 'Show full description' },
  { value: 'SHORT', name: 'Show short description' },
  { value: 'HIDE', name: 'Hide description' }
]
